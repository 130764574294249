import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { SvcReactionService, ReactionsParamsParent } from './svc-reaction.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CustomReactionImageAnimationSettings, ReactionType, ReactionTypeEnum } from './models/reaction-type.model';
import { SvcAppSettings, UserService} from 'projects/lib-shared-core/src/public-api';
import { Subscription } from 'rxjs';
import { Reacted } from './models/reacted.model';
import { ConnectedPosition, Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { animate, style, transition, trigger } from '@angular/animations';

const DEFAULT_ANIMATION_TIME = 2000;
const CUSTOM_ANIMATION_TIME = 1500;

@Component({
  selector: 'svc-reaction',
  templateUrl: './svc-reaction.component.html',
  styleUrls: ['./svc-reaction.component.scss'],
  animations: [
    trigger('animateContainerfadeOut', [
      transition(':leave', [
        animate('200ms', style({ opacity: 0, transform: 'scale(0.7)' })),
      ]),
    ]),
  ],
})
export class SvcReactionComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('attr.role') private role = 'button';
  @HostBinding('attr.tabIndex') private tabIndex = 0;
  @HostBinding('class.cursor-default') private get cursorDefault() {
    return this.disabled;
  }

  @ViewChild('reactionsTemplate') reactionsTemplate!: TemplateRef<any>;

  @Input() public registryUniqueId: number | string;
  @Input() public disabled: boolean = false;
  @Input() public position: 'left' | 'right' | 'above' | 'below' = 'above';
  @Input() public applicationId: string;
  @Input() public dontEmitFirstTime: boolean = false;
  @Input() public siteId: number;
  @Input() public parentApplicationId: string;
  @Input() public parentRegisterId: number | string;
  @Input() public parentOriginatorId: string;

  /*** @deprecated Use `position` instead */
  @Input() public positionX: 'left' | 'right' | 'center' = 'center';
  /*** @deprecated Use `position` instead */
  @Input() public positionY: 'top' | 'bottom' = 'top';

  @Output() public onLoadingChange = new EventEmitter<boolean>();
  @Output() public onReactionChange = new EventEmitter<{
    reactionType: ReactionType,
    wasAdded: boolean,
    wasRemoved: boolean,
    wasChanged: boolean,
    anticipated?: { targetReactionType: ReactionType, currentReactionType: ReactionType },
  }>();

  public loadingList: boolean = false;
  public loadingCurrent: boolean = false;
  public reactionsType: ReactionType[] = [];
  public currentReactionType: ReactionType;
  public hasReacted: boolean = false;

  private _requestSubscription: Subscription;
  private _overlayRef?: OverlayRef;
  private closeTimeout: any;
  private triggerListenerCleanup: (() => void)[] = [];
  private isMouseOverMenu = false;
  public showingAnimatedGif = false;
  public currentCustomReaction: CustomReactionImageAnimationSettings;
  public animatedGifParams = { left: 0, top: 0 };
  private animatedTimeout;

  constructor(
    private _appSettings: SvcAppSettings,
    private _service: SvcReactionService,
    private _userService: UserService,
    private overlay: Overlay,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
  ) {
  }

  public ngOnInit(): void {
    this._loadReaction(true);
  }

  public ngAfterViewInit(): void {
    const triggerElement = this.elementRef.nativeElement;
    const positionStrategy = this.getPositionStrategy();

    this._overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.triggerListenerCleanup.push(
      this.renderer.listen(triggerElement, 'mouseenter', () => {
        if(!this.showingAnimatedGif) {
          this.isMouseOverMenu = true;
          this.showMenu();
        }
      })
    );
    this.triggerListenerCleanup.push(
      this.renderer.listen(triggerElement, 'mouseleave', () => {
        this.isMouseOverMenu = false;
        this.scheduleMenuClose();
      })
    );

  }

  public ngOnDestroy(): void {
    this._requestSubscription?.unsubscribe();
    this.triggerListenerCleanup.forEach(cleanup => cleanup());
    clearTimeout(this.closeTimeout);
    this._overlayRef.dispose();
  }

  private _loadReaction(itIsFirstTime: boolean = false) {
    this.loadingList = true;
    const emit = !itIsFirstTime || !this.dontEmitFirstTime;
    emit && this.onLoadingChange.emit(true);
    this._requestSubscription = this._service.getReactionsType().pipe(
      tap((list: ReactionType[]) => {
        this.reactionsType = list;

        if (this.registryUniqueId) {
          const currentRequest = typeof this.registryUniqueId == 'number'
            ? this._service.getIdTotalReaction({
              applicationId: this.applicationId ?? this._appSettings.applicationId,
              registryUniqueId: this.registryUniqueId,
              siteId: this.siteId ?? this._userService.user.lastSiteId,
            })
            : this._service.getUidTotalReaction({
              applicationId: this.applicationId ?? this._appSettings.applicationId,
              registryUniqueUId: this.registryUniqueId,
              siteId: this.siteId ?? this._userService.user.lastSiteId,
            });
          this.loadingCurrent = true;
          emit && this.onLoadingChange.emit(true);
          this._requestSubscription = currentRequest.pipe(
            tap((totalReactions?: Reacted[]) => {
              const current = totalReactions?.find(x => x.userHasReacted);
              if (current) {
                const reactionType = this.reactionsType.find(x => x.reactionUniqueId === current.reactionUniqueId);
                this.changeReactionType(reactionType, { emit });
              }
              else {
                this.changeReactionType(null, { emit });
              }
            }),
            finalize(() => {
              this.loadingCurrent = false;
              emit && this.onLoadingChange.emit(false);
            }),
          ).subscribe();
        }
      }),
      finalize(() => this.loadingList = false),
    ).subscribe();
  }

  public refresh() {
    this._loadReaction();
  }

  public makeReactionByEnum(reactionTypeEnum: ReactionTypeEnum, options?: { dispatchLoading?: boolean, anticipateAction?: boolean }) {
    options = options ?? { anticipateAction: true, dispatchLoading: false };
    const reactionType = this.reactionsType.find(x => x.enumKey == reactionTypeEnum);
    if (reactionType) {
      this.makeReactionByClickEvent(reactionType);
    }
  }

  #getReactionsParamsParent(): ReactionsParamsParent {
    if (this.parentApplicationId && this.parentOriginatorId && this.parentRegisterId) {
      return typeof this.parentRegisterId === 'number' ?
        {
          parentApplicationId: this.parentApplicationId,
          parentOriginatorId: this.parentOriginatorId,
          parentRegisterId: this.parentRegisterId
        } :
        {
          parentApplicationId: this.parentApplicationId,
          parentOriginatorId: this.parentOriginatorId,
          parentRegisterUId: this.parentRegisterId
        };
    }

    return null;
  }

  public makeReactionByClickEvent(reactionType: ReactionType, event: MouseEvent = null, options = null): void {
    this.makeReaction(reactionType, options);

    this.showingAnimatedGif = false;

    const imageAdjustments = {
      halfImageWidth: this.currentCustomReaction.sizeWidth * 0.5,
      imageHeight: this.currentCustomReaction.sizeHeight,
    };

    if(event) {
      this.animatedGifParams = {
        left: Math.max(event.clientX - imageAdjustments.halfImageWidth, 0),
        top: Math.max(event.clientY - imageAdjustments.imageHeight, 0),
      };
    } else {
      this.animatedGifParams = {
        left: 0,
        top: 0
      };
    }

    if (!this.currentReactionType) {
      return;
    }

    this.showingAnimatedGif = true;
    this._overlayRef.dispose();
    clearTimeout(this.animatedTimeout);

    this.animatedTimeout = setTimeout(() => {
      this.showingAnimatedGif = false;
    }, this.currentCustomReaction.time);
  }

  public async makeReaction(reactionType: ReactionType, options?: { dispatchLoading?: boolean, anticipateAction?: boolean }) {
    options = options ?? { anticipateAction: true, dispatchLoading: false };
    if (reactionType.reactionUniqueId == this.currentReactionType?.reactionUniqueId) {
      this.removeCurrentReaction({
        dispatchLoading: options?.dispatchLoading,
        anticipateAction: options?.anticipateAction,
      });
    }
    else {
      this._requestSubscription?.unsubscribe();
      const currentRactionType = this.currentReactionType;
      if (options?.anticipateAction ?? false) {
        this.hideMenu();
        this.changeReactionType(reactionType, { wasAticipated: true });
      }
      if (currentRactionType) {
        try {
          await this.removeCurrentReaction({
            current: currentRactionType,
            dispatchChange: false,
            dispatchLoading: options?.dispatchLoading,
          });
        }
        catch {
          if (options?.anticipateAction ?? false) {
            this.changeReactionType(currentRactionType, { wasAticipated: true });
          }
          return;
        }
      }
      this.loadingCurrent = true;
      if (options?.dispatchLoading ?? true) {
        this.onLoadingChange.emit(true);
      }
      const parent = this.#getReactionsParamsParent();
      
      const request = typeof this.registryUniqueId == 'number'
        ? this._service.reactionId({
          siteId: this.siteId ?? this._userService.user.lastSiteId,
          applicationId: this.applicationId ?? this._appSettings.applicationId,
          reactionUniqueId: reactionType.reactionUniqueId,
          registryUniqueId: this.registryUniqueId,
          ...(parent && { parent })
        })
        : this._service.reactionUid({
          siteId: this.siteId ?? this._userService.user.lastSiteId,
          applicationId: this.applicationId ?? this._appSettings.applicationId,
          reactionUniqueId: reactionType.reactionUniqueId,
          registryUniqueUId: this.registryUniqueId,
          ...(parent && { parent })
        });
      this._requestSubscription = request.pipe(
        tap(() => {
          if (!(options?.anticipateAction ?? false)) {
            this.changeReactionType(reactionType);
          }
        }),
        catchError((error) => {
          if (!(options?.anticipateAction ?? false)) {
            this.changeReactionType(currentRactionType);
          }
          return error;
        }),
        finalize(() => {
          this._requestSubscription = null;
          this.loadingCurrent = false;
          this.hideMenu();
          if (options?.dispatchLoading ?? true) {
            this.onLoadingChange.emit(false);
          }
        }),
      ).subscribe();
    }
  }

  public removeCurrentReaction(options?: { current?: ReactionType, dispatchChange?: boolean, dispatchLoading?: boolean, anticipateAction?: boolean }) {
    options = options ?? { anticipateAction: true, dispatchLoading: false };
    const currentRactionType = ('current' in options) ? options.current : this.currentReactionType;
    this._requestSubscription?.unsubscribe();
    return new Promise<void>((resolve, reject) => {
      this.loadingCurrent = true;
      if ((options?.dispatchChange ?? true) && (options?.anticipateAction ?? false)) {
        this.hideMenu();
        this.changeReactionType(null, { wasAticipated: true });
      }
      if (options?.dispatchLoading ?? true) {
        this.onLoadingChange.emit(true);
      }
      const request = typeof this.registryUniqueId == 'number'
        ? this._service.removeIdReaction({
          siteId: this.siteId ?? this._userService.user.lastSiteId,
          applicationId: this.applicationId ?? this._appSettings.applicationId,
          reactionUniqueId: currentRactionType.reactionUniqueId,
          registryUniqueId: this.registryUniqueId,
        })
        : this._service.removeUidReaction({
          siteId: this.siteId ?? this._userService.user.lastSiteId,
          applicationId: this.applicationId ?? this._appSettings.applicationId,
          reactionUniqueId: currentRactionType.reactionUniqueId,
          registryUniqueUId: this.registryUniqueId,
        });
      this._requestSubscription = request.pipe(
        tap(() => {
          if ((options?.dispatchChange ?? true) && !(options?.anticipateAction ?? false)) {
            this.changeReactionType(null);
          }
          resolve();
        }),
        catchError((error) => {
          if ((options?.dispatchChange ?? true)) {
            this.changeReactionType(currentRactionType, { wasAticipated: options?.anticipateAction ?? false });
          }
          reject(error);
          return error;
        }),
        finalize(() => {
          this._requestSubscription = null;
          this.loadingCurrent = false;
					this.hideMenu();
          if (options?.dispatchLoading ?? true) {
            this.onLoadingChange.emit(false);
          }
        }),
      ).subscribe();
    });
  }

  private setCustomReactionGifData(reactionType: ReactionType): void {
    const settings = reactionType?.customReactionImageAnimationSettings ?? <CustomReactionImageAnimationSettings>{};
    if (settings.type === 'CUSTOM') {
      this.currentCustomReaction = <CustomReactionImageAnimationSettings>{
        ...(settings ?? {}),
        time: CUSTOM_ANIMATION_TIME,
        animationOriginX: `${settings.animationOriginX}px`,
        animationOriginY: `${settings.animationOriginY}px`,
      };
    } else if (settings.type === 'DEFAULT') {
      this.currentCustomReaction = {
        animationOriginX: '50%',
        animationOriginY: '50%',
        imageUrl: reactionType.animatedImageUrl,
        scale: 0.16,
        sizeHeight: 150,
        sizeWidth: 150,
        time: DEFAULT_ANIMATION_TIME,
        type: 'DEFAULT',
      };
    }
    if (this.currentCustomReaction) {
      this.currentCustomReaction.opacity = '0';
      this.currentCustomReaction.fullScale = `scale(${this.currentCustomReaction.scale})`;
      setTimeout(() => {
        this.currentCustomReaction.opacity = `1`;
        this.currentCustomReaction.fullScale = `scale(1)`;
      });
    }
  }

  private changeReactionType(reactionType: ReactionType, options?: { emit?: boolean, wasAticipated?: boolean }) {
    const currentReactionType = this.currentReactionType;
    this.currentReactionType = reactionType;
    this.setCustomReactionGifData(reactionType)
    this.hasReacted = !!(reactionType);
    (options?.emit ?? true) && this.onReactionChange.emit({
      reactionType,
      wasAdded: !(currentReactionType),
      wasRemoved: !(reactionType),
      wasChanged: reactionType?.enumKey != currentReactionType?.enumKey,
      anticipated: (options?.wasAticipated ?? false)
        ? {
          targetReactionType: reactionType,
          currentReactionType: currentReactionType,
        }
        : undefined
    });
  }

  private getPositionStrategy(): PositionStrategy {
    let connectedPosition: ConnectedPosition;

    if (['below', 'above'].includes(this.position)) {
      connectedPosition = {
        originX: 'center',
        originY: this.position == 'below' ? 'bottom' : 'top',
        overlayX: 'center',
        overlayY: this.position == 'below' ? 'top' : 'bottom',
        offsetY: this.position == 'above' ? -5 : 5,
      };
    }
    else if (['left', 'right'].includes(this.position)) {
      connectedPosition = {
        originX: this.position == 'left' ? 'start' : 'end',
        originY: 'center',
        overlayX: this.position == 'left' ? 'end' : 'start',
        overlayY: 'center',
        offsetX: this.position == 'left' ? -5 : 5,
      };
    }
    return this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        connectedPosition,
      ]);
  }

  private showMenu(): void {
    clearTimeout(this.closeTimeout);

    if (!this._overlayRef.hasAttached()) {
      const triggerElement = this.elementRef.nativeElement;
      const positionStrategy = this.getPositionStrategy();

      this._overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.close(),
      });
    }

    if (this._overlayRef.hasAttached()) return;
    this._overlayRef.attach(new TemplatePortal(this.reactionsTemplate, this.viewContainerRef));
    const overlayElement = this._overlayRef.overlayElement;

    this.triggerListenerCleanup.push(
      this.renderer.listen(overlayElement, 'mouseenter', () => {
        this.isMouseOverMenu = true;
        this.cancelMenuClose();
      })
    );
    this.triggerListenerCleanup.push(
      this.renderer.listen(overlayElement, 'mouseleave', () => {
        this.isMouseOverMenu = false;
        this.scheduleMenuClose();
      })
    );
  }

  private hideMenu(): void {
    this._overlayRef.detach();
  }

  private scheduleMenuClose(): void {
    this.closeTimeout = setTimeout(() => {
      if (!this.isMouseOverMenu)
        this.hideMenu();
    }, 200);
  }

  private cancelMenuClose(): void {
    clearTimeout(this.closeTimeout);
  }
}
