import { Component } from '@angular/core';
import { StaticApplicationId } from '../../../../../lib-shared-core/src/lib/Constants/static-application-id.enum';
import { SvcOnlyofficeModule } from '../../../../../lib-shared-feature/src/lib/general/svc-onlyoffice';

@Component({
  selector: 'app-onlyoffice',
  standalone: true,
  imports: [
    SvcOnlyofficeModule
  ],
  template: `
    <svc-onlyoffice
      [applicationId]="lppId"
    ></svc-onlyoffice>
  `,
  styleUrl: './onlyoffice.component.scss'
})
export class OnlyofficeComponent {

  lppId = StaticApplicationId.lpp;

}
