<ng-container *screenSizes="let size">
	<!-- JUST FROM POST ITEM AND (MOBILE OR LAST_INTERACTION) -->
	<div *ngIf="fromPostItem && (size.isXS || post?.lastInteraction)" class="flex items-center justify-end gap-1" [ngClass]="{ 'px-1 -mx-2.5 -mt-0.5': size.isBelowMD, 'px-2 -mx-4 -mt-3 pb-0.5': size.isAboveSM, 'border-b mb-2': post?.lastInteraction }">
		<div *ngIf="post?.lastInteraction" class="flex-auto flex items-center gap-2 pl-0.5">
      <svc-smart-user-avatar #svcAvatar [ids]="post.lastInteraction.userId" size="24"></svc-smart-user-avatar>
      <div class="flex gap-2" *ngIf="!svcAvatar.isLoading">
        <span class="font-bold">{{ svcAvatar?.avatar?.name }}</span>
        <span>{{ post.lastInteraction.interactionDescription | transloco }}</span>
      </div>
			<div class="flex gap-2" *ngIf="svcAvatar.isLoading">
				<ngx-skeleton-loader [theme]="{'margin-bottom': 0, width: '180px', height: '20px'}"></ngx-skeleton-loader>
				<ngx-skeleton-loader [theme]="{'margin-bottom': 0, width: '180px', height: '20px'}"></ngx-skeleton-loader>
			</div>
		</div>
		<ng-container [ngTemplateOutlet]="floatMenuButtons"></ng-container>
	</div>

	<div class="flex-auto flex flex-row items-center" [ngClass]="{ 'gap-3': fromPostItem, 'gap-2': !fromPostItem, 'px-4 py-2 border-b': fromPostModal && size.isXS }">
		<svc-smart-user-avatar #userAvatar [size]="fromPostModal && size.isXS ? 24 : avatarSize" [ids]="post.originator?.originatorId"></svc-smart-user-avatar>

		<!-- JUST IN POST ITEM -->
		<ng-container *ngIf="fromPostItem">
			<div class="flex flex-col">
				<div class="flex flex-col" *ngIf="userAvatar?.isLoading ?? true">
					<div>
						<ngx-skeleton-loader [theme]="{'margin-bottom': 0, width: '220px', height: '15px'}"></ngx-skeleton-loader>
					</div>
					<div>
						<ngx-skeleton-loader [theme]="{'margin-bottom': 0, width: '220px', height: '15px'}"></ngx-skeleton-loader>
					</div>
				</div>
				<ng-container *ngIf="!(userAvatar?.isLoading ?? true)"
					[ngTemplateOutlet]="userName"
					[ngTemplateOutletContext]="{ name: userAvatar.info?.name, role: userAvatar.info?.role }"
				></ng-container>
				<div *ngIf="!(userAvatar?.isLoading ?? true)" class="flex text-md items-center" [class.mt-1]="size.isXS">
					<ng-container [ngTemplateOutlet]="timeSpan"></ng-container>
					<ng-container [ngTemplateOutlet]="siteName"></ng-container>
				</div>
			</div>
			<ng-container *ngIf="!size.isXS && !post.lastInteraction" [ngTemplateOutlet]="floatMenuButtons"></ng-container>
		</ng-container>

		<!-- JUST IN POST MODAL -->
		<ng-container *ngIf="fromPostModal">
			<div class="flex flex-col truncate gap-1">
				<ng-container *ngIf="!(userAvatar?.isLoading ?? true)"
					[ngTemplateOutlet]="userName"
					[ngTemplateOutletContext]="{ name: userAvatar.info?.name, role: !size.isXS ? userAvatar.info?.role : null }"
				></ng-container>
				<div *ngIf="!size.isXS && !(userAvatar?.isLoading ?? true)" class="flex text-md">
					<ng-container [ngTemplateOutlet]="timeSpan"></ng-container>
					<ng-container [ngTemplateOutlet]="siteName"></ng-container>
				</div>
			</div>
			<div class="flex-auto flex items-center justify-end self-start">
				<ng-container *ngIf="!size.isXS" [ngTemplateOutlet]="floatMenuButtons"></ng-container>
				<ng-container [ngTemplateOutlet]="closeButton"></ng-container>
			</div>
		</ng-container>
	</div>

	<!-- JUST IN POST MODAL AND MOBILE -->
	<div *ngIf="fromPostModal && size.isXS" class="flex items-center pl-2.5 pr-2 pt-2">
		<div class="flex-auto flex text-sm">
			<ng-container [ngTemplateOutlet]="timeSpan"></ng-container>
			<ng-container [ngTemplateOutlet]="siteName"></ng-container>
		</div>
		<ng-container [ngTemplateOutlet]="floatMenuButtons"></ng-container>
	</div>

	<!-- JUST IN MOBILE -->
	<div class="flex justify-between pt-2 empty:pt-0" [class.px-2]="fromPostModal">
		<ng-container [ngTemplateOutlet]="applicationName"></ng-container>
	</div>

	<!-- USER NAME -->
	<ng-template #userName let-name="name" let-role="role">
		<div class="flex leading-tight" [class.flex-col]="size.isXS" [ngClass]="{ 'truncate': fromPostModal && size.isXS }">
			<span class="font-bold" [ngClass]="{ 'text-lg': fromPostModal && size.isXS }">
				{{ name }}
			</span>
			<ng-container *ngIf="role">
				<span *ngIf="!size.isXS" class="px-1">|</span>
				<span>{{ role }}</span>
			</ng-container>
		</div>
	</ng-template>

	<!-- TIMESPAN -->
	<ng-template #timeSpan>
    <div class="leading-tight">
			<span class="text-md">
				<span *ngIf="post.isUpdated">{{'Editado' | transloco}} </span>
				<span>{{post.isUpdated ? (post.lastUpdateDate | timeAgo | lowercase) : (post.inclusionDate | timeAgo)}}</span>
			</span>
    </div>
	</ng-template>

	<!-- SITE NAME -->
	<ng-template #siteName>
		<div *ngIf="post.site?.siteName" class="flex" [class.text-sm]="fromPostModal && size.isXS">
			<div class="px-3 leading-tight">•</div>
			<div class="flex-auto leading-tight">
				{{ post.site?.siteName }}
			</div>
		</div>
	</ng-template>

	<!-- FLOAT MENU BUTTONS -->
	<ng-template #floatMenuButtons>
		<div class="flex-auto flex flex-row justify-end self-start">

			<!-- FAVORITE BUTTON -->
			<svc-bookmark-button *ngIf="showFavorite"
				matRipple
				class="p-1 rounded-md"
				[referenceId]="post?.applicationRegistry ? post.applicationRegistry.applicationRegistryReferenceId : post?.id"
				[applicationId]="post?.applicationRegistry ? post.applicationRegistry.applicationId : applicationId"
				[siteId]="post?.applicationRegistry ? post.siteId : null"
				(onValueChanged)="onBookmarkChanged($event)"
				(onToggleError)="onBookmarkError($event)"
				[id]="'post-favorite-'+post.id"
			></svc-bookmark-button>

			<!-- EDITING BUTTON -->
			<button
        *ngIf="post.canEdit || post.canRemove"
        class="icon-only h-8 w-8 min-w-8 min-h-8 text-default"
        mat-button
        type="button"
        [matMenuTriggerFor]="userActions"
        [id]="'post-actions-'+post.id"
      >
				<mat-icon class="icon-size-5 text-current" svgIcon="fontawesome_solid:ellipsis"></mat-icon>
			</button>
			<mat-menu class="bg-white" xPosition="before" #userActions="matMenu">
				<button *ngIf="post.canEdit" mat-menu-item type="button" (click)="editPost()" [id]="'post-edit-'+post.id">
					<mat-icon svgIcon="heroicons_solid:pencil-square"></mat-icon>
					<span>{{ 'Editar' | transloco }}</span>
				</button>

				<button *ngIf="post.canRemove" mat-menu-item type="button" (click)="removePost()" [id]="'post-remove-'+post.id">
					<mat-icon [svgIcon]="'heroicons_solid:trash'"></mat-icon>
					<span>{{ 'Excluir' | transloco }}</span>
				</button>
			</mat-menu>
		</div>
	</ng-template>

	<!-- CLOSE BUTTON -->
	<ng-template #closeButton>
		<button class="icon-only self-start text-default" mat-button type="button" (click)="closed.emit()" [ngClass]="fromPostModal && size.isXS ? 'h-6 w-6 min-w-6 min-h-6' : 'h-8 w-8 min-w-8 min-h-8'" [id]="'post-close-'+post.id">
			<mat-icon class="text-current" svgIcon="heroicons_solid:x-mark"></mat-icon>
		</button>
	</ng-template>

	<!-- APPLICATION NAME -->
	<ng-template #applicationName>
		<span *ngIf="!(userAvatar?.isLoading ?? true) && post?.applicationTagName" class="text-center rounded p-1 text-sm whitespace-nowrap font-medium bg-gray-200 text-default inline-flex items-center justify-center h-6">
			{{ post.applicationTagName | transloco | uppercase }}
		</span>
    <span *ngIf="!(userAvatar?.isLoading ?? true) && post?.applicationRegistry?.savings" class="flex items-center justify-center gap-2 p-1 text-sm font-medium text-default h-6">
      <mat-icon class="icon-size-4 text-default" svgIcon="svc_solid:light-bulb"></mat-icon>
      {{ currencyAcronym }} {{ post.applicationRegistry.savings | formatNumberDefault }}
		</span>
	</ng-template>
</ng-container>
