<div
  class="progress flex flex-row bg-slate-50 border rounded-full md:min-w-0"
  (mouseenter)="eventMouseEnter()"
  (mouseleave)="eventMouseLeave()"
>
  <ng-container
    *ngIf="progressData"

  >
    <ng-container
      *ngFor="let item of filteredData; let first = first; let last = last"
    >
      <div
        class="flex flex-row justify-center items-center"
        [class.rounded-l-full]="first"
        [class.rounded-r-full]="last"
        [style.background-color]="item.color"
        [style.min-width.px]="item.minWidth"
        [style.width.%]="item.width"
        (mouseenter)="eventMouseEnterColumn(item)"
        (mouseleave)="eventMouseLeaveColumn(item)"
        (click)="eventMouseClickColumn(item)"
      >
        <div class="text-white text-xs font-semibold">{{item.value}}</div>
      </div>
    </ng-container>
  </ng-container>
</div>
