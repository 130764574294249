import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {changeSiteCallback, HttpErrorService, signinLegacyApi, updateIntegrationAPITokenCookie, updateLastSiteIdCookie} from 'projects/lib-shared-common/src/public-api';
import { Observable, Subject, debounceTime, forkJoin, from, map, of, switchMap, takeUntil, tap, timeout } from 'rxjs';
import { SitesService } from './services/sites.service';
import { Site } from './services/sites.types';
import { HttpClient} from "@angular/common/http";
import { AuthService } from '../../auth/auth.service';
import { EnvironmentInfo } from '../../auth/model/environment.info.model';
import { environment } from 'projects/environments/environment';
import { promises } from 'dns';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { SvcDialogService } from 'projects/lib-shared-component/src/public-api';
import { error } from 'console';

@Component({
  selector: 'sites',
  templateUrl: './sites.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'sites',
})
export class SitesComponent implements OnInit, OnDestroy {
  @Input() renderAsMenuItemContent: boolean = false;
  @Input() renderAsSubMenuItemContent: boolean = false;

  sites: Site[];
  active: Site;

  private isLoading: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  siteList: Site[];

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _sitesService: SitesService,
    private _authService: AuthService,
    private _httpClient: HttpClient,
    private _translocoService: TranslocoService,
    private _dialogService: SvcDialogService,
    private _httpErrorService: HttpErrorService,
    private _router: Router,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.listenGetSites();
    this._sitesService.getAll().subscribe();
  }

  private listenGetSites() {
    this._sitesService.sites$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((sites: Site[]) => {
        this.siteList = sites;
        this.sites = sites.filter((x) => !x.isSiteCurrent);
        this.active = sites.find((x) => x.isSiteCurrent);
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the active site
   *
   * @param id
   */
  async changeActiveSite(id: number) {
    if (this.isLoading) return;

    this.isLoading = true;
    forkJoin([
      this._sitesService.update(id),
      environment.isLocalhost ? of(true) : changeSiteCallback(this._httpClient, id),
    ]).subscribe({
      next:([done]) => {
        if (done.isSucess){
           updateLastSiteIdCookie(id);

           forkJoin({
            legacy : this.signInCurrentUserLegacy(),
            revamp : this._authService.signInUsingRefreshToken(false),
           }).subscribe({
            next: async ({ legacy, revamp }) => {
              if (legacy && revamp)
                 window.location.reload();
              else
                 this.requestSignIn();

              this.isLoading = false;
            },
            error: (err) => {
              this.isLoading = false;
              this.requestSignIn();
            }
          });
        }else {
          throw new Error();
        }
      },
      error: (err) => {
        this.isLoading = false;
        this._httpErrorService.showErrorInToast(err);
      }
    });
  }

  private signInCurrentUserLegacy(): Observable<boolean> {
    if (environment.isLocalhost) {
      updateIntegrationAPITokenCookie('EMPTY_VALUE');
    }
    else {
      return from(this._authService.prepareCognitoFromEnvironment()).pipe(
        switchMap((user) => {
          if (!user) return of(false);
          return signinLegacyApi(this._httpClient, user.baseUrl, user.ssoId).pipe(
            map((res) => {
              updateIntegrationAPITokenCookie(res.token);
              return true;
            })
          );
        }),
      );
    }
    return of(true);
  }

  private async requestSignIn() {
    const message = this._translocoService.translate('Ocorreu um problema e você precisará se autenticar novamente.');
    this._dialogService.openWarning(message).subscribe(() => {
      this._router.navigate(['/sign-out']);
    })
  }

}




