import { SvcMediaCarouselModule } from 'projects/lib-shared-component/src/lib/svc-media-carousel/svc-media-carousel.module';
import { NgModule } from '@angular/core';
import { TranslocoModule } from "@ngneat/transloco";
import { LibSharedComponentModule, SvcButtonsModule, SvcDialogsModule, SvcLockButtonModule, SvcStatusBadgeModule } from 'projects/lib-shared-component/src/public-api';
import { CommunicationCardComponent } from './communication-card.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcTotalsStatusModule } from '../../general/svc-totals-status/svc-totals-status.module';
import { SvcBookmarkButtonModule } from 'projects/lib-shared-feature/src/public-api';

@NgModule({
  declarations: [
    CommunicationCardComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcDialogsModule,
    SvcButtonsModule,
    SvcTotalsStatusModule,
    SvcStatusBadgeModule,
    SvcLockButtonModule,
    SvcBookmarkButtonModule,
    TranslocoModule,
    SvcMediaCarouselModule
  ],
  exports: [
    CommunicationCardComponent
  ],
  providers: [
  ]
})
export class CommunicationCardModule { }
