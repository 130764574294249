export enum SvcPivotTableFilterOrderBy {
  'year' = 0,
  'requestDateFormatted' = 1,
  'fileName' = 2
}

export enum SvcPivotTableFilterOrderDirecton {
  Asc = 0,
  Desc = 1,
}
