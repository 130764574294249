<div class="svc-search-actionplan">
  <svc-breadcrumb class="py-1 px-4 sm:py-2 sm:px-6">
    <svc-breadcrumb-item type="HOME">
      Home
    </svc-breadcrumb-item>

    <svc-breadcrumb-item *ngIf="moduleName" link="/">
      {{ moduleName | transloco }}
    </svc-breadcrumb-item>

    <svc-breadcrumb-item [active]="true">
      {{ 'Busca de planos de ação' | transloco }}
    </svc-breadcrumb-item>
  </svc-breadcrumb>

  <div class="w-full h-full" #iframeContent>
    <iframe class="w-full h-full" id="legacy-support-search-actionplan" src="assets/legacy-support/search-actionplan/main.html" (load)="showActionPlanSearch()">
    </iframe>
  </div>

  <router-outlet></router-outlet>
</div>
