<div class="flex relative">
  <quill-editor
    #editor
    class="mentions-editor w-full"
    [class.readonly]="readOnly"
    [class.cursor-force]="cursorForce"
    [class.small-size]="parent.smallSize"
    classes="mentions-container"
    [modules]="modules"
    [(ngModel)]="value"
    [readOnly]="readOnly || loading"
    [placeholder]="!readOnly ? placeholder : null"
    (ngModelChange)="onChange(filterValue(value))"
    (onContentChanged)="onContentChanged($event)"
    (onEditorCreated)="onEditorCreated($event)"
  ></quill-editor>
  <button matSuffix mat-mini-fab color="primary" [class.small-button]="parent.smallSize" class="absolute right-2.5 mat-elevation-z1 no-hover-shadow" [ngClass]="{'top-2.5': parent.smallSize, 'top-2': !parent.smallSize }" [disabled]="loading || buttonDisabled" (click)="!loading && !buttonDisabled && onButtonClicked.emit()">
    <div *ngIf="!loading" class="text-white">
      <mat-icon class="text-current icon-size-5" svgIcon="fontawesome_solid:paper-plane"></mat-icon>
    </div>
    <mat-spinner *ngIf="loading" diameter="22"></mat-spinner>
  </button>
</div>
