import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { SvcHttpClient } from "projects/lib-shared-common/src/public-api";
import { Observable, delay, map, of } from "rxjs";
import { SvcTranscriptionStatusResponse } from "../models/transcription-status-response";
import { SvcSubtitleResponse } from "../models/subtitle-response.model";

interface GetTranscriptionOrderStatusRequest {
  featureTypeId: string;
  referenceSiteId: number;

  /**
   * Video key in S3 storage.  
   * If this property is used, `urlS3` is not required.
   */
  keyMediaS3?: string;

  /**
   * Video URL in S3 storage.
   */
  urlS3?: string;
}

interface GetSubtitle {
  /**
   * User's site ID.
   */
  referenceSiteId: number;

  /**
   * Video key in S3 storage.  
   * If this property is used, `urlS3` is not required.
   */
  keyMediaS3?: string;

  /**
   * Video URL in S3 storage.
   */
  urlS3?: string;

  /**
   * Associated record ID.
   */
  registryId?: number;

  /**
   * Associated record UID.
   */
  registryUId?: string;

  /**
   * URL to open in email the related record when the user  
   * is notified that the subtitles are ready.
   */
  notificationCallbackUrl: string;

  /**
   * Registry ID associated by site.
   */
  registryCode?: string;
}

interface GetSubtitleByReferenceRequest extends GetSubtitle {
  /**
   * Static ID based on the video URL from modules like LPP, RCA, Posts and others.
   */
  featureTypeId: string;

  /**
   * User's language ID.
   */
  languageId: number;
}

interface RemoveSubtitleByReferenceRequest {
  subtitleFeatureTypeId: string;
  referenceId?: number;
  referenceUId?: string;
  referenceSiteId: number;
}

interface CreateTranscriptionOrderByReferenceRequest extends GetSubtitle{
  /**
   * Static ID based on the video URL from modules like LPP, RCA, Posts and others.
   */
  subtitleFeatureTypeId: string;
}

@Injectable()
export class SvcSubtitleService extends SvcHttpClient {
  constructor(
    protected _appConfig: AppEnvironmentConfig,
    protected _httpClient: HttpClient,
  ) {
    super(
      _appConfig.APIs.apiUrlSubtitle,
      _httpClient
    );
  }

  public createTranscription(params: CreateTranscriptionOrderByReferenceRequest): Observable<number> {
    return this.post('/transcriptions', params);
  }

  public getTranscriptionStatus(params: GetTranscriptionOrderStatusRequest): Observable<SvcTranscriptionStatusResponse> {
    return this.get('/transcriptions/status',
      {
        params: {
          ...params
        }
      });
  }

  public getSubtitle(params: GetSubtitleByReferenceRequest): Observable<SvcSubtitleResponse> {
    return this.get('/subtitles',
      {
        params: {
          ...params
        }
      });
  }

  public removeSubtitle(params: RemoveSubtitleByReferenceRequest): Observable<SvcSubtitleResponse> {
    return this.delete('/subtitles',
      {
        params: {
          ...params
        }
      });
  }
}