export enum ReactionTypeEnum {
	LIKE = 'LIKE',
	CELEBRATE = 'CELEBRATE',
	FUNNY = 'FUNNY',
	INSIGHTFUL = 'INSIGHTFUL',
	LOVE = 'LOVE',
}

export interface CustomReactionImageAnimationSettings {
	animationOriginX: number | string;
	animationOriginY: number | string;
	imageUrl: string;
	scale: number;
	sizeHeight: number;
	sizeWidth: number;
	type: string;
	fullScale?: string;
	opacity?: string;
	time?: number;
}

export interface ReactionType {
	reactionUniqueId: number;
	enumKey: ReactionTypeEnum;
	description: string;
	staticImageUrl: string;
	animatedImageUrl: string;
	active: boolean;
	createDate: string;
	customReactionImageAnimationSettings: CustomReactionImageAnimationSettings;
}
