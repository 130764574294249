import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { ModalInsightsDefault } from '../../models/modal-insights-default';
import { SvcModalViewCommunicationHighlighted } from './svc-modal-view-communication.interface';
import { ModalViewCommunicationComponent } from './modal-view-communication.component';
import { MatDialogRef, MatDialogState } from '@angular/material/dialog';

@Injectable()
export class CommunicationService extends ModalInsightsDefault<number[]> {

  public getCommunicationPreview(communicationId: number): Observable<any> {
    return this._httpClient.get<any>(`${this._appConfig.APIs.apiUrlCommunication}/Communication/${communicationId}/preview`);
  }

  public setAsRead(communicationId: number): Observable<void> {
    return this._httpClient.patch<void>(`${this._appConfig.APIs.apiUrlCommunication}/Communication/${communicationId}/read`, null);
  }

  public check(): Observable<boolean> {
    if (this.modal?.getState() === MatDialogState.OPEN) 
      return of(false);

    return this._httpClient.get<SvcModalViewCommunicationHighlighted[]>(`${this._appConfig.APIs.apiUrlCommunication}/Communication/highlighted`)
      .pipe(
        map(communications => {
          if (communications?.length) {
            this.model = communications.map(communication => communication.communicationId);
            return true;
          }
          return false;
        }),
        catchError(() => of(false))
      );
  }

  public showModal(): MatDialogRef<any> {
    if (this.modal?.getState() === MatDialogState.OPEN)
      return this.modal;
     
    return this._dialogService.open(ModalViewCommunicationComponent, {
      width: '100%',
      maxWidth: '800px',
      autoFocus: false,
      data: {
        id: this.model?.[0],
        communications: this.model
      }
    })
  }

  public saveOnClose(): Observable<boolean> {
    return of(true);
  }
}

