import { inject, Injectable } from '@angular/core';

import jsPDF from 'jspdf';
import autoTable, { CellHookData } from 'jspdf-autotable';
import { TranslocoService } from '@ngneat/transloco';

import { SvcExportFilePdfData } from './interfaces/svc-export-file-pdf.interface';
import { SettingsService } from 'projects/lib-shared-core/src/lib/features/settings/services/settings.service';
import { getCustomThemeColor } from 'projects/lib-shared-common/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class SvcExportFilePdfService {

  #settingsService = inject(SettingsService);
  #translocoService = inject(TranslocoService);


  #setPdfTitle(title: string, pdf: jsPDF): void {
    if (title) {
      pdf.setFont('helvetica', 'bold');
      pdf.text(this.#translocoService.translate(title) ?? '', 24, 10);
      pdf.setFont('helvetica', 'normal');
    }
  }

  #setCellStyleAccordion(cell: CellHookData, isAccordion: boolean): void {
    const rowData = cell?.row?.raw as any;
    if (rowData?.isAccordion && isAccordion)
      cell.cell.styles.fillColor = [240, 240, 240];
  }

  public exportToPdf(
    exportData: SvcExportFilePdfData,
    fileName: string,
    title: string,
    isAccordion?: boolean
  ): void {
    const pdf = new jsPDF('portrait');
    this.#setPdfTitle(title, pdf);

    autoTable(pdf, {

      columns: exportData.columns,
      body: exportData.rows,
      startY: title ? 14 : 10,
      styles: {
        fontSize: 9,
        valign: 'middle',
        halign: 'left',
      },
      theme: isAccordion ? 'plain' : 'striped',
      headStyles: {
        fillColor: getCustomThemeColor(this.#settingsService.theme$, 500),
        textColor: '#FFFFFF',
        fontStyle: 'bold',
        valign: 'middle',
        halign: 'left',
      },
      didParseCell: cell => this.#setCellStyleAccordion(cell, isAccordion),
    });
    const filename = this.#translocoService.translate(fileName || 'exportar');
    pdf.save(`${filename}.pdf`);
  }
}
