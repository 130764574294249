import { inject, Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { DateFormatPipe } from 'projects/lib-shared-common/src/lib/pipes/date-format.pipe';
import { FormatNumberDefaultPipe } from 'projects/lib-shared-common/src/lib/pipes/format-number.pipe';
import { SvcToastService } from 'projects/lib-shared-component/src/lib/svc-toast/svc-toast.service';
import { SvcExportFileType } from './interfaces/svc-export-file.interface';

@Injectable({
  providedIn: 'root',
})
export class SvcExportFileHelperService {

  #translocoService = inject(TranslocoService);
  #toastService = inject(SvcToastService);
  #errorMessage = this.#translocoService.translate('Não há dados a serem exportados');
  
  public getExportCellValueByType(value: any, fieldTypeExport: SvcExportFileType, datePipe: DateFormatPipe, formatNumber: FormatNumberDefaultPipe): string {
    const transformationValue = new Map<SvcExportFileType, () => string>([
      ['date', () => datePipe.transform(value)],
      ['translate', () => this.#translocoService.translate(value)],
      ['number', () => formatNumber.transform(value)],
      ['checkbox', () => this.#translocoService.translate(value ? 'Sim' : 'Não')]
    ]);

    return transformationValue.get(fieldTypeExport)?.() ?? value;
  }

  public showError(): void {
    this.#toastService.error(this.#errorMessage);
  }
}
