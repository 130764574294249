import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ISvcChartMonthData, ISvcChartMonthDefinitions, ISvcChartMonthInfo } from './interfaces/svc-chart-month.interface';
import { ECharts, EChartsOption } from "echarts";
import { TranslocoService } from '@ngneat/transloco';
import { SvcChartMonthHelper } from './svc-chart-month-helper';
import { SvcChartMonthFilterChange } from './svc-chart-month-filter/svc-chart-month-filter.component';

@Component({
  selector: 'svc-chart-month',
  templateUrl: './svc-chart-month.component.html',
  styleUrls: ['./svc-chart-month.component.scss'],
})
export class SvcChartMonthComponent implements OnChanges {
  instance: ECharts;

  @Input() height: string = '280px';
  @Input() chartInfo: ISvcChartMonthInfo = {} as ISvcChartMonthInfo;
  @Input() chartDefinitions: ISvcChartMonthDefinitions;
  @Output() chartClick: EventEmitter<any> = new EventEmitter<any>();

  public minDate: Date;
  public maxDate: Date;
  public rangeDateSelected: { start: Date, end: Date };

  chartOptions: Partial<any> = {
    tooltip: {
      borderWidth: 0,
      backgroundColor: '#334155',
      textStyle: {
        color: '#fff',
        fontSize: 12,
      },
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (chartInfo: any) => {
        const params = chartInfo.filter((param: any) => param.value != null && param.value > 0);
        if (params.length == 0) return null;

        const text = params.map((p: any) => (
          `<div class="flex flex-row space-x-3 items-center">
            <div class="grow">${p.marker}</div>
            <div>${p.value}</div>
          </div>`
        ));
        return `<div class="flex flex-col">
          <div>${params[0]?.axisValue}</div>
          <div class="flex flex-col gap-1">${text.join('')}</div>
        </div>`;
      },
    },
    legend: {
      orient: 'horizontal',
      left: 'left',
      top: 'bottom',
      icon: 'circle',
      padding: [0, 50],
      itemGap: 5,
      itemHeight: 10,
      itemWidth: 10,
      textStyle: {
        color: '#898989',
        fontSize: 11,
      },
    },
    grid: {
      top: '20',
      left: '10',
      right: '10',
      bottom: '30',
      containLabel: true,
    },
    series: [],
  };

  constructor(
    private _translocoService: TranslocoService,
  ) { }

  onChartInit(evt: ECharts) {
    this.instance = evt;
  }

  onChartClick(evt: any) {
    let index = evt.dataIndex;
    let values = this.chartDefinitions.seriesAndValues[0].values;
    for(let i = 0; i < evt.dataIndex; i++) {
      if (values[i].value === 0) {
        index--;
      }
    }
    this.chartClick.emit({
      name: evt.name,
      serieName: evt.seriesName,
      color: evt.color,
      value: evt.value,
      dataIndex: index,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chartDefinitions) {
      this.checkData();
      this.generateCategories();
      this.generateSeries();
      this.defineMinAndMaxDate();
      if (this.instance)
        this.instance.setOption(this.chartOptions);
    }
  }

  public onSelectedRangeChange(event: SvcChartMonthFilterChange) {
    this.generateCategories(event);
    this.generateSeries(event);
  }

  private checkData() {
    // Insert lacking months
    for (const serie of this.chartDefinitions.seriesAndValues) {
      for (let x = 0; x < serie.values.length; x++) {
        const curr = serie.values[x];
        if (x > 0) {
          const prev = serie.values[x - 1];
          let diffCurrPrev = curr.month - prev.month;
          if (![1, -11].includes(diffCurrPrev)) {
            if (diffCurrPrev > 0) {
              for (let y = 1; y < diffCurrPrev; y++) {
                serie.values.splice(x, 0, { month: prev.month + y, year: curr.year, value: 0 });
                x++;
              }
            }
            else {
              diffCurrPrev = diffCurrPrev + 11;
              do {
                serie.values.splice(x, 0, { month: diffCurrPrev + prev.month, year: prev.year, value: 0 });
                diffCurrPrev--;
              } while (diffCurrPrev > 0)
            }
          }
        }
      }
    }
  }

  private defineMinAndMaxDate() {
    const seriesAndValues = this.chartDefinitions.seriesAndValues;
    this.minDate = null;
    this.maxDate = null;
    if (seriesAndValues.length > 0 && seriesAndValues[0].values.length > 0) {
      const first = seriesAndValues[0].values[0];
      const last = seriesAndValues[0].values[seriesAndValues[0].values.length - 1];
      this.minDate = new Date(first.year, first.month - 1, 1);
      this.maxDate = new Date(last.year, last.month - 1, 1);
    }
    this.rangeDateSelected = {
      start: this.minDate,
      end: this.maxDate,
    };
  }

  private generateCategories(dateRangeFilter?: SvcChartMonthFilterChange) {
    const seriesAndValues = this.chartDefinitions.seriesAndValues;
    this.chartOptions = {
      ...this.chartOptions,
      xAxis: {
        type: 'category',
        data: seriesAndValues.length > 0 ? this._filterValues(seriesAndValues[0].values, dateRangeFilter).map((item) => {
          const month = SvcChartMonthHelper.getMonthName(item.month);
          return this._translocoService.translate(month)?.substring(0, 3) ?? '';
        }) : [],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: 11,
          color: '#898989',
          align: 'right',
          margin: 20
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          show: true,
        },
      },
    };
  }

  private generateSeries(dateRangeFilter?: SvcChartMonthFilterChange) {
    this.chartOptions = {
      ...this.chartOptions,
      series: this.chartDefinitions.seriesAndValues.map((item, i) => {
        let data = this._filterValues(item.values, dateRangeFilter);
        return {
          name: item.name,
          type: 'bar',
          stack: this.chartDefinitions.stacked ?? false ? 'stacked' : i.toString(),
          barMaxWidth: 35,
          barMinHeight: this.chartDefinitions.stacked ?? false ? 25 : 0,
          label: {
            rotate: 90,
            distance: 10,
            align: this.chartDefinitions.stacked ?? false ? 'left' : 'right',
            verticalAlign: 'middle',
            position: this.chartDefinitions.stacked ?? false ? 'insideBottom' : 'insideTop',
            fontSize: 11,
            show: true,
            color: '#ffffff',
          },
          emphasis: {
            focus: 'series',
          },
          itemStyle: {
            color: item.color,
            borderColor: 'transparent',
            borderWidth: 5,
            borderRadius: this.chartDefinitions.stacked ?? false ? [50] : [50, 50, 0, 0],
          },
          data: data.map((x) => x.value == 0 ? null : x.value),
        };
      })
    };
  }

  private _filterValues(values: ISvcChartMonthData[], dateRangeFilter?: SvcChartMonthFilterChange) {
    if (dateRangeFilter) {
      const startYear = dateRangeFilter.start.year;
      const startMonth = dateRangeFilter.start.month;
      const endYear = dateRangeFilter.end.year;
      const endMonth = dateRangeFilter.end.month;
      values = values.filter((x) => {
        return (x.year > startYear || (x.year == startYear && x.month >= startMonth)) &&
          (x.year < endYear || (x.year == endYear && x.month <= endMonth));
      });
    }
    return values;
  }
}
