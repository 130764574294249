<section class="leading-tight text-inherit">
	<div #ngContent class="leading-tight text-inherit" [ngClass]="{ 'truncate': !noTruncate && !customFilter, 'cursor-pointer': !noSort }" (click)="toggleSort()" [matTooltip]="(!noTruncate && ngContent.scrollWidth > ngContent.clientWidth) ? columnName : null" [matTooltipShowDelay]="1000" matTooltipPosition="above">
		<ng-content></ng-content>
	</div>
	<div *ngIf="!noSort" class="svc-data-col-sort text-inherit" [ngClass]="{ 'sort-none': !isSorting, 'sort-active': isSorting }" (click)="toggleSort()">
		<mat-icon *ngIf="!isSorting" class="icon-size-3 text-current" svgIcon="heroicons_solid:chevron-down"/>
		<mat-icon *ngIf="isSorting && isSortAsc" class="icon-size-3 text-current" svgIcon="heroicons_solid:chevron-down"/>
		<mat-icon *ngIf="isSorting && !isSortAsc" class="icon-size-3 text-current" svgIcon="heroicons_solid:chevron-up"/>
	</div>
</section>

<div *ngIf="canFiltering && parent?.filterCtrls">
	<svc-data-filter *ngFor="let field of fields"
		[frmControl]="parent?.filterCtrls[field]"
		[type]="filter"
		[placeholder]="placeholder"
		[readOnly]="readOnly"
		[minDate]="minDate"
		[maxDate]="maxDate"
		[dateFormat]="dateFormat"
		[dateSeparator]="dateSeparator"
		[optionAllLabel]="optionAllLabel"
		[options]="options"
		(onChange)="onFiltering()"
		(onRender)="onFilterRender()">
	</svc-data-filter>
</div>
