<div class="flex md:col-span-2 flex-wrap">
  <mat-form-field *ngIf="!readonly" class="w-full flex-auto svc-mat-no-subscript svc-mat-dense" appearance="fill">
    <mat-icon *ngIf="icon" matPrefix [svgIcon]="icon"></mat-icon>
    <mat-label *ngIf="label" class="inline-flex input-label-size input-label-weight text-default">
      <div class="flex items-center">
        <mat-icon *ngIf="label?.icon" class="icon-size-3 text-current mr-1" [svgIcon]="label?.icon"></mat-icon>
        {{ label?.text ?? label }}
        <mat-icon *ngIf="info" class="icon-size-5 ml-1" svgIcon="heroicons_solid:information-circle" [style.color]="'#969191'" [matTooltip]="info" matTooltipPosition="right"></mat-icon>
      </div>
    </mat-label>
    <input [formControl]="control" [type]="type" matInput [maxlength]="maxLength" [placeholder]="placeholder" [id]="inputId" [autocomplete]="autocomplete" [mask]="mask" [dropSpecialCharacters]="dropSpecialCharacters" (blur)="onBlur.emit()" />
    <button *ngIf="isObscure" mat-icon-button type="button" (click)="type = type === 'password' ? 'text' : 'password'" matSuffix>
      <mat-icon class="icon-size-5 text-default" [svgIcon]="type === 'password' ? 'heroicons_solid:eye' : 'heroicons_solid:eye-slash'"></mat-icon>
    </button>
  </mat-form-field>
  <svc-validation-message *ngIf="!readonly" [control]="control"></svc-validation-message>
  <ng-container *ngIf="readonly">
    <div class="flex flex-col flex-auto">
      <div class="flex flex-row items-center input-label-size input-label-weight text-default">
        <mat-icon *ngIf="icon" class="icon-size-4 text-current mr-1" [svgIcon]="icon"></mat-icon>
        {{ label }}
      </div>
      <span class="text-default font-medium text-base break-all">{{ control?.value }}</span>
    </div>
  </ng-container>
</div>
