import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { SvcComment } from '../../models/comment.model';
import { SvcCommentsService } from '../../services/svc-comments.service';
import { catchError, finalize, tap } from 'rxjs';
import { HttpErrorService, SvcFunctionsHelper, SvcMediaQuery } from 'projects/lib-shared-common/src/public-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonPagination } from 'projects/lib-shared-model/src/public-api';
import { UserService } from 'projects/lib-shared-core/src/public-api';
import { SvcCommentMentionType, SvcCommentsInputComponent } from '../svc-comments-input/svc-comments-input.component';
import { SvcCommentsMessageComponent } from '../svc-comments-message/svc-comments-message.component';
import { ActionType } from '../../../svc-audio-to-text-button/svc-audio-to-text-button.component';

enum AttachmentFileType {
  NORMAL,
  RECORDED_AUDIO,
}

@Component({
  selector: 'svc-comments-chat',
  templateUrl: './svc-comments-chat.component.html',
  styleUrls: ['./svc-comments-chat.component.scss']
})
export class SvcCommentsChatComponent implements OnInit {
  @Input() public referenceId: string | number;
  @Input() public configTypeId: string;
  @Input() public parentMessage: SvcComment;
  @Input('smallSize') public internalSmallSize: boolean = false;
  @Input() public applicationId: string;
  @Input() public placeholder = 'Adicionar comentário';
  @Input() public siteId: number;
  @Input() public external: boolean = false;
  @Input() public parentApplicationId: string;
  @Input() public parentRegisterId: number | string;

  @Output() public onCommentAdded = new EventEmitter<SvcComment>();
  @Output() public onCommentRemoved = new EventEmitter<SvcComment>();

  @ViewChild(SvcCommentsInputComponent)
  public commentInput: SvcCommentsInputComponent;

  @ViewChildren(SvcCommentsMessageComponent)
  public messagesCompList: QueryList<SvcCommentsMessageComponent>;

  public AttachmentFileType = AttachmentFileType;
  public isChild: boolean = false;
  public showInput: boolean = false;
  public totalMessages: number = 0;
  public messages: SvcComment[] = [];
  public chatForm: FormGroup;
  public attachmentFiles: { type: AttachmentFileType, file: Blob }[] = [];
  public isLoading: boolean = false;
  public isCreatingCommentLoading: boolean = false;
  public messagesAreGone: boolean = false;
  public commentIsValidToSend = false;
  public attachmentInput: HTMLInputElement;
  public suggestionsAdded: boolean;
  public actionType = ActionType

  private pageSize: number;
  private pageIndex: number;

  public get messagesComp() {
    return this.messagesCompList?.toArray();
  }

  public get smallSize(): boolean {
    return this.internalSmallSize || this._mediaQueryService.currentSize.isXS;
  }

  constructor(
    private _functionsHelper: SvcFunctionsHelper,
    private _userService: UserService,
    private _httpErrorService: HttpErrorService,
    private _commentsService: SvcCommentsService,
    private _formBuilder: FormBuilder,
    private _mediaQueryService: SvcMediaQuery
  ) { }

  ngOnInit(): void {
    this.chatForm = this._formBuilder.group({
      message: [''],
    });
    this.isChild = !!(this.parentMessage);
    this.showInput = !this.isChild;
    this.messagesAreGone = this.isChild && this.parentMessage.totalAnswers <= 0;
    this.pageSize = this.isChild ? 5 : 10;
    this.pageIndex = 1;
    this._getMessages();
    this._createInputFile();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (('referenceId' in changes || 'configTypeId' in changes) && ((changes.referenceId.previousValue && changes.referenceId.previousValue !== changes.referenceId.currentValue) || (changes.configTypeId.previousValue && changes.configTypeId.previousValue !== changes.configTypeId.currentValue))) {
      this.refresh();
    }
  }

  private _createInputFile() {
    this.attachmentInput = document.createElement('input');
    this.attachmentInput.type = 'file';
    this.attachmentInput.onchange = (event: Event) => {
      this.attachmentChanged(event);
    }
  }

  private _getMessages() {
    if (!this.messagesAreGone) {
      this.isLoading = true;
      const request = this.isChild
        ? this._commentsService.getParentComments(
          this.referenceId as number,
          {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            commentConfigTypeId: this.configTypeId,
          }
        )
        : (
          typeof this.referenceId == 'number'
            ? this._commentsService.getCommentsId.bind(this._commentsService)
            : this._commentsService.getCommentsUid.bind(this._commentsService)
        )({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          referenceId: this.referenceId,
          referenceUId: this.referenceId,
          commentConfigTypeId: this.configTypeId,
          externalSiteId: this.siteId,
        }, this.external);
      request.pipe(
        tap((res: CommonPagination<SvcComment[]>) => {
          this.messages = this.pageIndex <= 1 ? res.data : [...this.messages, ...res.data];
          this.messagesAreGone = res.data.length < this.pageSize;
          this.totalMessages = res.total;
        }),
        catchError((error) => {
          this._httpErrorService.showErrorInToast(error);
          return error;
        }),
        finalize(() => this.isLoading = false),
      ).subscribe();
    }
  }

  public refresh() {
    this.messagesAreGone = false;
    this.pageIndex = 1;
    this.messages = [];
    this._getMessages();
  }

  public tryToUpdateFromCommentId(commentId: number) {
    this._commentsService.getCommentById(commentId).pipe(
      tap((comment) => {
        if (comment.commentParentId > 0) {
          const messages = this.messagesComp;
          const index = messages.findIndex(m => m.message.commentId === comment.commentParentId);
          if (index >= 0) {
            if (!messages[index].showChildrenMessages) {
              messages[index].message.totalAnswers += 1;
              messages[index].showMessages();
            }
            else {
              messages[index].svcCommentsChatComponent?.addNewCommentInList(comment);
            }
          }
        }
        else {
          this.addNewCommentInList(comment);
        }
      }),
    ).subscribe();
  }

  public addNewCommentInList(message: SvcComment) {
    const messageIndex = this.messages.findIndex(m => m.commentId === message.commentId);
    if (messageIndex >= 0) {
      this.messages[messageIndex] = message;
    }
    else {
      this.messages = [
        message,
        ...this.messages,
      ];
    }
    this.onCommentAdded.emit(message);
  }

  public openAttachmentWindow() {
    if (!this.isCreatingCommentLoading) {
      this.attachmentInput.click();
    }
  }

  public attachmentChanged(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    for (let i = 0; i < files.length; i++) {
      this.addFileToAttachments(
        files[i],
        AttachmentFileType.NORMAL,
      );
    }
    input.value = '';
  }

  public addFileToAttachments(file: Blob, type: AttachmentFileType) {
    this.attachmentFiles.push({
      type: type,
      file: file instanceof File ? file : new File([file], 'audio_recorded.mp3'),
    });
    this.validateSendButton();
  }

  public sendMessage() {
    if (this.chatForm.invalid) {
      return;
    }

    const message = this.chatForm.value.message as string;
    const mentionUsers = this.commentInput.mentions.filter(x => x.type === 1).map(x => x.id);
    const mentionTeams = this.commentInput.mentions.filter(x => x.type === 2).map(x => x.id);
    const mentionHashTags = this.commentInput.mentions.filter(x => x.type === 3).map(x => x.id);
    const attachmentsFiles = this.attachmentFiles.map(x => x.file);

    this.isCreatingCommentLoading = true;
    (this.isChild
      ? this._commentsService.createAnswer(
        this.referenceId as number,
        {
          siteId: this.siteId ?? this._userService.user.lastSiteId,
          configTypeId: this.configTypeId,
          commentText: message,
          mentionUsers: mentionUsers,
          mentionTeams: mentionTeams,
          mentionHashTags: mentionHashTags,
          attachments: attachmentsFiles,
        }
      )
      : this._commentsService.createComment({
        referenceId: this.referenceId,
        siteId: this.siteId ?? this._userService.user.lastSiteId,
        configTypeId: this.configTypeId,
        commentText: message,
        mentionUsers: mentionUsers,
        mentionTeams: mentionTeams,
        mentionHashTags: mentionHashTags,
        attachments: attachmentsFiles,
      })
    ).pipe(
      tap((message) => {
        this.chatForm.reset();
        this.attachmentFiles = [];
        this.messages = [
          message,
          ...this.messages,
        ];
        this.onCommentAdded.emit(message);
        if (this.parentMessage) this.showInput = false;
      }),
      catchError((error) => {
        this._httpErrorService.showErrorInToast(error);
        return error;
      }),
      finalize(() => {
        this.isCreatingCommentLoading = false;
        this.suggestionsAdded = false;
      }),
    ).subscribe();
  }

  public removeAttachment(file: File) {
    const fileIndex = this.attachmentFiles.findIndex((item) => item.file === file);
    this.attachmentFiles.splice(fileIndex, 1);
    this.attachmentFiles = [...this.attachmentFiles];

    this.validateSendButton();
  }

  public commentRemoved(message: SvcComment) {
    this.onCommentRemoved.emit(message);
    this.messages = this.messages.filter(x => x.commentId !== message.commentId);
  }

  public nextPage() {
    this.pageIndex += 1;
    this._getMessages();
  }

  public focus() {
    if (this.showInput) {
      this.commentInput?.focus();
    }
    else {
      this.showInput = true;
      setTimeout(() => this.commentInput?.focus());
    }
  }

  public validateSendButton() {
    this.commentIsValidToSend = this._functionsHelper.removeHtml(this.chatForm.value.message ?? '') != '' ||
      this.attachmentFiles.length > 0;
  }

  public loadSuggestions() {
    if (this.suggestionsAdded) return;
    this._commentsService.getCommentMentionSuggestion({
      commentId: this.referenceId
    }).subscribe(r => {
      r.suggestionUsers.forEach(u => this.commentInput.addMention(SvcCommentMentionType.USER, u.userId, u.userName));
      r.suggestionTeams.forEach(t => this.commentInput.addMention(SvcCommentMentionType.TEAM, t.teamId, t.teamName))
      this.suggestionsAdded = true;
    });
  }

  ngOnDestroy() {
    this.attachmentInput?.remove();
    this.attachmentInput?.['removeAllListeners']?.();
  }
}
