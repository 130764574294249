import { Subscription } from 'rxjs';
import { HttpRequest } from '@angular/common/http';
import { environment } from 'projects/environments/environment';
import { Injectable } from '@angular/core';

const APIs = environment.APIs;
const urlExceptions: string[] = [
	`${APIs.apiUrlAuth}/site`,
	`${APIs.apiUrlAuth}/Environment/info`,
	`${APIs.apiUrlAdministration}/MenuGroup/module`,
	`${APIs.apiUrlCommentManager}/Manager`,
	`${APIs.apiUrlAdministration}/SatisfactionSurvey`,
];

@Injectable({
	providedIn: 'root'
})
export class RequestCollectorService {
	private cancelStopped: boolean = false;
	private requests: RequestIdentifier[] = [];

	public canCancel(url: string): boolean {
		return !urlExceptions.some(exceptUrl => url.indexOf(exceptUrl) === 0);
	}

	/**
	 * Adicionar uma nova request à lista de requests
	 */
	public add(subscription: Subscription, request?: HttpRequest<any>) {
		if (!this.requests) this.requests = [];
		this.requests.push(new RequestIdentifier(this, subscription, request));
	}

	/**
	 * Cancela todas as requests ativas
	 */
	public cancelAll() {
		if (!this.cancelStopped) {
			if (this.requests && this.requests.length > 0) this.requests.forEach(x => x.abort());
			this.requests = [];
		}
	}

	public disableCancels() {
		this.cancelStopped = true;
	}
	public enableCancels() {
		this.cancelStopped = false;
	}
}

export class RequestIdentifier {
	public collector: RequestCollectorService;
	public subscription: Subscription;
	public request: HttpRequest<any>;

	constructor(collector: RequestCollectorService, subscription: Subscription, request?: HttpRequest<any>) {
		this.collector = collector;
		this.subscription = subscription;
		this.request = request;
	}

	public abort() {
		if (this.request && !this.collector.canCancel(this.request.url)) return;	
		this.subscription.unsubscribe();
	}
}
