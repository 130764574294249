import { SvcDataTableSettings } from "./svc-data-table-settings.model";

export class SvcDataTableSettingsInternal extends SvcDataTableSettings {

	public getParentScrollElement(): HTMLElement {
		const element = this.parentScrollElement;
		if (typeof element === 'function') return element();
		if (typeof element === 'object') {
			if (element.selector) return document.querySelector(element.selector);
		}
		return;
	}
	
}
