import { Pipe, PipeTransform } from '@angular/core';
import { ISvcSelectOption } from './svc-select.component';

@Pipe({
  name: 'svcSelectOptionsFilterByValue'
})
export class SvcSelectOptionsFilterByValuePipe implements PipeTransform {

  transform(valuesToFilter: any[], actualValue: any, options: ISvcSelectOption[]): ISvcSelectOption[] {
    return options.filter(option =>
      !valuesToFilter.includes(option.value) || option.value === actualValue
    );
  }

}
