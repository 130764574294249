import { Injectable } from '@angular/core';

import { Observable, combineLatest, map } from 'rxjs';

import { ISvcChipOption } from 'projects/lib-shared-component/src/public-api';
import { SvcMasterdataService } from 'projects/lib-shared-core/src/lib/services/svc-masterdata.service';
import { SvcMasterdataUdaLocationList, SvcMasterdataContractor } from 'projects/lib-shared-core/src/lib/interfaces/svc-masterdata.interface';
import { UserService } from 'projects/lib-shared-core/src/public-api';
import { SvcBosShiftService } from './svc-bos-shift.service';
import { SvcBosTypeService } from './svc-bos-type.service';
import { SvcBosTypeCombo } from '../interfaces/svc-bos-type.interface';
import { SvcBosShift } from '../interfaces/svc-bos-shift.interface';
import { SvcModalBosCombos } from '../interfaces/svc-modal-bos.interface';
import { TranslocoService } from '@ngneat/transloco';
import { SvcBosBehaviorService } from './svc-bos-behavior.service';
import { BosBehaviorCombo, GetBehaviorListResponse } from '../interfaces/svc-bos-behavior.interface';
import { BosCategoryService } from 'projects/bos/src/app/shared/services/category.service';

@Injectable({
  providedIn: 'root',
})
export class SvcBosCombosService {

  constructor(
    private _masterdataService: SvcMasterdataService,
    private _behaviorService: SvcBosBehaviorService,
    private _userService: UserService,
    private _shiftService: SvcBosShiftService,
    private _typeService: SvcBosTypeService,
    private _translocoService: TranslocoService,
    private _categoryService:BosCategoryService
  ) { }

  private _mapDataResponse(dataList: any[], keyValue: string, keyLabel: string): ISvcChipOption[] {
    return dataList?.map(data => {
      return {
        value: data?.[keyValue],
        label: data?.[keyLabel]
      }
    });
  }

  public getTeams(active = null): Observable<ISvcChipOption[]> {
    return this._userService.getTeamsInfo({
      pageSize: 9999999,
      pageIndex: 1,
      active,
      name: ''
    }).pipe(
      map((teamsList: any) =>
        this._mapDataResponse(teamsList?.teams, 'teamId', 'name')
      )
    );
  }

  public getLocations(): Observable<ISvcChipOption[]> {
    return this._masterdataService.getLocations().pipe(
      map((locations: SvcMasterdataUdaLocationList[]) =>
        this._mapDataResponse(locations, 'udaId', 'udaName')
      )
    );
  }

  public getAreas(): Observable<ISvcChipOption[]> {
    return this._masterdataService.getAreas().pipe(
      map((locations: SvcMasterdataUdaLocationList[]) =>
        this._mapDataResponse(locations, 'udaId', 'udaName')
      )
    );
  }

  public getDepartments(): Observable<ISvcChipOption[]> {
    return this._masterdataService.getDepartments().pipe(
      map((locations: SvcMasterdataUdaLocationList[]) =>
        this._mapDataResponse(locations, 'udaId', 'udaName')
      )
    );
  }

  public getShifts(): Observable<ISvcChipOption[]> {
    return this._shiftService.getShift().pipe(
      map((shifts: SvcBosShift[]) => {
        return shifts?.map(data => {
          return {
            value: data?.shiftId,
            label: this._translocoService.translate(data?.shiftDescription)
          }
        });
      })
    );
  }

  public getContractors(): Observable<ISvcChipOption[]> {
    return this._masterdataService.getContractors().pipe(
      map((contractors: SvcMasterdataContractor[]) =>
        this._mapDataResponse(contractors, 'id', 'name')
      )
    );
  }
  public getBehaviors(): Observable<ISvcChipOption[]> {
    return this._behaviorService.getBehaviorBySiteId().pipe(
      map((contractors: BosBehaviorCombo[]) => {
        return contractors?.map(data => {
          return {
            value: data?.behaviorId,
            label: data?.behaviorName
          }
        });
      }));
  }
  public getCategories(): Observable<ISvcChipOption[]> {
    return this._categoryService.getCategoryCombo().pipe(
      map((categories: any[]) => {
        return categories?.map(data => {
          return {
            value: data?.value,
            label: data?.text
          }
        });
      }));
  }


  public getTypes(isActive?: boolean): Observable<ISvcChipOption[]> {
    return this._typeService.getTypes(isActive).pipe(
      map((types: SvcBosTypeCombo[]) =>
        this._mapDataResponse(types, 'typeId', 'typeName')
      )
    );
  }

  public getObservers(): Observable<ISvcChipOption[]> {
    return this._userService.getUsers(null).pipe(
      map((users: {
          userId: string;
          name: string;
          active: boolean;
        }[]
      ) =>
        this._mapDataResponse(users, 'userId', 'name')
      )
    );
  }

  public getAllCombosToRegisterBos(): Observable<SvcModalBosCombos> {
    return combineLatest([
      this.getTeams(true),
      this.getShifts(),
      this.getContractors()
    ]).pipe(
      map(([teams, shifts, contractors]) => ({
        teams,
        shifts,
        contractors
      }))
    );
  }
}
