import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UserRole, UserRoleItem } from './models/user-role.model';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';

@Injectable({
  providedIn: 'root'
})
export class SvcAccessPermissionService {

  private get apiUrlAuth(): string {
    return this._appConfig.APIs.apiUrlAuth;
  }

  private get apiUrlUsers(): string {
    return this._appConfig.APIs.apiUrlUsers;
  }

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private http: HttpClient

  ) { }

  listPermissionUsers(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrlUsers}/User/names?active=true`);
  }

  getUsersPerRole(applicationId: any) {
    return this.http.get<UserRole[]>(`${this.apiUrlUsers}/UsersPerRole/${applicationId}`);
  }
  
  getUsersPerRoleAvailables(applicationId: any) {
    return this.http.get<{ userId: string, name: string }[]>(`${this.apiUrlUsers}/UsersPerRole/${applicationId}/availables`);
  }

  getRoles(applicationId: string) {
    return this.http.get<{ roleId: string, roleName: string }[]>(`${this.apiUrlUsers}/Roles/${applicationId}`);
  }

  public getRolesByUser(applicationId: string): Observable<UserRoleItem[]> {
    return this.http.get<UserRoleItem[]>(`${this.apiUrlAuth}/roles/user/${applicationId}`);
  }

  usersInLastSite(applicationId: any) {
    return this.http.get(`${this.apiUrlUsers}/UsersInLastSiteId`);
  }

  addUserRoles(data: { roleUserId: any, applicationId: any, roleIds: string[] }) {
    return this.http.put(`${this.apiUrlUsers}/UserRoles/roles`, data);
  }
}
