import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MatDialogRef } from '@angular/material/dialog';
import { SvcValidators } from 'projects/lib-shared-common/src/public-api';
import { AuthService } from '../../../auth/auth.service';
import { passwordValidator } from './password-validator';
import { svcAnimations } from 'projects/lib-shared-component/src/lib/animations/animations';

@Component({
  selector: 'user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss'],
  animations: svcAnimations,
})
export class UserPasswordComponent implements OnInit {
  alert: { type: string; message: string } = {
    type: 'success',
    message: '',
  };
  showAlert: boolean = false;
  passwordForm: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _translocoService: TranslocoService,
    public _dialogRef: MatDialogRef<UserPasswordComponent>
  ) { }

  ngOnInit() {
    this.passwordForm = this._formBuilder.group(
      {
        passwordActual: ['', Validators.required],
        password: ['', Validators.required, passwordValidator()],
        passwordConfirm: ['', Validators.required]
      },
      {
        validators: SvcValidators.mustMatch('password', 'passwordConfirm'),
      }
    );
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      this.passwordForm.markAllAsTouched();

      // Disable the form
      this.passwordForm.disable();

      // Hide the alert
      this.showAlert = false;

      this._authService.changePassword(
        this.passwordForm.get('passwordActual').value,
        this.passwordForm.get('password').value
      )
        .then(() => {
          this.alert = {
            type: 'success',
            message: this._translocoService.translate('Senha redefinida com sucesso'),
          };
          setTimeout(() => this._dialogRef.close(), 2500);
        })
        .catch((err) => {
          this.alert = {
            type: 'error',
            message: this._translocoService.translate('Ocorreu um erro ao tentar alterar a senha'),
          };
        })
        .finally(() => {
          this.passwordForm.enable();
          this.passwordForm.reset();
          this.showAlert = true;
        });
    }
  }

  closeDialog() {
    this._dialogRef.close();
  }

}
