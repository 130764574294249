<div #modal class="flex flex-col flex-auto bg-white" *transloco="let t" [matDialogFullscreen]="fullscreen">
  <div #titleContainer mat-dialog-title class="flex items-center justify-between border-b border-inherit py-2" [class]="innerDividingLines ? 'px-0 mx-4' : 'px-4'" [class.hidden]="!hasTitle"
    [class.py-0]="['sm'].includes(size)" [class.px-2]="['sm'].includes(size)" [class.p-2]="['md'].includes(size)"
    [class.p-4]="['lg', 'xl'].includes(size)">
    <div class="svc-dialog-title flex-auto flex gap-2 text-xl font-bold text-default">
      <ng-content select="[svc-dialog-title-icon]"></ng-content>
      <ng-content select="[svc-dialog-title]"></ng-content>
    </div>

    <button *ngIf="!hideCloseButton" class="-mr-2 print:hidden" mat-icon-button (click)="closeDialog()">
      <mat-icon class="text-current" [class.icon-size-4]="size === 'sm'"
        [class.icon-size-5]="['md', 'lg', 'xl'].includes(size)" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
    </button>

  </div>

  <div *screenSizes="let size" class="flex flex-col flex-auto" [class.overflow-auto]="scrollInsideContent" [style.maxHeight]="size.isXS && scrollInsideContent ? ('calc(100vh - ' +  ((dialogTitleContainer?.nativeElement?.clientHeight ?? 0) + (dialogActions?.nativeElement?.parentElement?.clientHeight ?? 0) + (defaultActions?.nativeElement?.clientHeight ?? 0)) + 'px)') : null">
    <ng-content select="[svc-dialog-content]" mat-dialog-content></ng-content>
  </div>

  <div mat-dialog-actions align="end" class="border-t border-inherit" *ngIf="hasActions && manualActions" [class]="innerDividingLines ? 'px-0 mx-4' : 'px-4'">
    <ng-content select="[svc-dialog-actions]"></ng-content>
  </div>

  <div #defaultActions mat-dialog-actions align="end" class="border-t border-inherit" *ngIf="hasActions && !manualActions && dialogStyle" [class]="innerDividingLines ? 'px-0 mx-4' : 'px-4'">
    <div *ngIf="dialogStyle === 'form'" class="flex flex-row gap-2">
      <svc-button mode="stroked" type="button" (click)="closeDialog()">
        {{textCancel ?? t('Cancelar')}}
      </svc-button>
      <svc-button class="button-confirm" mode="flat" type="button" color="primary" (click)="confirmed.emit()" cdkFocusInitial [disabled]="disabledConfirm">
        {{textConfirm ?? t('Salvar')}}
      </svc-button>
    </div>
    <div *ngIf="dialogStyle === 'close'">
      <svc-button mode="flat" color="primary" type="button" cdkFocusInitial (click)="closeDialog()">
        {{textCancel ?? t('Fechar')}}
      </svc-button>
    </div>
  </div>

</div>
