import { DatePipe } from '@angular/common';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { isNullOrUndefined } from '../functions/helper-functions';

@Injectable({
	providedIn: 'root'
})
export class SvcFunctionsHelper {

	constructor() { }

	randomNumber(min = 1, max = 9999) {
		let number = Math.floor(Math.random() * max);

		return Math.max(number, min);
	}

	onlyNumbers(text: string) {
		if (!isNullOrUndefined(text)) return text.replace(/[^\d]/g, '');
		return '';
	}

	onlyAlphaNumeric(text: string) {
		if (!isNullOrUndefined(text)) return text.replace(/[^a-zA-Z0-9]+/g, '');
		return '';
	}

	onlyAlpha(text: string) {
		if (!isNullOrUndefined(text)) return text.replace(/[^a-zA-Z]+/g, '');
		return '';
	}

	onlyNumberAndToFloat(text: string) {
		if (text) {
			let strNumber = text.replace(/[^\d.\d]/g, '');
			let number = parseFloat(strNumber);
			if (!isNaN(number)) return number;
		}
		return 0;
	}

	removeAccents(text: string) {
		return text ? text.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';
	}

	copy(obj: any) {
		if (obj) return JSON.parse(JSON.stringify(obj));
		else return obj;
	}

	copyToClipboard(inputElement) {
		inputElement.focus();
		inputElement.select();
		if (!navigator.clipboard) {
			let successful = document.execCommand('copy');
		} else {
			navigator.clipboard.writeText(inputElement?.value);
		}
	}

	getDiffOfDate(dateBegin: Date, dateEnd: Date, returnType: 'days' | 'hours' | 'minutes' | 'seconds' = 'days'): number {
		if (dateBegin && dateEnd) {
			let delta = Math.abs(dateEnd.getTime() - dateBegin.getTime()) / 1000;

			let days = Math.floor(delta / 86400);
			delta -= days * 86400;

			let hours = Math.floor(delta / 3600) % 24;
			delta -= hours * 3600;

			let minutes = Math.floor(delta / 60) % 60;
			delta -= minutes * 60;

			let seconds = Math.floor(delta % 60);

			if (returnType === 'days') return days;
			else if (returnType === 'hours') return hours;
			else if (returnType === 'minutes') return minutes;
			else if (returnType === 'seconds') return seconds;
		}

		return 0;
	}

	public decodeBase64(text: string) {
		return atob(text);
	}

	public encodeBase64(text: string) {
		return btoa(text);
	}

	public uid() {
		let pad4 = (num: number) => {
			let ret: string = num.toString(16);
			while (ret.length < 4) {
				ret = '0' + ret;
			}
			return ret;
		};

		let random4 = () => {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		};

		if (
			typeof window !== 'undefined' &&
			typeof window.crypto !== 'undefined' &&
			typeof window.crypto.getRandomValues !== 'undefined'
		) {
			let buf: Uint16Array = new Uint16Array(8);
			window.crypto.getRandomValues(buf);
			return (
				pad4(buf[0]) +
				pad4(buf[1]) +
				'-' +
				pad4(buf[2]) +
				'-' +
				pad4(buf[3]) +
				'-' +
				pad4(buf[4]) +
				'-' +
				pad4(buf[5]) +
				pad4(buf[6]) +
				pad4(buf[7])
			);
		} else
			return (
				random4() +
				random4() +
				'-' +
				random4() +
				'-' +
				random4() +
				'-' +
				random4() +
				'-' +
				random4() +
				random4() +
				random4()
			);
	}

	removeHtml(html: string) {
		return html
			?.replace(/(<([^>]+)>)/gi, '')
			.replace(/&nbsp;/g, ' ') ?? '';
	}
}