import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ContentChild, ElementRef, EventEmitter, forwardRef, HostBinding, HostListener, Inject, Injector, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipGrid } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import _, { cloneDeep } from 'lodash';
import { Subject, takeUntil, tap } from 'rxjs';
import { SvcMediaQuery } from 'projects/lib-shared-common/src/public-api';
import { MAX_OPTIONS_LIST_HEIGHT } from '../utils/droplists.const';
import { optionsContainer } from '../utils/droplists.animations';
import { SvcControl } from '../svc-control';

export interface ISvcSelectOption {
  text: string;
  value: any;
}

@Component({
  selector: 'svc-select',
  templateUrl: './svc-select.component.html',
  styleUrls: ['./svc-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SvcSelectComponent),
    multi: true
  }],
  animations: [
    optionsContainer
  ],
})
export class SvcSelectComponent extends SvcControl implements OnInit, OnChanges {

  @HostBinding('class.svc-field-in-primary') protected inPrimaryAppearance = false;

  private _options: ISvcSelectOption[] = [];

  public canShowSearchInput: boolean;
  public maxOptionsListHeight = MAX_OPTIONS_LIST_HEIGHT;

  @Input() label: string | { text: string, icon: string };
  @Input() placeholder: string;
  @Input() set options(value: ISvcSelectOption[]) {
    const options = [...(value ? value : [])];
    this._options = _.cloneDeep(options);
    this._checkCanShowSearchInput();
  }
  @Input() appearance : 'default' | 'primary' = 'default';
  @Input() icon: string;
  @Input() readonly: boolean = false;
  @Input() readonlyStyle: 'default' | 'chip' | 'transparent-chip' = 'default';
  @Input() truncateTextValue: boolean = false;
  @Input() inputId: string;
  @Input() fallbackTextValue: string;
  @Input() loading: boolean = false;
  @Input() externalSearch: boolean;
  @Input() minCharactersExternalSearchLength = 3;

  @Output() ngModelChange = new EventEmitter<any>();
  @Output() searchTextChange = new EventEmitter<string>();

  @Optional() @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  @ViewChild('formField', { read: ElementRef }) formField: ElementRef<HTMLElement>;
  @ViewChild('optionsListTemplate') optionsListTemplate: TemplateRef<any>;
  @ViewChild(MatChipGrid) matChipGrid: MatChipGrid;

  public filteredOptions: ISvcSelectOption[] = [];
  public selected: ISvcSelectOption;
  protected searchText: string = null;
  protected optionsListIsOpened = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _isUpdatingControl = false;
  private _overlayRef: OverlayRef;
  private _initialDisableClose = false;

  constructor(
    @Inject(Injector) injector: Injector,
    @Optional() private dialogRef: MatDialogRef<any>,
    private _viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,
    private _mediaQuery: SvcMediaQuery,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initialDisableClose = this.dialogRef.disableClose ?? false;

    this._mediaQuery.size$.pipe(
      takeUntil(this._unsubscribeAll),
      tap(() => this._checkCanShowSearchInput()),
    ).subscribe();


    this.control?.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap((value: any) => {
        if (!this._isUpdatingControl) {
          if (!this.loading) {
            let selected = null;
            if (value) {
              for (const option of this._options ?? []) {
                if (value == option.value) {
                  selected = option;
                  break;
                }
              }
            }
            this.selected = selected;
          }
          this._options = cloneDeep(this._options ?? []);
          this.searchText = '';
          this.filterOptions(this.searchText);
        }
      }),
    ).subscribe();
    this._checkOptions();
    this._checkSelected();
    this.filterOptions(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._checkOptions();
    this._checkSelected();
    this._update();
    this.filterOptions(this.searchText);
    this._checkCanShowSearchInput();
    if ('appearance' in changes) {
      this.inPrimaryAppearance = this.appearance === 'primary';
    }
  }

  private _checkCanShowSearchInput() {
    const size = this._mediaQuery.currentSize;
    this.canShowSearchInput = !this.selected && ((this._options?.length > 100 && size.isXS) || size.isAboveXS);
  }

  private _checkOptions(): void {
    this._options = (this._options ?? []).map(option => {
      return (['string', 'number', 'boolean'].includes(typeof option)
        ? { text: option, value: option }
        : option) as ISvcSelectOption;
    });
  }

  private _checkSelected(): void {
    if (this.loading) return;
    this.selected = this._options?.find(option => this.control?.value === option.value);
  }

  public filterOptionsByApi(): void {
    if (this.externalSearch)
      this.searchTextChange.emit(this.searchText);
  }

  public filterOptions(value: string): void {
    if (this.externalSearch) {
      this.filteredOptions = this._options;
      return;
    }
    const filterValue = value ? value.toString().toLowerCase() : '';
    this.filteredOptions = (this._options ?? []).filter(option => this.selected?.value !== option.value && (!filterValue || option.text.toString().toLowerCase().includes(filterValue)));
  }

  private _available(value: any) {
    const item = (this._options ?? []).find(option => option.value?.toString().toLowerCase() === value.toString().toLowerCase());
    if (item) {
      this._options = cloneDeep(this._options ?? []);
      this._update();
    }
  }

  private _unavailable(value: any) {
    const item = (this._options ?? []).find(option => option.value.toString().toLowerCase() === value.toString().toLowerCase());
    if (item) {
      this.selected = item;
      this._options = cloneDeep(this._options ?? []);
      this._update();
    }
  }

  private _update() {
    if (this.loading) return;
    if (this.selected?.value != this.control?.value) {
      try {
        this._isUpdatingControl = true;
        this.control?.setValue(this.selected?.value);
      }
      finally {
        this._isUpdatingControl = false;
      }
    }
  }

  openOptions() {
    if (this.loading || this.optionsListIsOpened || (this.control?.disabled ?? false)) {
      return;
    }

    const hasOverlayRefInstance = !!(this._overlayRef);

    if (!this._overlayRef && this.formField.nativeElement) {
      this._overlayRef = this._overlay.create({
        hasBackdrop: true,
        backdropClass: 'bg-transparent',
        scrollStrategy: this._overlay.scrollStrategies.block(),
        width: this.formField.nativeElement.clientWidth,
        maxHeight: MAX_OPTIONS_LIST_HEIGHT,
        positionStrategy: this._getOptionsPositionStrategy(),
      });

      this._overlayRef.backdropClick().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(() => {
        this.closeOptions();
      });

      this._overlayRef.detachments().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(() => {
        this.formField?.nativeElement?.focus();
        if (this.dialogRef) this.dialogRef.disableClose = this._initialDisableClose;
        setTimeout(() => this.optionsListIsOpened = false);
      });
    }

    if (hasOverlayRefInstance) {
      this._overlayRef?.updateSize({
        width: this.formField?.nativeElement?.clientWidth,
      })
      this._overlayRef.updatePositionStrategy(this._getOptionsPositionStrategy());
    }

    if (!this.optionsListIsOpened) {
      try {
        const viewRef = this._overlayRef.attach(
          new TemplatePortal(this.optionsListTemplate, this._viewContainerRef)
        );
        this.optionsListIsOpened = true;
        if (this.dialogRef) this.dialogRef.disableClose = true;
        setTimeout(() => {
          if (this.optionsListIsOpened) {
            if (viewRef.rootNodes.length) {
              const element = viewRef.rootNodes[0] as HTMLElement;
              if (element?.querySelector) {
                const input: HTMLInputElement = element.querySelector('#app-svc-select-search-input-options');
                if (input) {
                  input?.focus();
                }
                else {
                  this.formField?.nativeElement?.focus();
                }
              }
            }
          }
        });
      }
      catch { }
    }
  }

  closeOptions() {
    this._overlayRef?.detach();
    this.control?.markAsTouched();
  }

  private _getOptionsPositionStrategy() {
    const fieldWrapper = this.formField.nativeElement.querySelector('.mat-mdc-text-field-wrapper') as HTMLElement;
    return this._overlay
      .position()
      .flexibleConnectedTo(this.formField.nativeElement)
      .withLockedPosition(true)
      .withPush(true)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetY: fieldWrapper ? (this.formField.nativeElement.clientHeight - fieldWrapper.offsetHeight) : 0
        },
      ]);
  }

  remove(): void {
    const prevValue = this.selected.value;
    this.selected = null;
    this._available(prevValue);
    this.filterOptions(this.searchText);
  }

  select(option: ISvcSelectOption): void {
    this._unavailable(option.value);
    this.closeOptions();
    this.filterOptions(this.searchText);
  }

  forceFocusItemWhenDownPressed(event: Event) {
    event.preventDefault();
    if (this.filteredOptions.length > 0 && !this.selected) {
      const container = (event.target as HTMLElement).parentElement.parentElement.children[1];
      if (container?.children.length > 0) {
        const item = container?.querySelector('.select-option') as HTMLElement;
        item?.focus();
      }
    }
  }

  @HostListener('window:resize')
  private onWindowResize() {
    if (this.optionsListIsOpened) {
      this._overlayRef?.updateSize({
        width: this.formField?.nativeElement?.clientWidth,
      })
      this._overlayRef?.updatePositionStrategy(this._getOptionsPositionStrategy());
    }
  }

  @HostListener('document:keydown.esc', ['$event'])
  private onEsc(event: Event) {
    if (this.optionsListIsOpened) {
      this.closeOptions();
    }
  }

  public ngOnDestroy() {
    this.closeOptions();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  protected onValueChanged(value): void {
    this.ngModelChange.emit(value);
  }

}

