import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { StaticApplicationId } from 'projects/lib-shared-core/src/lib/Constants/static-application-id.enum';
import {
  AuthService,
  NavigationService,
  SvcUserPreferencesEvents,
  SvcUserPreferenceFeatureCustomPorpertyKey,
  UserService,
  PageCantBeLoadedType,
} from 'projects/lib-shared-core/src/public-api';
import { BehaviorSubject, catchError, delay, finalize, first, forkJoin, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { externalURLSamePageWithoutHistory, getAccessTokenPayloadInfo } from 'projects/lib-shared-common/src/public-api';
import { MyworkspaceUserPreferencesFeaturesNames, MyworkspaceTabs } from './board/main/models/myworkspace-preferences';
import { environment } from 'projects/environments/environment';
import { AuthStatus } from "../../../lib-shared-core/src/lib/auth/model/user-auth.model";
import { EnvironmentInfo } from "../../../lib-shared-core/src/lib/auth/model/environment.info.model";

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver extends SvcUserPreferencesEvents<MyworkspaceTabs>  {

  private _firstResolve = true;

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _userService: UserService,
    private _navigationService: NavigationService,
  ) {
    super({
      featureName: MyworkspaceUserPreferencesFeaturesNames.MyworkspaceTabs,
      customPropertyKey: SvcUserPreferenceFeatureCustomPorpertyKey.Tab,
      customPropertyType: 'string',
      customPropertyValue: ['posts', 'kaizen', 'obeya', 'projects', 'dashboard/subordinates', 'dashboard/user'],
      activeCustomProperty: 'posts',
      applicationId: StaticApplicationId.myworkspace,
      executeFirstTime: false,
    });
  }

  private redirectLastTab(state: RouterStateSnapshot): Observable<{ needToRedirect: boolean, routePath: string }> {
    const defaultValue = {
      needToRedirect: false,
      routePath: state.url,
    };

    if (!this._firstResolve)
      this._firstResolve = true;

    this.getUserPreferencesFeatures(true);
    return this.activeCustomProperty$.pipe(
      take(1),
      map((lastTab: MyworkspaceTabs) => {
        const mainPath = '/board/main/posts';
        if (lastTab && state.url === mainPath && state.url !== `/board/main/${lastTab}`) {
          return {
            needToRedirect: true,
            routePath: `/board/main/${lastTab}`,
          };
        }
        return defaultValue;
      }),
      catchError(() => of(defaultValue)),
      finalize(() => this._firstResolve = false),
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.redirectLastTab(state).pipe(
      switchMap((redirect) => {
        return this._userService.get().pipe(
          first(),
          switchMap(_ => {
            if (redirect.needToRedirect) {
              this._router.navigateByUrl(redirect.routePath);
              return of(null);
            }
            this._navigationService.get(StaticApplicationId.myworkspace).subscribe();
            return forkJoin([
              this._userService.getEnvironment(),
            ]);
          }),
          tap(([environment]) => {
            const userStatus = this._authService.userStatusAuth?.status;
            if (<AuthStatus>userStatus == AuthStatus.SupplierUser)
              externalURLSamePageWithoutHistory(`${(<EnvironmentInfo>environment).url}/suppliermanagement`, ``);

            if (<AuthStatus>userStatus == AuthStatus.ContractorUser)
              externalURLSamePageWithoutHistory(`${(<EnvironmentInfo>environment).url}/contractormanagement`, ``);

            if (<AuthStatus>userStatus == AuthStatus.PendingGDPRUser)
              this._router.navigate(['page-cant-be-load'], {
                queryParams: {
                  type: PageCantBeLoadedType.GDPR,
                  from: this._router.url,
                }
              });
          }),
          catchError((_) => {
            this._router.navigate(['page-cant-be-load']);
            return of(null);
          })
        );
      })
    );
  }
}
