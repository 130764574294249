import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, combineLatest, map, of, shareReplay, tap } from 'rxjs';

import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { AlertClassificationList, AlertCombos, AlertFailureList, AlertMaterialList, AlertMaterialTypeList, AlertSupplierList, AlertTeamsList, AlertUdaLocationList } from '../interfaces/alert-combos-data.interface';
import { AvailableHashtags } from 'projects/lib-shared-core/src/lib/features/user/user-hashtags/services/user-hashtags.types';
import { ISvcChipGroupedOption, ISvcChipOption, ISvcSelectOption } from 'projects/lib-shared-component/src/public-api';
import { SvcAlertHttpClient } from '../../svc-alert-http-client';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class AlertCombosService {

  private readonly _apiUrlMasterdata: string = this._appConfig.APIs.apiUrlMasterdata;
  private readonly _apiUrlHashtag: string = this._appConfig.APIs.apiUrlHashtag;
  private readonly _apiUrlUsers: string = this._appConfig.APIs.apiUrlUsers;
  private _teamsCache: ISvcSelectOption[] | null = null;
  private _locationsCache: ISvcChipOption[] | null = null;
  private _classificationsCache: ISvcChipOption[] | null = null;
  private _failuresCache: { [classificationId: number]: ISvcChipOption[] } = {};
  private _materialTypesCache: ISvcChipOption[] | null = null;
  private _materialsCache: { [materialTypeId: number]: ISvcChipOption[] } = {};
  private _suppliersCache: ISvcChipOption[] | null = null;
  private _hashtagsCache: ISvcChipGroupedOption[] | null = null;
  private _departmentsCache: ISvcChipOption[] | null = null;
  private _areasCache: ISvcChipOption[] | null = null;

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _http: HttpClient,
    private _translocoService: TranslocoService,
    private _alertHttp: SvcAlertHttpClient
  ) { }

  private _mapDataResponse(dataList: any[], keyValue: string, keyLabel: string): ISvcChipOption[] {
    return dataList?.map(data => {
      return {
        value: data?.[keyValue],
        label: data?.[keyLabel]
      }
    });
  }

  public getTeams(active: boolean = true): Observable<ISvcSelectOption[]> {
    if (this._teamsCache) {
      return of(this._teamsCache);
    }
    return this._http.get<AlertTeamsList>(`${this._apiUrlUsers}/Team`, {
      params: {
        pageSize: 9999999,
        pageIndex: 1,
        active: true
      }
    })
    .pipe(
      map((teamsList: AlertTeamsList) =>
        this._mapDataResponse(teamsList?.teams, 'teamId', 'name').map((option) => (<ISvcSelectOption>{
          text: option.label,
          value: option.value,
        }))
      ),
      tap(data => this._teamsCache = data),
      shareReplay(1)
    );
  }

  public getLocations(): Observable<ISvcChipOption[]> {
    if (this._locationsCache) {
      return of(this._locationsCache)
    }

    return this._http.get<AlertUdaLocationList[]>(`${this._apiUrlMasterdata}/UDA/location`)
      .pipe(
        map((locations: AlertUdaLocationList[]) =>
          this._mapDataResponse(locations, 'udaId', 'udaName')
        ),
        tap(data => this._locationsCache = data),
        shareReplay(1)
      );
  }

  public getClassificationList(active = true): Observable<ISvcChipOption[]> {
    if (this._classificationsCache) {
      return of(this._classificationsCache);
    }

    return this._http.get<AlertClassificationList[]>(`${this._apiUrlMasterdata}/classification/list`, {
      params: {
        active,
        ...this._alertHttp.siteId
      }
    })
    .pipe(
      map((classifications: AlertClassificationList[]) =>
        this._mapDataResponse(classifications, 'classificationId', 'description')
      ),
      tap(data => this._classificationsCache = data),
      shareReplay(1)
    );
  }

  public getFailures(classificationId: number, active: boolean = true): Observable<ISvcChipOption[]> {
    if (!classificationId && this._failuresCache['default']) {
      return of(this._failuresCache['default']);
    }
  
    const cacheKey = classificationId ?? 'default';
    if (this._failuresCache[cacheKey]) {
      return of(this._failuresCache[cacheKey]);
    }

    return this._http.get<AlertFailureList[]>(`${this._apiUrlMasterdata}/failure/list`, {
      params: {
        active,
        classificationId,
        ...this._alertHttp.siteId
      }
    })
    .pipe(
      map((failures: AlertFailureList[]) =>
        this._mapDataResponse(failures, 'failureId', 'description')
      ),
      tap(data => this._failuresCache[cacheKey] = data as ISvcChipOption[]),
      shareReplay(1)
    );
  }

  public getMaterialType(active: boolean = true): Observable<ISvcChipOption[]> {
    if (this._materialTypesCache) {
      return of(this._materialTypesCache);
    }

    return this._http.get<AlertMaterialTypeList[]>(`${this._apiUrlMasterdata}/MaterialType/List`, {
      params: {
        active,
        ...this._alertHttp.siteId
      }
    })
    .pipe(
      map((materials: AlertMaterialTypeList[]) =>
        this._mapDataResponse(materials, 'materialTypeId', 'description')
      ),
      tap(data => this._materialTypesCache = data),
      shareReplay(1)
    )
  }

  public getMaterial(materialTypeId: number, productId: number, active = true, isGrouped = false): Observable<ISvcChipOption[] | AlertMaterialList[]> {
    if (!materialTypeId && this._materialsCache['default']) {
      return of(this._materialsCache['default']);
    }
  
    const cacheKey = materialTypeId ?? 'default';
    if (this._materialsCache[cacheKey]) {
      return of(this._materialsCache[cacheKey]);
    }

    return this._http.get<AlertMaterialList[]>(`${this._apiUrlMasterdata}/material/list`, {
      params: {
        active,
        materialTypeId,
        ...this._alertHttp.siteId
      }
    })
    .pipe(
      map((materials: AlertMaterialList[]) => {
        if (isGrouped)
          return materials;

        return this._mapDataResponse(materials, 'materialId', 'description');
      }),
      tap(data => {
        this._materialsCache[cacheKey] = data as ISvcChipOption[];
      }),
      shareReplay(1)
    );
  }

  public getSupplier(active = true): Observable<ISvcChipOption[]> {
    if (this._suppliersCache) {
      return of(this._suppliersCache);
    }

    return this._http.get<AlertSupplierList[]>(`${this._apiUrlMasterdata}/Supplier/List`, {
      params: {
        active,
        ...this._alertHttp.siteId
      }
    })
    .pipe(
      map((suppliers: AlertSupplierList[]) =>
        this._mapDataResponse(suppliers, 'supplierId','description')
      ),
      tap(data => this._suppliersCache = data),
      shareReplay(1)
    );
  }

  public getHastagDropList(active: boolean = true, globalhashtags: boolean = true): Observable<ISvcChipGroupedOption[]> {
    if (this._hashtagsCache) {
      return of(this._hashtagsCache);
    }

    return this._http.get<AvailableHashtags[]>(`${this._apiUrlHashtag}/dropList`, {
      params: {
        active,
        globalhashtags,
        ...this._alertHttp.siteId
      }
    })
    .pipe(
      map((dropList: AvailableHashtags[]) => {
        return dropList?.map(siteGroup => (<ISvcChipGroupedOption>{
          group: this._translocoService.translate(siteGroup.siteName),
          options: siteGroup.types.map((typeGroup) => (<ISvcChipGroupedOption>{
            group: typeGroup.typeName,
            options: typeGroup.hashtags.map((hashtag) => (<ISvcChipOption>{
              label: hashtag.name,
              value: hashtag.hashtagId,
            }))
          })).filter(group => group.options?.length > 0)
        })).filter(group => group.options?.length > 0) ?? [];
      }),
      tap(data => this._hashtagsCache = data),
      shareReplay(1)
    )
  }

  public getAllCombosToRegisterAlert(): Observable<AlertCombos> {
    return combineLatest([
      this.getTeams(),
      this.getClassificationList(),
      this.getMaterialType(),
      this.getSupplier(),
      this.getHastagDropList(),
    ]).pipe(
      map(([teams, classification, materialType, suppliers, hashtags]) => ({
        teams,
        classification,
        materialType,
        suppliers,
        hashtags
      }))
    );
  }

  public getAreas(): Observable<ISvcChipOption[]> {
    if (this._areasCache) {
      return of(this._areasCache);
    }

    return this._http.get<AlertUdaLocationList[]>(`${this._apiUrlMasterdata}/UDA/area`)
    .pipe(
      map((locations: AlertUdaLocationList[]) =>
        this._mapDataResponse(locations, 'udaId', 'udaName')
      ),
      tap(data => this._areasCache = data),
      shareReplay(1)
    );
  }

  public getDepartments(): Observable<ISvcChipOption[]> {
    if (this._departmentsCache) {
      return of(this._departmentsCache);
    }

    return this._http.get<AlertUdaLocationList[]>(`${this._apiUrlMasterdata}/UDA/department`)
    .pipe(
      map((locations: AlertUdaLocationList[]) =>
        this._mapDataResponse(locations, 'udaId', 'udaName')
      ),
      tap(data => this._departmentsCache = data),
      shareReplay(1)
    );
  }
}
