import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from '@angular/core';
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { UserService } from 'projects/lib-shared-core/src/public-api';
import { Observable, Subject, map, of, tap } from "rxjs";
import { Post } from "../models/post";
import { PostFilters } from "../models/post-filter";
import { PostMedia } from "../models/post-media";
import { PostOrdenation } from "../models/post-ordenation";
import { PostPagination } from "../models/post-pagination";
import { PostShared } from '../models/post-shared';
import { SvcPostsHttpClient } from "../svc-posts-http-client";

const isXS = () => window.innerWidth < 600;

@Injectable()
export class PostService {

  private get _defaultPage(): PostPagination {
    const viewPort = document.querySelector('svc-workspace-layout mat-tab-nav-panel');
    return {
      pageIndex: 0,
      pageSize: (() => {
        const qtty = viewPort ? viewPort.clientHeight / (isXS() ? 240 : 270) : 4;
        return Math.max(Math.floor(qtty) + (qtty > Math.floor(qtty) ? 1 : 0) + 20, 3);
      })(),
      ordenationOption: 'AllSites',
    };
  }

  private _currentPage: PostPagination = this._defaultPage;
  private _loadedPages: PostPagination[] = [];
  private _postFilter: PostFilters = {};

  private _posts: Post[] = [];

  private _postsAdded = new Subject<Post[]>();
  public postsAdded$ = this._postsAdded.asObservable();

  private _postsUpdated = new Subject<Post[]>();
  public postsUpdated$ = this._postsUpdated.asObservable();

  private _pageChanged = new Subject<PostPagination>();
  public pageChanged$ = this._pageChanged.asObservable();

  public filterChanged$ = new EventEmitter<PostFilters>();

  public loading: boolean;
  public loadingChange = new Subject<boolean>();

  constructor(
    private _postsHttpClient: SvcPostsHttpClient,
    private _appConfig: AppEnvironmentConfig,
    private _http: HttpClient,
    private _userServie: UserService,
  ) { }

  /**
   * Returns the list of posts based on the current filter
   */
  getPosts(): Observable<Post[]> {

    this.setFilterInPage();
    this.setLoading(true);

    const formData = Object.keys(this._currentPage)
      .reduce((param, key) => {
        const value = this._currentPage[key];
        if (value === undefined
          || (typeof value === "number" && !value?.toString().length)
          || (typeof value === "string" && !value?.length)
          || (Array.isArray(value) && !value?.length)
          || ['pagesTotal', 'registersTotal'].includes(key)
        ) {
          return param;
        }

        if (Array.isArray(value)) {
          value.forEach((v, i) => {
            param = param.append(key, v)
          });
        } else {
          param = param.set(key, value)
        }
        return param;
      }, new HttpParams());

    return this._postsHttpClient.get<any>(`/List`, { params: formData })
      .pipe(
        map((r) => {
          const posts = r.posts.map((p: any) => Object.assign(new Post(), {
            ...p,
            mediaUrls: p.mediaUrlsSigned,
            ...this.getCanEditOrRemovePost(p),
            isUpdated: new Date(p.lastUpdateDate).getTime() > new Date(p.inclusionDate).getTime(),
          }));
          return {
            ...r,
            posts
          };
        }),
        tap((response) => {
          this._posts = (this._posts || []).concat(response.posts
            .filter((p: Post) => !this._posts?.find(pf => pf.id === p.id))
          );

          this._currentPage = response.page;
          this.setFilterInPage();

          this._loadedPages = (this._loadedPages || []).concat({ ...response.page });
          this._pageChanged.next(this._currentPage);
          this._postsUpdated.next(this._posts)
          this.setLoading(false);
        }),
      );
  }

  /**
   * Check if posts already in posts list
   * @param id
   */
  checkPostIsInList(post: Post): boolean {
    return this._posts.some(p => p.id === post.id);
  }

  private getCanEditOrRemovePost(post: Post): { canEdit: boolean, canRemove: boolean } {
    const hasAccess = !post?.applicationRegistry && (
      post.originator?.originatorId.toUpperCase() === this._userServie.userId$.toUpperCase() ||
      this._userServie.user.roles.some(role => role.roleId.toUpperCase() === '7713050A-015D-4B9F-B847-00004289D9F5') ||
      this._userServie.user.isSolvaceAdmin
    );
    return { canEdit: hasAccess,canRemove: hasAccess}
  }

  /**
   * Request for a post by id
   * @param id
   */
  getPost(id: string): Observable<Post> {
    return this._postsHttpClient.get<any>(`/Post/${id}`).pipe(
      map((p) => {
        return Object.assign(new Post(), {
          ...p,
          mediaUrls: p.mediaUrlsSigned,
          isUpdated: new Date(p.lastUpdateDate).getTime() > new Date(p.inclusionDate).getTime(),
          ...this.getCanEditOrRemovePost(p),
        })
      })
    );
  }

  /**
   * Return current filter
   */
  getFilter() {
    return this._postFilter;
  }

  /**
   * Sets the post list loading status
   * @param status
   */
  setLoading(status: boolean) {
    this.loading = status;
    this.loadingChange.next(this.loading);
  }

  /**
   * Defines the data that will be used to filter posts
   * @param filter
   */
  setFilter(filter: PostFilters) {
    this._postFilter = filter;
    this.filterChanged$.emit(this._postFilter);
  }

  setFilterFavorites(active: boolean) {
    if (!this._postFilter)
      this._postFilter = {};
    this._postFilter.onlyBookmarked = active;
  }

  /**
   * Set the text that will be used to filter posts
   * @param term
   */
  setFilterText(term: string) {
    if (!this._postFilter || !Object.keys(this._postFilter).length) {
      this._postFilter = {};
    }
    this._postFilter.searchTerm = term;
  }

  /**
   * Set post list order
   * @param order
   */
  setOrder(order: PostOrdenation) {
    this._currentPage.ordenationOption = order;
  }

  /**
   * Request for loading next page
   */
  setNextPage() {

    const nextPage = this._currentPage.pageIndex + 1;

    if (nextPage > this._currentPage.pagesTotal)
      return of({});

    if (this._loadedPages.some(p => p.pageIndex === nextPage))
      return of({});

    this._currentPage.pageIndex = nextPage;

    return this.getPosts();
  }

  /**
   * Refresh current list of posts
   */
  resetList() {
    this._posts = [];
    this._loadedPages = [];
    this._currentPage = this._defaultPage;
    this.setFilterInPage();
    this._postsUpdated.next(this._posts);
  }

  /**
   * Adds a new post
   * @param post
   */
  addPost(post: Post) {
    return this._postsHttpClient.post<{ postId: string }>('/Post', post);
  }

  /**
   * Request to update a post
   * @param post
   */
  updatePost(post: Post) {
    return this._postsHttpClient.put<{ postId: string }>(`/Post`, post);
  }

  /**
   * Request to remove a post
   * @param post
   */
  deletePost(post: Post) {
    return this._postsHttpClient.delete(`/Post`, {
      body: {
        id: post.id,
        site: { siteId: post.site.siteId },
      }
    }).pipe(
      tap((resp: any) => {
        this._posts = this._posts.filter(fpost => fpost.id !== resp["postId"]);
        this._postsUpdated.next(this._posts);
      })
    );
  }

  /**
   * Adicionar um post no começo da listagem de posts atual
   * @param post
   */
  unshiftPost(post: Post) {
    this._posts = [
      post,
      ...this._posts ?? [],
    ];
    this._postsUpdated.next(this._posts)
  }

  /**
   * Atualiza o post com os dados do post informado
   * @param post
   */
  replacePost(post: Post) {
    const index = this._posts.findIndex(p => p.id === post.id);
    if (index >= 0) this._posts[index] = post;
    this._postsUpdated.next(this._posts);
  }

  /**
   * Remove a post from the current listing
   * @param post
   */
  removePost(post: Post) {
    this._posts = this._posts.filter(p => p.id !== post.id);
    this._postsUpdated.next(this._posts);
  }

  /**
   * Send an image and return its ID
   * @param formData
   */
  addPostMedia(formData: FormData) {
    return this._postsHttpClient.put('/Media', formData);
  }

  /**
   * Search for posts in the active list
   * @param id
   */
  getPostFromList(id: string): Post {
    return this._posts.find(p => p.id === id);
  }

  /**
   * Search all media of a post by ID
   * @param id
   */
  getPostsMedias(id: any) {
    return this._postsHttpClient.get<{ mediaUrls: PostMedia[] }>(`/Media/`, {
      params: { id: id }
    });
  }

  /**
   * Search all shared users from post
   * @param id
   */
  getAllSharedUsersByPostId(id: string) {
    return this._postsHttpClient.get<PostShared[]>(`/Share/${id}`);
  }

  mentionHashtag(name: string) {
    return this._http.get<{
      hashtags: {
        hashtagId: number,
        name: string,
        hashtagTypeId: number,
        createUserId: string,
        createDate: Date,
        openToSubscribe: boolean,
        isGlobal: boolean,
        siteId: number,
        active: boolean
      }[],
      page: {
        pageIndex: number,
        pageSize: number,
        pagesTotal: number,
        registersTotal: number
      }
    }>(`${this._appConfig.APIs.apiUrlHashtag}/Post/Paginated`, {
      params: {
        name: name,
        active: true,
        pageIndex: 1,
        pageSize: 100
      }
    });
  }

  getHashTagById(ids: number[]) {
    return this._http.get<{
      hashtagId: number,
      name: string,
      hashtagTypeId: number,
      createUserId: string,
      createDate: Date,
      openToSubscribe: boolean,
      isGlobal: boolean,
      siteId: number,
      active: boolean
    }[]>(`${this._appConfig.APIs.apiUrlHashtag}/Post/ByIds`, { params: { hashtagId: ids } });
  }

  /**
   * Request to translate the post text
   * @param terms
   */
  translatePost(terms: string): Observable<{ terms: string[] }> {
    return this._http.get<{ terms: string[] }>(this._appConfig.APIs.apiUrlMultilingual + '/Translate', {
      params: { terms }
    })
  }

  /**
   * Request to share the post with informed users and teams
   * @param params
   */
  sharePost(params: {
    id: string,
    userShares: { userId: string }[],
    teamShares: { teamId: number, siteId: number }[]
  }) {
    return this._postsHttpClient.put('/Share', params);
  }

  /**
   * Set the current filter on the current page
   * @private
   */
  private setFilterInPage() {
    this._currentPage.inclusionStartDate = this._postFilter?.inclusionStartDate?.toString() || undefined;
    this._currentPage.inclusionEndDate = this._postFilter?.inclusionEndDate?.toString() || undefined;
    this._currentPage.originatorIds = this._postFilter?.originatorIds || [];
    this._currentPage.hashTagIds = this._postFilter?.hashTagIds || [];
    this._currentPage.siteIds = this._postFilter?.siteIds || [];
    this._currentPage.searchTerm = this._postFilter?.searchTerm || undefined;
    this._currentPage.onlyBookmarked = this._postFilter?.onlyBookmarked || undefined;
  }
}
