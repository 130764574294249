import { Inject, Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { signIn, updatePassword, signInWithRedirect, signOut, getCurrentUser, fetchAuthSession, fetchUserAttributes, updateUserAttribute, confirmSignIn, confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import { CognitoUser, EnvironmentInfo, SsoItem } from './model/environment.info.model';
import { AWSAmplifyAuthInfo, AWSAmplifyAuthResult, AWSAmplifyLoginResponse } from './model/aws-amplify-auth-info';
import { APPNAME } from '../Constants/app-injection-token';
import { environment } from 'projects/environments/environment';

// const debug = !environment.isLocalhost && (environment.isDEV || environment.isQA || environment.isRC);
// Logger.LOG_LEVEL = debug ? 'DEBUG' : 'INFO';
// window['LOG_LEVEL'] = debug ? 'DEBUG' : 'INFO';

@Injectable({
  providedIn: 'root',
})
export class AuthCognitoService {

  private _environmentURL: string = location.origin;
  
  public setEnvironmentURL(envURL: string) {
    this._environmentURL = envURL;
  }

  constructor(
    @Inject(APPNAME) public APPNAME: string,
  ) { }

  hasCognitoConfig(): boolean {
    return JSON.stringify(Amplify.getConfig() ?? {}) !== '{}';
  }

  applyCongnitoConfig(env: EnvironmentInfo): void {
    Amplify.configure({
      Auth: new AWSAmplifyAuthInfo(env, this.APPNAME),
    });
  }

  async ssoSignIn(sso: SsoItem): Promise<void> {
    await signInWithRedirect({
      provider: {
        custom: sso.id,
      }
    })
  }

  async isAlreadyAuthenticated(): Promise<boolean> {
    try {
      const user = await this.getCurrentUser();
      return !!(user);
    }
    catch {
      return false;
    }
  }

  async signIn(credentials: { user: string, password: string }): Promise<AWSAmplifyLoginResponse> {
    if (await this.isAlreadyAuthenticated()) {
      return {
        result: AWSAmplifyAuthResult.Success,
        currentUser: await this.getCurrentUser(),
      };
    }
    const response = await signIn({
      username: credentials.user,
      password: credentials.password,
    });

    if (response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
      return {
        result: AWSAmplifyAuthResult.NewPasswordRequired,
        currentUser: await this.getCurrentUser(),
      };
    }
    return {
      result: AWSAmplifyAuthResult.Success,
      currentUser: await this.getCurrentUser(),
    };
  }

  async getCurrentUser(): Promise<CognitoUser | null> {
    try {
      const currentUser = await getCurrentUser();
      const session = await fetchAuthSession();
      const attributes = await fetchUserAttributes();
      const user = <CognitoUser>{
        ssoId: session.userSub,
        ssoUsername: currentUser.username,
        username: (attributes ? ((attributes.identities && attributes.email) ? attributes.email : currentUser.username.replace(/^sso_|(sso[1-4]_)/g, '')) : currentUser.username),
        accessToken: session.tokens.accessToken.toString(),
        firstName: attributes.name,
        lastName: attributes.family_name,
        fullName: attributes.name + (attributes.family_name ? ` ${attributes.family_name}` : ''),
        email: attributes.email,
        isFederated: !!attributes && !!attributes.identities,
        baseUrl: this._environmentURL,
      };
      return user;
    }
    catch {
      return null;
    }
  }

  async sendVerificationCode(username: string): Promise<void> {
    const response = await resetPassword({ username });
    if (response.nextStep.resetPasswordStep !== 'CONFIRM_RESET_PASSWORD_WITH_CODE' && !response.isPasswordReset) {
      throw new Error();
    }
  }

  async resetPasswordWithVerificationCode(confirmationCode: string, username: string, newPassword: string): Promise<void> {
    await confirmResetPassword({ username, confirmationCode, newPassword });
  }

  async changeTemporaryPassword(password: string): Promise<void> {
    const response = await confirmSignIn({ challengeResponse: password });
    if (!response.isSignedIn) {
      throw new Error();
    }
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return await updatePassword({ newPassword, oldPassword });
  }

  async signOut(): Promise<void> {
    try {
      await signOut({ global: true });
    }
    catch (e) {
      console.error(e);
      return;
    }
  }

  async getUserAttribute(name: string) {
    try {
      const attributes = await fetchUserAttributes();
      return attributes?.[name];
    } catch {
      return null;
    }
  }

  async setUserAttribute(name: string, value: any) {
    try {
      return await updateUserAttribute({userAttribute: { attributeKey: name, value, }});
    } catch {
      return null;
    }
  }
}
