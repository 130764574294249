import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { AbstractCounterBusStop } from '../abstract-counter-bus-stop';
import { map, Observable } from 'rxjs';
import { GetStatusByIdParams, GetStatusByIdResponse } from './bookmark-status-classes';

@Injectable({
  providedIn: 'root',
})
export class SvcBookmarkStatusService extends AbstractCounterBusStop<GetStatusByIdParams, GetStatusByIdResponse> {

  protected get busAwaitingTime(): number {
    return 100;
  }

  constructor(
    protected appConfig: AppEnvironmentConfig,
  ) {
    super({
      apiURL: appConfig.APIs.apiUrlBookmark,
      idBatchParamName: 'registryUniqueIds',
      registryIdFilterPropName: 'registryUniqueId',
      idBatchRequestPath: '/ListId/ByIds',
      uidBatchParamName: 'registryUniqueUIds',
      registryUidFilterPropName: 'registryUniqueUId',
      uidBatchRequestPath: '/ListUId/ByUIds',
    });
  }

  public getStatusByRegistryId(registryId: number | string, params: GetStatusByIdParams): Observable<GetStatusByIdResponse> {
    return this.getCountByIdOrUid(registryId, params).pipe(
      map((result) => result.length > 0 ? result[0] : null),
    );
  }
}

