import { Injectable } from '@angular/core';
import {
  ActionPlanDrillDownStatusBadge,
  LibServiceActionplanAnalyticsService
} from 'projects/lib-service-actionplan-analytics/src/public-api';
import {
  InspectionDrillDownStatusBadge as ChecklistInspectionDrillDownStatusBadge,
  LibServiceChecklistAnalyticsService
} from 'projects/lib-service-checklist-analytics/src/public-api';
import {
  DefectTagDrillDownStatusBadge,
  LibServiceDefecttagAnalyticsService
} from 'projects/lib-service-defecttag-analytics/src/public-api';
import {
  InspectionDrillDownStatusBadge as LilInspectionDrillDownStatusBadge,
  LibServiceCilAnalyticsService
} from 'projects/lib-service-cil-analytics/src/public-api';
import { CommomAnalyticsIndicator } from "./models/commom-analytics-indicator";
import { Subject, Subscription, catchError, finalize, tap, throwError } from "rxjs";
import { TasksModule } from "./models/tasks-module";
import { TasksFilter } from "./models/tasks-filter";
import { TasksCategory } from './models/tasks-category';
import { getCustomThemeColor, HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { SettingsService } from '../settings/services/settings.service';
import { StaticApplicationId } from '../../Constants/static-application-id.enum';

export enum MainTasksIndicatorType {
  NONE,
  USERS,
  SUBORDINATES,
}

@Injectable({
  providedIn: 'root'
})
export class MainTasksService {

  hasFilter: boolean = false;
  _allTasks: any = {};

  private set allTasks(value: any) {
    value[TasksCategory.LATE] = this.#sortTasks(value[TasksCategory.LATE], false);
    value[TasksCategory.TODAY] = this.#sortTasks(value[TasksCategory.TODAY], false);
    value[TasksCategory.TOMORROW_UP_TO_7_DAYS] = this.#sortTasks(value[TasksCategory.TOMORROW_UP_TO_7_DAYS], true);
    value[TasksCategory.MORE_THAN_7_DAYS] = this.#sortTasks(value[TasksCategory.MORE_THAN_7_DAYS], true);

    this._allTasks = value;
  }
  
  public get allTasks() {
    return this._allTasks;
  }

  public indicatorsLoadingType = MainTasksIndicatorType.NONE;
  private _allTasksUpdated = new Subject<any>();
  allTasksUpdated$ = this._allTasksUpdated.asObservable();

  private _isAllTasksLoading = new Subject<any>();
  isAllTasksLoading$ = this._isAllTasksLoading.asObservable();

  private _hasError = new Subject<{ hasError: boolean, apiName?: string }>();
  hasError$ = this._hasError.asObservable();

  private filter: TasksFilter;

  private _taskFiltering = new Subject<TasksFilter>();
  taskFiltering$ = this._taskFiltering.asObservable();

  constructor(
    private _settingsService: SettingsService,
    private _checklistAnalyticsService: LibServiceChecklistAnalyticsService,
    private _actionPlanAnalyticsService: LibServiceActionplanAnalyticsService,
    private _defectTagAnalyticsService: LibServiceDefecttagAnalyticsService,
    private _cilAnalyticsService: LibServiceCilAnalyticsService,
  ) {
  }

  #sortTasks(tasks: any[], ascending: boolean): any[] {
    const moduleOrder = [TasksModule.ACTIONPLAN, TasksModule.DEFECTTAG, TasksModule.CIL, TasksModule.CHECKLIST];
    return tasks?.sort((a, b) =>
      (ascending ? a.deadline - b.deadline : b.deadline - a.deadline) ||
      moduleOrder.indexOf(a.moduleName) - moduleOrder.indexOf(b.moduleName)
    ) || [];
  }

  filterTasks(filter: TasksFilter) {
    this.filter = filter;
    if (filter) {
      let filtered = {};
      Object.keys(this.allTasks).forEach((key) => {
        filtered = {
          ...filtered,
          [key]: [...(this.allTasks[key])
            .filter((x: any) => x.moduleName == filter.module)
          ]
        }
      });
      this.hasFilter = true;
      this._allTasksUpdated.next({ ...filtered });
    } else {
      this.hasFilter = false;
      this._allTasksUpdated.next(this.allTasks);
    }

    this._taskFiltering.next(this.filter);
  }


  #getTasksByTypeAndModules(type: TasksCategory, onlyModules: TasksModule[], isGetAll: boolean): any[] {
    return isGetAll ? [] : (this._allTasks[type]?.filter((item) => !onlyModules.includes(item.moduleName)) ?? []);
  }

  #getOnlyModules(only: { checklist?: boolean, lil?: boolean, actionPlan?: boolean, defectTag?: boolean }): TasksModule[] {
    const moduleMap: Record<string, TasksModule> = {
      checklist: TasksModule.CHECKLIST,
      lil: TasksModule.CIL,
      actionPlan: TasksModule.ACTIONPLAN,
      defectTag: TasksModule.DEFECTTAG
    };
    const modules: TasksModule[] = [];
    
    if (only) {
      Object.keys(only || {}).forEach(key => {
        if (only?.[key])
          modules.push(moduleMap?.[key]);
      });
    }
    return modules;
  }

  #setAllTasks(onlyModules: TasksModule[], isGetAll: boolean): void {
    if (isGetAll) this._allTasks = {};
    const categories = [
      TasksCategory.LATE,
      TasksCategory.TODAY,
      TasksCategory.TOMORROW_UP_TO_7_DAYS,
      TasksCategory.MORE_THAN_7_DAYS
    ];

    categories.forEach(category =>
      this.allTasks[category] = this.#getTasksByTypeAndModules(category, onlyModules, isGetAll)
    );

    this._allTasks = {
      ...this._allTasks,
      count: Object.fromEntries(categories.map(category => [category, 0])),
      loaded: Object.fromEntries(categories.map(category => [category, false])),
    };
  }

  #getOnlyData(
    only: {
      checklist?: boolean,
      lil?: boolean,
      actionPlan?: boolean,
      defectTag?: boolean
    }) {
    if (only && Object.keys(only).some((key) => only[key])) {
      only = {
        checklist: only.checklist ?? false,
        lil: only.lil ?? false,
        actionPlan: only.actionPlan ?? false,
        defectTag: only.defectTag ?? false,
      }
    }

    return only;
  }

  getUserDrillDowns(only?: { checklist?: boolean, lil?: boolean, actionPlan?: boolean, defectTag?: boolean }, type = TasksCategory.TODAY) {
    this.indicatorsLoadingType = MainTasksIndicatorType.USERS;
    only = this.#getOnlyData(only);
    const onlyModules: TasksModule[] = this.#getOnlyModules(only);
    this.#setAllTasks(onlyModules, !(onlyModules.length));

    this._allTasksUpdated.next(this.allTasks);

    let requests: Subscription[] = [];
    this._isAllTasksLoading.next(true);

    const services = [
      { key: 'actionPlan', service: this._actionPlanAnalyticsService.getActionPlanCategorizedDrillDown.bind(this._actionPlanAnalyticsService), addFn: this.addActionPlanTasks.bind(this) },
      { key: 'checklist', service: this._checklistAnalyticsService.getInspectionCategorizedDrillDown.bind(this._checklistAnalyticsService), addFn: this.addChecklistTasks.bind(this) },
      { key: 'lil', service: this._cilAnalyticsService.getInspectionCategorizedDrillDown.bind(this._cilAnalyticsService), addFn: this.addLilTasks.bind(this) },
      { key: 'defectTag', service: this._defectTagAnalyticsService.getDefectTagCategorizedDrillDown.bind(this._defectTagAnalyticsService), addFn: this.addDefectTagTasks.bind(this) }
    ];

    services.forEach(({ key, service, addFn }) => {
      if (only?.[key] ?? true) {
        requests.push(service(type).pipe(
          tap((drillDown: any[]) => {
            drillDown.forEach((ddlItem: any) => this.allTasks = addFn(ddlItem));
            this._allTasksUpdated.next(this.allTasks);
          }),
          catchError((error) => {
            this.handleError(error, key);
            return throwError(() => error);
          }),
          finalize(() => this.#checkIfAllDone(requests, type))
        ).subscribe());
      }
    });
  }

  #checkIfAllDone(requests: Subscription[], type: TasksCategory): void {
    if (requests.every(request => request.closed)) {
      this.allTasks.loaded[type] = true;
      this._isAllTasksLoading.next(false);
    }
  }

  getSubordinatesDrillDowns(only?: { checklist?: boolean, lil?: boolean, actionPlan?: boolean, defectTag?: boolean }, type = TasksCategory.TODAY) {
    this.indicatorsLoadingType = MainTasksIndicatorType.SUBORDINATES;
    only = this.#getOnlyData(only);
    const onlyModules: TasksModule[] = this.#getOnlyModules(only);
    this.#setAllTasks(onlyModules, !(onlyModules.length));

    this._allTasksUpdated.next(this.allTasks);

    let requests: Subscription[] = [];
    this._isAllTasksLoading.next(true);

    const services = [
      { key: 'actionPlan', service: this._actionPlanAnalyticsService.getActionPlanCategorizedDrillDownSubordinates.bind(this._actionPlanAnalyticsService), addFn: this.addActionPlanTasks.bind(this) },
      { key: 'checklist', service: this._checklistAnalyticsService.getInspectionCategorizedDrillDownSubordinates.bind(this._checklistAnalyticsService), addFn: this.addChecklistTasks.bind(this) },
      { key: 'lil', service: this._cilAnalyticsService.getInspectionCategorizedDrillDownSubordinates.bind(this._cilAnalyticsService), addFn: this.addLilTasks.bind(this) },
      { key: 'defectTag', service: this._defectTagAnalyticsService.getDefectTagCategorizedDrillDownSubordinates.bind(this._defectTagAnalyticsService), addFn: this.addDefectTagTasks.bind(this) }
    ];

    services.forEach(({ key, service, addFn }) => {
      if (only?.[key] ?? true) {
        requests.push(service(type).pipe(
          tap((drillDown: any[]) => {
            drillDown.forEach((ddlItem: any) => this.allTasks = addFn(ddlItem));
            this._allTasksUpdated.next(this.allTasks);
          }),
          catchError((error) => {
            this.handleError(error, key);
            return throwError(() => error);
          }),
          finalize(() => this.#checkIfAllDone(requests, type))
        ).subscribe());
      }
    });
  }

  private handleError(error: any, source: string) {
    this._hasError.next({ hasError: true, apiName: source });
    return throwError(() => error);
  }

  private addChecklistTasks(ddlItem: any) {
    return {
      ...this.allTasks,
      [ddlItem.indicatorEnum]: [
        ...(this.allTasks[ddlItem.indicatorEnum] || []),
        ...ddlItem.indicatorItems
          .map((item) => ({
            id: item.checkListId,
            date: item.checkListCreateDate,
            label: 'CHK',
            inspectionId: item.inspectionId,
            registryNumber: item.inspectionRegistryNumber,
            description: item.checkListName,
            moduleName: TasksModule.CHECKLIST,
            status: ChecklistInspectionDrillDownStatusBadge(item.checkListStatus),
            locked: item.isPrivate,
            owners: item.owners?.map((owner) => ({
              id: owner.ownerId,
              userName: owner.ownerName,
              picture: owner.userPictureFile,
              ownerType: owner.ownerType ?? 1,
              initialsColor: owner.initialColor ? owner.initialColor : getCustomThemeColor(this._settingsService.theme$, 500),
            })),
            deadline: item.deadline ?? item.age,
            itemsData: {
              total: item.inspectionItemsNotOk + item.inspectionItemsOk + item.inspectionItemsPending,
              totalPerformed: item.inspectionItemsOk + item.inspectionItemsNotOk,
              data: [
                { label: 'OK', value: item.inspectionItemsOk, color: '#466900' },
                { label: 'NOK', value: item.inspectionItemsNotOk, color: '#f44336', customClass: 'cursor-pointer' },
                { label: 'PENDENTE', value: item.inspectionItemsPending, color: '#d4d4d4' }
              ]
            }
          }))
      ],
      count: {
        ...(this._allTasks?.count || {}),
        [ddlItem.indicatorEnum]: this.#getCountTasks(ddlItem.indicatorEnum, ddlItem?.indicatorItemsCounter)
      }
    }
  }

  private addActionPlanTasks(ddlItem: any) {
    return {
      ...this.allTasks,
      [ddlItem.indicatorEnum]: [
        ...(this.allTasks[ddlItem.indicatorEnum] || []),
        ...ddlItem.indicatorItems
          .map((item) => ({
            id: item.actionPlanId,
            date: item.createDate,
            label: 'PLA',
            description: item.actionPlanName,
            moduleName: TasksModule.ACTIONPLAN,
            status: ActionPlanDrillDownStatusBadge(item.status),
            hasBookmark: true,
            isBookmark: undefined,
            applicationId: StaticApplicationId.actionPlan,
            locked: item.isPrivate,
            owners: item.owners?.map((owner) => ({
              id: owner.userId,
              userName: owner.userName,
              picture: owner.pictureFile,
              ownerType: owner.ownerType ?? 1,
              initialsColor: owner.initialColor ? owner.initialColor : getCustomThemeColor(this._settingsService.theme$, 500),
            })),
            deadline: item.deadline ?? item.age,
          } as CommomAnalyticsIndicator))
      ],
      count: {
        ...(this._allTasks?.count || {}),
        [ddlItem.indicatorEnum]: this.#getCountTasks(ddlItem.indicatorEnum, ddlItem?.indicatorItemsCounter),
      }
    }
  }

  private addLilTasks(ddlItem: any) {
    return {
      ...this.allTasks,
      [ddlItem.indicatorEnum]: [
        ...(this.allTasks[ddlItem.indicatorEnum] || []),
        ...ddlItem.indicatorItems
          .map((item) => ({
            id: item.cilId,
            date: item.cilCreateDate,
            label: 'LIL',
            inspectionId: item.inspectionId,
            registryNumber: item.inspectionRegistryNumber,
            description: item.cilName,
            moduleName: TasksModule.CIL,
            status: LilInspectionDrillDownStatusBadge(item.cilStatus),
            locked: item.isPrivate,
            owners: item.owners?.map((owner) => ({
              id: owner.ownerId,
              userName: owner.ownerName,
              picture: owner.userPictureFile,
              ownerType: owner.ownerType ?? 1,
              initialsColor: owner.initialColor ? owner.initialColor : getCustomThemeColor(this._settingsService.theme$, 500),
            })),
            deadline: item.deadline ?? item.age,

            itemsData: {
              total: item.inspectionItemsNotOk + item.inspectionItemsOk + item.inspectionItemsPending,
              totalPerformed: item.inspectionItemsOk + item.inspectionItemsNotOk,
              data: [
                { label: 'OK', value: item.inspectionItemsOk, color: '#466900' },
                { label: 'NOK', value: item.inspectionItemsNotOk, color: '#f44336', customClass: 'cursor-pointer' },
                { label: 'PENDENTE', value: item.inspectionItemsPending, color: '#d4d4d4' }
              ]
            }
          }))
      ],
      count: {
        ...(this._allTasks?.count || {}),
        [ddlItem.indicatorEnum]: this.#getCountTasks(ddlItem.indicatorEnum, ddlItem?.indicatorItemsCounter),
      }
    }
  }

  private addDefectTagTasks(ddlItem: any) {
    return {
      ...this.allTasks,
      [ddlItem.indicatorEnum]: [
        ...(this.allTasks[ddlItem.indicatorEnum] || []),
        ...ddlItem.indicatorItems
          .map((item) => ({
            id: item.defectTagId,
            date: item.inclusionDate,
            label: 'DFT',
            hasBookmark: true,
            applicationId: StaticApplicationId.defectTag,
            description: item.defect,
            moduleName: TasksModule.DEFECTTAG,
            status: DefectTagDrillDownStatusBadge(item.status),
            owners: item.owners?.map((owner) => ({
              id: owner.userId,
              userName: owner.userName,
              picture: owner.pictureFile,
              ownerType: owner.ownerType ?? 1,
              initialsColor: owner.initialColor ? owner.initialColor : getCustomThemeColor(this._settingsService.theme$, 500),
            })),
            deadline: item.deadline ?? item.age,
          } as CommomAnalyticsIndicator))
      ],
      count: {
        ...(this._allTasks?.count || {}),
        [ddlItem.indicatorEnum]: this.#getCountTasks(ddlItem.indicatorEnum, ddlItem?.indicatorItemsCounter),
      }
    }
  }

  #getCountTasks(type: TasksCategory, indicatorItemsCounter: number): number {
    return (this._allTasks?.count?.[type] ?? 0) + (indicatorItemsCounter ?? 0);
  }
}
