import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { SvcDataCacheService } from '../cache/svc-data-cache.service';
import { SvcCacheName } from '../cache/svc-cache-name.enum';
import { SvcParamItem } from './svc-param-item.model';
import { SvcGlobalParamService } from './global-parameter.service';
import { SvcSiteParamService } from './site-parameter.service';

@Injectable({
  providedIn: 'root',
})
export class ParameterService {

  private allGlobalAppParamKeys = [
    'VERSION_ID',
    'IDLE_TIMEOUT',
    'API_URL',
    'FEED_ENABLE',
    'POST_ENABLE',
    'APP_OFFLINE_ENABLE',
    'COMMUNICATION_ENABLE',
  ];

  private allSiteAppParamKeys = [
    'SUBTITLE_ENABLE',
  ];

  constructor(
    private _globalParamService: SvcGlobalParamService,
    private _siteParamService: SvcSiteParamService,
    private _dataCacheService: SvcDataCacheService,
  ) { }

  public setCurrentSiteId(siteId: number) {
    this._siteParamService.setSiteId(siteId);
  }

  public getSiteParam(paramName: string, options?: { cache?: boolean }): Observable<string> {
    const type = 'site';
    const cachedValue = (options?.cache ?? true) ? this.getParamCachedValue(type, paramName) : null;
    return (cachedValue != null
      ? of({ key: paramName, value: cachedValue })
      : this._siteParamService.getParameterByParamName(paramName)
    ).pipe(
      map((res: SvcParamItem) => {
        (options?.cache ?? true) && this.setParamCachedValue(type, paramName, res.value);
        return typeof res === 'object' ? res.value : res;
      })
    );
  }

  public getSiteParams(paramsName: string[], options?: { cache?: boolean }): Observable<SvcParamItem[]> {
    const type = 'site';
    const cachedParams: SvcParamItem[] = (options?.cache ?? true)
      ? paramsName
        .map((p) => {
          const value = this.getParamCachedValue(type, p);
          return value != null ? <SvcParamItem>{ key: p, value } : null;
        })
        .filter((param) => !!(param))
      : [];
    paramsName = paramsName.filter((paramName) => !cachedParams.some((cached) => cached.key === paramName));
    return (
      !paramsName.length
      ? of(cachedParams)
      : this._siteParamService.getParameterByParamsName(paramsName).pipe(
        map((params) => {
          (options?.cache ?? true) && params.forEach((p) => this.setParamCachedValue(type, p.key, p.value));
          return [
            ...params,
            ...cachedParams,
          ];
        }),
      )
    );
  }

  public getGlobalParam(paramName: string, options?: { cache?: boolean }): Observable<string> {
    const type = 'global';
    const cachedValue = (options?.cache ?? true) ? this.getParamCachedValue(type, paramName) : null;
    return (cachedValue != null
      ? of({ key: paramName, value: cachedValue })
      : this._globalParamService.getParameterByParamName(paramName)
    ).pipe(
      map((res: SvcParamItem) => {
        (options?.cache ?? true) && this.setParamCachedValue(type, paramName, res.value);
        return typeof res === 'object' ? res.value : res;
      })
    );
  }

  public getGlobalParams(paramsName: string[], options?: { cache?: boolean }): Observable<SvcParamItem[]> {
    const type = 'global';
    const cachedParams: SvcParamItem[] = (options?.cache ?? true)
      ? paramsName
        .map((p) => {
          const value = this.getParamCachedValue(type, p);
          return value != null ? <SvcParamItem>{ key: p, value } : null;
        })
        .filter((param) => !!(param))
      : [];
    paramsName = paramsName.filter((paramName) => !cachedParams.some((cached) => cached.key === paramName));
    return (
      !paramsName.length
      ? of(cachedParams)
      : this._globalParamService.getParameterByParamsName(paramsName).pipe(
        map((params) => {
          (options?.cache ?? true) && params.forEach((p) => this.setParamCachedValue(type, p.key, p.value));
          return [
            ...params,
            ...cachedParams,
          ];
        }),
      )
    );
  }

  public getAllAppSiteParams(): Observable<SvcParamItem[]> {
    return this.getSiteParams(this.allSiteAppParamKeys);
  }

  public getAllAppGlobalParams(): Observable<SvcParamItem[]> {
    return this.getGlobalParams(this.allGlobalAppParamKeys);
  }

  private setParamCachedValue(type: 'site' | 'global', name: string, value: string): void {
    this._dataCacheService.set<string>(`${type.toUpperCase()}_PARAM_${name}` as SvcCacheName, value);
  }

  private getParamCachedValue(type: 'site' | 'global', name: string): string {
    return this._dataCacheService.get<string>(`${type.toUpperCase()}_PARAM_${name}` as SvcCacheName);
  }
}
