import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { Subscription } from 'rxjs';
import { SvcTotalsStatusService } from './svc-totals-status.service';
import { environment } from 'projects/environments/environment';

interface SvcTotalsStatus<T> {
  reactions: T;
  comments: T;
  views: T;
  actionplans: T;
}

@Component({
  selector: 'svc-totals-status',
  templateUrl: './svc-totals-status.component.html',
  styleUrls: ['./svc-totals-status.component.scss'],
  providers: [
    SvcTotalsStatusService,
  ]
})
export class SvcTotalsStatusComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public referenceId: number | string;
  @Input() public applicationId: string;
  @Input() public configTypeId: string;
  @Input() public featureName: string;
  @Input() public siteId: number;
  @Input() public external: boolean = false;
  @Input() public type: 'text' | 'icon' = 'icon';
  @Input() public mockMode: boolean = false;
  @Input() public hideIfEmpty: boolean = true;
  @Input() public available!: SvcTotalsStatus<boolean>;
  @Input() public classColor = 'text-default';
  @Input() public classLoading: string;
  @Input() public rotate: SvcTotalsStatus<boolean>;

  @Output() public reactionsClick = new EventEmitter<void>();
  @Output() public commentsClick = new EventEmitter<void>();
  @Output() public viewsClick = new EventEmitter<void>();
  @Output() public actionplansClick = new EventEmitter<void>();

  private _wasInitialized = false;
  private _requestSubscription: Subscription;

  public env = environment;
  public isLoading = false;
  public total = 0;
  public totals: SvcTotalsStatus<number> = {
    reactions: 0,
    comments: 0,
    views: 0,
    actionplans: 0,
  };
  protected totalKeysName = Object.keys(this.totals);
  protected totalsInfo: SvcTotalsStatus<{ name: { single: string, plural: string }, icon: string, clickEmitter: EventEmitter<void> }> = {
    reactions: {
      name: { single: 'Reação', plural: 'Reações' },
      icon: 'heroicons_solid:hand-thumb-up',
      clickEmitter: this.reactionsClick,
    },
    comments: {
      name: { single: 'Comentário', plural: 'Comentários' },
      icon: 'mat_solid:chat',
      clickEmitter: this.commentsClick,
    },
    views: {
      name: { single: 'Visualização', plural: 'Visualizações' },
      icon: 'mat_solid:visibility',
      clickEmitter: this.viewsClick,
    },
    actionplans: {
      name: { single: 'Plano de ação', plural: 'Planos de ação' },
      icon: 'heroicons_solid:exclamation-triangle',
      clickEmitter: this.actionplansClick,
    },
  };

  constructor(
    private _appSettings: SvcAppSettings,
    private _totalsStatusService: SvcTotalsStatusService,
  ) {}

  public ngOnInit(): void {
    this._get();
    this._wasInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('available' in changes && !this.available) {
      this.available = {
        reactions: false,
        comments: false,
        views: false,
        actionplans: false,
      };
    }
    if (this._wasInitialized && ('referenceId' in changes || 'applicationId' in  changes || 'featureName' in  changes || 'configTypeId' in  changes)) {
      this._get();
    }
  }

  private _get(only?: { reactions?: boolean, comments?: boolean, views?: boolean, actionplans?: boolean }) {
    if (!this.mockMode) {
      only = only ?? this.available;
      this._requestSubscription?.unsubscribe();
      this.isLoading = true;
      this._requestSubscription = this._totalsStatusService.getTotals({
        applicationId: this.applicationId ?? this._appSettings.applicationId,
        siteId: this.siteId,
        referenceId: this.referenceId,
        configTypeId: this.configTypeId,
        featureName: this.featureName,
        avoid: {
          reactions: !(only?.reactions ?? false),
          comments: !(only?.comments ?? false),
          views: !(only?.views ?? false),
          actionplans: !(only?.actionplans ?? false),
        }
      }, this.external).pipe(
        tap((response) => {
          this.totals.reactions = response.reactions ?? this.totals.reactions;
          this.totals.comments = response.comments ?? this.totals.comments;
          this.totals.views = response.views ?? this.totals.views;
          this.totals.actionplans = response.actionplans ?? this.totals.actionplans;
          this.total = Object.keys(this.totals).reduce((sum, key) => sum + (this.totals[key] ?? 0), 0);
        }),
        finalize(() => {
          this._requestSubscription = null;
          this.isLoading = false;
        }),
      ).subscribe();
    }
    else {
      this.totals = {
        reactions: 68,
        comments: 26,
        views: 250,
        actionplans: 128,
      }
      this.total = Object.keys(this.totals).reduce((sum, key) => sum + (this.totals[key] ?? 0), 0);
    }
  }

  public refresh(only?: { reactions?: boolean, comments?: boolean, views?: boolean, actionplans?: boolean }) {
    this._get(only);
  }

  ngOnDestroy(): void {
    this._requestSubscription?.unsubscribe();
  }
}
