<div class="relative w-full max-w-200" matDialogFullscreen="ALWAYS">
	<svc-media-carousel *ngIf="carouselHeight"
		[height]="carouselHeight"
		[items]="carouselItems"
		[initialItem]="carouselInitialItem"
		[canClickToExpandImage]="false"
		[isDark]="true"
		[fitContent]="config.fitContent"
		renderMode="one-by-one"
	/>
	<div class="flex m-auto pt-2" *ngIf="carouselItems.length > 1" [style.maxWidth]="'inherit'">
		<button mat-icon-button class="bg-transparent w-8 h-8 min-h-8 text-on-primary" [class.invisible]="mediasContainer.scrollWidth === mediasContainer.offsetWidth" (click)="prevMediasPreview()">
			<mat-icon class="text-current icon-size-6" svgIcon="heroicons_solid:chevron-left"/>
		</button>
		<div #mediasContainer class="flex-auto overflow-hidden">
			<div class="flex gap-2 w-fit m-auto">
				<div *ngFor="let item of carouselItems" class="w-15 h-[30px] rounded-lg bg-gray-900 overflow-hidden cursor-pointer" (click)="carousel?.setCurrent(item)">
					<img *ngIf="item.type === 'image'" class="height-inherit w-full object-cover" [src]="item.url"/>
					<video *ngIf="item.type === 'video'" class="height-inherit w-full object-cover" [src]="item.url" [controls]="false"></video>
				</div>
			</div>
		</div>
		<button mat-icon-button class="bg-transparent w-8 h-8 min-h-8 text-on-primary" [class.invisible]="mediasContainer.scrollWidth === mediasContainer.offsetWidth" (click)="nextMediasPreview()">
			<mat-icon class="text-current icon-size-6" svgIcon="heroicons_solid:chevron-right"/>
		</button>
	</div>
	<button class="img-viewer-close-btn absolute top-3 right-3 text-white" mat-icon-button [matTooltip]="'Fechar' | transloco" matTooltipPosition="above" (click)="close()">
		<mat-icon class="text-current" svgIcon="mat_solid:close"></mat-icon>
	</button>
</div>