<div 
  class="gap-4 mb-2 w-full empty:hidden"
  #optionSubtitle 
  [class]="(isFeatureEnabled && transcriptionStatus) || isLoading ? 'flex' : 'hidden'"
>
  <div *ngIf="isFeatureEnabled && transcriptionStatus && !isLoading" class="flex-auto">
    <svc-button class="-mx-2 my-2" mode="simple" *ngIf="transcriptionStatus === subtitleStatus.NONE" (click)="transcriptionOrder()">
      <div class="flex items-center gap-2">
        <mat-icon class="text-primary icon-size-5" svgIcon="heroicons_solid:document-text"></mat-icon>
        <span class="text-primary font-semibold">{{'Gerar legendas' | transloco}}</span>
      </div>
    </svc-button>
  
    <div *ngIf="transcriptionStatus === subtitleStatus.ERROR" class="w-full min-h-[47px] flex items-center gap-2 py-1.5 pl-4 pr-2 text-sm bg-gray-400 text-white">
      <mat-icon class="text-current icon-size-6" svgIcon="heroicons_solid:exclamation-triangle">
      </mat-icon>

      <span class="flex-auto text-sm">
        {{ 'Houve um erro ao buscar o status de legendas para o vídeo abaixo' | transloco}}
      </span>
      
      <button mat-icon-button class="text-white w-6 min-w-6 min-h-6 h-6" [matTooltip]="'Tentar novamente' | transloco" matTooltipPosition="above" (click)="getStatusTranscription()">
        <mat-icon class="text-current icon-size-6" svgIcon="heroicons_solid:arrow-path"></mat-icon>
      </button>
    </div>
  
    <div *ngIf="transcriptionStatus === subtitleStatus.WAITING" class="w-full min-h-[47px] flex items-center gap-2 py-1.5 pl-4 pr-2 text-sm bg-primary-400 text-white">
      <mat-icon class="text-current icon-size-6" svgIcon="heroicons_solid:exclamation-circle"></mat-icon>
      <span class="text-sm">{{ 'As legendas estão sendo geradas, volte mais tarde para conferir o resultado' | transloco}}</span>
    </div>
  
    <div 
      class="flex items-center justify-between my-[11.5px] mx-2 w-fit h-fit gap-2" 
      *ngIf="transcriptionStatus === subtitleStatus.DONE" 
      (click)="toggleSubtitles()"
    >
      <svc-slide-toggle
        [checked]="subtitlesEnabled"
        [disabled]="isLoading"
      ></svc-slide-toggle>

      <div class="flex-auto cursor-pointer">
        <p class="text-base font-bold text-default">
          {{ 'Ativar legendas' | transloco }}
        </p>
      </div>
    </div>
  </div>

  <ngx-skeleton-loader *ngIf="isLoading" [theme]="{width: '80px', height: '34px', borderRadius: '0', margin: '8px 8px 0 8px' }"/>
</div>

<video
  class="w-full"
  controls  
  *ngIf="isFeatureEnabled" 
  [style.height]="height && transcriptionStatus ? 'calc(' + height + 'px - ' + (optionSubtitle?.offsetHeight ?? 0) + 'px)' : null"
  [attr.crossorigin]="subtitlesEnabled ? 'anonymous' : null" 
  [style.object-fit]="fitContent"
>
  <source [src]="videoSrc" type="video/mp4">  
  <track *ngIf="subtitlesEnabled" [src]="subtitleInternalUrl" srclang="default" label="default" kind="subtitles" default>
</video>

<video
  class="w-full"
  controls
  *ngIf="!isFeatureEnabled"
  [style.height]="height && transcriptionStatus ? 'calc(' + height + 'px - ' + (optionSubtitle?.offsetHeight ?? 0) + 'px)' : null"
  [src]="videoSrc"
  [style.object-fit]="fitContent"
></video>


