import { inject } from "@angular/core";

import { Subject, Subscription } from "rxjs";

import { UserPreferencesService } from 'projects/lib-shared-core/src/lib/features/user/services/user-preferences.service';
import { SvcUserPreferencesCustomProperties, SvcUserPreferenceFeatureCustomPorpertyKey, SvcUserPreferencesFeatures, SvcUserPreferencesFeatureFilters, SvcUserPreferenceFilterUpdate, SvcUserPreferencesFilters } from "./svc-user-preferences.model";

export interface SvcUserPreferencesEventsInterface<T> {
  featureName: string;
  customPropertyKey: SvcUserPreferenceFeatureCustomPorpertyKey;
  customPropertyType: 'string';
  customPropertyValue: string[];
  activeCustomProperty: T;
  applicationId: string;
  executeFirstTime?: boolean;
  customFilter?: string;
}

export class SvcUserPreferencesEvents<ActiveCustomProperty> {

  protected activeCustomProperty$ = new Subject<ActiveCustomProperty>();
  protected activeFilter$ = new Subject<SvcUserPreferencesFeatureFilters>();
  protected filtersList$ = new Subject<SvcUserPreferencesFeatureFilters[]>();

  private userPreferencesService = inject(UserPreferencesService);
  private userPreferencesFeatures: SvcUserPreferencesFeatures;
  private userPreferenceCustomPropertie: SvcUserPreferencesCustomProperties;
  private userPreferencesFeatureFilters: SvcUserPreferencesFeatureFilters[];
  private activeFilter: SvcUserPreferencesFeatureFilters;

  private requestSubscription: Subscription;
  private constructorWasExecuted = false;

  constructor(
    protected preferencesConfig?: SvcUserPreferencesEventsInterface<ActiveCustomProperty>,
  ) {
    this.getUserPreferencesFeatures();
    this.constructorWasExecuted = true;
  }

  private setUserPreferencesFeatures(userPreferences: SvcUserPreferencesFeatures[]): void {
    this.userPreferencesFeatures = userPreferences?.find((preference: SvcUserPreferencesFeatures) => preference.featureName === this.preferencesConfig.featureName);
  }

  private setUserPreferenceCustomPropertie(): void {
    this.userPreferenceCustomPropertie = this.userPreferencesFeatures?.customProperties?.find((customPropertie: SvcUserPreferencesCustomProperties) => customPropertie.propertyKey === this.preferencesConfig.customPropertyKey);
  }

  private updateActiveCustomProperty(): void {
    if (this.userPreferenceCustomPropertie?.propertyValue) {
      const isHaveCustomPropertie: boolean = this.preferencesConfig.customPropertyValue.includes(this.userPreferenceCustomPropertie.propertyValue);

      if (isHaveCustomPropertie) {
        this.preferencesConfig.activeCustomProperty = this.userPreferenceCustomPropertie.propertyValue as ActiveCustomProperty;
      }
    }
    if (this.constructorWasExecuted) this.activeCustomProperty$.next(this.preferencesConfig.activeCustomProperty);
    else setTimeout(() => this.activeCustomProperty$.next(this.preferencesConfig.activeCustomProperty));
  }

  private updateActiveFilter() {
    this.userPreferencesFeatureFilters = this.userPreferencesFeatures?.featureFilters;
    if (this.preferencesConfig?.customFilter) {
      const filter = this.userPreferencesFeatureFilters?.find(f => f.filterName === this.preferencesConfig.customFilter);    
      if (this.constructorWasExecuted) this.activeFilter$.next(filter);
      else setTimeout(() => this.activeFilter$.next(filter));
    }
    if (this.constructorWasExecuted) this.filtersList$.next(this.userPreferencesFeatures?.featureFilters);
    else setTimeout(() => this.filtersList$.next(this.userPreferencesFeatures?.featureFilters));
    
  }

  public getUserPreferencesFeatures(executeRequestNow?: boolean): void {
    if ((this.preferencesConfig.executeFirstTime ?? true) || executeRequestNow)
      this.userPreferencesService.getUserPreferencesFeatures(this.preferencesConfig.applicationId)
        .subscribe({
          next: (userPreferences: SvcUserPreferencesFeatures[]) => {
            this.setUserPreferencesFeatures(userPreferences);
            this.setUserPreferenceCustomPropertie();
            this.updateActiveCustomProperty();
            this.updateActiveFilter();
          },
          error: () => {
            if (this.constructorWasExecuted) this.activeCustomProperty$.next(null);
            else setTimeout(() => this.activeCustomProperty$.next(null));
          }
        });
  }

  private removeOldCustomPropertie(): void {
    if (this.userPreferencesFeatures?.customProperties?.length) {
      this.userPreferencesFeatures?.customProperties.forEach((customPropertie: SvcUserPreferencesCustomProperties, index: number) => {
        if (customPropertie?.propertyKey === this.preferencesConfig.customPropertyKey)
          this.userPreferencesFeatures.customProperties.splice(index, 1);
      });
    }
  }

  protected changePreferenceCustom(activeCustom?: ActiveCustomProperty): void {
    if (activeCustom) this.preferencesConfig.activeCustomProperty = activeCustom;

    this.userPreferenceCustomPropertie = {
      propertyKey: this.preferencesConfig.customPropertyKey,
      propertyType: this.preferencesConfig.customPropertyType,
      propertyValue: this.preferencesConfig.activeCustomProperty as string
    };

    this.removeOldCustomPropertie();

    this.userPreferencesFeatures = {
      featureName: this.preferencesConfig.featureName,
      customProperties: this.userPreferencesFeatures?.customProperties?.length ? [...this.userPreferencesFeatures?.customProperties, this.userPreferenceCustomPropertie] : [this.userPreferenceCustomPropertie],
      featureFilters: this.userPreferencesFeatures?.featureFilters?.length ? this.userPreferencesFeatures.featureFilters : []
    };

    this.requestSubscription?.unsubscribe();
    this.requestSubscription = this.userPreferencesService.updateUserPreferences(this.preferencesConfig.applicationId, this.userPreferencesFeatures).subscribe();
  }

  protected setPreferenceFilter(params:{ filterName: string, filter: { [key: string]: any } }) {
    const newFilter: SvcUserPreferenceFilterUpdate = {
      applicationId: this.preferencesConfig.applicationId,
      featureName: this.preferencesConfig.featureName,
      filterPreferences: {
        filterName: params.filterName,
        filters: Object.keys(params.filter)
          .filter(p => (typeof params.filter[p] === 'boolean' && typeof params.filter[p] !== 'undefined') ||  !!params.filter[p]?.length)
          .map((f) => {
          const value = params.filter[f];
          return {
            filterKey: f,
            filterType: this.getParamType(value),
            filterValue: this.getParamsValue(value)
          }
        }),
        lastFilter: true
      }
    };

    this.updateUserPreferencesFilter(newFilter);
  }

  protected setPreferenceFilters(filterName: string, filters: SvcUserPreferencesFilters[]) {
    const newFilter: SvcUserPreferenceFilterUpdate = {
      applicationId: this.preferencesConfig.applicationId,
      featureName: this.preferencesConfig.featureName,
      filterPreferences: {
        filterName: filterName,
        filters: filters ?? [],
        lastFilter: true
      }
    };
    this.updateUserPreferencesFilter(newFilter);
  }


  protected setPreferenceFilterCustomized(params:{ filterName: string, filter: { [key: string]: { value: any, type: string } } }) {
    const newFilter: SvcUserPreferenceFilterUpdate = {
      applicationId: this.preferencesConfig.applicationId,
      featureName: this.preferencesConfig.featureName,
      filterPreferences: {
        filterName: params.filterName,
        filters: Object.keys(params.filter)
          .filter(p => (typeof params.filter[p].value === 'boolean' && typeof params.filter[p].value !== 'undefined') ||  !!params.filter[p].value?.length)
          .map((f) => {
          const value = params.filter[f].value;
          const type = params.filter[f].type;
          return {
            filterKey: f,
            filterType: type ?? this.getParamType(value),
            filterValue: this.getParamsValue(value)
          }
        }),
        lastFilter: true
      }
    };

    this.updateUserPreferencesFilter(newFilter);
  }

  private updateUserPreferencesFilter(filter: SvcUserPreferenceFilterUpdate) {
    this.requestSubscription?.unsubscribe();
    this.requestSubscription = this.userPreferencesService.updateUserPreferencesFilter(filter)
      .subscribe({
        next: r => {
          if (r) {
            this.activeFilter = {
              filterName: filter.filterPreferences.filterName,
              filters: filter.filterPreferences.filters,
              lastFilter: filter.filterPreferences.lastFilter
            };
            this.activeFilter$.next(this.activeFilter);
          }
        },
        error: () => {}
      })
  }

  protected clearPreferenceFilter(filterName: string) {
    this.requestSubscription?.unsubscribe();
    this.requestSubscription = this.userPreferencesService.removeUserPreferencesFilter({
      filterName: filterName,
      featureName: this.preferencesConfig.featureName,
      applicationId: this.preferencesConfig.applicationId
    }).subscribe()
  }

  protected getParamType(param) {
    if (Array.isArray(param))
      return typeof param[0];
    return typeof param;
  }

  protected getParamsValue(param) {
    if (Array.isArray(param))
      return param.map(i => i.toString());
    return [ param.toString() ];
  }

  protected getArrayValueAsString(array) {
    if (Array.isArray(array))
      return array[0].toString();
    return array;
  }

  protected getArrayValueAsBoolean(array) {
    if (Array.isArray(array))
      return array[0] === 'true';
    return array;
  }
}
