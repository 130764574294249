export class SvcFeatureToggleItem<T> {
  value?: T;
  hasHave: boolean = false;

  public resetValue() {
    delete this.value;
    this.hasHave = false;
  }

  public setValue(value: T) {
    this.value = value;
    this.hasHave = true;
  }
}

export class SvcFeatureToggle {
  public feedIsEnabled = new SvcFeatureToggleItem<boolean>();
  public postIsEnabled = new SvcFeatureToggleItem<boolean>();
  public communicationIsEnabled = new SvcFeatureToggleItem<boolean>();
  public appOffilineEnabled = new SvcFeatureToggleItem<boolean>();
  public copilotIsEnabled = new SvcFeatureToggleItem<boolean>();
}