import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'svc-validation-message',
  templateUrl: './svc-validation-message.component.html',
  styleUrls: ['./svc-validation-message.component.scss'],
  providers: [
    DatePipe,
  ]
})
export class SvcValidationMessageComponent {

  private _validationMessage: any = {};
  @Input() control!: AbstractControl | null;

  constructor(
    private _datePipe: DatePipe,
    private _translocoService: TranslocoService,
  ) { }

  get validationMessage(): { message: string } {
    for (let validator in this.control?.errors) {
      if (this.control!.errors.hasOwnProperty(validator) && this.control!.touched) {
        this._validationMessage = this.formatValidationMessage(validator, this.control!.errors[validator]);
        return this._validationMessage;
      }
    }
    return null;
  }


  private formatValidationMessage(validator: string, value: any): any {
    let validationMessages: any = {
      required: () => ({ message: this._translocoService.translate('Campo obrigatório') }),
      mustMatch: () => ({ message: this._translocoService.translate('As senhas devem ser iguais') }),
      invalidDate: () => ({ message: this._translocoService.translate('Data inválida') }),
      minDate: (value: { date: Date, format: string }) => ({ message: `${this._translocoService.translate('A data deve ser maior ou igual a')} ${this._datePipe.transform(value.date, value.format)}` }),
      maxDate: (value: { date: Date, format: string }) => ({ message: `${this._translocoService.translate('A data deve ser menor ou igual a')} ${this._datePipe.transform(value.date, value.format)}` }),
      maxlength: (value: { requiredLength: number }) => ({ message: `${this._translocoService.translate('O conteúdo deve conter o número máximo de caracteres')}: ${value.requiredLength}`}),
      minlength: (value: { requiredLength: number }) => ({ message: `${this._translocoService.translate('O conteúdo deve conter o número mínimo de caracteres')}: ${value.requiredLength}`}),
      max: (value: { max: number }) => ({ message: `${this._translocoService.translate('O valor máximo é')}: ${value.max}`}),
      min: (value: { min: number }) => ({ message: `${this._translocoService.translate('O valor mínimo é')}: ${value.min}`}),
      invalid24Hour: () => ({ message: `${this._translocoService.translate('Insira um horário válido no formato HH:MM')}`})
    };
    return validationMessages.hasOwnProperty(validator)
      ? validationMessages[validator](value)
      : { message: 'O campo está com erro' };
  }
}
