import { AfterViewInit, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { catchError, first, forkJoin, map, Observable, switchMap, takeUntil } from 'rxjs';
import { Navigation, SvcDefaultNavigationItem } from 'projects/lib-shared-core/src/lib/features/navigation/services/navigation.types';
import { BaseLayoutComponent, SvcLayoutResolveStatus } from '../base-layout.component';
import { MainTasksService } from 'projects/lib-shared-core/src/lib/features/main-tasks/main-tasks.service';
import { AuthStatus } from 'projects/lib-shared-core/src/lib/auth/model/user-auth.model';
import { externalURLSamePageWithoutHistory } from 'projects/lib-shared-common/src/public-api';
import { StaticApplicationId } from 'projects/lib-shared-core/src/public-api';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'svc-custom-layout',
  templateUrl: './solvace-custom-layout.component.html',
  styleUrls: ['./solvace-custom-layout.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms 100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('250ms 100ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class SolvaceCustomLayoutComponent extends BaseLayoutComponent {
  public navigation: Navigation;
  public urlHome = `${location.origin}/myworkspace/`;
  public currentNavigationItem: SvcDefaultNavigationItem;

  private _mainTasksService = inject(MainTasksService);

  /**
   * Constructor
   */
  constructor() {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  public ngOnInit(): void {
    super.ngOnInit();
  }

  protected onLayoutResolving(): Observable<SvcLayoutResolveStatus> {
    return forkJoin({
      user: this._userService.get(),
      navigation: this._navigationService.get(this._appSettings.applicationId as StaticApplicationId),
      environment: this._userService.getEnvironment(),
    }).pipe(
      map(({ environment }) => {
        const userStatus = this._authService.userStatusAuth?.status;
        if (<AuthStatus>userStatus == AuthStatus.SupplierUser) {
          externalURLSamePageWithoutHistory(`${(environment).url}/suppliermanagement`, ``);
          return SvcLayoutResolveStatus.CustomRedirection;
        }

        if (<AuthStatus>userStatus == AuthStatus.ContractorUser) {
          externalURLSamePageWithoutHistory(`${(environment).url}/contractormanagement`, ``);
          return SvcLayoutResolveStatus.CustomRedirection;
        }

        if (<AuthStatus>userStatus == AuthStatus.PendingGDPRUser)
          return SvcLayoutResolveStatus.Error;
      }),
      catchError<any, any>((error: HttpErrorResponse) => {
        if (error?.status === 400) {
          return SvcLayoutResolveStatus.HasNoPermission;
        }
        else {
          return SvcLayoutResolveStatus.Error;
        }
      }),
    );
  }

  protected onLayoutResolvingFinished(): void {
    super.onLayoutResolvingFinished();

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
        if (this.navigation.default.length) {
          for (let item of this.navigation.default) {
            for (let childItem of item.children) {
              if (typeof childItem.url === 'string') {
                if (this._router.isActive(childItem.url, true)) {
                  item['expanded'] = true;
                  this.currentNavigationItem = item;
                  break;
                }
              }
            }
            if (this.currentNavigationItem != null) {
              break;
            }
          }
        }
      });

    this._mainTasksService.getUserDrillDowns();
  }

  /**
   * On destroy
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  protected toggleNavigation(): void {
    if (this._mediaQuery.currentSize.isBelowMD) {
      this.verticalNavigation.toggle();
    }
  }

  protected toggleNavigationRetraction() {
    this.verticalNavigation?.toggleRetraction();
    if (this.currentNavigationItem) {
      this.currentNavigationItem['expanded'] = !this.currentNavigationItem;
      this.currentNavigationItem = null;
    }
  }

  protected toggleCurrentNavigationItem(navigationItem: SvcDefaultNavigationItem) {
    if (navigationItem != this.currentNavigationItem) {
      if (this.currentNavigationItem != null) {
        this.currentNavigationItem['expanded'] = false;
      }
      navigationItem['expanded'] = true;
      this.currentNavigationItem = navigationItem;
    }
    else if (!this.verticalNavigation.isRetracted) {
      navigationItem['expanded'] = !this.currentNavigationItem;
      this.currentNavigationItem = null;
    }
    if (this.verticalNavigation.isRetracted) {
      this.verticalNavigation.toggleRetraction();
    }
  }
}
