import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

import { generateModuleMenuURL } from 'projects/lib-shared-common/src/public-api';
import { SvcAppSettings, UserService } from 'projects/lib-shared-core/src/public-api';
import { SvcActionPlanList, SvcActionPlanPagedList, SvcInsertPlanFromDraftPlanResponse } from '../interfaces/svc-actionplan-list.interface';
import { UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { LegacyService } from 'projects/lib-shared-feature/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class SvcActionPlanService {

  public activeComponentId: string;

  private readonly _apiActionPlan: string = this._appConfig.APIs.apiUrlActionPlan;

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _appSettings: SvcAppSettings,
    private _http: HttpClient,
    private _legacyService: LegacyService,
    private _translocoService: TranslocoService,
    private _userService: UserService
  ) {
    this._legacyService.addLegacySupport();
  }

  public openDialogActionPlanLegacy(systemReferenceId: number, planId: number, isEdit: boolean, isDraft: boolean, additionalReferenceId = 1, paramPlanName?: string): void {
    const applicationId: string = this._appSettings.applicationId;

    const url: string | Function = generateModuleMenuURL(
      'MESSAGE',
      location.origin,
      'openModal',
      `{
        "functionName": "OpenDynamicModal",
        "urlToOpen": "/actionplan/Plan/View",
        "modalData": {
          "flEdit": ${isEdit},
          "flDraft": ${isDraft},
          "planId": ${planId},
          "systemId": "${applicationId}",
          "systemAdditionalReferenceId": ${additionalReferenceId},
          "systemReferenceId": ${systemReferenceId}
        },
        "modalId": "#registry-action-plan-modal",
        "modalTimeout": 0,
        "loadingText": "${this._translocoService.translate('Carregando')}",
        "paramPlanName": "${this._translocoService.translate(paramPlanName) ?? ''}"
      }`
    );

    if (typeof url === 'function')
      url();
  }

  private _getActionsPlansParams(applicationId: string, referenceId: number, pageIndex: number, search?: string, additionalReferenceId?: number, pageSize?: number): HttpParams {
    let params = new HttpParams()
      .append('applicationId', applicationId)
      .append('referenceId', referenceId)
      .append('pageSize', pageSize ?? 10)
      .append('pageIndex', pageIndex)
      .append('search', search ?? '');

    if (additionalReferenceId)
      params = params.append('additionalReferenceId', additionalReferenceId);

    return params;
  }

  private _mapActionsPlans(actionPlans: SvcActionPlanList[]): SvcActionPlanList[] {
    return actionPlans?.map((actionPlan: SvcActionPlanList) => {
      return {
        ...actionPlan,
        delayedDays: Math.abs(actionPlan?.delayedDays),
        svcUserAvatar: {
          name: actionPlan?.senderName,
          picture: actionPlan?.senderPicture,
          initialsColor: actionPlan?.senderInitialsColor,
          size: UserAvatarSize.Table
        }
      }
    });
  }

  public getActionsPlans(referenceId: number, applicationId: string,  pageIndex: number, search: string, additionalReferenceId?: number, pageSize = 10): Observable<SvcActionPlanPagedList> {
    const params: HttpParams = this._getActionsPlansParams(applicationId, referenceId, pageIndex, search, additionalReferenceId, pageSize);

    return this._http.get(`${this._apiActionPlan}/Analytics/actionplan/linked/paged-list`, {
      params
    })
      .pipe(
        map((actionPlans: SvcActionPlanPagedList) => {
          actionPlans.data = this._mapActionsPlans(actionPlans?.data); 
          return actionPlans;
        })
      );
  }

  public insertPlanFromDraftPlan(tempId: number, registerId: number): Observable<SvcInsertPlanFromDraftPlanResponse> {
    const params: any = {
      siteId: this._userService.user.lastSiteId,
      applicationId: this._appSettings.applicationId,
      temp_id: tempId,
      registerId,
    };
    const supplierId: number = this._userService.user?.supplierId;
    if (supplierId)
      params.supplierId = supplierId;

    return this._http.get<SvcInsertPlanFromDraftPlanResponse>(`${location.origin}/helpers/actionPlan/InsertPlanFromDraftPlan`, {
      params
    });
  }
}
