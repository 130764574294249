import { ex } from "@fullcalendar/core/internal-common";
import { EChartsOption } from "echarts";

export interface ISvcChartStackedInfo {
  title: string;
  total: number;
  description: string;
  loading: boolean;
  error: any;
}

export interface ISvcChartStackedValue {
  name: string;
  value: number;
  isLast?: boolean;
  visualization?: boolean;
  borderRadius?: number[];
  labelDinamic?: any;
}
export interface ISvcChartStackedTooltip {
  backgroundColor?: string;
  formatter?:any;
}

export interface ISvcChartStackedSerie {
  name: string;
  type?: 'line' | 'bar';
  color: string | any;
  values: ISvcChartStackedValue[];
  minHeight?: number;
  maxWidth?: number;
  minWidth?: number;
  markLine?: any;
  label?: any;
  z?: number;
  zLevel?: number;
  yAxisIndex?: number;
}

export interface ISvcChartStackedCategorie {
  label: string;
  error?: boolean;
}

export interface ISvcChartStackedDefinitions {
  categories: ISvcChartStackedCategorie[];
  series: ISvcChartStackedSerie[];
  tooltip?: ISvcChartStackedTooltip;
  stacked?: boolean;
  showLegend?: boolean;
}

export enum ISvcChartStackedOrientation {
  HORIZONTAL,
  VERTICAL
}

export interface SvcChartStackedConfig extends Partial<EChartsOption> { }
