import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'projects/lib-shared-core/src/public-api';

@Component({
  selector: 'auth-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
})
export class AuthSignOutComponent implements OnInit {

  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _activatedRouter: ActivatedRoute,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Sign out
    this._authService.signOut(true).subscribe(() => {
      const user = this._activatedRouter.snapshot?.queryParamMap.get('user') ?? '';
      this._router.navigate(['/sign-in'], {
        queryParams: user ? { user } : null,
      });
    });
  }
}
