<svc-dialog 
  #dialog
  dialogStyle="form"
>

  <div svc-dialog-title class="text-xl font-bold">
    {{ "Compartilhar" | transloco }}
  </div>

  <div svc-dialog-content class="flex flex-col divide-y">

    <div class="flex flex-row w-full px-2 py-3 self-end">

      <mat-form-field class="flex-auto svc-mat-no-subscript svc-mat-dense">
        <input
          matInput
          [placeholder]="'Digite ao menos 3 caracteres' | transloco"
          type="text"
          [formControl]="searchControl"
          id="post-share-search"
        />
        <mat-icon
          matSuffix
          class="text-current icon-size-5"
          [svgIcon]="'heroicons_solid:magnifying-glass'"
        />
      </mat-form-field>

    </div>

    <div class="flex flex-col items-center gap-2 p-3 max-h-[50vh] overflow-y-auto text-default">

      <div class="flex flex-col gap-2 w-full">
        <ng-container
          *ngFor="let item of listSelected"
          [ngTemplateOutlet]="userTemplate"
          [ngTemplateOutletContext]="{ $implicit: item, selected: true }"
        />
      </div>

      <div class="flex flex-col gap-2 w-full">
        <ng-container
          *ngFor="let item of list"
          [ngTemplateOutlet]="userTemplate"
          [ngTemplateOutletContext]="{ $implicit: item, selected: false }"
        />
      </div>

      <mat-spinner
        *ngIf="!(list?.length) && (searching || !(listSelected?.length))"
        diameter="28"
        [class.invisible]="!searching"
        class="mt-4 mb-4 text-current"
      />
    </div>

  </div>

  <div svc-dialog-actions class="flex flex-row gap-2">
    <svc-button
      mode="stroked"
      type="button"
      [disabled]="loading"
      (click)="dialog.closeDialog()"
    >{{ 'Cancelar' | transloco }}</svc-button>

    <svc-button
      mode="flat"
      type="button"
      color="primary"
      [processing]="loading"
      (click)="shareWithSelectedUsers()"
    >{{'Compartilhar' | transloco}}</svc-button>

  </div>
  
</svc-dialog>


<ng-template #userTemplate let-item let-selected="selected">
  <div class="flex flex-row items-center w-full">

    <svc-smart-user-avatar [ids]="item.userId" [size]="48"/>

    <div class="grow flex flex-col pl-2.5">
      <span class="font-semibold leading-tight">{{ item.name }}</span>
      <span *ngIf="item.role?.length">{{ item.role }}</span>
    </div>

    <mat-checkbox
      color="primary"
      [id]="'post-share-' + item.userId"
      (change)="selectItem($event, item)"
      [checked]="selected"
    />

  </div>
</ng-template>

