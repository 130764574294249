import { NgModule } from '@angular/core';
import { VerticalNavigationComponent } from './vertical-navigation/vertical-navigation.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingBarInterceptor } from './loading-bar/loading.interceptor';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SidebarNavigationComponent } from './sidebar-navigation/sidebar-navigation.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { TranslocoModule } from "@ngneat/transloco";
import { MainTasksModule } from "../../../../../../../lib-shared-core/src/lib/features/main-tasks/main-tasks.module";
import { SvcSidebarNavigationService } from "./sidebar-navigation/svc-sidebar-navigation.service";
import { SvcCommentsModule } from '../../../../general/svc-comments/svc-comments.module';
import { SvcKaizenResumeModule } from 'projects/lib-shared-feature/src/lib/general/svc-kaizen-resume/svc-kaizen-resume.module';
import { SvcButtonsModule } from 'projects/lib-shared-component/src/public-api';
import { SvcCopilotModule } from '../../../../general/svc-copilot/svc-copilot.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { AlertBarComponent } from './alert-bar/alert-bar.component';
import { SvcAlertBarService } from './alert-bar/alert-bar.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    VerticalNavigationComponent,
    LoadingBarComponent,
    SidebarNavigationComponent,
    MobileMenuComponent,
    AlertBarComponent,
  ],
  imports: [
    LibSharedCommonModule,
    SvcButtonsModule,
    TranslocoModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatMenuModule,
    MainTasksModule,
    SvcCommentsModule,
    SvcKaizenResumeModule,
    SvcCopilotModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    VerticalNavigationComponent,
    LoadingBarComponent,
    SidebarNavigationComponent,
    MobileMenuComponent,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    AlertBarComponent,
  ],
  providers: [
    SvcSidebarNavigationService,
    SvcAlertBarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingBarInterceptor,
      multi: true
    }
  ],
})
export class LayoutComponentsModule {
}
