import { Component, HostBinding, Inject, Input, OnChanges, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { SvcTreeBranchComponent } from '../svc-tree-branch/svc-tree-branch.component';
import { SvcTreeComponent, SvcTreeItem, SvcTreeOrientation } from '../svc-tree.component';
import { isNullOrUndefined } from 'projects/lib-shared-common/src/lib/functions/helper-functions';

@Component({
  selector: 'svc-tree-entry',
  templateUrl: './svc-tree-entry.component.html',
  styleUrls: ['./svc-tree-entry.component.scss'],
  host: {
    '[class.svc-tree-entry]': 'true',
    '[class.is-vertical]': 'parentTree.isVertical',
    '[class.is-horizontal]': 'parentTree.isHorizontal',
  }
})
export class SvcTreeEntryComponent implements OnInit, OnChanges, OnDestroy {

  @Input() input: SvcTreeItem<any>;

  public entry: SvcTreeBranchComponent;
  public showBranches: boolean = true;

  constructor(
    @Inject(forwardRef(() => SvcTreeComponent))
    public parentTree: SvcTreeComponent,
    @Inject(forwardRef(() => SvcTreeBranchComponent))
    public parentBranch: SvcTreeBranchComponent,
  ){
    this.entry = this as any;
  }

  public ngOnInit(): void {
    this.showBranches = !isNullOrUndefined(this.input?.forceStartOpened)
      ? this.input?.forceStartOpened
      : this.parentTree.startOpened && (this.input?.children ?? []).length > 0;
  }

  public ngOnChanges(): void {
  }

  public toggleVisibility() {
    if (this.input.children?.length) {
      this.showBranches = !this.showBranches;
    }
  }

  public addChildren(children: SvcTreeItem<any>[]) {
    if (!children?.length) return;
    if (!this.input.children?.length) this.input.children = [];
    this.input.children.push(...children);
  }

  public ngOnDestroy(): void {
  }
}
