import { Component, OnInit, Inject, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SvcMediaCarouselComponent, } from '../../svc-media-carousel/svc-media-carousel.component';
import { SvcMediaCarouselItem } from '../../svc-media-carousel/interfaces/svc-media-carousel-item';

export type SvcImagesViewerItem = {
	url: string;
	type: 'image' | 'video';
	current?: boolean;
}
export type SvcImagesViewerConfig = {
	fitContent?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
}

@Component({
	selector: 'svc-images-viewer-modal',
	templateUrl: './svc-images-viewer-modal.component.html',
	styleUrls: ['./svc-images-viewer-modal.component.scss'],
	host: {
		'(document:keydown)': 'onKeydown($event)',
		'(window:resize)': 'onWindowResize()'
	}
})
export class SvcImagesViewerModalComponent implements OnInit, AfterViewInit {

	@ViewChild('mediasContainer') protected mediasContainer: ElementRef<HTMLElement>;
	@ViewChild(SvcMediaCarouselComponent) protected carousel: SvcMediaCarouselComponent;

	protected config: SvcImagesViewerConfig = {
		fitContent: 'contain',
	};
	protected carouselItems: SvcMediaCarouselItem[] = [];
	protected carouselInitialItem: SvcMediaCarouselItem;
	protected carouselHeight: number = 0;

	private get element() {
		return this.elRef?.nativeElement;
	}

	private get mediasContainerEl() {
		return this.mediasContainer?.nativeElement;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: {
			imagesUrl: string | SvcImagesViewerItem[],
			config?: SvcImagesViewerConfig,
		},
		public dialogRef: MatDialogRef<SvcImagesViewerModalComponent>,
		public elRef: ElementRef<HTMLElement>
	) {
		const imagesUrl: SvcImagesViewerItem[] = Array.isArray(data.imagesUrl)
			? data.imagesUrl
			: (data.imagesUrl ? [{ url: data.imagesUrl, type: 'image' }] : []);
		this.carouselItems = imagesUrl.map((object) => {
			const item = {
				url: object.url,
				type: object.type,
			};
			if (object.current) {
				this.carouselInitialItem = item;
			}
			return item;
		});
		this.config = {
			...this.config,
			...(data.config ?? {}),
		};

		this.dialogRef.disableClose = true;
	}

	ngOnInit() {
		this.onWindowResize();
		this.addClassMatDialogContainer();
	}

	ngAfterViewInit() {
		this.addClassMatDialogContainer();
	}

	private addClassMatDialogContainer() {
		this.element?.parentElement.classList.add('bg-transparent', 'p-0');
	}

	onKeydown(event: KeyboardEvent) {
		event.preventDefault();
		event.stopImmediatePropagation();

		if (event.keyCode === 27)
			this.close();
	}

	onWindowResize() {
		this.carouselHeight = Math.min(window.innerHeight - 40, 410);
	}

	protected prevMediasPreview() {
		if (this.mediasContainerEl) {
			this.mediasContainerEl.scrollLeft -= 68;
		}
	}

	protected nextMediasPreview() {
		if (this.mediasContainerEl) {
			this.mediasContainerEl.scrollLeft += 68;
		}
	}

	protected close() {
		this.dialogRef.close()
	}
}
