<div class="flex space-x-2 space-y-1" [ngClass]="{'pl-0': data.isOwnerMessage, 'external-call': !data.isOwnerMessage && externalCall, 'internal-call': !data.isOwnerMessage && !externalCall}">
  <div class="flex flex-col flex-shrink-0" #userAvatarContainer>
    <svc-user-avatar [info]="data.isOwnerMessage ? currentUserAvatar : solvaceIAAvatar"></svc-user-avatar>
  </div>

  <div class="flex flex-col w-full" [ngClass]="{ 'flex-auto': hasImageOrAudioAttachment }">
    <div class="relative flex flex-col rounded-b-2xl py-2 px-4 rounded-tr-2xl" [ngClass]="{'bg-[#DDEAF9]': data.isOwnerMessage, 'bg-[#17457A]': !data.isOwnerMessage}">
      <div class="flex flex-row space-x-3 mb-2" [ngClass]="{ 'blur-sm': removingIsLoading || translatingIsLoading }">
        <span class="text-sm font-semibold" [style.word-break]="'break-all'" [ngClass]="{'text-[#17457A]': data.isOwnerMessage, 'text-white': !data.isOwnerMessage}">
          {{ data.isOwnerMessage ? currentUserAvatar.name : solvaceIAAvatar.name }}          
        </span>
      </div>

      <quill-editor *ngIf="data.content" class="text-sm font-light"
        [modules]="{ toolbar: false, clipboard: { matchVisual: false } }"
        [ngClass]="{ 'blur-sm': removingIsLoading || translatingIsLoading, 'text-default': data.isOwnerMessage, 'text-white': !data.isOwnerMessage }"
        [(ngModel)]="data.content"
        [readOnly]="true"
      ></quill-editor>

      <div *ngIf="removingIsLoading || translatingIsLoading" class="loading-container">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
    </div>

    <div *ngIf="!data.isOwnerMessage" class="flex flex-row divide-x text-sm font-medium pt-1">
      <a
        class="flex px-2"
        [class]="data.liked === true ? 'text-[#17457A]' : 'text-default'"
        [class.cursor-pointer]="!removingIsLoading && !translatingIsLoading"
        (click)="setReaction(data.messageId, true)"
        [matTooltip]="'Gostei' | transloco"       
      >
        <mat-icon class="text-current icon-size-5 mr-1" svgIcon="heroicons_solid:hand-thumb-up"/>
        {{ 'Gostei' | transloco }}
      </a>
      <a
        class="flex px-2"
        [class]="data.liked === false ? 'text-[#17457A]' : 'text-default'"
        [class.cursor-pointer]="!removingIsLoading && !translatingIsLoading"
        (click)="setReaction(data.messageId, false)"
        [matTooltip]="'Não Gostei' | transloco"
      >
        <mat-icon class="text-current icon-size-5 mr-1" svgIcon="heroicons_solid:hand-thumb-down"/>
        {{ 'Não Gostei' | transloco }}        
      </a>
      <a
        class="flex px-2 text-default"
        [ngClass]="{'cursor-pointer': !removingIsLoading && !translatingIsLoading}"
        (click)="textCopy(data.content)"
        [matTooltip]="'Copiar' | transloco"
      >
        <mat-icon class="text-current icon-size-5 mr-1" svgIcon="heroicons_solid:document-duplicate"/>
        {{ 'Copiar' | transloco }}
      </a>
    </div>
  </div>
</div>