import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { finalize, takeUntil, Subject, tap, catchError } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

import { SvcActionPlanService } from './shared/services/svc-action-plan.service';
import { ISvcUserAvatar, SvcDialogService, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { ActionPlanStatusMapping, ActionPlanStatusName, ActionPlanStatusType } from './shared/enums/action-plan-status-type.enum';
import { SvcActionPlanList, SvcActionPlanPagedList, SvcInsertPlanFromDraftPlanResponse } from './shared/interfaces/svc-actionplan-list.interface';
import { LegacyService } from 'projects/lib-shared-feature/src/lib/ui/legacy/legacy.service';
import { SvcAccessPermissionService } from '../../admin/svc-access-permission/svc-access-permission.service';
import { UserRoleItem } from '../../admin/svc-access-permission/models/user-role.model';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { AuthService } from 'projects/lib-shared-core/src/lib/auth/auth.service';
import { SVC_ACTION_PLAN_MESSAGES } from './shared/utils/svc-actionplan-config';
import { SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { SvcPaginateEvent } from 'projects/lib-shared-component/src/lib/svc-paginate/svc-paginate.component';

@Component({
  selector: 'svc-actionplan',
  templateUrl: './svc-actionplan.component.html',
  styleUrls: ['./svc-actionplan.component.scss'],
  providers: [SvcActionPlanService]
})
export class SvcActionplanComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() public id: number;
  @Input() public originatorId: string;
  @Input() public rolesCreatePermission: string[] = [];
  @Input() public isViewMode: boolean;
  @Input() public applicationId: string = this._appSettings.applicationId;
  @Input() public additionalReferenceId = 1;
  @Input() public showSearch = true;

  @Output() public openPlans = new EventEmitter<number>();
  @Output() public completePlans = new EventEmitter<number>();

  public actionsPlans: SvcActionPlanList[] = [];
  public isLoading: boolean;
  public userAvatar: ISvcUserAvatar;
  public actionPlanStatusType: ActionPlanStatusMapping = ActionPlanStatusType;
  public actionPlanStatusName: ActionPlanStatusMapping = ActionPlanStatusName;
  public showButtonAddActionplan: boolean;
  public tempId: number;
  public messagesToast = SVC_ACTION_PLAN_MESSAGES;
  public searchText = '';
  public pageIndex = 1;
  public totalRegisters: number;

  private _activeComponentId: string = `${Date.now()}-${Math.floor(Math.random()) * 10000}`;
  private _isDraft: boolean;
  private _unsubscribe$ = new Subject<void>();

  constructor(
    private _actionPlanService: SvcActionPlanService,
    private _legacyService: LegacyService,
    private _toastService: SvcToastService,
    private _translocoService: TranslocoService,
    private _accessPermissionService: SvcAccessPermissionService,
    private _httpErrorService: HttpErrorService,
    private _authService: AuthService,
    private _dialogService: SvcDialogService,
    private _appSettings: SvcAppSettings
  ) {
    this.setFunctionShowSimpleToast();
  }

  public ngOnInit(): void {
    this.getRolesApplication();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.id?.previousValue !== changes?.id?.currentValue)
      this._insertPlanFromDraftPlan();
  }

  public ngAfterViewInit(): void {
    this.observeChangesOnModalLegacy();
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    delete window?.['showSimpleToast'];
    const modalLegacy: HTMLElement = document.querySelector('#legacy-modal-support');
    if (modalLegacy)
      modalLegacy.style.zIndex = '500';
  }

  private getRolesApplication(): void {
    this.isLoading = true;
    this._accessPermissionService.getRolesByUser(this.applicationId)
      .pipe(
        finalize(() => this.getActionsPlans(true))
      )
      .subscribe({
        next: (rolesUser: UserRoleItem[]) => this.setShowButtonAddActionplan(rolesUser),
        error: err => this._httpErrorService.showErrorInToast(err)
      });
  }

  private setShowButtonAddActionplan(rolesUser: UserRoleItem[]): void {
    this.rolesCreatePermission = this.rolesCreatePermission?.map(role => role?.toLowerCase());
    this.showButtonAddActionplan = rolesUser.some((role: UserRoleItem) =>
      this.rolesCreatePermission?.includes(role.roleId)) ||
      this.originatorId?.toLowerCase() === this._authService.userId?.toLowerCase();
  }

  private setFunctionShowSimpleToast(): void {
    window['showSimpleToast'] = (message: string): string => message;
  }

  private getActionsPlans(isFirstTime = false): void {
    if ((this.id || this.tempId) && (!this.isLoading || isFirstTime)) {
      this.isLoading = true;
      this._actionPlanService.getActionsPlans(this.id ?? this.tempId, this.applicationId, this.pageIndex, this.searchText, this.additionalReferenceId)
        .pipe(
          catchError(err => {
            this._httpErrorService.showErrorInToast(err);
            return err;
          }),
          finalize(() => this.isLoading = false)
        )
        .subscribe({
          next: (actionsPlans: SvcActionPlanPagedList) => {
            this.totalRegisters = actionsPlans.totalCount;
            this.actionsPlans = actionsPlans?.data;
            this.openPlans.emit(actionsPlans.totalCount ?? 0);
            this.completePlans.emit(actionsPlans.completedTotalCount ?? 0);
          }
        });
    }
    else
      this.isLoading = false;
  }

  private updateActionsPlans(messageKey: string): void {
    if (this._actionPlanService.activeComponentId !== this._activeComponentId)
      return;

    const message: string = this.messagesToast.get(messageKey);

    if (message)
      this._toastService.success(
        this._translocoService.translate(message)
      );

    if (this.messagesToast?.has(messageKey))
      this.getActionsPlans();
  }

  private observeChangesOnModalLegacy(): void {
    this._legacyService.onLegacyCallback$
      .pipe(
        tap((customEvent: CustomEvent) =>
          this.updateActionsPlans(customEvent?.detail?.functionName)
        ),
        takeUntil(this._unsubscribe$)
      ).subscribe();
  }


  public openDialogActionPlan(actionPlan: SvcActionPlanList = null, isEdit = true, paramPlanName?: string): void {
    if (actionPlan?.classified && !actionPlan?.allowed)
      this._dialogService.openWarning('Registro de acesso restrito');
    else {
      this._actionPlanService.activeComponentId = this._activeComponentId;
      const modalLegacy: HTMLElement = document.querySelector('#legacy-modal-support');

      if (modalLegacy)
        modalLegacy.style.zIndex = '2000';

      if (!this.id && !this.tempId) {
        this.tempId = Math.floor(Math.random() * (999999999 - 1 + 1)) + 1;
        this._isDraft = true;
      }

      this._actionPlanService.openDialogActionPlanLegacy(this.id ?? this.tempId, actionPlan?.planId ?? 0, isEdit ?? false, this._isDraft ?? false, this.additionalReferenceId, paramPlanName);
    }
  }

  public filterActionsPlans(text: string): void {
    this.searchText = text;
    this.pageIndex = 1;
    this.getActionsPlans();
  }

  private _insertPlanFromDraftPlan(): void {
    if (this.tempId && this.id) {
      this._isDraft = false;
      this.isLoading = true;
      this._actionPlanService.insertPlanFromDraftPlan(this.tempId, this.id).pipe(
        tap((insertDraft: SvcInsertPlanFromDraftPlanResponse) => {
          if (!insertDraft?.success) {
            const messageError = this._translocoService.translate(insertDraft?.messageErro ?? 'Ocorreu um erro, tente novamente em outro momento');
            this._toastService.error(messageError);
          }
        }),
        catchError(err => {
          this._httpErrorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => {
          this.tempId = null;
          this.getActionsPlans(true);
        }),
        takeUntil(this._unsubscribe$)
      ).subscribe();
    }
  }

  public onPageChange(event: SvcPaginateEvent): void {
    this.pageIndex = event.page;
    this.getActionsPlans();
  }
}
