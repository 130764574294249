<div class="w-full" *screenSizes="let size" [matDialogFullscreen]="['XS', 'SM']">
  <div class="flex items-center justify-between w-full gap-2 bg-primary-400 px-4 py-2 border-b border-[#D0D5DD] h-10" mat-dialog-title #title>
    <div class="flex gap-2 items-center">
      <mat-icon class="icon-size-6 text-white" svgIcon="heroicons_solid:speakerphone">
      </mat-icon>

      <p class="text-white font-bold text-xl"> {{ 'Comunicado' | transloco }} </p>
    </div>

    <button class="-mr-2" mat-icon-button (click)="closeDialog()">
      <mat-icon class="text-white icon-size-6" svgIcon="heroicons_solid:x-mark"></mat-icon>
    </button>
  </div>

  <div *ngIf="isLoading || setAsViewsIsLoading || setViewsIncrementIsLoading" class="flex items-center justify-center py-30 m-4">
    <mat-spinner diameter="30"></mat-spinner>
  </div>

  <div class="flex flex-col justify-between min-h-[calc(100vh-41px)] md:min-h-[calc(100%-41px)]" *ngIf="!isLoading && !setAsViewsIsLoading && !setViewsIncrementIsLoading">
    <div class="m-4">
      <div class="relative overflow-hidden" #containerCarousel>
        <svc-media-carousel
          #carousel
          fitContent="cover"
          renderMode="one-by-one"
          [canClickToExpandImage]="false"
          [height]="size?.isXS ? 208 : 300"
          [items]="info?.midiasCarousel"
          [initialItem]="info?.midiasCarousel?.[0]"
          (onVideoPlay)="handleVideoPlay($event, size?.isXS)"
          (onVideoPause)="showTitle = true"
          (onVideoEnd)="showTitle = true"
        ></svc-media-carousel>

        <div 
          class="absolute z-10 right-0 flex flex-col gap-2.5 items-end"
          *ngIf="!carousel?.current?.fileLoading && showTitle"
          [ngClass]="{ 
            'bottom-[18px]': info?.midiasCarousel?.[0]?.type !== 'video',
            'bottom-[28px]': info?.midiasCarousel?.[0]?.type === 'video' 
          }"
        >
          <button 
            class="bg-primary-200 title-shadow px-4 py-2 rounded-2xl h-10 flex gap-2 mr-2 w-fit" 
            *ngIf="info?.midiasCarousel?.[0]?.type === 'video'"
            (click)="carousel?.tryPlayCurrentItemVideo()"
          >
            <p class="text-base font-normal text-primary">
              {{ 'Play' | transloco }}
            </p>

            <mat-icon class="text-primary icon-size-6" svgIcon="heroicons_solid:play-circle">
            </mat-icon>
          </button>

          <div class="title-shadow py-2 px-4 rounded-tl-lg rounded-bl-lg bg-primary bg-opacity-90 max-w-[319px]">
            <h1
              class="text-2xl font-semibold text-right text-white leading-7 text-ellipsis line-clamp-2 break-words"
              [matTooltip]="info?.title?.length > 40 ? info?.title : null"
            >
              {{ info?.title | transloco }}
            </h1>
          </div>
        </div>
      </div>
 
      <div 
        class="flex w-full sm:pl-4 sm:pr-4 sm:pt-2.5 pt-4 overflow-auto"
        [style.maxHeight]="size.isXS && !showCommentsChat ? ('calc(100vh - ' + ((title?.clientHeight ?? 0) + (actions?.clientHeight ?? 0) + (containerCarousel?.clientHeight ?? 0) + 64) + 'px)') : '345px'"
      >
        <quill-editor
          *ngIf="info?.editor"
          class="text-viewer text-base font-normal w-full"
          [modules]="{ toolbar: false, clipboard: { matchVisual: false } }"
          [(ngModel)]="info.editor"
          [readOnly]="true"
        ></quill-editor>
      </div>
    </div>

    <div class="container-actions mt-4 px-4 py-2" #actions>
      <div class="flex flex-row items-center gap-2 pb-2">
        <svc-total-reactions
          [registryUniqueId]="communicationId"
          [applicationId]="applicationId"
          [reaction]="svcReaction"
          [mockMode]="isPreview"
        ></svc-total-reactions>

        <div class="flex-auto flex items-center justify-end">
          <svc-totals-status #totalStatus
            class="h-fit w-fit"
            [referenceId]="communicationId"
            [applicationId]="applicationId"
            [configTypeId]="configTypeId"
            [featureName]="featureName"
            [mockMode]="isPreview"
            [available]="{ comments: true, views: true }"
          ></svc-totals-status>
        </div>
      </div>  
  
      <div class="py-2 px-8 border-t border-[#D0D5DD]">
        <div class="flex flex-col w-full">
          <div class="flex gap-8">
            <div class="flex flex-row justify-evenly">
              <svc-reaction #svcReaction
                class="flex flex-auto"
                [registryUniqueId]="communicationId"
                [applicationId]="applicationId"
                [disabled]="isPreview || isReactionLoading"
                [dontEmitFirstTime]="true"
                positionX="left"
                (onLoadingChange)="isReactionLoading = $event"
              >
                <button class="flex flex-auto rounded-none cursor-pointer" mat-flat-button type="button" [ngClass]="svcReaction.hasReacted ? 'text-primary' : 'text-default'" (click)="!isPreview && !isReactionLoading && (svcReaction.hasReacted ? svcReaction?.removeCurrentReaction() : svcReaction?.makeReactionByEnum('LIKE'))">
                  <ng-container *ngIf="isPreview || !isReactionLoading">
                    <mat-icon class="align-middle icon-size-4.5 text-current" svgIcon="heroicons_solid:hand-thumb-up"></mat-icon>
                    <span class="align-middle ml-1 text-base font-medium leading-tight">{{ 'Reagir' | transloco }}</span>
                  </ng-container>
                  <div class="flex align-center justify-center p-2" *ngIf="!isPreview && isReactionLoading">
                    <mat-spinner diameter="20"></mat-spinner>
                  </div>
                </button>
              </svc-reaction>
            </div>

            <button class="flex rounded-none text-default" mat-flat-button type="button" (click)="showComments()">
              <mat-icon class="align-middle icon-size-6 text-current" svgIcon="heroicons_solid:chat-alt"></mat-icon>
              <span class="align-middle ml-1 text-base font-medium leading-tight">{{ "Comentar" | transloco }}</span>
            </button>
          </div>
        </div> 
      </div>

      <div class="sm:border sm:border-[#D0D5DD] sm:rounded-lg sm:p-4 sm:mt-2 mt-6 mb-4" *ngIf="showCommentsChat">
        <svc-comments-chat
          class="block overflow-auto pr-2 max-h-[412px]"
          #svcCommentsChat
          [referenceId]="communicationId"
          [configTypeId]="configTypeId"
          [applicationId]="applicationId"
          [parentApplicationId]="applicationId"
          [parentRegisterId]="communicationId"
          (onCommentAdded)="totalStatus?.refresh()"
          (onCommentRemoved)="totalStatus?.refresh()"
        ></svc-comments-chat>
      </div>

      <div 
        class="flex w-full justify-center items-center gap-4 mb-2" 
        [class.mt-2]="!showCommentsChat"
        *ngIf="communications?.length > 1"
      >
        <button 
          class="w-6 h-6 min-w-6 min-h-6" 
          mat-icon-button 
          [disabled]="communicationIndexToShow === 0"
          (click)="changeCommunicationToShow('previous')"
        >
          <mat-icon class="text-primary icon-size-6" svgIcon="heroicons_solid:chevron-left">
          </mat-icon>
        </button>

        <div class="bg-primary-200 flex items-center justify-center h-[35px] min-w-[98px] rounded-2xl">
          <p class="text-base font-normal text-primary">
            {{ communicationIndexToShow + 1 }} {{ 'de' | transloco }} {{ communications?.length }}
          </p>
        </div>
  
        <button 
          class="w-6 h-6 min-w-6 min-h-6"
          mat-icon-button
          [disabled]="communicationIndexToShow === communications?.length - 1"
          (click)="changeCommunicationToShow('next')"
        >
          <mat-icon class="text-primary icon-size-6" svgIcon="heroicons_solid:chevron-right">
          </mat-icon>
        </button>
      </div>
    </div>

  </div>
</div>