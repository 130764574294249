import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BosHttpClient } from 'projects/lib-shared-feature/src/lib/modals/svc-modal-bos/shared/services/bos-http-client';

import { CategoryResponse, CategoryPay, BosCategoryCombo } from '../interfaces/category.interface';
import { ISvcSelectOption } from 'projects/lib-shared-component/src/public-api';
import { SvcBosFilterParams } from 'projects/lib-shared-feature/src/lib/modals/svc-modal-bos/shared/interfaces/svc-modal-bos.interface';

@Injectable({
  providedIn: 'root'
})

export class BosCategoryService {

  constructor(
    private _http: BosHttpClient,
  ) { }

  public getCategoryList(requestParams: SvcBosFilterParams): Observable<CategoryResponse> {
    return this._http.get<CategoryResponse>('/Category/List', {
      params: this._http.getRequestParams(requestParams)
    });
  }

  updateCategory(category: CategoryPay): Observable<CategoryPay> {
    const payload = {
      categoryId: category.categoryId,
      categoryName: category.categoryName,
      isActive: category.isActive,
      siteId: this._http.siteId
    };
    return this._http.put<CategoryPay>(`/Category`, payload);
  }

  createCategory(category: CategoryPay): Observable<CategoryPay> {
    const payload = {
      categoryName: category.categoryName,
      isActive: category.isActive,
      siteId: this._http.siteId
    };
    return this._http.post<CategoryPay>('/Category', payload);
  }

  deleteCategory(categoryId: number): Observable<void> {
    return this._http.delete<void>(`/Category`, {
      params: {
        categoryId,
        siteId: this._http.siteId
      }
    });
  }

  public getCategoryCombo(isActive = true): Observable<ISvcSelectOption[]> {
    return this._http.get('/Category/BySiteId', {
      params: {
        siteId: this._http.siteId,
        isActive
      }
    }).pipe(
      map((categories: BosCategoryCombo[]) => {
        return categories?.map(categorie => (
          {
            text: categorie.categoryName,
            value: categorie.categoryId
          }
        ))
      })
    );
  }
}

