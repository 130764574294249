import { Injectable } from '@angular/core';

import { Observable, Subject, catchError, delay, finalize, map, of, tap } from 'rxjs';

import { SvcUserPreferenceFilterUpdate, SvcUserPreferencesFeatureFilters, SvcUserPreferencesFeatures } from '../models/svc-user-preferences.model';
import { UserHttpClient } from '../user-http-client';

enum UserPreferencesUpdateType {
  UPDATE,
  REMOVE,
}

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  private _pendingUserPreferencesRequest: Subject<SvcUserPreferencesFeatures[]>;
  private _cachedPreferences: { [key: string]: SvcUserPreferencesFeatures[] } = {};

  constructor(private http: UserHttpClient) { }

  public reset() {
    this._cachedPreferences = {};
  }

  public getUserPreferencesFeatures(applicationId: string, options?: { cache?: boolean }): Observable<SvcUserPreferencesFeatures[]> {
    const hasCache = (options?.cache ?? true) && !!(this._cachedPreferences[applicationId]);
    if (hasCache) return of(this._cachedPreferences[applicationId]).pipe(delay(50));
    try {
      if (!this._pendingUserPreferencesRequest) {
        this._cachedPreferences[applicationId] = null;
        this._pendingUserPreferencesRequest = new Subject<SvcUserPreferencesFeatures[]>();
        const subject = this._pendingUserPreferencesRequest;
        this.http.get<SvcUserPreferencesFeatures[]>(`/userpreference/features/${applicationId}`).pipe(
          tap((response) => {
            this._cachedPreferences[applicationId] = response;
            this._pendingUserPreferencesRequest = null;
            subject.next(response);
          }),
          catchError((error) => {
            this._pendingUserPreferencesRequest = null;
            subject.error(error);
            return error;
          }),
          finalize(() => {
            this._pendingUserPreferencesRequest = null;
            subject.complete();
          })
        ).subscribe();
      }
    }
    finally {
      return this._pendingUserPreferencesRequest;
    }
  }

  public getByFeatureName(applicationId: string, featureName: string): Observable<SvcUserPreferencesFeatures> {
    return this.getUserPreferencesFeatures(applicationId).pipe(
      map((response) => response.find(x => x.featureName == featureName))
    );
  }

  public getByFeatureNames(applicationId: string, featureNames: string[]): Observable<SvcUserPreferencesFeatures[]> {
    return this.getUserPreferencesFeatures(applicationId).pipe(
      map((response) => response.filter(x => featureNames.includes(x.featureName)))
    );
  }

  public updateUserPreferences(applicationId: string, featurePreferences: SvcUserPreferencesFeatures): Observable<boolean> {
    return this.http.put<boolean>(`/userpreference/features`, {
      applicationId,
      featurePreferences
    }).pipe(
      tap(() => {
        this.updateCachedPreferences({
          applicationId,
          preferences: [featurePreferences],
        });
      }),
    );
  }

  public updateUserPreferencesFilter(filter: SvcUserPreferenceFilterUpdate): Observable<boolean> {
    return this.http.put<boolean>(`/userpreference/feature/filters`, filter).pipe(
      tap((success) => {
        if (success) {
          this.updateCachedPreferencesActiveFilter({
            applicationId: filter.applicationId,
            featureName: filter.featureName,
            activeFilter: filter.filterPreferences,
          });
        }
      }),
    );
  }

  public removeUserPreferencesFilter(params: { featureName: string, applicationId: string, filterName: string }): Observable<SvcUserPreferencesFeatures[]> {
    return this.http.delete<SvcUserPreferencesFeatures[]>(`/userpreference/feature/filters`, {
      params: params
    }).pipe(
      tap((success) => {
        if (success) {
          this.updateCachedPreferences({
            applicationId: params.applicationId,
            preferences: [{ featureName: params.featureName, customProperties: [] }],
            type: UserPreferencesUpdateType.REMOVE,
          });
        }
      }),
    );
  }

  private updateCachedPreferences(data: { applicationId: string, preferences: SvcUserPreferencesFeatures[], type?: UserPreferencesUpdateType }) {
    const type = data.type ?? UserPreferencesUpdateType.UPDATE;
    const featureNames = data.preferences.map((p) => p.featureName);
    this._cachedPreferences[data.applicationId] = [
      ...(this._cachedPreferences[data.applicationId] ?? []).filter((preference) => !featureNames.includes(preference.featureName)),
      ...(type !== UserPreferencesUpdateType.REMOVE ? data.preferences : []),
    ];
  }

  private updateCachedPreferencesActiveFilter(data: { applicationId: string, featureName: string, activeFilter: SvcUserPreferencesFeatureFilters, type?: UserPreferencesUpdateType }) {
    const cachedCurrentFeature = this._cachedPreferences[data.applicationId]?.find((f) => f.featureName === data.featureName) ?? <SvcUserPreferencesFeatures>{};
    const preferences = [{
      featureName: data.featureName,
      featureFilters: [
        ...((cachedCurrentFeature?.featureFilters
          ?.filter((f) => f.filterName !== data.activeFilter.filterName)
          ?.map((f) => {
            f.lastFilter = false;
            return f;
          })
        ) ?? []),
        data.activeFilter,
      ],
      customProperties: cachedCurrentFeature?.customProperties ?? [],
    }];
    this.updateCachedPreferences({
      applicationId: data.applicationId,
      preferences,
    });
  }

}
