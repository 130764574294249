import { Injectable } from '@angular/core';
import { DocumentHttpClient } from './document.http.client';
import { EmptyDocumentRequest, FromUploadDocumentRequest, FromUploadIdDocumentRequest, ListDocumentRequest } from '../models/document.request';
import { UserService } from 'projects/lib-shared-core/src/public-api';
import { Observable } from 'rxjs';
import { Document } from '../models/document';


@Injectable({
  providedIn: 'root'
})
export class OnlyofficeService {

  private get _siteId() {
    return this._userService.user.lastSiteId;
  }

  constructor(
    private _http: DocumentHttpClient,
    private _userService: UserService
  ) {
  }

  public createNewEmptyFile(params: EmptyDocumentRequest): Observable<Document> {
    return this._http.post('/Document/CreateNewEmptyFile', {
      ...params,
      siteId: this._siteId,
    });
  }

  public createNewEmptyFileFromUpload(params: FromUploadDocumentRequest) {
    return this._http.post('/Document/CreateNewEmptyFileFromUpload', {
      ...params,
      siteId: this._siteId,
    });
  }

  public createNewEmptyFileFromUploadId(params: FromUploadIdDocumentRequest) {
    return this._http.post('/Document/CreateNewEmptyFileFromUploadId', {
      ...params,
      siteId: this._siteId,
    });
  }

  public updateDocumentReference(uploadId: number, referenceId: number) {
    return this._http.put(`/Document/${uploadId}/reference/${referenceId}`, {});
  }

}
