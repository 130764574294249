import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { AbstractCounterBusStop } from '../counters/abstract-counter-bus-stop';
import { SvcParamItem } from './svc-param-item.model';

@Injectable({
  providedIn: 'root',
})
export class SvcSiteParamService extends AbstractCounterBusStop<{ siteId?: number }, SvcParamItem> {

  private siteId: number;

  protected get skipRequestCollector(): boolean {
    return true;
  }

  constructor(
    private appConfig: AppEnvironmentConfig,
  ) {
    super({
      apiURL: appConfig.APIs.apiUrlAdministration,
      singleParamInPath: true,
      idBatchParamName: 'paramKeys',
      registryIdFilterPropName: 'key',
      idSingleRequestPath: (_, ids) => `/Parameter/site/value/cached/${ids[0]}`, 
      idBatchRequestPath: () => '/Parameter/site/values/cached',
    });
  }

  public setSiteId(siteId: number) {
    this.siteId = siteId;
  }

  public getParameterByParamName(paramName: string): Observable<SvcParamItem> {
    return this.getCountByIdOrUid(paramName, this.siteId ? { siteId: this.siteId } : {}).pipe(
      map((r) => r.length ? (typeof r[0] === 'object' ? r[0] : { key: paramName, value: r[0] }) : null),
    );
  }

  public getParameterByParamsName(paramsName: string[]): Observable<SvcParamItem[]> {
    return forkJoin(paramsName.map((p) => this.getParameterByParamName(p)));
  }
}

