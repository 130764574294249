<div class="svc-tb-line" [class.svc-tb-line-with-text]="input.childrenLineText">
  <span class="svc-tb-solid-line" [class.with-bullets]="input.childrenLineText"></span>
  <span *ngIf="input.childrenLineText" [ngClass]="parentTree.isVertical ? (input.children?.length > 1 ? '' : 'py-1') : 'px-2'">
    {{ input.childrenLineText }}
  </span>
  <span class="svc-tb-solid-line" [class.with-bullets]="input.childrenLineText"></span>
</div>
<section>
  <svc-tree-entry
    *ngFor="let child of input.children ?? []"
    [ngClass]="{ 'only-entry': input.children?.length === 1, 'from-first-branch': !parentEntry }"
    [input]="child"
  ></svc-tree-entry>
</section>