<svc-dialog #dialog [hasActions]="false" fullscreen="XS" *screenSizes="let size">
	<div svc-dialog-content class="h-full">

    <div class="flex min-h-80 items-center justify-center my-2" *ngIf="loading">
      <mat-progress-spinner
        [diameter]="28"
        color="primary"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>

		<div *ngIf="!loading" class="h-full"
      [ngClass]="{ 'grid grid-cols-1': !size.isXS, 'flex flex-col': size.isXS }"
      [class.p-5]="!size.isXS"
      [class.content-hide]="loading"
      [class.md:grid-cols-2]="_mediasCarousel?.length"
    >

      <div *ngIf="_mediasCarousel?.length && !size.isXS" class="row-span-4 w-full order-2 md:my-3 md:order-1 md:pr-3" [style.maxWidth.px]="size.isAboveSM ? 690 : null">
        <div class="bg-gray-50 h-full">

          <svc-media-carousel
            class="rounded-none"
            fitContent="contain"
            renderMode="one-by-one"
            [items]="_mediasCarousel"
            [height]="400"
          >
            <ng-template 
              let-videoSrc="videoSrc" 
            >
              <svc-video-player
                fitContent="contain"
                [videoSrc]="videoSrc"
                [height]="400"
                [applicationId]="_post?.applicationRegistry?.applicationId ?? applicationId"
                [registryId]="_post?.applicationRegistry?.applicationRegistryReferenceId ?? _post?.id"
                [notificationCallbackUrl]="'myworkspace?openNotificationModal=/notification/post/' + _post?.id"
                [registryCode]="_post?.applicationRegistry?.applicationRegistryAdditionalReferenceId"
              >
              </svc-video-player>
            </ng-template>
          </svc-media-carousel>
        </div>
      </div>

      <div class="order-1 md:order-2 flex-auto" [ngClass]="{ 'md:pl-4': _mediasCarousel?.length }">
        <svc-post-item-title
          [avatarSize]="_avatarSize"
          [post]="_post"
          (closed)="closeDialog()"
          (removed)="dialog.closeDialog()"
        ></svc-post-item-title>

        <div *ngIf="_mediasCarousel?.length && size.isXS" class="w-full mt-4">
          <div class="bg-gray-50">
            <svc-media-carousel
              class="rounded-none"
              fitContent="contain"
              renderMode="one-by-one"
              [items]="_mediasCarousel"
              [height]="400"
            >
              <ng-template 
                let-videoSrc="videoSrc" 
              >
                <svc-video-player
                  fitContent="contain"
                  [videoSrc]="videoSrc"
                  [height]="400"
                  [applicationId]="_post?.applicationRegistry?.applicationId ?? applicationId"
                  [registryId]="_post?.applicationRegistry?.applicationRegistryReferenceId ?? _post?.id"
                  [notificationCallbackUrl]="'myworkspace?openNotificationModal=/notification/post/' + _post?.id"
                  [registryCode]="_post?.applicationRegistry?.applicationRegistryAdditionalReferenceId"
                >
                </svc-video-player>
              </ng-template>
            </svc-media-carousel>
          </div>
        </div>

        <div *ngIf="text?.length" class="mt-4 px-2">
          <svc-post-mention
            [(ngModel)]="text"
            [readOnly]="true"
            [lineClamp]="false"
          ></svc-post-mention>
        </div>

        <svc-post-translate *ngIf="text?.length"
          class="block px-2"
          [text]="textTranslated"
          [loading]="translatingText"
          (translate)="translatePost()"
          [postId]="_post.id"
        ></svc-post-translate>

      </div>

      <div class="order-3" [ngClass]="{ 'md:pl-4': _mediasCarousel?.length }">
        <svc-post-item-footer [post]="_post"></svc-post-item-footer>
      </div>

		</div>

	</div>

</svc-dialog>

