
export interface SvcTranscriptionStatusResponse {
  subtitleId: string;
  status: SvcTranscriptionStatus;
}

export enum SvcTranscriptionStatus {
  ERROR = 'Error',
  NONE = 'None',
  WAITING = 'Waiting',
  DONE = 'Done'
}