import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHtml'
})
export class RemoveHtmlPipe implements PipeTransform {

  transform(value: string): string {
    return value
      ?.replace(/(<([^>]+)>)/gi, '')
      .replace(/&nbsp;/g, ' ') ?? '';
  }

}
