import { Injectable } from '@angular/core';
import { DefectTagIndicator } from './models/defecttag-indicator';
import { Observable, Subject, ReplaySubject, catchError, map, of, tap, throwError } from 'rxjs';
import { CommonIndicator } from 'projects/lib-shared-model/src/public-api';
import { DefectTagCategorizedDrillDown } from './models/defecttag-drilldown';
import { ModuleHttpClient } from './module-http-client';
import { TasksCategory } from 'projects/lib-shared-core/src/lib/features/main-tasks/models/tasks-category';

@Injectable()
export class LibServiceDefecttagAnalyticsService {

  private _indicator: ReplaySubject<DefectTagIndicator> = new ReplaySubject<DefectTagIndicator>();
  private _subordinatesIndicator: Subject<DefectTagIndicator> = new Subject<DefectTagIndicator>();
  private _categorizedDrillDown: Subject<DefectTagCategorizedDrillDown[]> = new Subject<DefectTagCategorizedDrillDown[]>();

  constructor(
    private _httpClient: ModuleHttpClient,
  ) {}

  public _indicator$: ReplaySubject<DefectTagIndicator> = this._indicator;
  public _subordinatesIndicator$: Observable<DefectTagIndicator> = this._subordinatesIndicator.asObservable();
  public _categorizedDrillDown$: Observable<DefectTagCategorizedDrillDown[]> = this._categorizedDrillDown.asObservable();

  getDefectTagIndicators(): Observable<DefectTagIndicator> {
    this._indicator.next(null);
    return this._httpClient.get<CommonIndicator>('/Counters/defecttag').pipe(
      map((response: CommonIndicator) => new DefectTagIndicator(response)),
      tap((indicator) => {
        this._indicator.next(indicator);
      }),
      catchError((error) => {
        this._indicator.next(new DefectTagIndicator({ hasError: true }));
        return throwError(() => error);
      }),
    );
  }

  getDefectTagSubordinatesIndicators(): Observable<DefectTagIndicator> {
    this._indicator.next(null);
    return this._httpClient.get<CommonIndicator>('/Counters/defecttag/subordinates').pipe(
      map((response: CommonIndicator) => new DefectTagIndicator(response)),
      tap((indicator) => {
        this._subordinatesIndicator.next(indicator);
      }),
      catchError((error) => {
        this._indicator.next(new DefectTagIndicator({ hasError: true }));
        return throwError(() => error);
      }),
    );
  }

  getDefectTagCategorizedDrillDown(type: TasksCategory): Observable<DefectTagCategorizedDrillDown[]> {
    return this._httpClient.get<DefectTagCategorizedDrillDown[]>(`/Analytics/defecttag/details/type?indicadorTypeDetail=${type}`).pipe(
      map((response: DefectTagCategorizedDrillDown[]) => response.map(r => {
        return {
          ...r,
          indicatorItems: r.indicatorItems.map(i => ({
            ...i,
            owners: [i['owner']]
          }))
        };
      })),
      tap((indicator) => {
        this._categorizedDrillDown.next(indicator);
      })
    );
  }
  
  getDefectTagCategorizedDrillDownSubordinates(type: TasksCategory): Observable<DefectTagCategorizedDrillDown[]> {
    return this._httpClient.get<DefectTagCategorizedDrillDown[]>(`/Analytics/defecttag/subordinates/details/type?indicadorTypeDetail=${type}`).pipe(
        map((response: DefectTagCategorizedDrillDown[]) => response.map(r => {
          return {
            ...r,
            indicatorItems: r.indicatorItems.map(i => ({
              ...i,
              owners: [i['owner']]
            }))
          };
        })),
      tap((indicator) => {
        this._categorizedDrillDown.next(indicator);
      })
    );
  }
}
