import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { AbstractCounterBusStop } from '../counters/abstract-counter-bus-stop';
import { SvcParamItem } from './svc-param-item.model';

@Injectable({
  providedIn: 'root',
})
export class SvcGlobalParamService extends AbstractCounterBusStop<any, SvcParamItem> {

  protected get skipRequestCollector(): boolean {
    return true;
  }

  constructor(
    protected appConfig: AppEnvironmentConfig,
  ) {
    super({
      apiURL: appConfig.APIs.apiUrlAdministration,
      idBatchParamName: 'paramKeys',
      registryIdFilterPropName: 'key',
      idSingleRequestPath: (_, ids) => `/Parameter/global/value/cached/${ids[0]}`, 
      idBatchRequestPath: () => '/Parameter/global/values/cached',
    });
  }

  public getParameterByParamName(paramName: string): Observable<SvcParamItem> {
    return this.getCountByIdOrUid(paramName, null).pipe(
      map((r) => r.length ? (typeof r[0] === 'object' ? r[0] : { key: paramName, value: r[0] }) : null),
    );
  }

  public getParameterByParamsName(paramsName: string[]): Observable<SvcParamItem[]> {
    return forkJoin(paramsName.map((p) => this.getParameterByParamName(p)));
  }
}

