import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, PageCantBeLoadComponent } from 'projects/lib-shared-core/src/public-api';
import { InitialDataResolver } from './app.resolver';
import { SvcDataTableExampleComponent } from 'projects/lib-shared-component/src/public-api';
import { ModalPraiseNewComponent } from "projects/lib-shared-feature/src/lib/modals/modal-praise-new/modal-praise-new.component";
import { SvcDialogResolver } from "projects/lib-shared-component/src/lib/svc-dialogs/svc-dialog-resolver";
import { environment } from 'projects/environments/environment';
import { LayoutComponent } from 'projects/lib-shared-feature/src/public-api';
import { SvcModalAlertComponent } from 'projects/lib-shared-feature/src/lib/modals/svc-modal-alert/svc-modal-alert.component';
import { DialogAlertType } from 'projects/lib-shared-feature/src/lib/modals/svc-modal-alert/shared/enums/dialog-alert-type.enum';
import { SvcModalBosComponent } from 'projects/lib-shared-feature/src/lib/modals/svc-modal-bos/svc-modal-bos.component';
import { SvcModalBosType } from 'projects/lib-shared-feature/src/lib/modals/svc-modal-bos/shared/enums/svc-modal-bos.enum';
import { NewCarouselComponent } from './examples/new-carousel.component';
import { OnlyofficeComponent } from './examples/onlyoffice/onlyoffice.component';

let routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'board' },

  // Auth rotes are defined at auth-module import
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'board/main/posts' },
  {
    path: 'board',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'svc-workspace-layout'
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./board/board.module').then((m) => m.BoardModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      { path: 'page-cant-be-load', component: PageCantBeLoadComponent },
    ],
  },
  {
    path: 'praise-new',
    component: ModalPraiseNewComponent,
    resolve: {
      dialogRef: SvcDialogResolver
    },
    data: {
      width: '100%',
      maxWidth: '500px',
    }
  },
  {
    path: 'alert-new',
    component: SvcModalAlertComponent,
    resolve: {
      dialogRef: SvcDialogResolver,
    },
    data: {
      width: '100%',
      maxWidth: '500px',
      panelClass: 'app-dialog-alert',
      resolveDialog: () => {
        return {
          mode: DialogAlertType.add
        };
      },
    },
  },
  {
    path: 'bos-new',
    component: SvcModalBosComponent,
    resolve: {
      dialogRef: SvcDialogResolver,
    },
    data: {
      width: '100%',
      maxWidth: '500px',
      resolveDialog: () => {
        return {
          mode: SvcModalBosType.add
        };
      },
    },
  },
  {
    path: 'office',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: { layout: 'svc-workspace-layout' },
    children: [
      {
        path: '',
        component: OnlyofficeComponent
      },
    ],
  },
];

if (environment.isDEV || environment.isQA) {
  routes.push({
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      { path: 'new-carousel', component: NewCarouselComponent },
    ],
  });
  routes.push({
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      { path: 'table-example', component: SvcDataTableExampleComponent },
    ],
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
