import {
	Component,
	HostBinding,
	ElementRef,
	HostListener, Output, EventEmitter,
	forwardRef,
	Inject,
	AfterContentInit,
	Optional
} from '@angular/core';
import { SvcDataRowComponent } from '../svc-data-row/svc-data-row.component';
import { isNullOrUndefined } from 'projects/lib-shared-common/src/public-api';

@Component({
	selector: 'svc-data-cell',
	templateUrl: './svc-data-cell.component.html',
	styleUrls: ['./svc-data-cell.component.scss'],
})
export class SvcDataCellComponent implements AfterContentInit {
	@HostBinding('class.svc-data-cell') public class: boolean = true;
	@HostBinding('class.needs-to-freeze') public isFreezing: boolean = false;
	@HostBinding('class.is-last-freezed') public isLastFreezed = false;
	@HostListener('click', ['$event']) onHostClick(event: Event) {
		this.cellClick.emit(this);
	}

	@Output() cellClick: EventEmitter<any> = new EventEmitter();

	public cellIndex: number = null;
	public get body() {
		return this.parent?.parent;
	}

	public get col() {
		if (!isNullOrUndefined(this.cellIndex) && this.parent?.parent?.parent?.header?.cols) {
			if (this.cellIndex < this.parent.parent.parent.header.cols.length)
				return this.parent.parent.parent.header.cols[this.cellIndex];
			else {
				return null;
			}
		}
		return null;
	}

	public get el() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement : null;
	}
	public get height() {
		return this.el.offsetHeight;
	}

	constructor(
    @Optional()  @Inject(forwardRef(() => SvcDataRowComponent))
    public parent: SvcDataRowComponent,
		private elRef: ElementRef<HTMLElement>,
	) {
	}

	public ngAfterContentInit(): void {
		this.onRender();
	}

	public onRender() {
		this.updateIndex();
		this.col?.parent?.mobileMaxHeaderIndex?.forEach((elem, headerIndex) => {
			if (this.cellIndex === headerIndex) {
				this.el.classList.add('header-cell', 'order-' + elem);
			}
		});

		this.setWidth();
		this.checkFreezing();
	}

	public updateIndex() {
		const wasRendered = this.parent?.queryCells != null;
		if (this.parent) {
			this.cellIndex = wasRendered
				? this.parent?.cells.indexOf(this)
				: Array.from(this.parent?.el.querySelectorAll('svc-data-cell')).indexOf(this.el);
		}
	}

	public setWidth() {
		const col = this.col;
		const header = this.parent?.parent?.parent?.header;
		const colEl = col ? col.el : header?.el?.querySelectorAll('.svc-data-col').item(this.cellIndex) as HTMLElement;
		if (this.el && (col || colEl)) {
			const newWidth = (col ? col._width : `${colEl.offsetWidth ?? 0}px`) ?? 'auto';

			this.el.style.width = newWidth;
			this.el.style.maxWidth = newWidth == 'auto' ? null : newWidth;
			this.el.style.minWidth = newWidth == 'auto' ? null : newWidth;
		}
	}

	public checkFreezing() {
		if (this.el && this.parent) {
			const col = this.col;
			this.isFreezing = col?.isFreezing ?? false;
			this.isLastFreezed = col?.isLastFreezed ?? false;
			this.el.style.left = this.isFreezing ? `${this.parent.cells.slice(0, this.cellIndex).reduce((prev, curr) => prev + (curr.el?.offsetWidth ?? 0) + 8, 0)}px` : null;
		}
	}
}
