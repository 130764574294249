<svc-dialog-primary #dialog dialogStyle="empty" [fullscreen]="['XS', 'SM']">
  <div svc-dialog-title class="flex w-full">
    <img class="w-auto max-w-fit h-8" [src]="environment.logoImageNegative">

    <div class="flex items-center mx-auto">
      <h1 class="uppercase text-base font-bold">
        {{ "Alerta" | transloco }}
      </h1>
    </div>
  </div>

  <div *ngIf="dialog.isViewInitialized" #dialogContent svc-dialog-content class="flex flex-col h-full">
    <div class="flex-auto flex items-center justify-center py-30" *ngIf="isLoading">
      <mat-spinner diameter="30"></mat-spinner>
    </div>

    <div class="flex-auto overflow-auto px-4 pt-6" [hidden]="isLoading">
      <div #topInfo>
        <div class="mb-[26px]">
          <p class="text-[11px] text-default mb-2">
            {{ 'Número' | transloco }}
          </p>

          <p class="text-default text-base font-medium" [class.mx-2]="!isViewMode" [class.mt-2.5]="!isViewMode">
            {{ 'ALE' | transloco }}-{{ formAlert?.controls.alertId.value }}
          </p>
        </div>
      </div>

      <nav class="w-full border-b" mat-tab-nav-bar mat-stretch-tabs="false" mat-align-tabs="center" [tabPanel]="tabPanel">
        <div mat-tab-link [active]="tabIndex === 0" (click)="tabIndex = 0">
          {{ 'Alerta' | transloco }}
        </div>
        <div *ngIf="showTabActionPlan || isShowTabActionPlanLoading" mat-tab-link [active]="tabIndex === 1" (click)="!isShowTabActionPlanLoading && tabIndex = 1" [disabled]="isShowTabActionPlanLoading">
          {{ 'Plano de ação' | transloco }}

          <ng-container *ngIf="openPlans && !isShowTabActionPlanLoading">
            <mat-icon class="ml-3 mr-1 icon-size-3" svgIcon="heroicons_solid:clipboard-list" [class.text-primary]="tabIndex === 1">
            </mat-icon>

            <p class="font-normal text-base">
              {{ completePlans }}/{{ openPlans }}
            </p>
          </ng-container>
          <mat-spinner class="text-current ml-2" *ngIf="isShowTabActionPlanLoading" diameter="20"></mat-spinner>
        </div>
        <div mat-tab-link [active]="tabIndex === 2" (click)="!isAddMode && tabIndex = 2" [disabled]="isAddMode">
          {{ 'Feed' | transloco }}

          <ng-container *ngIf="!isAddMode && alertFeedTab?.svcCommentsChat && alertFeedTab?.svcCommentsChat?.messages?.length >= 0">
            <mat-icon class="ml-2.5 mr-1 icon-size-3" svgIcon="heroicons_solid:chat-alt" [class.text-primary]="tabIndex === 2">
            </mat-icon>

            <p class="font-normal text-base">
              {{ alertFeedTab?.svcCommentsChat.messages.length }}
            </p>
          </ng-container>
        </div>
      </nav>

      <mat-tab-nav-panel #tabPanel class="block py-4">
        <!-- ALERT -->
        <div [class.hidden]="tabIndex !== 0" #alertTab>
          <form class="grid gap-4 sm:grid-cols-2 md:grid-cols-1" [formGroup]="formAlert">

            <div class="flex gap-2 px-4 py-2 rounded-lg bg-primary-200 sm:col-span-2 md:col-span-1">
              <mat-icon class="text-primary mt-[18px] icon-size-6" svgIcon="mat_solid:date_range">
              </mat-icon>

              <div class="grid grid-cols-2 gap-2 w-full">
                <mat-form-field class="create-date w-full h-fit flex-auto svc-mat-no-subscript svc-mat-dense">
                  <mat-label class="text-default text-[11px] font-normal">
                    {{ 'Data de abertura' | transloco }}
                  </mat-label>
                  <input class="font-bold text-base text-default" type="text" matInput [value]="formAlert?.controls?.createDate?.value | dateFormat" disabled />
                </mat-form-field>

                <svc-date-picker
                  class="date-picker"
                  formControlName="occurrenceDate"
                  [label]="'Data de ocorrência' | transloco"
                  [showIcon]="false"
                >
                </svc-date-picker>
              </div>
            </div>

            <div class="flex justify-center sm:col-span-2 md:col-span-1 overflow-hidden">
              <input
                #inputFile
                type="file"
                multiple
                hidden
                [accept]="acceptFiles"
                (change)="addMediaInputFile($event)"
              />

              <svc-media-carousel
                *ngIf="!isLoading"
                [class]="midiasCarousel?.length ? 'w-full' : 'w-64'"
                [height]="160"
                [editMode]="!isViewMode"
                [items]="midiasCarousel"
                (onAddTriggered)="carouselAddTrigger = $event; inputFile.click()"
                (onDeleteTriggered)="removeSlide($event)"
              >
              </svc-media-carousel>
            </div>

            <div>
              <p class="text-[11px] font-normal text-default">
                {{'Originador' | transloco}}
              </p>

              <div class="flex gap-2 mt-2" *ngIf="userAvatar?.name || userAvatar?.picture">
                <svc-user-avatar
                  [info]="userAvatar"
                ></svc-user-avatar>

                <p class="text-default text-base font-medium">
                  {{ userAvatar?.name }}
                </p>
              </div>
            </div>

            <svc-select
              class="block"
              formControlName="teamId"
              fallbackTextValue="-"
              [label]="'Time' | transloco"
              [placeholder]="'Opcional' | transloco"
              [options]="alertCombos?.teams"
              [loading]="!isViewMode && isAllCombosLoading"
              [readonly]="isViewMode"
            >
            </svc-select>

            <svc-location-field
              inputId="alert-register-form-location"
              [formControl]="formAlert?.controls?.locationId"
              [readonly]="isViewMode"
            >
            </svc-location-field>

            <svc-chip
              class="block"
              type="single"
              formControlName="classificationId"
              fallbackTextValue="-"
              [label]="'Classificação' | transloco"
              [options]="alertCombos?.classification"
              [loading]="!isViewMode && isAllCombosLoading"
              [readonly]="isViewMode"
            >
            </svc-chip>

            <svc-chip
              class="block sm:col-span-2 md:col-span-1"
              type="single"
              formControlName="failureId"
              fallbackTextValue="-"
              [label]="'Falha' | transloco"
              [options]="alertCombos?.failures"
              [loading]="!isViewMode && (isAllCombosLoading || isLoading || isFailuresLoading)"
              [readonly]="isViewMode"
            >
            </svc-chip>

            <svc-chip
              class="block"
              type="single"
              formControlName="materialTypeId"
              fallbackTextValue="-"
              [label]="'Tipo de material' | transloco"
              [placeholder]="'Opcional' | transloco"
              [options]="alertCombos?.materialType"
              [loading]="!isViewMode && isAllCombosLoading"
              [readonly]="isViewMode"
            >
            </svc-chip>

            <svc-chip
              class="block"
              type="single"
              formControlName="productId"
              fallbackTextValue="-"
              [label]="'Material' | transloco"
              [placeholder]="'Opcional' | transloco"
              [options]="alertCombos?.materials"
              [loading]="!isViewMode && (isAllCombosLoading || isMaterialsLoading)"
              [readonly]="isViewMode"
            >
            </svc-chip>

            <svc-chip
              class="block"
              type="single"
              formControlName="supplierId"
              fallbackTextValue="-"
              [label]="'Fornecedor' | transloco"
              [placeholder]="'Opcional' | transloco"
              [options]="alertCombos?.suppliers"
              [loading]="!isViewMode && isAllCombosLoading"
              [readonly]="isViewMode"
            >
            </svc-chip>

            <svc-chip-grouped
              class="block"
              formControlName="hashtagIds"
              fallbackTextValue="-"
              readonlyStyle="chip"
              appearance="primary"
              [label]="'Hashtag' | transloco"
              [placeholder]="'Opcional' | transloco"
              [options]="alertCombos?.hashtags"
              [loading]="!isViewMode && isAllCombosLoading"
              [readonly]="isViewMode"
              [collapsedDisplaying]="false"
            >
            </svc-chip-grouped>

            <svc-audio-to-text-wrapper *ngIf="!isViewMode" class="d-block w-full overflow-hidden sm:col-span-2 md:col-span-1" [showVoiceToTextButton]="showVoiceToTextButton" [control]="formAlert?.controls?.description">
              <svc-text-area
                class="block w-full"
                formControlName="description"
                fallbackTextValue="-"
                [label]="'Observações' | transloco"
                [placeholder]="'Opcional' | transloco"
                [minRows]="4"
                [maxRows]="4"
                [readonly]="isViewMode"
              ></svc-text-area>
            </svc-audio-to-text-wrapper>

            <svc-text-area
              *ngIf="isViewMode"
              class="block w-full sm:col-span-2 md:col-span-1"
              formControlName="description"
              fallbackTextValue="-"
              [label]="'Observações' | transloco"
              [placeholder]="'Opcional' | transloco"
              [minRows]="4"
              [maxRows]="4"
              [readonly]="true"
            ></svc-text-area>
          </form>
        </div>

        <!-- ACTION PLAN -->
        <div *ngIf="showTabActionPlan" [class.hidden]="tabIndex !== 1" #actionPlanTab>
          <svc-actionplan
            [id]="alertMode?.id"
            [originatorId]="alert?.originatorId || formAlert?.controls?.originatorId?.value"
            [rolesCreatePermission]="rolesCreatePermission"
            [isViewMode]="isViewMode"
            [applicationId]="applicationId"
            (openPlans)="openPlans = $event"
            (completePlans)="completePlans = $event"
          >
          </svc-actionplan>
        </div>

        <!-- FEED -->
        <div [class.hidden]="tabIndex !== 2" #feedTab>
          <app-alert-feed-tab #alertFeedTab [alertId]="alertMode?.id" [isAddMode]="isAddMode"></app-alert-feed-tab>
        </div>
      </mat-tab-nav-panel>
    </div>

    <div class="border-t flex justify-between px-4 py-2.5 w-full h-10" *ngIf="!isLoading && !isAddMode && tabIndex !== dialogAlertTabs.Feed && (showTabActionPlan || tabIndex !== dialogAlertTabs.ActionPlan)">     
      <div class="flex gap-4 items-center justify-between py-2">        
        <svc-bookmark-button
          class="w-fit"
          [referenceId]="alert?.alertId"
          [applicationId]="applicationId"
          [initialValue]="alert?.isBookmarked"
          [iconAlwaysFilled]="true"
          (onValueChanged)="onBookmarkChanged($event)"
        ></svc-bookmark-button>   
        
        <svc-reaction
          class="flex flex-auto"
          #svcReaction
          [registryUniqueId]="alert?.alertId"
          [applicationId]="applicationId"            
          positionX="left"            
          (onLoadingChange)="isReactionLoading = $event"
        >
          <div
            class="flex flex-auto"              
            [ngClass]="svcReaction.hasReacted ? 'text-primary' : 'text-default'"
            (click)="!isReactionLoading && (svcReaction.hasReacted ? svcReaction?.removeCurrentReaction() : svcReaction?.makeReactionByEnum('LIKE'))"
          >
            <ng-container *ngIf="!isReactionLoading">
              <mat-icon class="align-middle icon-size-6 text-current" svgIcon="heroicons_solid:hand-thumb-up"></mat-icon>
            </ng-container>

            <div class="flex align-center justify-center p-2" *ngIf="isReactionLoading">
              <mat-spinner diameter="20"></mat-spinner>
            </div>
          </div>
        </svc-reaction>


        <div class="flex items-center text-default">
          <mat-icon
            svgIcon="mat_solid:comment"
            class="cursor-pointer text-current icon-size-6"
            (click)="goToFeed()"
          ></mat-icon>
        </div>
      </div>

      <div>
        <svc-totals-status
          class="h-fit self-end"
          [referenceId]="alert.alertId"
          [applicationId]="applicationId"
          [configTypeId]="configType"
          [featureName]="featureName"
          [available]="{ reactions: true, comments: true, views: true }"
        ></svc-totals-status>
      </div>
    </div>
  </div>

  <div
    svc-dialog-actions-options
    class="flex gap-2 items-center mr-3"
    *ngIf="!isLoading"
  >
    <button
      class="button-more-options w-7 h-7 min-h-7 min-w-7"
      *ngIf="isViewMode"
      mat-icon-button
      [matMenuTriggerFor]="moreOptionsMenu"
    >
      <mat-icon class="text-current text-white icon-size-5" svgIcon="heroicons_solid:ellipsis-vertical"></mat-icon>
    </button>

    <mat-menu xPosition="before" #moreOptionsMenu="matMenu">
      <button
        class="flex items-center gap-2"
        mat-menu-item
        (click)="print()"
      >
        <mat-icon class="icon-size-5" svgIcon="heroicons_solid:printer">
        </mat-icon>

        <span class="text-default text-base font-normal">
          {{ 'Imprimir' | transloco }}
        </span>
      </button>
    </mat-menu>

    <svc-button
      *ngIf="!isAddMode && (userLoggedId === alert?.originatorId || userHasPermissionToEdit)"
      color="on-primary"
      mode="stroked"
      type="button"
      cdkFocusInitial
      [processing]="isLoading"
      (click)="setEditMode()"
    >
      {{ isViewMode ? ("Editar" | transloco) : ("Salvar" | transloco) }}
    </svc-button>

    <svc-button
      color="on-primary"
      [mode]="isAddMode ? 'stroked' : 'flat'"
      type="button"
      (click)="dialogRef.close()"
    >
      {{ "Fechar" | transloco }}
    </svc-button>

    <svc-button
      class="button-add"
      *ngIf="isAddMode"
      color="on-primary"
      mode="flat"
      type="button"
      cdkFocusInitial
      [disabled]="isViewMode || formAlert?.invalid"
      [processing]="isLoading"
      (click)="createOrUpdateAlert()"
    >
      {{ "Incluir" | transloco}}
    </svc-button>
  </div>
</svc-dialog-primary>
