import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Post } from "../../models/post";
import { UserService } from 'projects/lib-shared-core/src/public-api';
import { ISvcUserAvatar, SvcDialogComponent, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { PostUser } from "../../models/post-user";
import { TranslocoService } from "@ngneat/transloco";
import { PostService } from "../../services/post.service";
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { catchError, debounceTime, distinctUntilChanged, finalize, of, Subscription, tap } from "rxjs";
import { FormControl } from '@angular/forms';

export interface ShareItem {
  user: PostUser;
  avatar: ISvcUserAvatar;
  role: string;
}

@Component({
  selector: 'svc-modal-share',
  templateUrl: './svc-modal-share.component.html',
  styleUrls: ['./svc-modal-share.component.scss']
})
export class SvcModalShareComponent {

  @Input() post: Post;
  @ViewChild('dialog') dialog: SvcDialogComponent;

  text: string;
  searching: boolean;
  list: PostUser[] = [];
  listSelected: PostUser[] = [];
  loading: boolean;

  lastSearchTerm: string;
  searchControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _userService: UserService,
    private _translocoService: TranslocoService,
    private _toastService: SvcToastService,
    private _postService: PostService,
    private _httpErrorService: HttpErrorService
  ) {
    this.post = data;

    let subscription: Subscription;
    this.searchControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap((term) => {
        subscription?.unsubscribe();
        subscription = null;
        if (!term || term.length < 3 || term === this.lastSearchTerm) return;
        this.searching = true;
        this.list = [];
        this.lastSearchTerm = term;
        subscription = this._userService.getUsersInfo({
          pageSize: 50,
          pageIndex: 0,
          active: true,
          name: term,
        }).pipe(
          tap((r) => this.appendUsers(r.users)),
          finalize(() => this.searching = false),
        ).subscribe();
      }),
      catchError(() => {
        this.searching = false;
        return of('');
      }),
    ).subscribe();
  }

  selectItem(event: any, item: PostUser) {
    if (event.checked) {
      this.list = this.list.filter((u => u.userId !== item.userId));
      this.listSelected.push(item);
    } else {
      this.listSelected = this.listSelected.filter((u => u.userId !== item.userId));
      this.list.push(item);
    }
  }

  shareWithSelectedUsers() {

    if (this.listSelected.length === 0) {
      this._toastService.warning(this._translocoService.translate('Selecione pelo menos um funcionário.'))
      return;
    }

    this.loading = true;
    this._postService.sharePost({
      id: this.post.id,
      userShares: this.listSelected.map(u => ({ userId: u.userId })),
      teamShares: []
    }).pipe(
      tap(() => {
        this.post.sharedTimes++;
        this._toastService.success(this._translocoService.translate('Post compartilhado com Sucesso!'))
        this.dialog.closeDialog();
      }),
      catchError((e) => {
        this._httpErrorService.showErrorInToast(e);
        return e;
      }),
      finalize(() => this.loading = false),
    ).subscribe();

  }


  private appendUsers(users: PostUser[]) {
    this.list = [
      ...this.list,
      ...users.filter(user => {
        return !this.listSelected.some(u => u.userId === user.userId);
      }),
    ];
  }


}
