import { Component, ElementRef, Inject, Injector, ViewChild } from '@angular/core';
import {
  SvcDialogComponent,
  SvcDialogService,
} from 'projects/lib-shared-component/src/public-api';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Post } from "../../models/post";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PostForm } from "../../models/post-form";
import { SvcPostMentionComponent } from "../svc-post-mention/svc-post-mention.component";
import { PostErrorMsg } from "../../models/post-error-msg";
import { SvcFunctionsHelper } from 'projects/lib-shared-common/src/public-api';
import { SitesService } from 'projects/lib-shared-core/src/public-api';
import { SvcMediaCarouselRemoveEvent } from 'projects/lib-shared-component/src/lib/svc-media-carousel/interfaces/svc-media-carousel-events';

export type FileType = 'image' | 'video';
export type StarterType = 'text' | 'photo' | 'video';

export interface DialogConfig {
  post: Post;
  type?: StarterType;
}

const MAX_FILE_SIZE = 25000000;

@Component({
  selector: 'svc-post-form-modal',
  templateUrl: './svc-post-form-modal.component.html',
  styleUrls: ['./svc-post-form-modal.component.scss']
})
export class SvcPostFormModalComponent extends PostForm {

  @ViewChild('dialog') dialog: SvcDialogComponent;
  @ViewChild('imageInput') imageInput: ElementRef;
  @ViewChild('videoInput') videoInput: ElementRef;

  @ViewChild('postMention') postMentionElement: SvcPostMentionComponent;

  mode: 'new' | 'edit' = 'edit';
  form: FormGroup;
  loading: boolean = true;
  hasTextOrMedia: boolean = false;
  addPhotoEnabled = true;
  addVideoEnabled = true;

  private _config = {
    form: {
      text: {
        minLength: 5,
        maxLength: 1000
      }
    },
    upload: {
      maxFiles: 6,
      maxVideos: 1,
      maxPhotos: 5,
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: DialogConfig,
    protected injector: Injector,
    private formBuilder: FormBuilder,
    private _functionsHelper: SvcFunctionsHelper,
    private _sitesService: SitesService,
    private dialogService: SvcDialogService,
  ) {
    super(injector);
    this._avatarSize = 40;
  }

  ngOnInit() {
    if (!this._data.post) {
      this.mode = 'new';
      this._post = new Post();
      this.initForm();
    } else {
      this.mode = 'edit';
      this._postService.getPost(this._data.post.id).subscribe({
        next: post => {
          this._post = post;
          this.initForm();
        },
        error: () => {
          this._toast.error(this._transloco.translate(PostErrorMsg.defaultError));
          this._post = null;
          this.dialog.closeDialog();
        }
      })
    }
  }

  private initForm() {
    super.ngOnInit();
    this._post.originator = { originatorId: this._userService.userId$ };
    this.form = this.formBuilder.group({
      id: [this._post.id],
      textContent: [this._post.textContent, [
        Validators.minLength(this._config.form.text.minLength),
        Validators.maxLength(this._config.form.text.maxLength),
        Validators.required,
      ]],
      hashtags: [this._post.hashtags],
      mediaUrls: [this._post.mediaUrls?.map(m => ({ awsMediaName: m.awsMediaName }))],
      userMentions: [this._post.userMentions?.map(u => ({ userId: u.userId }))],
      teamMentions: [this._post.teamMentions?.map(t => ({ teamId: t.teamId, siteId: t.siteId }))]
    });

    this.form.get('textContent').valueChanges.subscribe(() => this._checkIfHasTextOrMedia());
    this.form.get('mediaUrls').valueChanges.subscribe(() => this._checkIfHasTextOrMedia());

    if (this.mode === 'edit') {
      this.setPostMediaCarousel();
      this.setPostTeamInfo();
      this.setPostUsersInfo();
    }
    this.loading = false;
    setTimeout(() => { this.loadStarterAction(this._data.type) }, 0);
    this._checkIfHasTextOrMedia();
    /* this.setOriginatorAvatar().subscribe({
      next: (r) => {
        this.form = this.formBuilder.group({
          id: [this._post.id],
          textContent: [this._post.textContent, [
            Validators.minLength(this._config.form.text.minLength),
            Validators.maxLength(this._config.form.text.maxLength),
            Validators.required,
          ]],
          hashtags: [this._post.hashtags],
          mediaUrls: [this._post.mediaUrls?.map(m => ({ awsMediaName: m.awsMediaName }))],
          userMentions: [this._post.userMentions?.map(u => ({ userId: u.userId }))],
          teamMentions: [this._post.teamMentions?.map(t => ({ teamId: t.teamId, siteId: t.siteId }))]
        });

        this.form.get('textContent').valueChanges.subscribe(() => this._checkIfHasTextOrMedia());
        this.form.get('mediaUrls').valueChanges.subscribe(() => this._checkIfHasTextOrMedia());

        if (this.mode === 'edit') {
          this.setPostMediaCarousel();
          this.setPostTeamInfo();
          this.setPostUsersInfo();
        }
        this.loading = false;
        setTimeout(() => { this.loadStarterAction(this._data.type) }, 0);
        this._checkIfHasTextOrMedia();
      },
      error: () => {
        this._toast.error(this._transloco.translate(PostErrorMsg.defaultError));
        this.dialog.closeDialog();
      }
    }); */
  }

  private _checkIfHasTextOrMedia() {
    const value = this._functionsHelper.removeHtml(this.form.get('textContent').value ?? '');
    const qttyMedia = this.form.get('mediaUrls').value?.length ?? 0;
    this.hasTextOrMedia = qttyMedia > 0 || (value ?? '').trim() !== '';
  }

  protected setPostMediaCarousel(): void {
    super.setPostMediaCarousel();
    this.checkAddVideoAndPhotoButtons();
  }

  submitForm() {

    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    this.loading = true;
    this.postMentionElement.setDisabledState(true);

    const post: Post = {
      ...this.form.value,
      textContent: this.form.value?.textContent ?? '',
    };

    if (!post.mediaUrls) post.mediaUrls = [];

    const mentions = this.postMentionElement.allMentions;

    post.hashtags = mentions
      .filter(m => m.denotationChar === '#')
      .map(m => ({
        hashtagId: m.id
      }));

    post.userMentions = mentions
      .filter(m => m.denotationChar === '@' && m.type === '1')
      .map(m => ({
        userId: m.id
      }));

    post.teamMentions = mentions
      .filter(m => m.denotationChar === '@' && m.type === '2')
      .map(m => ({
        siteId: m.siteId,
        teamId: m.id
      }));

    if (this.mode === 'edit') {
      this.updatePost(post);
    }

    if (this.mode === 'new') {
      this.addPost(post);
    }
  }

  addPost(post: Post) {
    post.site = { siteId: this._userService.user.lastSiteId };
    this._postService.addPost(post).subscribe({
      next: (p) => {
        setTimeout(() => {
          this._postService.getPost(p.postId).subscribe(p => {
            this._postService.unshiftPost(p);
            this._toast.success(this._transloco.translate('Post adicionado com sucesso!'));
            this.dialog.closeDialog();
          });
        }, 1000)

      },
      error: () => {
        this.loading = false;
        setTimeout(() => {
          this.postMentionElement.setDisabledState(false);
          this._toast.error(this._transloco.translate(PostErrorMsg.defaultError));
        });
      }
    })
  }

  updatePost(post: Post) {
    post.site = {
      siteId: this._sitesService.currentSite.siteId,
    };
    this._postService.updatePost(post).subscribe({
      next: (p: any) => {
        setTimeout(() => {
          this._postService.getPost(post.id).subscribe(p => {
            this._postService.replacePost(p);
            this._toast.success(this._transloco.translate('Post atualizado com sucesso!'));
            this.dialog.closeDialog();
          });
          // needs a timeout of 1 second otherwise it will look for an outdated record
        }, 1000);
      },
      error: () => {
        this.loading = false;
        setTimeout(() => {
          this.postMentionElement.setDisabledState(false);
          this._toast.error(this._transloco.translate(PostErrorMsg.defaultError));
        });
      }
    })
  }

  addMediaFile(type: FileType, e: Event) {
    const target = e.target as HTMLInputElement;
    const files = target.files;

    if (!files) return;

    const mediaUrl = this._post?.mediaUrls ?? [];
    const fileType = this.getFiletypeByMimetype(files[0].type);
    const fileSize = files[0].size;

    // Check media size
    if (fileSize > MAX_FILE_SIZE) {
      target.value = null;
      this._toast.error(this._transloco.translate('O tamanho do arquivo excede o tamanho máximo permitido.'))
      return;
    }

    // Check total medias
    if (mediaUrl.length + files.length > this._config.upload.maxFiles) {
      target.value = null;
      this._toast.error(this._transloco.translate('Limite máximo de arquivos atingido.'))
      return;
    }

    // Check media type
    if (!fileType) {
      this._toast.error(this._transloco.translate('Tipo de arquivo não permitido'))
      return;
    }

    // Check total image medias
    if (fileType == 'image' && (mediaUrl.filter(x => ['png', 'jpg', 'jpeg'].includes(x.awsMediaName.split('.')[1])).length) >= this._config.upload.maxPhotos) {
      target.value = null;
      this._toast.error(this._transloco.translate('Limite de fotos atingido para a publicação.'))
      return;
    };
    // Check total video medias
    if (fileType == 'video' && (mediaUrl.filter(x => ['mp4', '3gpp', 'mov', 'webm'].includes(x.awsMediaName.split('.')[1])).length) >= this._config.upload.maxVideos) {
      target.value = null;
      this._toast.error(this._transloco.translate('Limite de vídeos atingido para a publicação.'))
      return;
    }

    this.loading = true;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('Files', files[i], files[i].name);
    }
    formData.append('Site.SiteId', this._userService.user.lastSiteId.toString());

    this._postService.addPostMedia(formData)
      .subscribe({
        next: (r: { mediaUrls: { awsMediaName: string }[] }) => {
          r.mediaUrls.forEach((m, i) => {
            this.appendMediaFile(m.awsMediaName, fileType, files[i]);
          });
          this._post.mediaUrls = (this._post.mediaUrls || []).concat(r.mediaUrls);
          this.form.get('mediaUrls').setValue(this._post.mediaUrls.map(m => ({ awsMediaName: m.awsMediaName })));
          this._checkIfHasTextOrMedia();
          this.loading = false;
          setTimeout(() => {
            target.value = null;
          }, 200);
        },
        error: () => {
          target.value = null;
          this.loading = false;
          this._toast.error(this._transloco.translate(PostErrorMsg.defaultError));
        }
      })
  }

  removeMediaFile(media: SvcMediaCarouselRemoveEvent) {
    const text = this._transloco.translate('Tem certeza que deseja remover este anexo do post?');
    this.dialogService.openConfirm(text).subscribe((confirmed) => {
      if (confirmed) {
        this._mediasCarousel = this._mediasCarousel.filter(s => s.name !== media.item.name);
        this._post.mediaUrls = this._post.mediaUrls.filter(p => p.awsMediaName !== media.item.name);
        this.form.get('mediaUrls').setValue(this._post.mediaUrls);
        this._checkIfHasTextOrMedia();
        this.checkAddVideoAndPhotoButtons();
        media.onDeleted();
      }
    });
  }

  canAddPhoto(options: { showMsg: boolean } = { showMsg: true }) {
    const qttyPhotos = this._mediasCarousel?.filter(s => !s.type || s.type === 'image').length ?? 0;
    if (qttyPhotos >= this._config.upload.maxPhotos) {
      options?.showMsg && this._toast.error(this._transloco.translate('Limite de fotos atingido para a publicação.'));
      this.addPhotoEnabled = false;
    }
    else {
      this.addPhotoEnabled = true;
    }
    return this.addPhotoEnabled;
  }

  canAddVideo(options: { showMsg: boolean } = { showMsg: true }) {
    const qttyVideos = this._mediasCarousel?.filter(s => s.type === 'video').length ?? 0;
    if (qttyVideos >= this._config.upload.maxVideos) {
      options?.showMsg && this._toast.error(this._transloco.translate('Limite de vídeos atingido para a publicação.'));
      this.addVideoEnabled = false;
    }
    else {
      this.addVideoEnabled = true;
    }
    return this.addVideoEnabled;
  }

  private checkAddVideoAndPhotoButtons() {
    this.canAddPhoto({ showMsg: false });
    this.canAddVideo({ showMsg: false });
  }

  private appendMediaFile(name: string, type: FileType, file: any) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this._mediasCarousel = [
        ...(this._mediasCarousel || []),
        ({
          name: name,
          url: e.target.result as string,
          type: type
        })
      ];
      this.checkAddVideoAndPhotoButtons();
    };
    reader.readAsDataURL(file);
  }

  private loadStarterAction(type: StarterType) {
    switch (type) {
      case "photo":
        this.imageInput?.nativeElement.click();
        break;
      case "video":
        this.videoInput?.nativeElement.click();
        break;
      case "text":
      default:
        this.postMentionElement?.editor.quillEditor.focus();
        break;
    }
  }

  private getFiletypeByMimetype(mimetype: string): FileType {
    switch (mimetype) {
      case 'video/mp4':
      case 'video/3gpp':
      case 'video/quicktime':
        return 'video';
      case 'image/png':
      case 'image/jpg':
      case 'image/jpeg':
        return 'image';
      default:
        return null;
    }
  }
}
