import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { MatIconModule } from "@angular/material/icon";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SvcButtonsModule, SvcSlideToggleModule } from 'projects/lib-shared-component/src/public-api';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvcVideoPlayerComponent } from './components/svc-video-player/svc-video-player.component';
import { SvcSubtitleService } from './services/svc-subtitle.service';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    SvcVideoPlayerComponent
  ],
  imports: [
    LibSharedCommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatButtonModule,
    TranslocoModule,
    SvcSlideToggleModule,
    NgxSkeletonLoaderModule,
    SvcButtonsModule,
  ],
  exports: [
    SvcVideoPlayerComponent
  ],
  providers: [
    SvcSubtitleService
  ]
})
export class SvcVideoPlayerModule { }
