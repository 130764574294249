import { inject, Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import * as ExcelJS from 'exceljs';

import { SvcExportFileExcelData } from './interfaces/svc-export-file-excel.interface';

@Injectable({
  providedIn: 'root'
})
export class SvcExportFileExcelService {

  #translocoService = inject(TranslocoService);

  #getColumnsHeader(data: SvcExportFileExcelData[]): ExcelJS.Column[] {
    return Object.keys(data[0])?.filter(key => key !== 'isAccordion')?.map(key => ({
      header: key,
      key: key,
    })) as ExcelJS.Column[];
  }

  public exportToExcel(data: SvcExportFileExcelData[], fileName: string, title: string): void {
    const workbook = new ExcelJS.Workbook();
    title = this.#translocoService.translate(title || 'Relatório');
    const worksheet = workbook.addWorksheet(title);

    let startRow = 1;

    const columnHeaders = this.#getColumnsHeader(data);

    worksheet.columns = columnHeaders;

    if (title) {
      this.#setTitleExcel(data, worksheet, title);
      startRow = 2;
    }

    this.#setColumnsStyle(columnHeaders, worksheet, startRow);
    this.#addCellsStyle(data, worksheet, title);
    this.#setWidthCells(worksheet);
    this.#downloadFile(workbook, fileName);
  }

  #setColumnsStyle(columnHeaders: ExcelJS.Column[], worksheet: ExcelJS.Worksheet, startRow: number): void {
    const headerRow = worksheet.getRow(startRow);
    columnHeaders.forEach((col, index) =>
      headerRow.getCell(index + 1).value = String(col.header)
    );
    headerRow.font = { bold: true };
  }

  #setCellsAlignment(cell: ExcelJS.Cell, row: number, title: string): void {
    if (!title || (title && row !== 1))
      cell.alignment = { horizontal: 'left' };
  }

  #addCellsStyle(data: SvcExportFileExcelData[], worksheet: ExcelJS.Worksheet, title: string): void {
    data.forEach(rowData => {
      const row = worksheet.addRow(rowData);

      row.eachCell((cell) => {
        this.#setCellsAlignment(cell, row?.number, title);
        if (rowData?.isAccordion) {
          cell.alignment = { horizontal: 'center' };
          cell.font = { bold: true };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' }
          };
        }
      });
    });
  }

  #setTitleExcel(data: SvcExportFileExcelData[], worksheet: ExcelJS.Worksheet, title: string): void {
    const totalColumns = Object.keys(data?.[0])?.filter(key => key !== 'isAccordion')?.length;

    if (totalColumns === 0) return;

    const lastColumn = worksheet.columns.length;
    worksheet.mergeCells(1, 1, 1, lastColumn);
    const titleRow = worksheet.getRow(1);
    const titleCell = titleRow.getCell(1);

    titleCell.value = title;
    titleCell.alignment = { horizontal: 'center' };
    titleCell.font = { bold: true };

    titleCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    };
  }

  #setWidthCells(worksheet: ExcelJS.Worksheet): void {
    worksheet.columns.forEach((column) => {
      let maxLength = column.header.length;

      column.eachCell({ includeEmpty: true }, (cell) => {
        if (cell.value) {
          const cellValueLength = cell.value.toString().length;
          maxLength = Math.max(maxLength, cellValueLength);
        }
      });

      column.width = maxLength + 2;
    });
  }

  #downloadFile(workbook: ExcelJS.Workbook, fileName: string): void {
    workbook.xlsx.writeBuffer().then((buffer) => {
      const fileToSave = new Blob([buffer], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(fileToSave);
      link.download = `${this.#translocoService.translate(fileName || 'exportar')}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}
