import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { StaticApplicationId, SvcUserPreferencesFeatures, tryToRedirectToAspFromQueryParamData, tryToRedirectToCoreFromQueryParamData } from 'projects/lib-shared-core/src/public-api';
import { catchError, first, forkJoin, map, Observable, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { BaseLayoutComponent, SvcLayoutResolveStatus } from '../base-layout.component';
import { Navigation } from 'projects/lib-shared-core/src/lib/features/navigation/services/navigation.types';
import { Counters } from 'projects/lib-shared-core/src/lib/features/user/models/counters';
import { EnvironmentInfo } from 'projects/lib-shared-core/src/lib/auth/model/environment.info.model';
import { MobileMenuType } from '../components/mobile-menu/mobile-menu.component';
import { ModalPraiseReceivedComponent } from '../../../../praise/modals/modal-praise-received/modal-praise-received.component';
import { externalURLSamePageWithoutHistory } from 'projects/lib-shared-common/src/public-api';
import { AuthStatus } from 'projects/lib-shared-core/src/lib/auth/model/user-auth.model';

@Component({
  selector: 'svc-workspace-layout',
  templateUrl: './solvace-workspace-layout.component.html',
  styleUrls: ['./solvace-workspace-layout.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms 50ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('250ms 50ms', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class SolvaceWorkspaceLayoutComponent extends BaseLayoutComponent {

  protected navigation: Navigation;
  protected counters: Counters;

  protected windowWidth: number = window.innerWidth;

  /**
   * Constructor
   */
  constructor() {
    super();
    this._renderer.listen(window, 'resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  
  /**
   * On init
   */
  public ngOnInit(): void {
    super.ngOnInit();
  }

  protected reloadUserNavigation(): void {
    if (!this.navigationIsLoading) {
      this._navigationService.get(StaticApplicationId.myworkspace).subscribe();
    }
  }

  protected onLayoutResolvingFinished(): void {
    super.onLayoutResolvingFinished();

    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    this._svcSidebarNavigationService.onOpen.subscribe(() => {
      this.mobileMenuClick('tasks');
    });

    this._activatedRoute.queryParamMap.pipe(
      tap((params) => {
        if (params.has('openMenuType')) {
          this.mobileMenuClick(params.get('openMenuType') as MobileMenuType);
        }
        else if (params.has('redirectAsp')) {
          const redirectAsp = params.get('redirectAsp');
          tryToRedirectToAspFromQueryParamData(redirectAsp);
        }
        else if (params.has('redirectCore')) {
          const redirectCore = params.get('redirectCore');
          tryToRedirectToCoreFromQueryParamData(redirectCore);
        }
        else if (params.has('openNotificationModal')) {
          this._notificationIntegrationService.openIframeByPath(params.get('openNotificationModal'));
        }
      }),
    ).subscribe();
  }

  protected onLayoutResolving(): Observable<SvcLayoutResolveStatus> {
    return this.getRedirectLastTab().pipe(
      switchMap((redirect) => {
        this._navigationService.get(StaticApplicationId.myworkspace).subscribe();
        return forkJoin({
          redirect: of(redirect),
          user: this._userService.get(),
          environment: this._userService.getEnvironment(),
        })
      }),
      switchMap(({ redirect, environment }) => {
        if (redirect.needToRedirect) {
          this._router.navigateByUrl(redirect.routePath);
        }
        const userStatus = this._authService.userStatusAuth?.status;
        if (<AuthStatus>userStatus == AuthStatus.SupplierUser) {
          externalURLSamePageWithoutHistory(`${environment.url}/suppliermanagement`, ``);;
          return of(SvcLayoutResolveStatus.CustomRedirection);
        }
        if (<AuthStatus>userStatus == AuthStatus.ContractorUser) {
          externalURLSamePageWithoutHistory(`${environment.url}/contractormanagement`, ``);
          return of(SvcLayoutResolveStatus.CustomRedirection);
        }

        if (<AuthStatus>userStatus == AuthStatus.PendingGDPRUser)
          return of(SvcLayoutResolveStatus.PendingGDPRUser);

        return of(SvcLayoutResolveStatus.Success);
      }),
    );
  }

  private getRedirectLastTab(): Observable<{ needToRedirect: boolean, routePath: string }> {
    const url = this._router.url;
    const defaultValue = {
      needToRedirect: false,
      routePath: url,
    };
    return this._userPreferencesService.getByFeatureName(
      StaticApplicationId.myworkspace,
      'Myworkspace Tabs',
    ).pipe(
      take(1),
      map((preferences: SvcUserPreferencesFeatures) => {
        const mainPath = '/board/main/posts';
        const customProperties = preferences.customProperties?.find((cp) => cp.propertyKey === 'LastTabView');
        const lastTabName = customProperties?.propertyValue;
        if (lastTabName && url === mainPath && url !== `/board/main/${lastTabName}`) {
          return {
            needToRedirect: true,
            routePath: `/board/main/${lastTabName}`,
          };
        }
        return defaultValue;
      }),
      catchError(() => of(defaultValue)),
    );
  }

  /**
   * On destroy
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  protected onKaizenResumeClicked() {
    this._router.navigate(['/board/main/kaizen']);
  }

  protected getCounters(value: Counters) {
    this.counters = { ...this.counters, ...value };
  }

  protected openPraiseReceived() {
    if (this.counters?.compliments > 0) {
      const userId = this._userService.userId$;
      this._dialogService.open(ModalPraiseReceivedComponent, {
        data: userId,
        width: '100%',
        panelClass: ['sm:p-3'],
        maxWidth: '794px',
        autoFocus: false,
        disableClose: true,
      });
    }
  }
}
