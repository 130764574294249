import { Component, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, forwardRef } from '@angular/core';
import { SvcTreeEntryComponent } from '../svc-tree-entry/svc-tree-entry.component';
import { SvcTreeComponent, SvcTreeItem, SvcTreeOrientation } from '../svc-tree.component';

@Component({
  selector: 'svc-tree-branch',
  templateUrl: './svc-tree-branch.component.html',
  styleUrls: ['./svc-tree-branch.component.scss'],
  host: {
    '[class.svc-tree-branch]': 'true',
    '[class.more-than-one-entry]': 'input?.children?.length > 1',
    '[class.is-vertical]': 'parentTree.isVertical',
    '[class.is-horizontal]': 'parentTree.isHorizontal',
  }
})
export class SvcTreeBranchComponent implements OnInit, OnDestroy {

  @Input() input: SvcTreeItem<any>;

  protected orientation = SvcTreeOrientation.VERTICAL;

  constructor(
    @Inject(forwardRef(() => SvcTreeComponent))
    public parentTree: SvcTreeComponent,
    @Optional()
    @Inject(forwardRef(() => SvcTreeEntryComponent))
    public parentEntry: SvcTreeEntryComponent,
  ){}

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }
}
