<div
  class="svc-alert-container"
  *ngIf="!dismissible || (dismissible && !dismissed)"
  [@fadeIn]="!dismissed"
  [@fadeOut]="!dismissed"
>
  <!-- Border -->
  <div class="svc-alert-border" *ngIf="appearance === 'border'"></div>

  <!-- Icon -->
  <div class="svc-alert-icon" *ngIf="showIcon">
    <!-- Custom icon -->
    <div class="svc-alert-custom-icon">
      <ng-content select="[svcAlertIcon]"></ng-content>
    </div>
    <!-- Default icons -->
    <div class="svc-alert-default-icon">
      <mat-icon
        *ngIf="type === 'primary'"
        svgIcon="heroicons_solid:check-circle"
      ></mat-icon>
      <mat-icon
        *ngIf="type === 'accent'"
        svgIcon="heroicons_solid:check-circle"
      ></mat-icon>
      <mat-icon
        *ngIf="type === 'warn'"
        svgIcon="heroicons_solid:x-circle"
      ></mat-icon>
      <mat-icon
        *ngIf="type === 'basic'"
        svgIcon="heroicons_solid:check-circle"
      ></mat-icon>
      <mat-icon
        *ngIf="type === 'info'"
        svgIcon="heroicons_solid:information-circle"
      ></mat-icon>
      <mat-icon
        *ngIf="type === 'success'"
        svgIcon="heroicons_solid:check-circle"
      ></mat-icon>
      <mat-icon
        *ngIf="type === 'warning'"
        svgIcon="heroicons_solid:exclamation"
      ></mat-icon>
      <mat-icon
        *ngIf="type === 'error'"
        svgIcon="heroicons_solid:x-circle"
      ></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="svc-alert-content">
    <div class="svc-alert-title">
      <ng-content select="[svcAlertTitle]"></ng-content>
    </div>
    <div class="svc-alert-message">
      <ng-content></ng-content>
    </div>
  </div>

  <!-- Dismiss button -->
  <button class="svc-alert-dismiss-button" mat-icon-button (click)="dismiss()">
    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
  </button>

  <!-- Custom button -->
  <button *ngIf="buttonText && buttonClick" mat-button (click)="buttonClick()">
    {{ buttonText }}
  </button>
</div>
