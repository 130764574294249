import { AfterViewInit, Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import {
  SvcChartStackedConfig,
  ISvcChartStackedDefinitions,
  ISvcChartStackedInfo,
  ISvcChartStackedOrientation,
  ISvcChartStackedSerie,
  ISvcChartStackedValue,
} from './interfaces/svc-chart-stacked.interface';
import { ECharts, EChartsOption } from 'echarts';
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared';
import { MediaQuerySize, SvcMediaQuery } from 'projects/lib-shared-common/src/lib/services/svc-media-query.service';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { Subject, tap, takeUntil } from 'rxjs';
import { FormatNumberDefaultPipe } from 'projects/lib-shared-common/src/public-api';
import { SvcAppSettings } from 'projects/lib-shared-core/src/public-api';

@Component({
  selector: 'svc-chart-stacked',
  templateUrl: './svc-chart-stacked.component.html',
  styleUrls: ['./svc-chart-stacked.component.scss']
})
export class SvcChartStackedComponent implements AfterViewInit, OnChanges {
  instance: ECharts;

  @Input() height: string = '280px';
  @Input() orientation: ISvcChartStackedOrientation = ISvcChartStackedOrientation.HORIZONTAL;
  @Input() chartInfo: ISvcChartStackedInfo = {} as ISvcChartStackedInfo;
  @Input() chartDefinitions: ISvcChartStackedDefinitions | null = null;
  @Input() public chartConfig: Partial<SvcChartStackedConfig>;
  @Input() errorMsg: string;
  @Input() public isLoading: boolean;
  @Input() public useSkeleton = true;
  @Input() public smallSkeleton: boolean;
  @Input() public classSizeBar = 'w-[72px]';
  @Input() public quantityBarsSkeleton: number;
  @Input() public typeSkeleton: 'custom' | 'default' = 'default';
  @Input() public textBarOrientation = ISvcChartStackedOrientation.HORIZONTAL;

  @Output() chartClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRefresh: EventEmitter<void> = new EventEmitter<void>();
  @Output() onRefreshByModule: EventEmitter<string> = new EventEmitter<string>();

  private formatNumberDefault = new FormatNumberDefaultPipe(inject(SvcAppSettings));

  @AutoDestroy public destroy$ = new Subject<void>();

  chartOptions: Partial<EChartsOption> = {
    tooltip: {
      borderWidth: 0,
      backgroundColor: '#334155',
      textStyle: {
        color: '#fff',
        fontSize: 12,
      },
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (chartInfo: any) => {
        if (!chartInfo.value) return null;
        const text =
          `<div class="flex flex-row space-x-3 items-center">
            <div class="grow">${chartInfo.marker}${chartInfo.seriesName}</div>
            <div>${this.formatNumberDefault.transform(chartInfo.value)}</div>
          </div>`;
        return `<div class="flex flex-col min-w-[130px]">
          <div>${chartInfo?.name}</div>
          <hr class="mt-1 mb-2"/>
          <div class="flex flex-col gap-1">${text}</div>
        </div>`;
      },
    },
    legend: {
      show: true,
      orient: 'horizontal',
      left: 'left',
      top: 'top',
      icon: 'retangule',
      padding: [0, 7],
      itemGap: 5,
      itemHeight: 20,
      itemWidth: 20,
      textStyle: {
        color: '#4d4d4d',
        fontSize: 16,
      },
    },
    grid: {
      top: '10',
      left: '10',
      right: '10',
      bottom: '10',
      height: 120,
      containLabel: false,
    },
    series: [],
  };
  public barsSkeleton: string[];
  public hasDataChart: boolean;
  public orientations = ISvcChartStackedOrientation;

  private _mediaQuery = inject(SvcMediaQuery);
  private _cd = inject(ChangeDetectorRef);

  public onChartInit(evt: ECharts): void {
    this.instance = evt;
    this.addIconClickListener();
  }

  public onChartClick(evt: any): void {
    this.chartClick.emit({
      name: evt.name,
      serieName: evt.seriesName,
      color: evt.color,
      value: evt.value,
      dataIndex: evt.dataIndex,
      componentIndex: evt.componentIndex,
    });
  }

  public ngAfterViewInit(): void {
    this.resize();
    this.setBarsSkeleton();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.smallSkeleton?.previousValue !== changes?.smallSkeleton?.currentValue)
      this.setBarsSkeleton();

    if (this.chartDefinitions)
      this.updateOptions();

    if (this.chartConfig)
      this.updateChartConfig();

    if (this.chartDefinitions || this.chartConfig)
      this.updateInstanceChart();
  }

  public updateInstanceChart(): void {
    this.generateCategories();
    this.generateSeries();
    this.generateTooltip();


    if (this.instance) {
      setTimeout(() => {
        this.instance.resize();
        this.instance.setOption(this.chartOptions);
        this.hasDataChart = (this.instance.getOption() as any)?.series?.some(serie => serie?.data?.length > 0);
      });
    }
  }

  public updateChartDefinitions(chartDefinitions: ISvcChartStackedDefinitions): void {
    if (chartDefinitions) {
      this.chartDefinitions = chartDefinitions;
      this.updateInstanceChart();
    }
  }

  private updateChartConfig(): void {
    if (this.chartConfig) {
      this.chartOptions.legend={
        ...this.chartOptions.legend,
        ...this.chartConfig?.legend
      };
      this.chartOptions.grid = {
        ...this.chartOptions.grid,
        ...this.chartConfig?.grid,
      };
      if (this.chartConfig?.legend){
          this.chartOptions.legend = {
            ...this.chartOptions.legend,
            ...this.chartConfig?.legend,
          };
      }
    }
  }

  private addIconClickListener(): void {
    this.instance.on('click', (params) => {
      if (params.componentType === 'xAxis') {
        const clickedLabel = params.value;

        // Verificando se o rótulo contém o marcador de erro
        const hasError = this.chartDefinitions?.categories?.some(category =>
          category.label === clickedLabel && category.error
        );

        if (hasError) this.refreshByModule(clickedLabel);
      }
    });
  }

  private updateOptions(): void {
    // @ts-ignore
    (<LegendComponent>this.chartOptions.legend).show = this.chartDefinitions?.showLegend ?? true;
  }

  private updateCategories(definitions: Partial<EChartsOption>): void {
    this.chartOptions.yAxis = definitions.yAxis;
    this.chartOptions.xAxis = definitions.xAxis;
  }

  private updateTooltip(definitions: Partial<EChartsOption>): void {
    this.chartOptions.tooltip = definitions.tooltip;
  }

  private updateSeries(definitions: Partial<EChartsOption>): void {
    this.chartOptions.series = definitions.series;
  }

  private generateCategories(): void {
    const iconUrl = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" style="width: 16px; height: 16px; vertical-align: middle;"><path fill-rule="evenodd" d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z" clip-rule="evenodd"/></svg>';

    if (this.orientation == ISvcChartStackedOrientation.HORIZONTAL) {
      this.updateCategories({
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: this.chartDefinitions?.categories?.map(c => c.label),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            rotate: 0,
            fontSize: 14,
            fontWeight: 300,
            color: '#4D4D4D',
            align: 'right',
            margin: 20
          },
        },
      });
    } else {
      this.updateCategories({
        xAxis: {
          triggerEvent: true,
          type: 'category',
          data: this.chartDefinitions?.categories?.map(c => c.label),
          axisLine: {
            show: false,
            ...(this.chartConfig?.xAxis as XAXisOption)?.axisLine
          },
          ...(this.chartConfig?.xAxis as XAXisOption)?.splitLine,
          axisTick: {
            show: false,
          },
          axisLabel: {
            rotate: -90,
            fontSize: 14,
            fontWeight: 300,
            color: (v, i) => {
              return this.chartDefinitions?.categories[i]?.error
                ? 'red'
                : '#4D4D4D';
            },
            verticalAlign: 'middle',
            align: 'left',
            margin: 5,
            formatter: (value: string, index: number) => {
              const maxLength = 13;
              const truncatedValue = value.length > maxLength ? value.slice(0, maxLength) + '...' : value;

              if (this.chartDefinitions?.categories[index]?.error) {
                return `{icon| } {value|${truncatedValue}}`
              }
              return truncatedValue;
            },
            ...(this.chartConfig?.xAxis as YAXisOption)?.axisLabel ?? null,
            rich: {
              icon: {
                height: 20,
                width: 20,
                backgroundColor: {
                  image: iconUrl
                },
              },
            }
          },
        },
        yAxis: Array.isArray(this.chartConfig?.yAxis) ? this.chartConfig?.yAxis.map(x=>{
          return {
            type: 'value',
            position: (x as YAXisOption)?.position??'left',
            min: (x as YAXisOption)?.min??null,
            max: (x as YAXisOption)?.max??null,
            axisLabel: {
              show: false,
              ...(x as YAXisOption)?.axisLabel ?? null
            },
            axisLine: {
              ...(x as YAXisOption)?.axisLine,
            },
            splitLine: {
              ...(x as YAXisOption)?.splitLine,
            }
          }
        }) :{
          type: 'value',
          max: (this.chartConfig?.yAxis as YAXisOption)?.max ?? null,
          axisLabel: {
            show: false,
            ...(this.chartConfig?.yAxis as YAXisOption)?.axisLabel ?? null
          },
          axisLine: {
            ...(this.chartConfig?.yAxis as YAXisOption)?.axisLine,
          },
          splitLine: {
            ...(this.chartConfig?.yAxis as YAXisOption)?.splitLine,
          }
        },
      });
    }
  }

  private generateTooltip(): void {
      const formatNumberDefaultLocal = this.formatNumberDefault;

      this.updateTooltip({
        tooltip:{ borderWidth: 0,
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
        trigger: 'item',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: this.chartDefinitions?.tooltip?.backgroundColor ?? '#334155',
        formatter: this.chartDefinitions?.tooltip?.formatter ?? function (chartInfo: any) {
          if (!chartInfo.value) return null;
          const text =
            `<div class="flex flex-row space-x-3 items-center">
              <div class="grow">${chartInfo.marker}${chartInfo.seriesName}</div>
              <div>${formatNumberDefaultLocal.transform(chartInfo.value)}</div>
            </div>`;
          return `<div class="flex flex-col min-w-[130px]">
            <div>${chartInfo?.name}</div>
            <hr class="mt-1 mb-2"/>
            <div class="flex flex-col gap-1">${text}</div>
          </div>`;
        },}
      });
  }

  private generateSeries(): void {
    const items: any[] = [];

    this.chartDefinitions?.series?.forEach((serie: ISvcChartStackedSerie, index: number) => {
      items.push({
        name: serie.name,
        type: serie.type ?? 'bar',
        stack: this.chartDefinitions.stacked ?? false ? 'stacked' : index.toString(),
        barMaxWidth: serie?.maxWidth ?? 60,
        barMinWidth: serie?.minWidth ?? 'auto',
        barMinHeight: serie?.minHeight ?? 30,
        z: serie?.z,
        symbol:'none',
        zlevel: serie?.zLevel,
        yAxisIndex: serie?.yAxisIndex ?? 0,
        itemStyle: {
          color: serie.color,
          borderColor: 'transparent',
          borderWidth: 0,
        },
        label: {
          show: true,
          inside: serie.label ?? true,
          fontSize: 10,
          fontWeight: 'bolder',
          ...serie?.label
        },
        data: serie.values.map((valueItem: ISvcChartStackedValue) => {
          var value = valueItem.value;
          if(!valueItem.visualization){
            value = valueItem.value == 0 ? null : valueItem.value;
          }
          return {
            name: valueItem.name,
            value: value,
            itemStyle: {
              borderRadius: valueItem.isLast ? valueItem?.borderRadius ? valueItem?.borderRadius : [6, 6, 0, 0] : 0,
            },
            label: valueItem.labelDinamic ?? null
          };
        }),
        markLine : {
          ...serie?.markLine
        }
      });
    });

    this.updateSeries({ series: items });
  }

  public refresh(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.onRefresh.emit();
  }

  public refreshByModule(type): void {
    this.onRefreshByModule.emit(type);
  }

  public resize(): void {
    this._mediaQuery.size$
      .pipe(
        tap(() => this.setBarsSkeleton()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getBarsQuantity(size: MediaQuerySize): number {
    if (this.typeSkeleton === 'custom') return this.quantityBarsSkeleton;
    if (!this.smallSkeleton)
      return size?.isXS ? 3 : size?.isSM ? 6 : size?.isMD ? 8 : 10;
    return size?.isXS ? 3 : size?.isMD ? 4 : 5;
  }

  public setBarsSkeleton(): void {
    const size: MediaQuerySize = this._mediaQuery.currentSize
    this.barsSkeleton = Array.from({ length: this.getBarsQuantity(size) }, () => (
      this.getRandomHeight()
    ));
    this._cd.detectChanges();
  }

  public getRandomHeight(): string {
    let maxHeight = Number(this.height?.match(/\d+/g)?.[0]) - 40;
    if (this.orientation === this.orientations.VERTICAL)
      maxHeight -= 28;
    const randomHeight = Math.random() * maxHeight;
    return `${Math.min(randomHeight, maxHeight)}px`;
  }
}
