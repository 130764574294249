import { Injectable } from '@angular/core';
import { InspectionIndicator } from './models/inspection-indicator';
import { Observable, Subject, catchError, map, of, tap, throwError } from 'rxjs';
import { CommonCounter, CommonIndicator } from 'projects/lib-shared-model/src/public-api';
import { InspetionCategorizedDrillDown } from './models/inspection-drilldown';
import { ModuleHttpClient } from './module-http-client';
import { SvcCilRegisterLink } from './models/svc-cil-register-link.interface';
import { SvcRegistersLink } from 'projects/lib-shared-core/src/lib/interfaces/svc-register-link.interface';
import { TasksCategory } from 'projects/lib-shared-core/src/lib/features/main-tasks/models/tasks-category';

@Injectable()
export class LibServiceCilAnalyticsService {

  private _indicator: Subject<InspectionIndicator> = new Subject<InspectionIndicator>();
  private _approvalsIndicator: Subject<CommonCounter[]> = new Subject<CommonCounter[]>();
  private _subordinatesIndicator: Subject<InspectionIndicator> = new Subject<InspectionIndicator>();
  private _categorizedDrillDown: Subject<InspetionCategorizedDrillDown[]> = new Subject<InspetionCategorizedDrillDown[]>();

  constructor(
    private _httpClient: ModuleHttpClient,
  ) { }

  public _indicator$: Observable<InspectionIndicator> = this._indicator.asObservable();
  public _subordinatesIndicator$: Observable<InspectionIndicator> = this._subordinatesIndicator.asObservable();
  public _approvalsIndicator$: Observable<CommonCounter[]> = this._approvalsIndicator.asObservable();
  public _categorizedDrillDown$: Observable<InspetionCategorizedDrillDown[]> = this._categorizedDrillDown.asObservable();
  
  getInspectionIndicators(): Observable<InspectionIndicator> {
    this._indicator.next(null);
    return this._httpClient.get<CommonIndicator>('/Counters/inspections').pipe(
      map((response: CommonIndicator) => new InspectionIndicator(response)),
      tap((indicator) => {
        this._indicator.next(indicator);
      }),
      catchError((error) => {
        this._indicator.next(new InspectionIndicator({ hasError: true }));
        return throwError(() => error);
      }),
    );
  }

  getInspectionSubordinatesIndicators(): Observable<InspectionIndicator> {
    this._indicator.next(null);
    return this._httpClient.get<CommonIndicator>('/Counters/inspections/subordinates').pipe(
      map((response: CommonIndicator) => new InspectionIndicator(response)),
      tap((indicator) => {
        this._subordinatesIndicator.next(indicator);
      }),
      catchError((error) => {
        this._indicator.next(new InspectionIndicator({ hasError: true }));
        return throwError(() => error);
      }),
    );
  }

  getInspectionApprovalsIndicator(): Observable<CommonCounter[]>{
    return this._httpClient.get<CommonCounter[]>('/Counters/inspections/approvals').pipe(
      tap((indicator) => {
        this._approvalsIndicator.next(indicator);
      })
    );
  }

  getInspectionCategorizedDrillDown(type: TasksCategory): Observable<InspetionCategorizedDrillDown[]> {
    return this._httpClient.get<InspetionCategorizedDrillDown[]>(`/Analytics/inspections/details/type?indicadorTypeDetail=${type}`).pipe(
      map((response: InspetionCategorizedDrillDown[]) => response),
      tap((indicator) => {
        this._categorizedDrillDown.next(indicator);
      })
    );
  }

  getInspectionCategorizedDrillDownSubordinates(type: TasksCategory): Observable<InspetionCategorizedDrillDown[]> {
    return this._httpClient.get<InspetionCategorizedDrillDown[]>(`/Analytics/subordinates/inspections/details/type?indicadorTypeDetail=${type}`).pipe(
      map((response: InspetionCategorizedDrillDown[]) => response),
      tap((indicator) => {
        this._categorizedDrillDown.next(indicator);
      })
    );
  }

  getRegistersLink(generalSearch: string): Observable<SvcCilRegisterLink[]> {
    return this._httpClient.get<SvcCilRegisterLink[]>(`/simpleSearch?generalSearch=${generalSearch}`);
  }

  getRegisterLinkDataGeneric(generalSearch: string): Observable<SvcRegistersLink[]> {
    return this.getRegistersLink(generalSearch).pipe(
      map((links: SvcCilRegisterLink[]) => {
        return links?.map(link => (
          {
            id: link.checklistId,
            name: link.checklistName,
            siteId: link.siteId,
            siteName: link.siteName,
            moduleAlias: link.moduleAlias,
            jsonCallback: link.jsonCallback,
          }
        ))
      })
    );
  }
}
