<ng-content></ng-content>

<div
  *ngIf="showingAnimatedGif && currentCustomReaction?.imageUrl"
  @animateContainerfadeOut
  class="svc-reaction-gif"
  [class.default-position]="animatedGifParams.left === 0"
  [style.left.px]="animatedGifParams.left"
  [style.top.px]="animatedGifParams.top"
>
  <img
    [src]="currentCustomReaction?.imageUrl"
    [style.width.px]="currentCustomReaction?.sizeWidth"
    [style.height.px]="currentCustomReaction?.sizeHeight"
    [style.opacity]="currentCustomReaction.opacity"
    [style.transform-origin]="currentCustomReaction?.animationOriginX + ' ' + currentCustomReaction?.animationOriginY"
    [style.transform]="currentCustomReaction?.fullScale"
  />
</div>

<ng-template #reactionsTemplate>
  <div [hidden]="disabled" class="svc-reaction-list-wrapper">
    <section class="shadow bg-white" [ngClass]="{ 'rounded-full': (loadingList || loadingCurrent) && !reactionsType.length }">
      <div class="flex" [style.width.px]="reactionsType.length * 30">
        <div
          *ngFor="let reactionType of reactionsType"
          class="svc-reaction-item"
          [ngClass]="{ 'active': reactionType.reactionUniqueId == currentReactionType?.reactionUniqueId }"
          (click)="makeReactionByClickEvent(reactionType, $event)"
        >
          <img [src]="reactionType.staticImageUrl" />
          <img [src]="reactionType.animatedImageUrl" />
        </div>
      </div>
      <div *ngIf="loadingList || loadingCurrent" class="flex justify-center items-center svc-reaction-loading">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
    </section>
  </div>
</ng-template>
