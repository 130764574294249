import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvcOnlyofficeComponent } from './components/svc-onlyoffice.component';
import { DocumentEditorModule } from '@onlyoffice/document-editor-angular';

@NgModule({
  declarations: [
    SvcOnlyofficeComponent
  ],
	imports: [
		CommonModule,
		DocumentEditorModule
	],
	exports: [
		SvcOnlyofficeComponent
	]
})
export class SvcOnlyofficeModule { }
