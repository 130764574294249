import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { SvcHttpClient } from "projects/lib-shared-common/src/public-api";
import { SvcReactionsCounterService } from "projects/lib-shared-core/src/public-api";
import { Observable, Subject, catchError, finalize, map, of, take, tap } from "rxjs";
import { ReactionType } from "./models/reaction-type.model";
import { Reacted } from "./models/reacted.model";

export interface ReactionsParamsParent {
  parentApplicationId: string;
  parentRegisterId?: number;
  parentRegisterUId?: string;
  parentOriginatorId: string;
}
export interface ReactionParams {
	applicationId: string;
  parent?: ReactionsParamsParent;
}
export interface IdReactionParams extends ReactionParams {
	siteId: number;
	reactionUniqueId: number;
	registryUniqueId: number;
}
export interface UidReactionParams extends ReactionParams {
	siteId: number;
	reactionUniqueId: number;
	registryUniqueUId: string;
}
export interface GetIdReactionParams extends ReactionParams {
	registryUniqueId: number;
  siteId: number;
}
export interface GetUidReactionParams extends ReactionParams {
	registryUniqueUId: string;
  siteId: number;
}

@Injectable()
export class SvcReactionService extends SvcHttpClient {

	private _reactionsType: ReactionType[];
	private _reactionsType$: Subject<ReactionType[]>;

	constructor(
		appConfig: AppEnvironmentConfig,
		httpClient: HttpClient,
		private counterBusService: SvcReactionsCounterService,
	) {
		super(appConfig.APIs.apiUrlReaction, httpClient);
	}

	getReactionsType(): Observable<ReactionType[]> {
		if (this._reactionsType) {
			return of(this._reactionsType);
		}

		if (!this._reactionsType$) {
			this._reactionsType$ = new Subject<ReactionType[]>();
			this.get<ReactionType[]>('/Reaction?active=true').pipe(
				tap((response) => {
					this._reactionsType = response;
					this._reactionsType$.next(response);
					this._reactionsType$.complete();
				}),
				catchError((error) => {
					this._reactionsType$.error(error);
					this._reactionsType$.complete();
					this._reactionsType$ = null;
					return error;
				})
			).subscribe();
		}

		return this._reactionsType$.pipe(take(1));
	}

	reactionId(data: IdReactionParams): Observable<number> {
		return this.post<{ registryReactionUniqueId: number }>('/RegistryId', data).pipe(
			map((response) => response.registryReactionUniqueId),
		);
	}

	reactionUid(data: UidReactionParams): Observable<number> {
		return this.post<{ registryReactionUniqueId: number }>('/RegistryUid', data).pipe(
			map((response) => response.registryReactionUniqueId),
		);
	}

	getIdTotalReaction(params: GetIdReactionParams): Observable<Reacted[]> {
		return this.counterBusService.getCountByRegistryId(
			params.registryUniqueId,
			{
				applicationId: params.applicationId,
				siteId: params.siteId,
			},
		);
	}

	getUidTotalReaction(params: GetUidReactionParams): Observable<Reacted[]> {
		return this.counterBusService.getCountByRegistryId(
			params.registryUniqueUId,
			{
				applicationId: params.applicationId,
				siteId: params.siteId,
			},
		);
	}

	removeIdReaction(data: IdReactionParams): Observable<void> {
		return this.request<void>('delete', '/RegistryId', {
			body: data
		});
	}

	removeUidReaction(data: UidReactionParams): Observable<void> {
		return this.request<void>('delete', '/RegistryUid', {
			body: data
		});
	}
}
