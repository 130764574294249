import {inject, Injectable} from '@angular/core';
import {SurveyService} from "./modals/survey/survey.service";
import {NewsletterService} from "./modals/newsletter/newsletter.service";
import {ModalInsightsDefault} from "./models/modal-insights-default";
import { CommunicationService } from './modals/modal-view-communication/communication.service';

/**
 * Shows Modals on Module Starts
 */
@Injectable({
  providedIn: 'root'
})
export class ModalInsightsService {

  services: Array<ModalInsightsDefault<any>> = [];

  constructor() {
    // Modal services in the order in which they should be performed
    this.services = [
      inject(NewsletterService),
      inject(SurveyService),
      inject(CommunicationService)
    ]
  }

  async showModalInsights() {
    await this.runInsightAtPosition(0);
  }

  private async runInsightAtPosition(index: number) {
    if (index > this.services.length - 1)return;
    const service = this.services[index];
    await new Promise<void>(() => {
      service.run();
      service.onClose.subscribe(r => {
        this.runInsightAtPosition(index + 1);
      },error => {
        this.runInsightAtPosition(index + 1);
      })
    });
  }
}
