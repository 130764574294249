import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DomSanitizer } from '@angular/platform-browser';
import { svcAnimations } from 'projects/lib-shared-component/src/public-api';
import { AuthService } from 'projects/lib-shared-core/src/public-api';

@Component({
  selector: 'auth-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: svcAnimations,
  host: {
    '[class.block]': 'true',
    '[class.min-h-full]': 'true',
    '[class.overflow-auto]': 'true',
  },
})
export class AuthForgotPasswordComponent implements OnInit {
  @ViewChild('forgotPasswordNgForm') forgotPasswordNgForm: NgForm;

  alert: { type: String; message: string } = {
    type: 'success',
    message: '',
  };
  forgotPasswordForm: UntypedFormGroup;
  showAlert: boolean = false;
  public solvaceLogo$ = this._authService.solvaceLogo$;
  isSmScreen = false;

  get backgroundImg() {
    return this._sanitizer.bypassSecurityTrustStyle(
      this._authService.getCoverImage()
    );
  }

  /**
   * Constructor
   */
  constructor(
    private _translocoService: TranslocoService,
    private _authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _sanitizer: DomSanitizer
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.onWindowResize();
    // Create the form
    this.forgotPasswordForm = this._formBuilder.group({
      user: ['', [Validators.required]],
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Send the reset link
   */
  sendResetLink(): void {
    // Return if the form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    // Disable the form
    this.forgotPasswordForm.disable();

    // Hide the alert
    this.showAlert = false;

    // Forgot password
    this._authService
      .forgotPassword(this.forgotPasswordForm.get('user').value)
      .then(() => {
        this._router.navigate(['/reset-password']);
      })
      .catch((error) => {
        this.#customizedErrorMessage(error);
      })
      .finally(() => {
        // Re-enable the form
        this.forgotPasswordForm.enable();

        // Reset the form
        this.forgotPasswordNgForm.resetForm();

        // Show the alert
        this.showAlert = true;
      });
  }

  #customizedErrorMessage(error?: any) {
    error = error ?? {};
    error = ('error' in error ? error.error : error) ?? {};
    const limitExceededType = 'LimitExceededException'.toLowerCase();
    const notAuthorizedType = 'NotAuthorizedException'.toLowerCase();
    const userLambdaValidationType = 'UserLambdaValidationException'.toLowerCase();
    const attemptMessage1 = 'Attempt limit exceeded, please try after some time.'.toLowerCase();
    const attemptMessage2 = 'Password attempts exceeded'.toLowerCase();
    const attemptMessage3 = 'envMaxFailedAttempts'.toLowerCase();
    const attemptMessage4 = 'repeated failed login attempts'.toLowerCase();
    const attemptMessage5 = 'PreAuthentication failed with error User is blocked due to repeated failed login attempts'.toLowerCase();
    const cantBeResetMessage = 'User password cannot be reset in the current state.'.toLowerCase();
    const errorCode = error?.code ?? error?.__type ?? error?.name ?? '';
    const errorMessage = error?.message ?? '';
    if (
      [limitExceededType, notAuthorizedType, userLambdaValidationType].some(m => errorCode.toLowerCase().includes(m)) &&
      [attemptMessage1, attemptMessage2, attemptMessage3, attemptMessage4, attemptMessage5, cantBeResetMessage].some(m => errorMessage.toLowerCase().includes(m))
    ) {
      this.alert = {
        type: 'error',
        message: this._translocoService.translate('Seu acesso está temporariamente bloqueado por consecutivas tentativas de login inválidas. Tente novamente mais tarde.')
      };
    } else {
      this.alert = {
        type: 'error',
        message: this._translocoService.translate('Usuário inválido')
      };
    }
  }
  
  @HostListener('window:resize')
  onWindowResize() {
    this.isSmScreen = window.innerWidth <= 1024;
  }
}
