import { NgModule } from '@angular/core';
import { SvcCarouselComponent } from './svc-carousel.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { TranslocoModule } from '@ngneat/transloco';

/**
 * @deprecated use module svc-media-carousel 
 */
@NgModule({
  imports: [
    LibSharedCommonModule, 
    LibSharedComponentModule,
    LibSharedIconsModule,
    TranslocoModule,
  ],
  exports: [SvcCarouselComponent],
  declarations: [SvcCarouselComponent],
})
export class SvcCarouselModule {}
