<div class="flex-auto divide-y">
  <ag-virtual-scroll *ngIf="tasks && tasks.length > 0" #vs [items]="tasks" height="500px" [min-row-height]="102">
    <div *ngFor="let task of vs.items" class="table w-full hover:bg-zinc-100 py-2 px-2">
      <div class="flex gap-2.5">
        <div class="flex flex-col items-center" [style]="{ 'max-width': '46px', 'min-width': '40px' }">
          <div class="flex items-center justify-start min-w-10" [style.minHeight.px]="30">
            <svc-bookmark-button
              *ngIf="task.hasBookmark"
              iconSizeClass="icon-size-5"
              [referenceId]="task.id"
              [applicationId]="task.applicationId"
              [initialValue]="task.isBookmark"
              (onInitialValueObtained)="handleBookmarkChange(task, $event, true)"
              (onValueChanged)="handleBookmarkChange(task, $event)"
            ></svc-bookmark-button>
            <svc-lock-button class="flex"
              *ngIf="task?.locked != null"
              [readonly]="true"
              [locked]="task.locked"
            />
          </div>
          <div class="flex-auto flex -space-x-5 items-center justify-center">
            <ng-container *ngFor="let owner of task.owners | slice:0:2; index as i">
              <svc-user-avatar
                *ngIf="i < 1 || (i >= 1 && task.owners.length <= 2)"
                [info]="{ name: owner.userName, picture: owner.picture, size: avatarSize, initialsColor: owner.initialsColor }"
                [matTooltip]="owner.userName"
              ></svc-user-avatar>
              <a
                *ngIf="i >= 1 && task.owners.length > 2"
                class="flex items-center justify-center w-8 h-8 text-base font-semibold text-white bg-primary rounded-full border-2 border-white"
                [matTooltip]="(task.owners | getProperty:'userName') | slice:1 | join"
              >
                +{{task.owners.length - 1}}
              </a>
            </ng-container>
          </div>
        </div>
        <div class="flex flex-col flex-auto">
          <div class="flex items-center gap-2 -ml-1.5" [style]="{ 'width': 'calc(100% + 6px)', 'min-height': '30px' }">
            <a class="flex-auto text-primary-500 text-base font-bold cursor-pointer shrink-0" (click)="openModuleItem(task)">
              {{ task.label | transloco }}-{{ task.id }}{{ task.registryNumber ? ('/' + task.registryNumber) : '' }}
            </a>
            <p *ngIf="task.deadline > 0" class="flex-auto text-default text-base leading-tight max-h-16 text-center shrink-0">
              {{ task.deadline | formatNumberDefault }} {{ 'dias' | transloco }}
            </p>
            <svc-status-badge class="flex-auto flex justify-end truncate" [badgeData]="task.status"/>
          </div>
          <div class="relative text-default text-base" [style.height.px]="54">
            <p class="absolute leading-tight line-clamp-3 break-all -translate-y-2/4 top-1/2">
              {{ task.description }}
            </p>
          </div>
        </div>
      </div>
      <ng-container *ngIf="[taskModules.CHECKLIST, taskModules.CIL].includes(task.moduleName)">
        <div class="basis-full relative mt-1">
          <svc-multicolor-progress-bar
            [progressData]="task.itemsData"
            [template-tooltip]="tooltipTemplate"
            (mouseClickColumn)="openInpectionNok(task, $event)"
          ></svc-multicolor-progress-bar>
          <ng-template #tooltipTemplate>
            <div class="flex flex-col text-white">
              <div class="flex flex-row">
                <span class="grow whitespace-nowrap uppercase">{{ 'Total tarefas' | transloco}}</span>
                <span class="ml-3">{{ task.itemsData.total }}</span>
              </div>
              <hr class="mx-0 my-1" />

              <div class="flex flex-col gap-1">
                <div *ngFor="let data of task.itemsData.data" class="flex flex-row items-center">
                  <div class="w-3 h-3 mr-2 rounded-full" [style.background-color]="data.color"></div>
                  <span class="grow">{{ data.label | transloco}}</span>
                  <span>{{data.value}}</span>
                </div>
              </div>

              <hr class="mx-0 my-1" />
              <div class="flex flex-row">
                <span class="grow whitespace-nowrap uppercase">{{ 'Total realizadas' | transloco}}</span>
                <span class="ml-3">{{ task.itemsData.totalPerformed }}</span>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ag-virtual-scroll>
</div>

<main-task-skeleton *ngIf="!tasks?.length && isTotalTasksLoading"></main-task-skeleton>
