import { BehaviorSubject, catchError, filter, finalize, map, Observable, Subscription, tap } from "rxjs";

export enum SvcDataPrefetchingStatus {
	NONE,
	LOADING,
	SUCCESS,
	ERROR,
}

export interface SvcDataPrefetchingItemStructure {
	[key: string]: SvcDataPrefetchingItem<any>;
}

export class SvcDataPrefetchingItem<T> {
	protected valueFallback?: T;
	protected get: () => Observable<T>;
	public status: SvcDataPrefetchingStatus;
	public value: T;
	public value$: Observable<T>;
	public isLoading$: Observable<boolean>;
	public get isLoading() {
		return (this.isLoading$ as BehaviorSubject<boolean>).value;
	}
	public get isSuccess() {
		return this.status === SvcDataPrefetchingStatus.SUCCESS;
	}
	public get isError() {
		return this.status === SvcDataPrefetchingStatus.ERROR;
	}

	constructor(config: { valueFallback?: T, get: () => Observable<T> }){
		Object.assign(this, config);
	}
}

export class SvcDataPrefetchingItemInternal<T> extends SvcDataPrefetchingItem<T> {
	public valueFallback?: T;
	public get: () => Observable<T>;
	public setStatus: (status: SvcDataPrefetchingStatus) => void;

	constructor(config: Partial<SvcDataPrefetchingItemInternal<T>>){
		super({
			...config!,
			get: config.get,
		});
		this.value = null;
		this.status = SvcDataPrefetchingStatus.NONE;
		this.isLoading$ = new BehaviorSubject(true);
		this.value$ = this.isLoading$.pipe(
			filter((loading) => !loading),
			map(() => this.value),
		);
	}
}

export abstract class SvcDataPrefetching<T extends SvcDataPrefetchingItemStructure> {
	protected _data: { [key: string]: SvcDataPrefetchingItemInternal<T> };
	public get data(): T { return this._data as any; }

	protected _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public isLoading$: Observable<boolean> = this._isLoading.asObservable();

	private subscriptions: Subscription[] = [];

	protected prepareDataFetching(data: T) {
		data = data ?? <T>{};
		this._data = Object.keys(data).reduce((_data, propName) => {
			_data[propName] = new SvcDataPrefetchingItemInternal({
				...data[propName],
				setStatus(status) {
					_data[propName].status = status;
					const isLoading = _data[propName].isLoading$ as BehaviorSubject<boolean>;
					isLoading.next(status === SvcDataPrefetchingStatus.LOADING);
				},
			});
			return _data;
		}, <{ [key: string]: SvcDataPrefetchingItemInternal<T> }>{});
	}

	public fetch() {
		this.cancel();
		if (this._data) {
			const allIsFinished = () => {
				if (this._data) {
					return Object.keys(this._data).every((propName) => [
						SvcDataPrefetchingStatus.SUCCESS,
						SvcDataPrefetchingStatus.ERROR,
					].includes(this._data[propName].status))
				}
				return false;
			}
			this._isLoading.next(true);
			for (const propName in this._data) {
				const data = this._data[propName];
				if (data?.status === SvcDataPrefetchingStatus.NONE) {
					if (typeof data.get === 'function') {
						data.setStatus(SvcDataPrefetchingStatus.LOADING);
						data.get?.().pipe(
							tap((response) => {
								data.value = response;
								data.setStatus(SvcDataPrefetchingStatus.SUCCESS);
							}),
							catchError((error) => {
								data.value = data.valueFallback;
								data.setStatus(SvcDataPrefetchingStatus.ERROR);
								return error;
							}),
							finalize(() => {
								if (allIsFinished()) {
									this._isLoading.next(false);
								}
							})
						).subscribe();
					}
				}
			}
		}
	}

	public reset() {
		if (this._data) {
			for (const propName in this._data) {
				this._data[propName].value = null;
				this._data[propName].setStatus(SvcDataPrefetchingStatus.NONE);
			}
		}
		this.cancel();
	}

	public cancel() {
		this.subscriptions.forEach((s) => s.unsubscribe());
		this.subscriptions = [];
		this._isLoading.next(false);
	}
}