import { TemplateRef } from "@angular/core";

import { SvcExportFileType } from "projects/lib-shared-core/src/lib/services/svc-export-file/interfaces/svc-export-file.interface";

interface SvcDataTableAccordionProperties {
  property?: string;
  type: 'string' | 'number' | 'numberRounded' | 'status' | 'numberBorderRadius' | 'bar' | 'bar-lightened' | 'progressBar';
  cssClass?: string;
  label?: string;
  propertyBarPercentage?: string;
  cellCssClass?: string
  propertyTooltip?: string;
  tooltipTemplateRef?: TemplateRef<void>;
  processExportingValue?: (item: any, column: SvcDataTableAccordionColumn) => string;
  exportable?: boolean;
  fieldTypeExport?: SvcDataTableAccordionType; 
}

export type SvcDataTableAccordionType = SvcExportFileType | 'status';

export interface SvcDataTableAccordion {
  columns: SvcDataTableAccordionColumn[];
  subItemsPropertyName: string;
  showHeaderRowSubItems?: boolean;
}

export interface SvcDataTableAccordionColumn extends SvcDataTableAccordionProperties {
  id?: number | string;
  label: string;
  sort: boolean;
  isSearchable?: boolean;
  accordion: SvcDataTableAccordionProperties;
}

export interface SvcDataTableAccordionSubmenu extends SvcDataTableAccordionProperties { }
