/**
 * @deprecated use componente svc-media-carousel and interface SvcMediaCarouselItem
 */
export interface ISvcCarousel {
  id?: any;
  url: string;
  title: string;
  type?: 'image' | 'video';
  fileLoading?: boolean;
}
