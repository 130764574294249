import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TRANSLOCO_LOADER, TranslocoService } from "@ngneat/transloco";
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { generateModuleMenuURL, getModuleMenuType } from 'projects/lib-shared-common/src/public-api';
import { Observable, ReplaySubject, Subject, catchError, filter, finalize, first, map, merge, mergeWith, of, shareReplay, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { StaticApplicationId } from '../../../Constants/static-application-id.enum';
import { ICONMENU } from './icon-menu-map';
import { Navigation, SvcDefaultNavigationItem, SvcMenuNavigation, SvcMenuNavigationItemType, SvcWorkspaceNavigationItem } from './navigation.types';
import { AuthService } from '../../../auth/auth.service';
import { SvcCacheName } from '../../../services/cache/svc-cache-name.enum';
import { SvcDataCacheService } from '../../../services/cache/svc-data-cache.service';
import { TranslocoApiLoader, TranslocoTermsStatus } from '../../transloco/transloco.api-loader';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _pendingNavigationRequest: Subject<SvcMenuNavigation[]>;
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  private navigation: Navigation;
  public navigation$ = this._navigation.asObservable();
  private _aplicationId: StaticApplicationId;
  public error: any;
  public isLoading: boolean = true;

  private set _navigationCached(value: SvcMenuNavigation[]) {
    this._dataCacheService.set(`MENU_GROUP_${this._aplicationId}` as SvcCacheName, value, { hours: 4 });
  }
  private get _navigationCached(): SvcMenuNavigation[] {
    return this._dataCacheService.get(`MENU_GROUP_${this._aplicationId}` as SvcCacheName);
  }

  constructor(
    private _httpClient: HttpClient,
    private _translocoService: TranslocoService,
    private _appConfig: AppEnvironmentConfig,
    private _authService: AuthService,
    private _dataCacheService: SvcDataCacheService,
    @Inject(TRANSLOCO_LOADER) private _translocoTermsService: TranslocoApiLoader,
  ) {
    merge(
      this._authService.signIn$,
      this._authService.signOut$,
    ).subscribe(logged => {
      if (logged?.success) this.reset();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public reset() {
    this._navigationCached = null;
    this.navigation = null;
  }

  get(aplicationId?: StaticApplicationId): Observable<Navigation> {
    const defaultSvc: any[] = [];
    const defaultSvcWorkspace: SvcWorkspaceNavigationItem[] = [];

    const defaultNavigation = {
      default: defaultSvc,
      workspace: defaultSvcWorkspace
    };

    if (!aplicationId) {
      this._navigation.next(defaultNavigation);
      return of(defaultNavigation);
    }

    this._aplicationId = aplicationId;
    if (this.navigation) {
      return of(this.navigation);
    }

    this.error = null;
    this.isLoading = true;
    let fromCached = false;

    const responseSubject = new Subject<void>();
    this._translocoTermsService.termsStatus$.pipe(
      takeUntil(responseSubject.pipe(mergeWith(this._authService.signOut$))),
      filter((status) => status === TranslocoTermsStatus.LOADED),
      tap(() => {
        responseSubject.next();
        responseSubject.complete();
      }),
    ).subscribe();
    return responseSubject.pipe(
      switchMap(() => {
        const cache = this._navigationCached;
        fromCached = Array.isArray(cache) && cache.length > 0;
        if (fromCached) return of(cache);
        if (!this._pendingNavigationRequest) {
          this._pendingNavigationRequest = new Subject<SvcMenuNavigation[]>();
          const subject = this._pendingNavigationRequest;
          const clear = () => this._pendingNavigationRequest = null;
          this._httpClient.get<SvcMenuNavigation[]>(`${this._appConfig.APIs.apiUrlAdministration}/MenuGroup/module/${aplicationId}`).pipe(
            tap((response) => {
              clear();
              subject.next(response);
            }),
            catchError((error) => {
              clear();
              subject.error(error);
              return error;
            }),
            finalize(() => {
              clear();
              subject.complete();
            })
          ).subscribe();
        }
        return this._pendingNavigationRequest;
      }),
      map((items) => {
        if (!fromCached) this._navigationCached = items;

        const menuItems: SvcDefaultNavigationItem[] = this.mapMenuNavigationItem(items);
        const workspaceItems: SvcWorkspaceNavigationItem[] = this.mapMenuToSvcWorkspaceNavigationItem(items);

        this.navigation = {
          default: menuItems,
          workspace: workspaceItems
        };
        this._navigation.next(this.navigation);
        this.isLoading = false

        return this.navigation;
      }),
      catchError((error) => {
        this.error = error;
        this.isLoading = false;
        return throwError(() => error);
      }),
    );
  }

  /**
   * Get all navigation data
   */
  isAvailable(aplicationId: string): Observable<boolean> {
    return this._httpClient.get<boolean>(`${this._appConfig.APIs.apiUrlAdministration}/MenuGroup/module/${aplicationId}/accessible`);
  }

  private mapMenuNavigationItem(
    menuItems: SvcMenuNavigation[]
  ): SvcDefaultNavigationItem[] {
    let navigationItem: SvcDefaultNavigationItem[] = [];

    menuItems.forEach((item) => {
      navigationItem.push(<SvcDefaultNavigationItem>{
        title: this._translocoService.translate(item.menuGroupName),
        icon: ICONMENU.getValue(item.menuGroupId) || '',
        children: this.hasChildren(item)
          ? item.menus.map((c) => {
            const itemType = c.menuCategoryProvider ? getModuleMenuType(SvcMenuNavigationItemType[c.menuCategoryProvider.applicationType]) : null;
            return {
              title: c.menuDesc,
              url: (itemType === 'link')
                ? generateModuleMenuURL(SvcMenuNavigationItemType[c.menuCategoryProvider.applicationType], c.menuCategoryProvider.url, c.menuCategoryProvider.route, c.menuCategoryProvider.params)
                : null,
            };
          })
          : [],
      });
    });

    return navigationItem;
  }

  private mapMenuToSvcWorkspaceNavigationItem(
    menuItems: SvcMenuNavigation[]
  ): SvcWorkspaceNavigationItem[] {
    let navigationItem: SvcWorkspaceNavigationItem[] = [];

    menuItems.forEach((item) => {
      if (this.hasChildren(item)) {
        item.menus.map((c) => {
          navigationItem.push({
            icon: ICONMENU.getValue(item.menuGroupId) || '',
            url: c.menuCategoryProvider ?
              generateModuleMenuURL(SvcMenuNavigationItemType[c.menuCategoryProvider.applicationType], c.menuCategoryProvider.url, c.menuCategoryProvider.route, c.menuCategoryProvider.params)
              : null,
            title: c.menuDesc,
            params: c.menuCategoryProvider.params
          });
        });
      }
    });
    return navigationItem;
  }

  private hasChildren(menuItem: SvcMenuNavigation): boolean {
    return !!menuItem.menus;
  }

}
