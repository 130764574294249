import {
  BaseCommonIndicatorChart,
  CommonIndicator,
  CommonIndicatorChart,
  CommonIndicatorChartInfo
} from "projects/lib-shared-model/src/public-api";

export enum DefectTagIndicatorCategory {
  LATE = 1,
  TODAY = 2,
  TOMORROW_UP_TO_7_DAYS = 3,
  MORE_THAN_7_DAYS = 4
}
export enum DefectTagIndicatorCategoryNames {
  'Atrasado' = 1,
  'Hoje' = 2,
  '<= 7 dias' = 3,
  '> 7 dias' = 4
}


export enum DefectTagIndicatorStatus {
  SOLVED = 2,
  PLANNED = 3,
  DELAYED = 6,
}
export enum DefectTagIndicatorStatusNames {
  'Resolvida' = 2,
  'Planejada - No Prazo' = 3,
  'Planejada - Atrasada' = 6,
}
export enum DefectTagIndicatorStatusColors {
  '#466900' = 2,
  '#A9D08E' = 3,
  '#E22A2E' = 6,
}


export class DefectTagIndicator extends BaseCommonIndicatorChart {
  data: CommonIndicator;
  chartInfo: CommonIndicatorChartInfo;

  chartOrder = [4,3,2,1];
  seriesOrder = [6, 3, 2];

  constructor(data: CommonIndicator){
    super({
      categories: DefectTagIndicatorCategory,
      categoriesNames: DefectTagIndicatorCategoryNames,
      statuses: DefectTagIndicatorStatus,
      statusesNames: DefectTagIndicatorStatusNames,
      colors: DefectTagIndicatorStatusColors
    });
    this.data = data;
    this.chartInfo = this.generateChartInfo();
  }
}
