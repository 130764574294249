import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { svcAnimations } from 'projects/lib-shared-component/src/lib/animations/animations';
import { SvcToastService } from 'projects/lib-shared-component/src/lib/svc-toast/svc-toast.service';
import { StaticApplicationId} from "../../../Constants/static-application-id.enum";
import { UserNotificationsService} from "../services/user-notifications.service";
import { MatSlideToggleChange} from "@angular/material/slide-toggle";
import { forkJoin, tap} from "rxjs";
import { TranslocoService} from "@ngneat/transloco";

export interface Rule {
  ruleName: string;
  types: RuleType [];
}

export interface RuleType {
  configurationId: number;
  typeName: string;
  active: boolean;
}


@Component({
  selector: 'user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss'],
  animations: svcAnimations,
})
export class UserNotificationsComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  displayedColumns: string[] = ['ruleName', 'web', 'mobile', 'email'];
  displayedConfigColumns = ['web', 'mobile', 'email'];

  applicationRules: {
    id: string,
    name: string,
    rules?: Rule[]
  }[] = [
    { id: StaticApplicationId.posts, name: 'Posts' },
    { id: StaticApplicationId.feed, name: 'Comentários' },
  ]

  ruleTypesToChange: { configurationId: number, active: boolean }[];
  loading: boolean = true;

  constructor(
    public _dialogRef: MatDialogRef<UserNotificationsComponent>,
    private _notificationsService: UserNotificationsService,
    private _toastService: SvcToastService,
    private _transloco: TranslocoService,
    ) {}

  ngOnInit() {
    const requestRules = this.applicationRules.map(app => {
      return this._notificationsService.getAppRules(app.id)
        .pipe(tap((r) => {
          app.rules = r;
        }))
    });

    forkJoin(requestRules).subscribe({
      next: () => {
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this._toastService.error(this._transloco.translate('Ocorreu um erro, tente novamente em outro momento'));
        setTimeout(() => this._dialogRef.close())
      }
    })
  }

  toggleRule(event: MatSlideToggleChange, rule: RuleType) {
    if (!this.ruleTypesToChange) this.ruleTypesToChange = [];
    const index = this.ruleTypesToChange.findIndex(i => i.configurationId === rule.configurationId);
    if (index >= 0) {
      this.ruleTypesToChange[index].active = event.checked;
    } else {
      this.ruleTypesToChange.push({ configurationId: rule.configurationId, active: event.checked });
    }
  }

  submit() {

    if (!this.ruleTypesToChange) return;
    this.loading = true;
    const requests = this.ruleTypesToChange.map(r => {
      return this._notificationsService.saveRule({
        ConfigurationId: r.configurationId,
        Active: r.active
      })
    });

    forkJoin(requests)
      .subscribe({
        next: (r) => {
          this._toastService.success(this._transloco.translate('Configurações de notificação alteradas com sucesso')+'!')
          this.loading = false;
          this._dialogRef.close();
        },
        error: () => {
          this._toastService.error(this._transloco.translate('Ocorreu um erro, tente novamente em outro momento'));
          this.loading = false;
        }
      })

  }

  closeDialog(){
    if (this.loading) return;
    this._dialogRef.close();
  }
}
