<div class="relative flex gap-2">
  <ng-container
    *ngFor="let totalKeyName of totalKeysName"
    [ngTemplateOutlet]="counter"
    [ngTemplateOutletContext]="{
      isAvailable: available?.[totalKeyName] ?? false,
      clickEmitter: totalsInfo[totalKeyName].clickEmitter,
      totalValue: totals?.[totalKeyName] ?? 0,
      icon: totalsInfo[totalKeyName].icon,
      name: (totals?.[totalKeyName] ?? 0) > 1 ? totalsInfo[totalKeyName].name.plural : totalsInfo[totalKeyName].name.single,
      isRotate: rotate?.[totalKeyName] ?? false
    }"
  />
  <ngx-skeleton-loader
    *ngIf="isLoading"
    class="absolute inset-0"
    [theme]="{ width: '100%', height: '100%', marginBottom: 0 }"
  />
</div>

<ng-template #counter let-isAvailable="isAvailable" let-clickEmitter="clickEmitter" let-totalValue="totalValue" let-icon="icon" let-name="name" let-isRotate="isRotate">
  <div *ngIf="isAvailable && (isLoading || (!hideIfEmpty || totalValue > 0))" class="flex text-default font-medium items-center" [ngClass]="type == 'icon' ? 'gap-2' : 'gap-1'" [class.cursor-pointer]="clickEmitter?.observe" [matTooltip]="name | transloco" matTooltipPosition="above" [matTooltipDisabled]="type !== 'icon'" [class]="classColor" (click)="!isLoading && clickEmitter?.emit()">
    <mat-icon *ngIf="type == 'icon'" class="icon-size-3 text-current" [svgIcon]="icon" [class.-scale-x-1]="isRotate"/>
    <div [ngClass]="type === 'text' ? 'text-sm' : 'text-xs'">{{ totalValue | formatNumberDefault }}</div>
    <div *ngIf="type == 'text'" class="text-sm">{{ name | transloco }}</div>
  </div>
</ng-template>
