import {NgModule } from '@angular/core';
import {ModalInsightsService} from "./modal-insights.service";
import {LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import {SurveyService} from "./modals/survey/survey.service";
import {SurveyComponent} from "./modals/survey/survey.component";
import {MatIconModule} from "@angular/material/icon";
import {NewsletterComponent} from "./modals/newsletter/newsletter.component";
import {NewsletterService} from "./modals/newsletter/newsletter.service";
import {TranslocoModule} from "@ngneat/transloco";
import {
  LibSharedComponentModule,
  SvcButtonsModule,
  SvcDialogsModule,
  SvcToastModule
} from 'projects/lib-shared-component/src/public-api';
import { ModalViewCommunicationModule } from './modals/modal-view-communication/modal-view-communication.module';

@NgModule({
  declarations: [
    NewsletterComponent,
    SurveyComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcDialogsModule,
    SvcToastModule,
    TranslocoModule,
    SvcButtonsModule,
    MatIconModule,
    ModalViewCommunicationModule
  ],
  providers: [
    ModalInsightsService,
    SurveyService,
    NewsletterService
  ]
})
export class ModalInsightsModule { }
