import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationService } from '../../features/navigation/services/navigation.service';
import { SvcAppSettings } from '../../settings/svc-app-settings';
import { Navigation } from '../../features/navigation/services/navigation.types';
import { StaticApplicationId } from '../../Constants/static-application-id.enum';
import { PageCantBeLoadedType } from '../../features/page-cant-be-load/page-cant-be-load.component';
import { UserService } from '../../features/user/services/user.service';

@Injectable({
	providedIn: 'root'
})
export class AvailableRouteGuard  {
	/**
	 * Constructor
	 */
	constructor(
		private _router: Router,
		private _navigationService: NavigationService,
		private _userService: UserService,
		private _appSettings: SvcAppSettings,
	) {
	}
	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Can activate
	 *
	 * @param route
	 * @param state
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		this._navigationService.get(this._appSettings.applicationId as StaticApplicationId).pipe(
			tap((response: Navigation) => {
				if (response.default) {
					for (const menu of response.default) {
						for (const subMenu of menu.children) {
							if (typeof subMenu.url === 'string' && state.url.startsWith(subMenu.url)) {
								return;
							}
						}
					}
				}

				if (!Array.isArray(response.default) || !response.default.length) {
					throw new HttpErrorResponse({ status: 400 });
				}
				this._router.navigate(['/']);
			}),
			catchError<any, any>((error: HttpErrorResponse) => {
				this._navigationService.reset();
				if (error?.status === 400) {
					this._router.navigate(['page-cant-be-load'], {
						queryParams: {
							type: PageCantBeLoadedType.PERMISSION,
							from: this._router.url,
						}
					});
				}
				else {
					this._router.navigate(['page-cant-be-load']);
				}
				return error;
			}) as any,
		).subscribe();
		return of(true);
	}
}
