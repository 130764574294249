import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { ISvcMulticolorProgress } from './interfaces/svc-multicolor-progress.interface';

@Component({
  selector: 'svc-multicolor-progress-bar',
  templateUrl: './svc-multicolor-progress-bar.component.html',
  styleUrls: ['./svc-multicolor-progress-bar.component.scss'],
})
export class SvcMultiColorProgressBarComponent {
  @Input() progressData: ISvcMulticolorProgress;

  @Output() mouseEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() mouseLeave: EventEmitter<any> = new EventEmitter<any>();
  @Output() mouseEnterColumn: EventEmitter<any> = new EventEmitter<any>();
  @Output() mouseLeaveColumn: EventEmitter<any> = new EventEmitter<any>();
  @Output() mouseClickColumn: EventEmitter<any> = new EventEmitter<any>();

  get filteredData() {
    return this.progressData.data.filter((c) => c.value).map((item) =>{
      item.minWidth = this.getMinWidth(item.value);
      item.width = this.whichProgress(this.progressData.total, item.value);
      return item;
    });
  }
  constructor() {}

  eventMouseEnter() {
    this.mouseEnter.emit();
  }
  eventMouseLeave() {
    this.mouseLeave.emit();
  }
  eventMouseEnterColumn(column: any) {
    this.mouseEnterColumn.emit(column);
  }
  eventMouseLeaveColumn(column: any) {
    this.mouseLeaveColumn.emit(column);
  }

  whichProgress(totalValue: number, progress: number) {
    try {
      return Math.round((+progress * 100) / totalValue);
    } catch {
      return progress;
    }
  }
  getMinWidth(value: number) {
    const charWidth = 12;
    const padding = 1;
    return value.toString().length * charWidth + padding;
  }

  eventMouseClickColumn(item: any) {
    this.mouseClickColumn.emit(item);
  }
}
