import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  from,
  lastValueFrom,
  map,
  Observable,
  of, Subject, Subscription, switchMap,
  tap,
  throwError
} from 'rxjs';

import { NgxPermissionsService } from 'ngx-permissions';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { AuthCognitoService } from './auth-cognito.service';
import {
  deleteLegacyCookies,
  externalURLSamePageWithoutHistory,
  getAccessTokenPayloadInfo,
  getCookie,
  setCookie,
  signinLegacyApi, updateActionPlanAPIUrlCookie, updateAdministrationAPIUrlCookie,
  updateAuthenticationAPIUrlCookie,
  updateBookmarkAPIUrlCookie, updateChecklistAPIUrlCookie, updateCilAPIUrlCookie,
  updateCloudFrontKeyPairIdCookie,
  updateCloudFrontPolicyCookie,
  updateCloudFrontSignatureCookie, updateCommentManagerAPIUrlCookie, updateCommentsAPIUrlCookie,
  updateCopilotAPIUrlCookie,
  updateDateFormatCookie, updateDefectTagAPIUrlCookie,
  updateDocumentAPIUrlCookie,
  updateEnvironmentFullURLCookie,
  updateHashtagAPIUrlCookie, updateIdleTimeoutCookie,
  updateIntegrationAPITokenCookie,
  updateIntegrationAPIURLCookie,
  updateLanguageIdCookie, updateLastAccessCookie,
  updateLastSiteIdCookie,
  updateMultilingualAPIUrlCookie,
  updateSubtitleAPIUrlCookie,
  updateNotificationAPIUrlCookie, updatePraiseAPIUrlCookie,
  updateProductAPITokenCookie, updateReactionAPIUrlCookie,
  updateReloadSessionCookie,
  updateUserIdCookie
} from 'projects/lib-shared-common/src/public-api';
import { CloudfrontService } from "./legacy/cloudfront.service";
import { AuthStatus, UserStatusAuth } from './model/user-auth.model';
import { AcessSub, CognitoUser, EnvironmentInfo, EnvironmentUserInfo, SsoItem, TokenRefresh, UserSignInFailedResponse } from './model/environment.info.model';
import { AWS_DEFAULT_BACKGROUND_IMAGE } from '../Constants/AWS-IMAGES';
import { AWSAmplifyAuthResult } from './model/aws-amplify-auth-info';
import { User } from '../features/user/services/user.types';
import { AuthUtils } from '../../../../lib-shared-feature/src/lib/auth/auth.utils';
import { tryToRedirectToAspFromQueryParamData, tryToRedirectToCoreFromQueryParamData, canRedirectToAspFromQueryParamData, canRedirectToCoreFromQueryParamData } from "./auth-functions";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from 'projects/environments/environment';
import { SvcDataCacheService } from '../services/cache/svc-data-cache.service';
import { ParameterService } from '../services/parameter/parameter.service';
import { SvcAppSettings } from '../settings/svc-app-settings';
import { SvcModule } from '../../public-api';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private _signIn$ = new Subject<{ success: boolean }>();
  private _signOut$ = new Subject<{ success: boolean }>();
  public signIn$ = this._signIn$.asObservable();
  public signOut$ = this._signOut$.asObservable();

  private _authenticated: boolean = false;
  private _changePasswordRequired: boolean = false;
  private _emailToResetPassword: string;
  private _environmentURL: string;
  private _environmentInfo: EnvironmentInfo;
  private _userStatusAuth: UserStatusAuth = {} as UserStatusAuth;

  public solvaceLogo$ = new BehaviorSubject<string>('');

  public getAuthStatusFn: () => Observable<AuthStatus>;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
    private _permissionsService: NgxPermissionsService,
    private _authCognitoService: AuthCognitoService,
    private _parameterService: ParameterService,
    private _cloudfrontService: CloudfrontService,
    private _activatedRoute: ActivatedRoute,
    private _svcAppSettings: SvcAppSettings,
    private _router: Router,
    private _dataCacheService: SvcDataCacheService
  ) {
    this._dataCacheService.initializeFromAuth(this.signIn$, this.signOut$)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    if (environment.isLocalhost) {
      localStorage.setItem('accessToken', token?.replace('\'\'', ''));
    }
    setCookie('EE3B69BC-D275-4D89-ACCD-D91136DAD45C', token?.replace('\'\'', ''));
  }

  get accessToken(): string {
    if (environment.isLocalhost) return localStorage.getItem('accessToken');
    return (getCookie('EE3B69BC-D275-4D89-ACCD-D91136DAD45C') || '').replace('\'\'', '');
  }

  get ssoRedirecting(): boolean {
    return !!localStorage.getItem('amplify-redirected-from-hosted-ui');
  }

  get environmentURL(): string {
    return this._environmentURL;
  }

  get userId(): string {
    return getAccessTokenPayloadInfo('UserId');
  }

  set userStatusAuth(userStatusAuth: UserStatusAuth) {
    this._userStatusAuth = userStatusAuth;
  }

  get userStatusAuth(): UserStatusAuth {
    return this._userStatusAuth;
  }

  set environmentURL(value: string) {
    if (value.startsWith('http://localhost:4200')) {
      this._environmentURL = 'https://devsp.solvacelabs.com';
      // this._environmentURL = 'https://qa.solvacelabs.com';
    } else {
      this._environmentURL = value;
    }
    this._authCognitoService.setEnvironmentURL(this.environmentURL);
  }

  get passwordExpirationDays(): number {
    return Number.parseInt(localStorage.getItem('passwordExpirationDays')) || 90;
  }

  get changePasswordRequired(): boolean {
    return this._changePasswordRequired;
  }

  get environmentInfo(): EnvironmentInfo {
    return this._environmentInfo;
  }

  get needToRedirectToOldVersion() {
    const cookies = document.cookie.split(';').map((cookieStr) => {
      const splitedCookie = cookieStr.trim().split('=');
      return {
        name: splitedCookie[0],
        value: splitedCookie[1],
      };
    });
    const cookieName = `NEWVER-ACTIVE-{${this.userId}}`;
    for (const cookie of cookies) {
      if (cookie.name == cookieName) {
        return cookie.value === 'false';
      }
    }
    return true;
  }

  checkAuthenticatedProperty() {
    if (!this._authenticated) {
      this._authenticated = !!(this.accessToken);
    }
  }

  applyCongnitoConfig(env: EnvironmentInfo) {
    env && this._authCognitoService.applyCongnitoConfig(env);
  }

  ssoSignIn(sso: SsoItem) {
    this._authCognitoService.ssoSignIn(sso);
  }

  getCurrentUser(): Promise<CognitoUser | null> {
    return this._authCognitoService.getCurrentUser();
  }

  getCoverImage(): string {
    return `url(${this._environmentInfo?.backgroundImage || AWS_DEFAULT_BACKGROUND_IMAGE
      })`;
  }

  getIsGlobalUrl() {
    return localStorage.getItem('authFlowGlobal_environment') !== null;
  }

  postEnvironmentInfo(urlEnv?: string): Observable<EnvironmentInfo> {
    if (this._environmentInfo) {
      this.solvaceLogo$.next(this._environmentInfo.solvaceLogo);
      return of(this._environmentInfo);
    }
    const url = `${this._appConfig.APIs.apiUrlAuth}/Environment/info`;
    return this._httpClient.post<EnvironmentInfo>(url, {
      environmentUrl: this.getEnvironmentUrlCurrent(urlEnv),
    })
      .pipe(
        tap((info) => {
          this._environmentInfo = info;
          this.solvaceLogo$.next(info?.solvaceLogo);
        }),
        catchError(() => {
          this._environmentInfo = null;
          return of(this._environmentInfo);
        }),
      );
  }

  postEnvironmentListByUserName(userName: string) {
    const url = `${this._appConfig.APIs.apiUrlAuth}/Environment/username`;

    return lastValueFrom(
      this._httpClient.post<EnvironmentUserInfo[]>(url, {
        userName: userName,
      })
    );
  }

  logSignInFailedAttempt(userName: string, urlEnv: string) {
    const url = `${this._appConfig.APIs.apiUrlAuth}/AccessLog?userName=${userName}&environmentUrl=${urlEnv}`;
    return lastValueFrom(
      this._httpClient.post<UserSignInFailedResponse>(url, {})
    );
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get environment url current
   *
   * @param urlEnv
   */
  public getEnvironmentUrlCurrent(urlEnv: string): string {
    this.environmentURL =
      urlEnv ||
      document.location.origin;
    return this.environmentURL;
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Promise<any> {
    this._emailToResetPassword = email;
    return this._authCognitoService.sendVerificationCode(email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  async resetPassword(code: string, password: string, options?: { updatePasswordChangeDate?: boolean }): Promise<CognitoUser> {
    const username = this._emailToResetPassword;
    await this._authCognitoService.resetPasswordWithVerificationCode(code, username, password);
    if (options?.updatePasswordChangeDate ?? true) {
      await this.setPasswordChangeDate();
    }
    let user = await this._authCognitoService.getCurrentUser();
    user = {
      ...(user ?? {}),
      username,
    };
    return user;
  }

  async changeTemporaryPassword(password: string, options?: { updatePasswordChangeDate?: boolean }): Promise<CognitoUser> {
    await this._authCognitoService.changeTemporaryPassword(password);
    if (options?.updatePasswordChangeDate ?? true) {
      await this.setPasswordChangeDate();
    }
    const user = this._authCognitoService.getCurrentUser();
    await this._authCognitoService.signOut();
    return user;
  }

  changePassword(oldPassword: string, newPassword: string, options?: { updatePasswordChangeDate?: boolean }): Promise<CognitoUser> {
    return new Promise<CognitoUser>(async (resolve, reject) => {
      let user: CognitoUser;
      this.prepareConfigFromEnvironmentInfo().pipe(
        switchMap(() => from(this._authCognitoService.getCurrentUser()).pipe(
          tap((user) => user = user)
        )),
        switchMap(() => from(this._authCognitoService.changePassword(oldPassword, newPassword))),
        switchMap(() => {
          if (options?.updatePasswordChangeDate ?? true) {
            return from(this.setPasswordChangeDate());
          }
          return of();
        }),
        tap(() => resolve(user)),
        catchError((e) => {
          reject(e);
          return e;
        }),
      ).subscribe();
    });
  }

  prepareConfigFromEnvironmentInfo(): Observable<void> {
    return this.postEnvironmentInfo().pipe(
      tap((c) => this.applyCongnitoConfig(c)),
      switchMap(() => of(null)),
    );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(
    credentials: {
      user: string;
      password: string;
    },
    urlEnv: string
  ): Promise<UserStatusAuth> {
    if (this._authenticated) {
      throwError(() => new Error('User is already logged in.'));
    }

    return this._authCognitoService
      .signIn(credentials)
      .then((response) => {
        if (response.result === AWSAmplifyAuthResult.Success) {
          return this.loginValidate(urlEnv);
        }
        else if (response.result === AWSAmplifyAuthResult.NewPasswordRequired) {
          this._changePasswordRequired = true;
          return {
            user: null,
            status: AuthStatus.TemporaryPasswordUser
          };
        }
      });
  }

  signInCurrentUser(urlEnv: string): Promise<UserStatusAuth> {
    return this.loginValidate(urlEnv);
  }

  private loginValidate(urlEnv: string) {
    return new Promise<UserStatusAuth>(async (resolve, reject) => {
      try {
        const userStatus = {} as UserStatusAuth;
        userStatus.user = await this.getCurrentUser();

        const sub = await this.postLoginSub(urlEnv, userStatus.user.accessToken);

        if (sub) {
          // Store the access token in the local storage
          this.accessToken = sub.accessToken;

          // Set and store user roles
          const roles = ['ADMIN'];
          this._permissionsService.loadPermissions(roles);
          localStorage.setItem('roles', JSON.stringify(roles));

          // Set the authenticated flag to true
          this._authenticated = true;

          // Set user status
          this._signIn$.next({ success: true });
          userStatus.status = await this.getUserStatusAuth();
        } else {
          // Set user status
          userStatus.status = AuthStatus.NotFoundUser;
        }

        this.userStatusAuth = userStatus;
        resolve(userStatus);
      } catch (err) {
        await lastValueFrom(this.signOut(true));
        reject(null);
      }
    });
  }

  private postLoginSub(environmentUrl: string, accessToken: string): Promise<AcessSub> {
    const url = `${this._appConfig.APIs.apiUrlAuth}/Login/sub`;

    return lastValueFrom(
      this._httpClient.post<AcessSub>(url, {
        accessToken,
        environmentUrl,
      })
    ).catch((err) => {
      if (err instanceof HttpErrorResponse && err.status === 400)
        return null;
      else
        throw new Error(err);
    });
  }

  async getUserStatusAuth(): Promise<AuthStatus> {
    const fn = this.getAuthStatusFn;
    return lastValueFrom(
      (fn ?? (() => of(null)))().pipe(
        map((status) => {
          this._userStatusAuth.status = status;
          return status;
        })
      )
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingRefreshToken(ignoreRefresh: boolean = true): Observable<boolean> {
    if (ignoreRefresh)
       return of(true); //TODO: remove this line to enable token refresh

    const url = `${this._appConfig.APIs.apiUrlAuth}/Token/refresh`;

    // Sign in using the token
    return this._httpClient.get<TokenRefresh>(url).pipe(
      map((response) => {
        // Replace the access token with the new one
        this.accessToken = response.refreshToken;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user permission
        this._permissionsService.loadPermissions(
          JSON.parse(localStorage.getItem('roles'))
        );

        return true;
      }),
      catchError((err: any) => {
        console.error(err);
        return of(false);
      })
    );
  }

  /**
   * Sign out
   */
  signOut(forceClear?: boolean): Observable<void> {
    // Clear local storage
    if (!forceClear && environment.isLocalhost) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expiredToken');
    }
    else if (forceClear) {
      localStorage.clear();
    }

    //remove all legacy cookies
    deleteLegacyCookies();

    // Set the authenticated flag to false
    this._authenticated = false;

    if (typeof (window as any).AppLogoffCall != 'undefined') {
      (window as any).AppLogoffCall.postMessage('<function></function>');
    }

    this._signOut$.next({ success: true });
    
    if (!this._authCognitoService.hasCognitoConfig()) {
      return this.prepareConfigFromEnvironmentInfo().pipe(
        switchMap(() => {
          return from(this._authCognitoService.signOut());
        }),
      );
    }

    return from(this._authCognitoService.signOut());
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {

    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingRefreshToken();
  }

  doValidatedUserRedirecting(user: User, userStatus: UserStatusAuth) {
    const status = userStatus.status;
    const fromAuthenticationApp = this._svcAppSettings.module === SvcModule.Authentication;
    const isContractorOrSupplierUser = [AuthStatus.SupplierUser, AuthStatus.ContractorUser].includes(status)
    const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
    const redirectAsp = this._activatedRoute.snapshot.queryParamMap.get('redirectAsp');
    const redirectCore = this._activatedRoute.snapshot.queryParamMap.get('redirectCore');
    const needToRedirectToAsp = canRedirectToAspFromQueryParamData(redirectAsp);
    const needToRedirectToCore = canRedirectToCoreFromQueryParamData(redirectCore);
    const needToRedirectToAspOrCore = needToRedirectToAsp || needToRedirectToCore;
    const redirectToSupplierManagement = () => externalURLSamePageWithoutHistory(`${userStatus.user.baseUrl}/suppliermanagement`, ``);
    const redirectToContractorManagement = () => externalURLSamePageWithoutHistory(`${userStatus.user.baseUrl}/contractormanagement`, ``);
    const defaultRedirecting = () => {
      if (!tryToRedirectToAspFromQueryParamData(redirectAsp) && !tryToRedirectToCoreFromQueryParamData(redirectCore)) {
        // Navigate to the redirect url (angular to angular)
        this._router.navigateByUrl(redirectURL);
      }
    }

    if (status !== AuthStatus.SupplierUser && status !== AuthStatus.ContractorUser && status !== AuthStatus.EmployeeUser) {
      this._router.navigate(['/sign-out']);
      return;
    }

    // Generate legacy support cookies
    this.signInLegacySupport(user, () => {
      if (!needToRedirectToAspOrCore && !isContractorOrSupplierUser && !fromAuthenticationApp) {
        defaultRedirecting();
      }
    })
      .then(() => {
        if (!needToRedirectToAspOrCore && !isContractorOrSupplierUser && !fromAuthenticationApp) return;

        if (status === AuthStatus.SupplierUser) return redirectToSupplierManagement();
        if (status === AuthStatus.ContractorUser) return redirectToContractorManagement();

        defaultRedirecting();
      })
      .catch((error) => {
        throw error;
      });
  }

  private async signInLegacySupport(user: User, afterMainCookiesCreated?: () => void): Promise<void> {

    //remove all old legacy cookies
    deleteLegacyCookies(['EE3B69BC-D275-4D89-ACCD-D91136DAD45C']);

    //TODO: remove this function when no legacy support needed (ASP + netcore)

    //environment
    updateEnvironmentFullURLCookie(this._environmentInfo.url);

    //user preferences
    updateLastSiteIdCookie(user.lastSiteId);
    updateDateFormatCookie(user.dateFormat);
    updateLanguageIdCookie(user.languageId);
    updateUserIdCookie(this.userId);
    updateReloadSessionCookie('True');

    //product APIs
    updateProductAPITokenCookie(this.accessToken);
    updateBookmarkAPIUrlCookie(this._appConfig.APIs.apiUrlBookmark);
    updateDocumentAPIUrlCookie(this._appConfig.APIs.apiUrlDocuments);
    updateNotificationAPIUrlCookie(this._appConfig.APIs.apiUrlNotification);
    updateAuthenticationAPIUrlCookie(this._appConfig.APIs.apiUrlAuth);
    updateHashtagAPIUrlCookie(this._appConfig.APIs.apiUrlHashtag);
    updatePraiseAPIUrlCookie(this._appConfig.APIs.apiUrlPraise);
    updateReactionAPIUrlCookie(this._appConfig.APIs.apiUrlReaction);
    updateCommentsAPIUrlCookie(this._appConfig.APIs.apiUrlComments);
    updateActionPlanAPIUrlCookie(this._appConfig.APIs.apiUrlActionPlan);
    updateChecklistAPIUrlCookie(this._appConfig.APIs.apiUrlChecklist);
    updateCilAPIUrlCookie(this._appConfig.APIs.apiUrlCil);
    updateDefectTagAPIUrlCookie(this._appConfig.APIs.apiUrlDefectTag);
    updateAdministrationAPIUrlCookie(this._appConfig.APIs.apiUrlAdministration);
    updateCommentManagerAPIUrlCookie(this._appConfig.APIs.apiUrlCommentManager);
    updateMultilingualAPIUrlCookie(this._appConfig.APIs.apiUrlMultilingual);
    updateSubtitleAPIUrlCookie(this._appConfig.APIs.apiUrlSubtitle);
    updateCopilotAPIUrlCookie(this._appConfig.APIs.apiUrlCopilot);

    await Promise.all([
      lastValueFrom(this._cloudfrontService.getSignedCookies().pipe(
        tap((signedCookies) => {
          //cloudfront signed cookies
          updateCloudFrontPolicyCookie(signedCookies.policy, signedCookies.expiryDate, signedCookies.domain);
          updateCloudFrontSignatureCookie(signedCookies.signature, signedCookies.expiryDate, signedCookies.domain);
          updateCloudFrontKeyPairIdCookie(signedCookies.keyPairId, signedCookies.expiryDate, signedCookies.domain);
        }),
      )),
      lastValueFrom(this._parameterService.getAllAppGlobalParams().pipe(
        switchMap((params) =>  {
          //idle timeout controller
          const idleTimeout = params.find((item) => item.key === 'IDLE_TIMEOUT')?.value ?? '';
          updateIdleTimeoutCookie(idleTimeout);
          updateLastAccessCookie(new Date());

          //integration APIs
          const apiUrl = params.find((item) => item.key === 'API_URL')?.value ?? '';
          updateIntegrationAPIURLCookie(apiUrl);

          return (environment.isLocalhost
            ? of({ token: 'EMPTY_VALUE' })
            : signinLegacyApi(this._httpClient, this._userStatusAuth.user.baseUrl, this._userStatusAuth.user.accessToken)
          ).pipe(
            tap((res) => {
              !environment.isLocalhost && updateIntegrationAPITokenCookie(res.token);
              afterMainCookiesCreated?.();
            })
          );
        }),
      )),
    ]);
  }

  async checkUserExpiredPassword(): Promise<{ status: 'expired' | 'expiring' | null, days?: number }> {
    const date = await this.getPasswordChangeDate();
    if (!date) {
      this.setPasswordChangeDate();
      return { status: null };
    }
    const remainingDays = this.passwordExpirationDays - Math.ceil((new Date().getTime() - new Date(Number.parseInt(date) * 1000).getTime()) / (1000 * 60 * 60 * 24));
    return {
      status: remainingDays <= 10 && remainingDays > 0 ? 'expiring' :
        remainingDays <= 0 ? 'expired' : null,
      days: remainingDays
    };
  }

  getExpiredToken() {
    return localStorage.getItem('expiredToken') === 'true';
  }

  setExpiredToken() {
    localStorage.setItem('expiredToken', 'true');
  }

  removeExpiredToken() {
    localStorage.removeItem('expiredToken');
  }

  async getPasswordChangeDate(): Promise<string> {
    return await this._authCognitoService.getUserAttribute('custom:lastUpdatePassword');
  }

  async setPasswordChangeDate() {
    await this._authCognitoService.setUserAttribute('custom:lastUpdatePassword', Math.floor(new Date().getTime() / 1000).toString());

  }

  async prepareCognitoFromEnvironment(): Promise<CognitoUser> {
    const env = await lastValueFrom(this.postEnvironmentInfo());
    this.applyCongnitoConfig(env);
    const user = await this.getCurrentUser();
    return user;
  }
}


