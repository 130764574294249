import { TranslocoService } from '@ngneat/transloco';
import { inject } from '@angular/core';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { debounceTime, distinctUntilChanged, filter } from 'rxjs';

import { SvcDataTableAccordion, SvcDataTableAccordionColumn } from './interfaces/svc-data-table-accordion.interface';


import _ from 'lodash';
import { SvcSearchFieldComponent } from '../svc-search-field/svc-search-field.component';
import { SvcExportFilePdfService } from 'projects/lib-shared-core/src/lib/services/svc-export-file/svc-export-file-pdf.service';
import { SvcExportFilePdfCol, SvcExportFilePdfData, SvcExportFilePdfRow } from 'projects/lib-shared-core/src/lib/services/svc-export-file/interfaces/svc-export-file-pdf.interface';
import { DateFormatPipe } from 'projects/lib-shared-common/src/lib/pipes/date-format.pipe';
import { SvcExportFileHelperService } from 'projects/lib-shared-core/src/lib/services/svc-export-file/svc-export-file-helper.service';
import { FormatNumberDefaultPipe } from 'projects/lib-shared-common/src/lib/pipes/format-number.pipe';
import { SvcExportFileExcelService } from 'projects/lib-shared-core/src/lib/services/svc-export-file/svc-export-file-excel.service';
import { SvcExportFileExcelData } from 'projects/lib-shared-core/src/lib/services/svc-export-file/interfaces/svc-export-file-excel.interface';

@Component({
  selector: 'svc-data-table-accordion',
  templateUrl: './svc-data-table-accordion.component.html',
  styleUrls: ['./svc-data-table-accordion.component.scss'],
  providers: [
    DateFormatPipe,
    FormatNumberDefaultPipe
  ]
})
export class SvcDataTableAccordionComponent implements OnChanges {

  @Input() public showSearchControl = true;
  @Input() public showOpenAllAccordions = true;
  @Input() public showDownloadOptions = true;
  @Input() public isAllExpanded: boolean;
  @Input() public dataTable: SvcDataTableAccordion;
  @Input() public isLoading: boolean;
  @Input() public isExporting: boolean;
  @Input() public items: any[];
  @Input() public classContainer: string = 'mx-6';
  @Input() public exportTitle: string;
  @Input() public exportFileName: string;

  @Output() public exportFile = new EventEmitter<'pdf' | 'excel'>();
  @Output() public onSubRowClicked = new EventEmitter<any>();
  @Output() public barClicked = new EventEmitter<any>();
  @Output() public onBarClicked = new EventEmitter<{row: any, col: any}>();

  @Output() public numberClicked = new EventEmitter<any>();
  @Output() public progressBarClicked = new EventEmitter<any>();

  public sort: MatSort;
  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    if (matSort) {
      this.sort = matSort;
      this.sort.start = 'desc';
      this.setDataSourceAttributes();
    }
  }
  @ViewChild(SvcSearchFieldComponent) svcSearchField: SvcSearchFieldComponent;

  public dataSource = new MatTableDataSource<any>();
  public expandedRows = new Set<any>();

  #translocoService = inject(TranslocoService);
  #exportPdfService = inject(SvcExportFilePdfService);
  #exportExcelService = inject(SvcExportFileExcelService);
  #exportFileHelperService = inject(SvcExportFileHelperService);
  #datePipe = inject(DateFormatPipe);
  #formatNumber = inject(FormatNumberDefaultPipe);

  constructor() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.items?.previousValue !== changes?.items?.currentValue)
      this.updateData();

    if (changes?.isAllExpanded?.previousValue !== changes?.isAllExpanded?.currentValue)
      this.toggleIsAllExpanded(true);

    if (changes?.dataTable?.previousValue !== changes?.dataTable?.currentValue)
      this.setDataMockSkeleton();
  }

  private setDataMockSkeleton(): void {
    if (!this.items?.length && this.isLoading && this.dataTable?.columns?.length) {
      let data = [];
      Array.from({length: 3}).forEach(() => {
        Array.from({length: this.dataTable?.columns?.length ?? 0}).forEach((column: any, index: number) => {
          column = this.dataTable?.columns?.[index];
          data.push({
            [column.property]: '',
          })
        })
      });
      this.dataSource.data = data;
    }
  }

  private setDataSourceAttributes(): void {
    this.dataSource.sort = this.sort;
  }

  private updateData(): void {
    this.svcSearchField?.reset();
    this.dataSource.data = this.items;
  }

  public getColumnLabels(): string[] {
    return this.dataTable?.columns?.map((column: SvcDataTableAccordionColumn, index: number) => column?.label + index);
  }

  public getColumnLabelsAccordion(): string[] {
    return this.dataTable?.columns?.map((column: SvcDataTableAccordionColumn, index: number) =>
      this.dataTable?.showHeaderRowSubItems && column?.accordion?.label ? column?.accordion?.label : column?.label + (index + this.dataTable?.columns?.length)
    );
  }

  public toggleExpanded(row: any): void {
    if (this.expandedRows.has(row)) {
      this.expandedRows.delete(row);

      if (this.expandedRows.size === 0)
        this.isAllExpanded = false;
    }
    else
      this.expandedRows.add(row);
  }

  public toggleIsAllExpanded(isInputProperty = false): void {
    if (!isInputProperty)
      this.isAllExpanded = !this.isAllExpanded;

    this.expandedRows.clear();
    if (this.isAllExpanded)
      this.dataSource?.data?.forEach(value => this.expandedRows.add(value));
  }

  public searchSubItems(filteredItems: any[], searchText: string): void {

    filteredItems.forEach((item: any) => {
      let subData: any[] = item[this.dataTable.subItemsPropertyName];
      if (subData?.length) {
        item[this.dataTable.subItemsPropertyName] = subData.filter((subItem: any) =>
          this.dataTable.columns.some((column: SvcDataTableAccordionColumn) =>
            column?.isSearchable &&
            subItem[column?.accordion?.property]?.toString().toLowerCase().includes(searchText)
          )
        );
      }
    });
  }

  public searchItems(text: string): void {
    const searchText = text.trim().toLowerCase();
    let filteredItems = this.items.filter((item: any) =>
      this.dataTable.columns.some((column: SvcDataTableAccordionColumn) =>
        column?.isSearchable &&
        (
          item[column.property]?.toString().toLowerCase().includes(searchText) ||
          item?.[this.dataTable?.subItemsPropertyName]?.some(data =>
            data?.[column?.accordion?.property]?.toString()?.toLowerCase()?.includes(searchText)
          )
        )
      )
    );

    filteredItems = _.cloneDeep(filteredItems);
    this.searchSubItems(filteredItems, searchText);
    this._restoreExpandedRows(filteredItems);
    this.dataSource.data = filteredItems;
  }

  private _restoreExpandedRows(filteredItems: any[]): void {
    const previousExpandedRows = _.cloneDeep(this.expandedRows);
    this.expandedRows.clear();
    previousExpandedRows?.forEach(row =>
      filteredItems?.forEach(info => {
        if (JSON.stringify(info) === JSON.stringify(row))
          this.expandedRows.add(info);
      })
    );
  }

  public barClick(pointerEvent: PointerEvent, row: any, col: any): void {
    pointerEvent.stopPropagation();
    this.barClicked.emit(row);
    this.onBarClicked.emit({row, col});
  }

  public onNumberClick(pointerEvent: PointerEvent, row: any): void {
    pointerEvent.stopPropagation();
    this.numberClicked.emit(row);
  }
  public barClickProgress(item: any, row: any): void {
    var type= item.label;
    row = {...row, type:type };
    this.progressBarClicked.emit(row);
  }

  #getColumnsExportable(): SvcDataTableAccordionColumn[] {
    return this.dataTable.columns.filter(col => col.exportable);
  }

  #getColumnsToExportPdf(): SvcExportFilePdfCol[] {
    const columnsExportable = this.#getColumnsExportable();
    return columnsExportable.map(col => ({
      header: this.#translocoService.translate(col?.label)?.toUpperCase()?.trim(),
      dataKey: col.property
    }));
  }

  #getSubColumnsToExport(isAccordion: boolean): SvcDataTableAccordionColumn[] {
    const columnsExportable = this.#getColumnsExportable();
    return isAccordion ? 
      columnsExportable?.filter(col => col?.accordion?.exportable) :
      columnsExportable;
  }

  #getRowValueToExportPdf(item: any, isAccordion: boolean, isSubItems: boolean): SvcExportFilePdfRow {
    const row: SvcExportFilePdfRow = { isAccordion };

    const columnsExportable = this.#getSubColumnsToExport(isSubItems);

    columnsExportable?.forEach(col => {
      row[col?.property] = this.#getExportValue(item, col, isSubItems);
    });

    return row;
  }

  #hasSubItemsToExportPdf(item: any): boolean {
    return this.dataTable?.subItemsPropertyName && item[this.dataTable.subItemsPropertyName]
  }

  #getRowsToExportPdf(): SvcExportFilePdfRow[] {
    const rows: SvcExportFilePdfRow[] = [];

    this.dataSource.data.forEach(item => {
      const row: SvcExportFilePdfRow = this.#getRowValueToExportPdf(item, true, false);
      rows.push(row);
      const hasItems = this.#hasSubItemsToExportPdf(item);
      if (hasItems) {
        item[this.dataTable.subItemsPropertyName].forEach(subItem => {
          const subRow: SvcExportFilePdfRow = this.#getRowValueToExportPdf(subItem, false, true);
          rows.push(subRow);
        });
      }
    });

    return rows;
  }

  public exportToPdf(): void {
    if (!this.dataSource?.data?.length) {
      this.#exportFileHelperService.showError();
      return;
    }

    const columns: SvcExportFilePdfCol[] = this.#getColumnsToExportPdf();
    const rows: SvcExportFilePdfRow[] = this.#getRowsToExportPdf();

    const exportData: SvcExportFilePdfData = { columns, rows };
    this.#exportPdfService.exportToPdf(exportData, this.exportFileName, this.exportTitle, true);
  }

  #getExportValueByType(item: any, column: SvcDataTableAccordionColumn, isAccordion = false): string {
    const value = item?.[isAccordion ? column?.accordion?.property : column?.property];
    const fieldTypeExport = isAccordion ? column?.accordion?.fieldTypeExport : column?.fieldTypeExport;

    if (fieldTypeExport === 'status')
      return this.#translocoService.translate(value?.description);

    return this.#exportFileHelperService.getExportCellValueByType(value, fieldTypeExport, this.#datePipe, this.#formatNumber);
  }

  #getExportValue(item: any, column: SvcDataTableAccordionColumn, isAccordion = false): string {
    if (isAccordion && column?.accordion?.processExportingValue)
      return column.accordion.processExportingValue(item, column);
    
    return column?.processExportingValue ? column?.processExportingValue(item, column) : this.#getExportValueByType(item, column, isAccordion);
  }

  #getCellValueToExportExcel(item: any, isAccordion: boolean, isSubItems: boolean): SvcExportFilePdfRow {
    const row: SvcExportFilePdfRow = { isAccordion };

    const columnsExportable = this.#getSubColumnsToExport(isSubItems);

    columnsExportable?.forEach(col => {
      row[`${(col?.label)?.toUpperCase()?.trim()}`] = this.#getExportValue(item, col, isSubItems);
    });

    return row;
  }

  #getCellsToExportExcel(): SvcExportFilePdfRow[] {
    const cells: SvcExportFileExcelData[] = [];

    this.dataSource.data.forEach(item => {
      const cell: SvcExportFileExcelData = this.#getCellValueToExportExcel(item, true, false);
      cells.push(cell);
      const hasItems = this.#hasSubItemsToExportPdf(item);
      if (hasItems) {
        item[this.dataTable.subItemsPropertyName].forEach(subItem => {
          const subCell: SvcExportFileExcelData = this.#getCellValueToExportExcel(subItem, false, true);
          cells.push(subCell);
        });
      }
    });

    return cells;
  }

  public exportToExcel(): void {
    if (!this.dataSource?.data?.length) {
      this.#exportFileHelperService.showError();
      return;
    }

    const cells: SvcExportFileExcelData[] = this.#getCellsToExportExcel();
    this.#exportExcelService.exportToExcel(cells, this.exportFileName, this.exportTitle);
  }
}
