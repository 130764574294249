import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SvcAlertBarBanner, SvcAlertBarService } from './alert-bar.service';
import { TranslocoService } from '@ngneat/transloco';
import { getAccessTokenPayloadInfo, getAlertBarCookie, updateAlertBarCookie } from 'projects/lib-shared-common/src/public-api';
import { catchError, finalize, Subject, takeUntil, tap } from 'rxjs';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { SvcMultilingualService } from 'projects/lib-shared-core/src/lib/services/svc-multilingual.service';

@Component({
  selector: 'alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss']
})
export class AlertBarComponent implements OnDestroy {
  @Input() message: string = '';
  @Input() dismissed: boolean = true;
  @Input() translated: boolean = false;
  @Output() onClose = new EventEmitter<void>();

  private _originalMessage: string = '';
  private _bannerId: number = 0;

  private _alertBarTypeDataList = [
    {
      typeId: 0,
      type: 'Generic',
      backgroundColor: '#FFE9A4',
      color: '#0A0A0B'
    },
    {
      typeId: 1,
      type: 'Indisponibilidade não planejada',
      backgroundColor: '#F5B8B9',
      color: '#0A0A0B'
    },
    {
      typeId: 2,
      type: 'Instabilidade não planejada',
      backgroundColor: '#F5B8B9',
      color: '#0A0A0B'
    },
    {
      typeId: 3,
      type: 'Bug',
      backgroundColor: '#F5B8B9',
      color: '#0A0A0B'
    },
    {
      typeId: 4,
      type: 'Indisponibilidade planejada',
      backgroundColor: '#FFE9A4',
      color: '#0A0A0B'
    }
    ,
    {
      typeId: 5,
      type: 'Instabilidade planejada',
      backgroundColor: '#FFE9A4',
      color: '#0A0A0B'
    },
    {
      typeId: 6,
      type: 'Nova funcionalidade',
      backgroundColor: 'var(--svc-color-primary-200)',
      color: 'var(--svc-text-secondary)'
    }
    ,
    {
      typeId: 7,
      type: 'Mudança funcionalidade',
      backgroundColor: 'var(--svc-color-primary-200)',
      color: 'var(--svc-text-secondary)'
    }
  ];
  private _alertBarTypeData = this._alertBarTypeDataList.find(type => type.typeId === 0);;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private _alertBarService: SvcAlertBarService,
    private _translocoService: TranslocoService,
    private _multilingualService: SvcMultilingualService
  ) { }
  ngOnDestroy(): void {
    this._alertBarService.stopConnections();
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngOnInit(): void {
    this._originalMessage = this.message;

    const statusAlertBar = getAlertBarCookie();
    if (!statusAlertBar) {
        this._alertBarService.getBannerActive().subscribe(alert => {
          this.handleAlertActivation(alert);
        });
    }

    this._alertBarService.startListenSignalR();
    this._alertBarService.alertBar$.pipe(
      takeUntil(this._unsubscribe$),
      tap((response:SvcAlertBarBanner) => {
        this.handleAlertActivation(response);
      }),
    ).subscribe();


    this._alertBarService.startDismissSignalR();
    this._alertBarService.closedBanner$.pipe(
      takeUntil(this._unsubscribe$),
      tap((response:any) => {
        if (response.active)
           this.closeAlertModal();
      }),
    ).subscribe();
  }

  private handleAlertActivation(alert: SvcAlertBarBanner) {

   if (alert?.active) {
      const environmentId = getAccessTokenPayloadInfo('EnvironmentId');
      if (alert?.allEnvironments || alert?.environments?.find(env => env.environmentId === Number(environmentId) && env.active)) {
        this._bannerId = alert.bannerId;
        this._originalMessage = alert.message;
        this.translated = false;
        this.dismissed = true;
        this.translate();

        this._alertBarTypeData = this._alertBarTypeDataList.find(type => type.typeId === alert.bannerTypeId);
      }
    }
  }

   public translate() {
        this._multilingualService.translateText([this._originalMessage]).pipe(
          tap((messages: any) => {
            if ((messages?.terms.length ?? 0) > 0) {
                this.message = messages.terms[0];
                this.translated = this._originalMessage !== this.message;
                this.dismissed = false;
            }
          }),
        ).subscribe();
   }

  closeAlert(){
    this._alertBarService.reportBannerClosed().subscribe();
    updateAlertBarCookie(this._bannerId);
    this.closeAlertModal();
  }
  closeAlertModal(){
    this.dismissed = true;
    this.onClose.emit();
  }

  noTranslate() {
    this.message = this._originalMessage;
    this.translated = false;
  }
}


