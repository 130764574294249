import { inject, Injectable } from '@angular/core';
import { SvcDataPrefetching, SvcDataPrefetchingItem, SvcDataPrefetchingItemStructure } from './svc-data-prefetching.abstract';
import { map, of, zip } from 'rxjs';
import { SvcAppDataPrefetching as SvcAppDataPrefetching } from './svc-app-data-prefetching.abstract';
import { UserService } from '../features/user/services/user.service';
import { AuthService } from '../auth/auth.service';
import { SvcParameters } from '../enums/svc-parameter.enum';
import { ParameterService } from '../services/parameter/parameter.service';

interface SvcGlobalData extends SvcDataPrefetchingItemStructure {
	isGamefied: SvcDataPrefetchingItem<boolean>;
	alertShowTabActionPlan: SvcDataPrefetchingItem<boolean>;
	voiceToText: SvcDataPrefetchingItem<boolean>;
	bosShowTabActionPlan: SvcDataPrefetchingItem<boolean>;
	bosShowOriginator: SvcDataPrefetchingItem<boolean>;
	workCouncils: SvcDataPrefetchingItem<boolean>
	subtitleEnabled: SvcDataPrefetchingItem<boolean>;
	whywhyShowTabActionPlan: SvcDataPrefetchingItem<boolean>;
}

@Injectable({
	providedIn: 'root'
})
export class SvcGlobalDataPrefetching extends SvcDataPrefetching<SvcGlobalData> {

	private appDataPrefetching = inject(SvcAppDataPrefetching, { optional: true });
	private authService = inject(AuthService);
	private alreadyFetched = false;

	private userService = inject(UserService);
	private svcParameterService = inject(ParameterService);

	public isLoading$ = zip(
		this._isLoading.asObservable(),
		this.appDataPrefetching?.isLoading$ ?? of(false),
	).pipe(
		map((result) => result[0] && result[1])
	);

	public getAppData<T>() {
		return <T>this.appDataPrefetching?.data;
	}

	private getPrepareDataFetching(): SvcGlobalData {
		return {
			isGamefied: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.userService.isGamified$,
			}),
			alertShowTabActionPlan: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcParameterService.getSiteParam(SvcParameters.AlertShowTabActionPlan).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
			}),
			bosShowTabActionPlan: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcParameterService.getSiteParam(SvcParameters.BosShowTabActionPlan).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
			}),
			bosShowOriginator: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcParameterService.getSiteParam(SvcParameters.BosFieldOriginatorModal).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
			}),
			voiceToText: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcParameterService.getSiteParam(SvcParameters.VoiceToText).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
			}),
			whywhyShowTabActionPlan: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcParameterService.getSiteParam(SvcParameters.WhyWhyShowTabActionPlan).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
			}),
			workCouncils: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcParameterService.getSiteParam(SvcParameters.WorkCouncils).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
			}),
			subtitleEnabled: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcParameterService.getSiteParam(SvcParameters.SubtitleEnabled).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
			}),
		};
	}

	public initAndFetch(options?: { force: boolean }) {
		this.authService.check().subscribe(authenticated => {
			if (authenticated && (!this.alreadyFetched || options?.force)) {
				this.alreadyFetched = true;
				this.reset();
				this.prepareDataFetching(this.getPrepareDataFetching());
				this.fetch();
				this.appDataPrefetching?.initAndFetch();
			}
		});
	}

	public reset(): void {
		super.reset();
		this.appDataPrefetching?.reset();
	}
}
