<div *ngIf="loading || total > 0" class="relative flex items-center gap-2" [class]="loading ? 'min-w-8 min-h-3.5' : null">
  <div *ngFor="let type of reactionsTypeReacted" class="flex-auto">
    <img [src]="type.staticImageUrl" />
  </div>
  <div class="text-md">
    <a href="javascript:void(0)" (click)="modalShowReactions(totalReactions)" [ngClass]="classText" [id]="buttonId">{{ total }}</a>
  </div>
  <ngx-skeleton-loader
    *ngIf="loading"
    class="absolute inset-0"
    [theme]="{ width: '100%', height: '100%', marginBottom: 0 }"
  />
</div>
