import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LangDefinition, TRANSLOCO_LOADER, TranslocoService } from '@ngneat/transloco';
import { LanguagesService } from './services/languages.service';
import { Subject, takeUntil  } from 'rxjs';
import { TranslocoApiLoader } from '../transloco/transloco.api-loader';

@Component({
  selector: 'languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
  exportAs: 'languages',
})
export class LanguagesComponent implements OnInit, OnDestroy {
  availableLangs: LangDefinition[] = [];
  activeLang: LangDefinition;
  flagCodes: any;
  isLoading = false;
  private destroy$ = new Subject();

  @Input() onlyImage: boolean = true;
  @Input() hasUser: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _translocoService: TranslocoService,
    private _languageService: LanguagesService,
    @Inject(TRANSLOCO_LOADER) private _translocoApiLoader: TranslocoApiLoader
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the available languages from transloco
    this.availableLangs = this._translocoService.getAvailableLangs() as LangDefinition[];

    // Subscribe to language changes
    this._translocoService.langChanges$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((selectedLang) => {
      this.activeLang = this.availableLangs.find((l: LangDefinition) => l.id == selectedLang);
      this.availableLangs = this._translocoService.getAvailableLangs() as LangDefinition[];
    });

    this._translocoService.events$.subscribe((e) => {
      if (this.isLoading) {
        this.isLoading = !(e.type === 'translationLoadSuccess');
      }
    });

    // Set the country iso codes for languages for flags
    this.flagCodes = {
      en: 'us',
      tr: 'tr',
      br: 'br',
      jp: 'jp',
    };
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.destroy$?.next(null);
    this.destroy$?.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the active lang
   *
   * @param lang
   */
  setActiveLang(lang: string): void {
    const currentLang = this._translocoService.getActiveLang();
    if (currentLang !== lang) {
      this.isLoading = !(this._translocoService['cache']?.has(lang) ?? false);
      
      // Set the active lang
      this._translocoService.setActiveLang(lang);
  
      //reflect language change at API
      if(this.hasUser)
        this._languageService.updateUserLanguage(lang).subscribe();
    }
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

}
