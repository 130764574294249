<div class="flex-auto divide-y">
  <div *ngFor="let task of [1, 2, 3]; index as index" class="table w-full py-2 px-2">
    <div class="flex">
      <div class="flex flex-col" [style.width.px]="52">
        <div class="flex items-center justify-center" [style.minHeight.px]="30">
          <ngx-skeleton-loader
            *ngIf="index >= 1"
            class="flex gap-1"
            [count]="index === 2 ? '2' : '1'"
            [theme]="{ width: '16px', height: '16px' }"
          >
          </ngx-skeleton-loader>
        </div>
  
        <div class="flex -space-x-7 justify-center">
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{ width: '40px', height: '40px' }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
  
      <div class="flex flex-col flex-auto pl-3">
        <div class="flex items-center justify-between">
          <ngx-skeleton-loader
            [theme]="{ width: '70px', height: '20px' }"
          >
  
          </ngx-skeleton-loader>
  
          <ngx-skeleton-loader
            class="flex flex-auto grow mx-2 justify-end"
            appearance="line"
            [theme]="{ width: '48px', height: '16px' }"
          >
  
          </ngx-skeleton-loader>
  
          <ngx-skeleton-loader
            class="flex justify-end"
            [theme]="{ width: '70px', height: '20px', borderRadius: '8px' }"
          >
          </ngx-skeleton-loader>
        </div>
       
        <div class="relative py-1" [style.height.px]="54">
          <div class="absolute -translate-y-2/4 top-1/2">
            <ngx-skeleton-loader
              [theme]="{ width: '150px', height: '20px' }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  
    <div class="basis-full relative mt-1 rounded-l-full rounded-r-full" *ngIf="index === 1">
      <ngx-skeleton-loader
        [theme]="{ width: '100%', height: '16px', borderRadius: '9999px' }"
      >
      </ngx-skeleton-loader>
    </div>
  </div>
</div>