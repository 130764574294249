import { Component, EventEmitter, forwardRef, HostBinding, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { SvcDialogService, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { Post } from '../../models/post';
import { SvcPostFormModalComponent } from "../svc-post-form-modal/svc-post-form-modal.component";
import { TranslocoService } from "@ngneat/transloco";
import { PostService } from "../../services/post.service";
import { environment } from "projects/environments/environment";
import { SitesService, StaticApplicationId, SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { SvcPostItemComponent } from '../svc-post-item/svc-post-item.component';
import { SvcPostViewModalComponent } from '../svc-post-view-modal/svc-post-view-modal.component';

@Component({
  selector: 'svc-post-item-title',
  templateUrl: './svc-post-item-title.component.html',
  styleUrls: ['./svc-post-item-title.component.scss']
})
export class SvcPostItemTitleComponent implements OnInit {

  @HostBinding('class.from-post-item') public fromPostItem = false;
  @HostBinding('class.from-post-modal') public fromPostModal = false;
  
  @Input('avatarSize') avatarSize: number;
  @Input('post') post: Post;
  @Input() showFavorite: boolean = true;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();

  public env = environment;
  public loadingBookmark: boolean;
  public currencyAcronym: string;
  public applicationId = StaticApplicationId.posts;

  constructor(
    @Optional() @Inject(forwardRef(() => SvcPostItemComponent))
    svcPostItem: SvcPostItemComponent,
    @Optional() @Inject(forwardRef(() => SvcPostViewModalComponent))
    svcPostModal: SvcPostViewModalComponent,
    private _svcDialog: SvcDialogService,
    private _transloco: TranslocoService,
    private _postService: PostService,
    private _toastService: SvcToastService,
    private _sitesService: SitesService,
    private _dialogService: SvcDialogService,
    private appSettings: SvcAppSettings,
  ) {
    this.fromPostItem = !!(svcPostItem);
    this.fromPostModal = !!(svcPostModal);
    this.currencyAcronym = this.appSettings.currencyAcronym;
  }

  ngOnInit(): void {
  }


  editPost() {
    this._svcDialog.open(SvcPostFormModalComponent, {
      data: {
        post: this.post
      },
      disableClose: true,
      width: '500px'
    }).afterClosed().subscribe(() => this.closed.emit());
  }

  removePost() {
    const text = this._transloco.translate('Tem certeza que deseja remover o Post') + '?';
    this._dialogService.openConfirm(text).subscribe((confirmed) => {
      if (confirmed) {
        this._postService.deletePost({
          ...this.post,
          site: { siteId: this._sitesService.currentSite.siteId },
        })
          .subscribe({
            next: (e) => {
              this.removed.emit();
              this._toastService.success(this._transloco.translate('Post removido com sucesso!'));
            },
            error: () => {
              this._toastService.error(this._transloco.translate('Ocorreu um erro ao remover o Post.'));
            }
          });
      }
    });
  }

  public onBookmarkChanged(value: boolean): void {
    if (value) {
      this._toastService.success(this._transloco.translate('Este post foi adicionado aos Favoritos com Sucesso!'));
    }
    else {
      this._toastService.success(this._transloco.translate('Este post foi removido dos Favoritos com Sucesso!'));
    }
  }

  public onBookmarkError(value: boolean) {
    if (value) {
      this._toastService.error(this._transloco.translate('Ocorreu um erro ao adicionar o Post aos Favoritos.'))
    }
    else {
      this._toastService.error(this._transloco.translate('Ocorreu um erro ao remover o Post dos Favoritos.'))
    }
  }
}
