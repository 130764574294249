<svc-dialog
  #dialog
  dialogStyle="form"
  [fullscreen]="['XS','SM']"
  class="h-full"
  [scrollInsideContent]="true"
  [innerDividingLines]="true"
>

  <div svc-dialog-title class="-mb-2">
    <div class="flex flex-row items-center gap-2 -mx-1" [class.hidden]="!userAvatar || userAvatar.isLoading">
      <svc-smart-user-avatar #userAvatar [size]="_avatarSize" [ids]="_userId"></svc-smart-user-avatar>
      <div *ngIf="userAvatar && !(userAvatar?.isLoading ?? true)" class="flex flex-col sm:flex-row justify-center sm:items-center gap-x-2 text-base leading-tight font-normal">
        <span class="shrink-0 font-semibold sm:max-w-1/2 line-clamp-1 sm:line-clamp-2">{{userAvatar.info?.name}}</span>
        <span *ngIf="userAvatar.info?.role" class="hidden sm:inline">|</span>
        <span *ngIf="userAvatar.info?.role" class="line-clamp-1 sm:line-clamp-2">{{userAvatar.info?.role | transloco}}</span>
      </div>
    </div>
  </div>

  <div svc-dialog-content class="flex flex-col bg-white h-full gap-2 p-3">

    <div class="flex min-h-72 items-center justify-center my-2" *ngIf="loading || (userAvatar?.isLoading ?? true)">
      <mat-spinner diameter="28"></mat-spinner>
    </div>

    <form class="flex flex-col h-full gap-2" *ngIf="!loading  && !(userAvatar?.isLoading ?? true)"[formGroup]="form">

      <div class="flex-auto pb-5">
        <svc-post-mention
          class="block h-full"
          #postMention
          formControlName="textContent"
          [placeholder]="'O que você está pensando?' | transloco"
          inputId="post-text"
          (click)="$event.target.classList.contains('mentions-container') && $event.target.querySelector('.ql-editor').focus()"
        ></svc-post-mention>

        <svc-validation-message [control]="form.controls.textContent"></svc-validation-message>
      </div>

      <div class="bg-gray-50 empty:hidden overflow-hidden">
        <svc-media-carousel
          *ngIf="_mediasCarousel?.length > 0"
          fitContent="contain"
          renderMode="one-by-one"
          [emptyView]="false"
          [editMode]="true"
          [height]="400"
          [canClickToExpandImage]="false"
          [items]="_mediasCarousel"
          (onDeleteTriggered)="removeMediaFile($event)"
        >
        </svc-media-carousel>
      </div>

      <div class="flex flex-row gap-1">

        <svc-button mode="simple" [disabled]="loading || !addPhotoEnabled" (click)="canAddPhoto() && imageInput.click()" buttonId="post-add-photo">
          <div class="flex gap-2">
            <mat-icon class="flex self-center text-current icon-size-6" svgIcon="heroicons_solid:photo"></mat-icon>
            <div class="flex self-center text-base font-semibold">{{ 'Foto' | transloco }}</div>
          </div>
        </svc-button>

        <input
          #imageInput
          type="file"
          (change)="addMediaFile('image', $event)"
          [multiple]="false"
          accept="image/png, image/jpg, image/jpeg"
          hidden
        />

        <svc-button mode="simple" [disabled]="loading || !addVideoEnabled" (click)="canAddVideo() && videoInput.click()" buttonId="post-add-video">
          <div class="flex gap-2">
            <mat-icon class="flex self-center text-current icon-size-6" svgIcon="heroicons_solid:film"></mat-icon>
            <div class="flex self-center text-base font-semibold">{{ 'Vídeo' | transloco }}</div>
          </div>
        </svc-button>

        <input
          #videoInput
          type="file"
          (change)="addMediaFile('video', $event)"
          [multiple]="false"
          accept="video/mp4, video/3gpp, video/quicktime"
          hidden
        />

      </div>

    </form>

  </div>
  <div svc-dialog-actions>
    <div
      class="flex flex-row gap-2"
    >
      <svc-button
        mode="stroked"
        type="button"
        (click)="!loading ? dialog.closeDialog() : null"
        [disabled]="loading"
        buttonId="post-cancel"
      >
        <span class="text-sm">
          {{ 'Cancelar' | transloco }}
        </span>
      </svc-button>

      <svc-button
        *ngIf="!loading"
        mode="flat"
        type="button"
        color="primary"
        [disabled]="loading || !hasTextOrMedia"
        (click)="!loading && hasTextOrMedia && submitForm()"
        buttonId="post-submit"
        cdkFocusInitial
      >
        <div *ngIf="loading" class="px-3">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate"
            [diameter]="24"
          ></mat-progress-spinner>
        </div>
        <span *ngIf="!loading" class="text-sm">
          {{ (mode === 'new' ? 'Publicar' : 'Salvar') | transloco }}
        </span>
      </svc-button>

    </div>
  </div>

</svc-dialog>
