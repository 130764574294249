import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TasksCategory } from "../models/tasks-category";
import { TranslocoService } from "@ngneat/transloco";
import { Subject, takeUntil } from "rxjs";
import { MainTasksService } from "../main-tasks.service";
import { Router } from '@angular/router';

@Component({
  selector: 'main-tasks',
  templateUrl: './main-tasks.component.html',
  styleUrls: ['./main-tasks.component.scss']
})
export class MainTasksComponent implements OnInit {

  allTasks: any = [];
  categories: any = [];
  allTasksCount = {};
  @Output() onTotalChanged = new EventEmitter<number>();
  @Output() onLoadingChanged = new EventEmitter<number>();
  @Output() onError = new EventEmitter<{ allHadErrors: boolean, hadAtLeastOneError: boolean }>();

  public apiErrors = {
    'Action Plan': false,
    'Checklist': false,
    'CIL': false,
    'Defect Tag': false
  };
  public allHadErrors = false;
  public hadAtLeastOneError = false;
  public isTotalTasksLoading = true;
  public isLoadingTasks = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _totalTasks: any = {};
  #isFirstTime = true;
  #typeToUpdate = TasksCategory.TODAY;

  constructor(
    private _mainTasksService: MainTasksService,
    private _translocoService: TranslocoService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.#setCategories();
    this.allTasks = this._mainTasksService._allTasks;
    this.updateTotalTasks();
    this.#tasksUpdate();
    this.#tasksLoading();
    this.#tasksError();
  }

  #setCategories(): void {
    this.categories = [
      {
        enum: [TasksCategory.LATE],
        title: this._translocoService.translate('Atrasados'),
        opened: false
      },
      {
        enum: [TasksCategory.TODAY],
        title: this._translocoService.translate('Hoje'),
        opened: true
      },
      {
        enum: [TasksCategory.TOMORROW_UP_TO_7_DAYS],
        title: this._translocoService.translate('<= 7 dias'),
        opened: false
      },
      {
        enum: [TasksCategory.MORE_THAN_7_DAYS],
        title: this._translocoService.translate('> 7 dias'),
        opened: false
      }
    ];
  }

  #tasksUpdate(): void {
    this._mainTasksService.allTasksUpdated$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        if (this.isLoadingTasks) {
          this.allTasks[this.#typeToUpdate] = value[this.#typeToUpdate];
        }
        else {
          this.allTasks = value;
          this.updateTotalTasks();
        }
      });
  }

  #tasksLoading(): void {
    this._mainTasksService.isAllTasksLoading$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isLoading) => {
        if (this.#isFirstTime || !this.isLoadingTasks) {
          this.isTotalTasksLoading = isLoading;
          this.onLoadingChanged.emit(isLoading);
          this.categories?.forEach(categorie => {
            const type = categorie?.enum[0] === this.#typeToUpdate ? this.#typeToUpdate : categorie?.enum?.[0];
            if (categorie?.enum?.[0] === this.#typeToUpdate || categorie.opened)
              categorie.opened = this.allTasks[type]?.length > 0;
          });
        }

        if (!isLoading) {
          this.allHadErrors = Object.keys(this.apiErrors).every((key) => this.apiErrors[key]);
          this.hadAtLeastOneError = Object.keys(this.apiErrors).filter((key) => this.apiErrors[key]).length > 0;
          this.onError.emit({ allHadErrors: this.allHadErrors, hadAtLeastOneError: this.hadAtLeastOneError });
          this.#setCount();
          this.isLoadingTasks = false;
        } else {
          for (const key in this.apiErrors) {
            this.apiErrors[key] = false;
          }
        }
      });
  }

  #tasksError(): void {
    this._mainTasksService.hasError$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(errorInfo => {
        this.apiErrors[errorInfo.apiName] = true;
      });
  }

  #setCount(): void {
    if (this.#isFirstTime || !this.isLoadingTasks) {
      this.#isFirstTime = false;
      this.allTasksCount = this._mainTasksService._allTasks?.count;
      this.updateTotalTasks();
    }
    else {
      let isUpdate: boolean;
      Object.keys(this.allTasksCount).forEach(key => {
        if (this.allTasksCount?.[key] !== this._mainTasksService._allTasks?.count?.[key]) {
          isUpdate = true;
          this.allTasksCount[key] = this._mainTasksService._allTasks?.count?.[key];
        }
      });
      if (isUpdate) this.updateTotalTasks();
    }
  }
  
  getTasks() {
    this._mainTasksService.getUserDrillDowns();
    this.allHadErrors = Object.keys(this.apiErrors).every((key) => this.apiErrors[key]);
  }

  private updateTotalTasks() {
    this._totalTasks = Object.values(this.allTasksCount).reduce((total: number, tasks: number) => total + tasks, 0);
    this.onTotalChanged.emit(this._totalTasks);
  }

  public openCategory(type: TasksCategory): void {
    if (this.allTasksCount?.[type] > 0 && !this.allTasks?.loaded?.[type] && this.allTasks[type]?.length === 0) {
      this.isLoadingTasks = true;
      this.#typeToUpdate = type;
      this.allTasks = { ...this.allTasks };
  
      if (this._router.url.includes('dashboard/subordinates'))
        this._mainTasksService.getSubordinatesDrillDowns({
          actionPlan: true,
          checklist: true,
          defectTag: true,
          lil: true
        }, type);
      else
        this._mainTasksService.getUserDrillDowns({
          actionPlan: true,
          checklist: true,
          defectTag: true,
          lil: true
        }, type);
    }
  }
}
