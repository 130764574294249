import { inject, Injectable } from '@angular/core';
import { catchError, finalize, Observable, of, Subject, BehaviorSubject, switchMap } from 'rxjs';
import { ParameterService } from '../parameter/parameter.service';
import { SvcFeatureToggle } from './svc-feature-toggle.model';
import { SvcCopilotService } from 'projects/lib-shared-feature/src/lib/general/svc-copilot/services/svc-copilot.service';

export enum SvcFeatureToggleStatus {
  PENDING,
  LOADED,
}

@Injectable({
  providedIn: 'root',
})
export class SvcFeatureToggleService {

  #parameterService = inject(ParameterService);
  #copilotService = inject(SvcCopilotService);
  #featureToggle = new SvcFeatureToggle();
  #pendingRequest: Subject<SvcFeatureToggleService>;

  #featureToggleStatus$ = new BehaviorSubject(SvcFeatureToggleStatus.PENDING);
  public featureToggleStatus$ = this.#featureToggleStatus$.asObservable();

  public get feedIsEnabled(): boolean { return this.#featureToggle.feedIsEnabled.value; }
  public get feedIsEnabledAlreadyLoaded(): boolean { return this.#featureToggle.feedIsEnabled.hasHave; }
  #setFeedIsEnabled(value: boolean) {
    this.#featureToggle.feedIsEnabled.setValue(value);
  }

  public get postIsEnabled(): boolean { return this.#featureToggle.postIsEnabled.value }
  public get postIsEnabledAlreadyLoaded(): boolean { return this.#featureToggle.communicationIsEnabled.hasHave; }
  #setPostIsEnabled(value: boolean) {
    this.#featureToggle.postIsEnabled.setValue(value);
  }

  public get communicationIsEnabled(): boolean { return this.#featureToggle.communicationIsEnabled.value }
  public get communicationIsEnabledAlreadyLoaded(): boolean { return this.#featureToggle.communicationIsEnabled.hasHave; }
  #setCommunicationIsEnabled(value: boolean) {
    this.#featureToggle.communicationIsEnabled.setValue(value);
  }

  public get appOffilineEnabled(): boolean { return this.#featureToggle.appOffilineEnabled.value }
  public get appOffilineEnabledAlreadyLoaded(): boolean { return this.#featureToggle.communicationIsEnabled.hasHave; }
  #setAppOffilineEnabled(value: boolean) {
    this.#featureToggle.appOffilineEnabled.setValue(value);
  }

  public get copilotIsEnabled(): boolean { return this.#featureToggle.copilotIsEnabled.value }
  public get copilotIsEnabledAlreadyLoaded(): boolean { return this.#featureToggle.communicationIsEnabled.hasHave; }
  #setCopilotIsEnabled(value: boolean) {
    this.#featureToggle.copilotIsEnabled.setValue(value);
  }

  public reset() {
    this.#pendingRequest = null;
    this.#featureToggle = new SvcFeatureToggle();
  }

  public load(): Observable<SvcFeatureToggleService> {
    if (!this.#pendingRequest) {
      const complete = () => {
        this.#featureToggleStatus$.next(SvcFeatureToggleStatus.LOADED);
        this.#pendingRequest.next(this);
        this.#pendingRequest.complete();
        this.#pendingRequest.closed = true;
      };
      this.#pendingRequest = new Subject<SvcFeatureToggleService>();
      let isGlobalLoading = true;
      let isSiteLoading = true;
      this.#parameterService.getAllAppGlobalParams().pipe(
        catchError(() => of([])),
        switchMap((res) => {
          const feedIsEnabled = (res.find(p => p.key === 'FEED_ENABLE')?.value ?? 'FALSE').toString().toLocaleLowerCase();
          const postIsEnabled = (res.find(p => p.key === 'POST_ENABLE')?.value ?? 'FALSE').toString().toLocaleLowerCase();
          const communicationIsEnabled = (res.find(p => p.key === 'COMMUNICATION_ENABLE')?.value ?? 'FALSE').toString().toLocaleLowerCase();
          const appOffilineIsEnabled = (res.find(p => p.key === 'APP_OFFLINE_ENABLE')?.value ?? 'FALSE').toString().toLocaleLowerCase();
          this.#setFeedIsEnabled(typeof feedIsEnabled === 'boolean' ? feedIsEnabled : feedIsEnabled === 'true');
          this.#setPostIsEnabled(typeof postIsEnabled === 'boolean' ? postIsEnabled : postIsEnabled === 'true');
          this.#setCommunicationIsEnabled(typeof communicationIsEnabled === 'boolean' ? communicationIsEnabled : communicationIsEnabled === 'true');
          this.#setAppOffilineEnabled(typeof appOffilineIsEnabled === 'boolean' ? appOffilineIsEnabled : appOffilineIsEnabled === 'true');
          return of(this);
        }),
        finalize(() => {
          isGlobalLoading = false;
          !isSiteLoading && complete();
        }),
      ).subscribe();

      this.#copilotService.getUserAccessControl().pipe(
        catchError(() => of([])),
        switchMap((copilotIsEnabled) => {
          this.#setCopilotIsEnabled(copilotIsEnabled as boolean);
          return of(this);
        }),
        finalize(() => {
          isSiteLoading = false;
          !isGlobalLoading && complete();
        }),
      ).subscribe();
    }
    return this.#pendingRequest.closed
      ? of(this)
      : this.#pendingRequest.asObservable();
  }
}

