import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, finalize, Subscription, tap, filter } from 'rxjs';
import { SvcSubtitleService } from '../../services/svc-subtitle.service';
import { SvcSubtitleResponse } from '../../models/subtitle-response.model';
import { SvcTranscriptionStatusResponse, SvcTranscriptionStatus } from '../../models/transcription-status-response';
import { SvcVideoPlayerFeaturesTypes } from '../../utils/svc-video-player.utils';
import { AuthService } from 'projects/lib-shared-core/src/lib/auth/auth.service';
import { UserService } from 'projects/lib-shared-core/src/lib/features/user/services/user.service';
import { SvcGlobalDataPrefetching } from 'projects/lib-shared-core/src/lib/data-prefetching/svc-global-data-prefetching';
import { HttpErrorService } from 'projects/lib-shared-common/src/lib/services/http-error.service';
import { SvcVideoPlayerApplicationId } from '../../enums/svc-video-player-applications.enum';

@Component({
  selector: 'svc-video-player',
  templateUrl: './svc-video-player.component.html',
  styleUrls: ['./svc-video-player.component.scss'],
  host: {
    '[class.controls-up]': `controlsUp`,
    '[class.controls-down]': `!controlsUp`,
  }
})
export class SvcVideoPlayerComponent implements OnInit, OnDestroy {

  @Input() videoSrc!: string;
  @Input() featureTypeId!: string;
  @Input() registryId!: number | string;
  /**
   * URL to open in email the related record when the user   
   * is notified that the subtitles are ready.
   */
  @Input() notificationCallbackUrl: string;
  @Input() referenceSiteId: number;
  @Input() languageId: number;
  @Input() controlsUp: boolean = true;
  @Input() fitContent: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' = 'cover';
  @Input() height: number;
  @Input() applicationId: string;
  @Input() registryCode: string;

  protected isLoading: boolean = false;
  protected subtitlesEnabled: boolean = false;
  protected isFeatureEnabled: boolean = false;
  protected transcriptionStatus: SvcTranscriptionStatus;
  protected subtitleInternalUrl: string = '';
  protected subtitleStatus = SvcTranscriptionStatus;

  #toggleRequestSubscription: Subscription;
  #transcriptionStatusRequestSubscription: Subscription;
  #transcriptionOrderRequestSubscription: Subscription;

  get #registryIsNumeric(): boolean {
    return typeof this.registryId === 'number';
  }

  #cd = inject(ChangeDetectorRef);
  #httpErrorService = inject(HttpErrorService);
  #subtitleService = inject(SvcSubtitleService);
  #globalDataPrefetching = inject(SvcGlobalDataPrefetching);
  #userService = inject(UserService);
  #authService = inject(AuthService);

  public ngOnInit(): void {
    this.#authService.environmentURL = location.origin;
    this.#setFeatureEnabled();
  }

  #setFeatureEnabled (): void {
    const prefetchingItem = this.#globalDataPrefetching.data.subtitleEnabled;
    if (prefetchingItem?.isLoading)
      this.#globalDataPrefetching.data.subtitleEnabled.isLoading$.pipe(
        filter((value) => !value),
        tap(() => {
          this.isFeatureEnabled = this.#globalDataPrefetching.data.subtitleEnabled.value;
          this.getStatusTranscription();
        }),
      ).subscribe();
    else {
      this.isFeatureEnabled = prefetchingItem.value;
      this.getStatusTranscription();
    }
  }

  #setFeatureTypeIdLpp(): void {
    Object.keys?.(SvcVideoPlayerFeaturesTypes)?.forEach(key => {
      if(this.videoSrc?.includes(key))
        this.featureTypeId = SvcVideoPlayerFeaturesTypes[key];
    });
  }

  #setFeatureTypeId(): void {
    if (!this.featureTypeId && this.videoSrc && this.applicationId) {
      this.applicationId = this.applicationId?.toUpperCase();
      const type = SvcVideoPlayerApplicationId;
      switch(this.applicationId) {
        case type.LPP:
          this.#setFeatureTypeIdLpp();
          break;
        case type.Post:
          this.featureTypeId = SvcVideoPlayerFeaturesTypes['post'];
          break;
        case type.Kaizen:
          this.featureTypeId = SvcVideoPlayerFeaturesTypes['kaizen'];
          break;
        case type.RCA:
          this.featureTypeId = SvcVideoPlayerFeaturesTypes['rca'];
          break;
      }
    }
  }

  private getKeyFormat(url: string): string {
    const regex = /(?<=\.com\/)([^?]+)/;
    const match = url.match(regex);
    return match[0].toString();
    // return match[0].replace(/^\/+/, '');
  }

  protected getStatusTranscription(): void {
    this.#setFeatureTypeId();
    if (this.isFeatureEnabled) {
      this.transcriptionStatus = null;
      this.#transcriptionStatusRequestSubscription?.unsubscribe();
     
      this.isLoading = true;
      this.#transcriptionStatusRequestSubscription = this.#subtitleService.getTranscriptionStatus({
        featureTypeId: this.featureTypeId,
        referenceSiteId: this.referenceSiteId ?? this.#userService.user?.lastSiteId ?? 0,
        keyMediaS3: this.getKeyFormat(this.videoSrc),
      }).pipe(
        tap((res: SvcTranscriptionStatusResponse) => {
          this.transcriptionStatus = res.status;
        }),
        catchError((error) => {
          this.#httpErrorService.showErrorInToast(error);
          this.transcriptionStatus = SvcTranscriptionStatus.ERROR;
          return error;
        }),
        finalize(() => {
          this.#transcriptionStatusRequestSubscription = null;
          this.isLoading = false;
          this.#cd.detectChanges();
        }),
      ).subscribe();
    }
  }

  #getParamsSubtitle() {
    let paramsToRequest: any = {
      featureTypeId: this.featureTypeId,
      keyMediaS3: this.getKeyFormat(this.videoSrc),
      languageId: this.languageId ?? this.#userService.user?.languageId ?? 0,
      referenceSiteId: this.referenceSiteId ?? this.#userService.user?.lastSiteId ?? 0,
      notificationCallbackUrl: this.#getNotificationCallbackUrl(),
      ...(this.registryCode && { registryCode: this.registryCode })
    };

    paramsToRequest = this.#getRegistryId(paramsToRequest);
    return paramsToRequest;
  }

  public toggleSubtitles(): void {
    if (!this.subtitlesEnabled) {
      if (this.subtitleInternalUrl) {
        this.subtitlesEnabled = true;
        return;
      }
      
      this.#toggleRequestSubscription?.unsubscribe();

      this.isLoading = true;
      const paramsToRequest = this.#getParamsSubtitle();
      this.#toggleRequestSubscription = this.#subtitleService.getSubtitle(paramsToRequest).pipe(
        tap((res: SvcSubtitleResponse) => {
          this.subtitleInternalUrl = res.subtitleVttS3Assign;
          this.subtitlesEnabled = true;
        }),
        catchError((error) => {
          this.#httpErrorService.showErrorInToast(error);
          return error;
        }),
        finalize(() => {
          this.#toggleRequestSubscription = null;
          this.isLoading = false;
        }),
      ).subscribe();
    }
    else
      this.subtitlesEnabled = false;
  }

  #getRegistryId(paramsToRequest: any): any {
    if (this.#registryIsNumeric)
      paramsToRequest.registryId = this.registryId as number;
    else
      paramsToRequest.registryUId = this.registryId as string;
    
    return paramsToRequest;
  }

  #getNotificationCallbackUrl(): string {
    const url = location.host.startsWith('localhost') ? this.#authService.environmentURL : location.origin;
    return `${url}/${this.notificationCallbackUrl}`; 
  }

  #getParamsTranscriptionOrder() {
    let paramsToRequest: any = {
      subtitleFeatureTypeId: this.featureTypeId,
      keyMediaS3: this.getKeyFormat(this.videoSrc),
      referenceSiteId: this.referenceSiteId ?? this.#userService.user?.lastSiteId ?? 0,
      notificationCallbackUrl: this.#getNotificationCallbackUrl(),
      ...(this.registryCode && { registryCode: this.registryCode?.toString() })
    };

    paramsToRequest = this.#getRegistryId(paramsToRequest);
    return paramsToRequest;
  }

  protected transcriptionOrder() {
    if (this.isLoading) return;
    this.#transcriptionOrderRequestSubscription?.unsubscribe();
  
    this.isLoading = true;
    const paramsToRequest = this.#getParamsTranscriptionOrder();

    this.#transcriptionOrderRequestSubscription = this.#subtitleService.createTranscription(paramsToRequest).pipe(
      tap((res: number) => {
        if (res > 0)
          this.transcriptionStatus = SvcTranscriptionStatus.WAITING;;
      }),
      catchError((error) => {
        this.#httpErrorService.showErrorInToast(error);
        return error;
      }),
      finalize(() => {
        this.#transcriptionOrderRequestSubscription = null;
        this.isLoading = false;
      }),
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this.#toggleRequestSubscription?.unsubscribe();
    this.#transcriptionStatusRequestSubscription?.unsubscribe();
    this.#transcriptionOrderRequestSubscription?.unsubscribe();
  }
}