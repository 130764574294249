import { Component } from '@angular/core';

import { catchError, finalize, isEmpty, Subject, takeUntil, tap } from 'rxjs';

import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { SvcPivotTableService } from './shared/services/svc-pivot-table.service';
import { SvcPivotTable, SvcPivotTableFiles, SvcPivotTableFilter } from './shared/interfaces/svc-pivot-table.interface';
import { SvcDataTableEvent, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { SvcPivotTableErrorService } from './shared/services/svc-pivot-table-error.service';
import { SvcPivotTableFilterOrderBy, SvcPivotTableFilterOrderDirecton } from './shared/enums/svc-pivot-table-filter-order.enum';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'svc-pivot-table',
  templateUrl: './svc-pivot-table.component.html',
  styleUrls: ['./svc-pivot-table.component.scss']
})
export class SvcPivotTableComponent {

  @AutoDestroy public destroy$: Subject<void> = new Subject<void>();

  public moduleName: string;
  public files: SvcPivotTableFiles[];
  public totalFiles: number;
  public isLoading = true;
  public filter: SvcPivotTableFilter = {
    pageSize: 10,
    pageIndex: 1,
  };
  public orderBy = SvcPivotTableFilterOrderBy;
  public orderDirection = SvcPivotTableFilterOrderDirecton;
  public hasCol: { year: boolean; filename?: boolean } = {year: true};

  constructor (
    private _appSettings: SvcAppSettings,
    private _pivotTableService: SvcPivotTableService,
    private _pivotTableErrorService: SvcPivotTableErrorService,
    private _toastService: SvcToastService,
    private _translocoService: TranslocoService
  ) {
    this.moduleName = this._appSettings.applicationName;
  }

  public onGetData(dataTableEvent: SvcDataTableEvent): void {
    this.filter = {
      ...this.filter,
      pageSize: dataTableEvent.pageSize,
      pageIndex: dataTableEvent.page,
      orderBy: SvcPivotTableFilterOrderBy[dataTableEvent?.order?.field] ?? null,
      orderDirection: dataTableEvent?.order?.asc ? this.orderDirection.Asc : dataTableEvent?.order?.asc === false ? this.orderDirection.Desc : null,
    };

    Object.keys(this.filter)?.forEach((key: string) => {
      if (this.filter?.[key] === null)
        delete this.filter?.[key];
    });

    this.getFiles();
  }

  private setFiles(pivotTable: SvcPivotTable): void {
    this.files = pivotTable?.files;
    this.hasCol.year = this.files.filter(f => f.month > 0 && f.year > 0).length > 0;
    this.hasCol.filename = this.files.filter(f => f.fileName && f.fileName.trim() !== '').length > 0;
    this.totalFiles = pivotTable.page?.registersTotal;
  }

  public getFiles(): void {
    this.isLoading = true;
    this._pivotTableService.getRequestDownloadList(this.filter)
      .pipe(
        tap((pivotTable: SvcPivotTable) => this.setFiles(pivotTable)),
        catchError(err => {
          this._pivotTableErrorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => this.isLoading = false),
        takeUntil(this.destroy$)
      )
      .subscribe()
  }

  public downloadFile(link: string): void {
    this._toastService.success(
      this._translocoService.translate('Download realizado com sucesso')
    );
    window.open(link, '_self');
  }
}
