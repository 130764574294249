
import { AuthUserPoolConfig, CognitoUserPoolConfig, OAuthConfig } from '@aws-amplify/core';
import { EnvironmentInfo } from './environment.info.model';

const isLocalhost = location.origin.startsWith('http://localhost:4200');

export class AWSAmplifyAuthInfo implements AuthUserPoolConfig {
  public Cognito: CognitoUserPoolConfig;

  constructor(env: EnvironmentInfo, app: string) {
    this.Cognito = new AWSAmplifyCognito(env, app);
  }
}
export class AWSAmplifyCognito implements CognitoUserPoolConfig {
  public identityPoolId: string = '';
  public userPoolClientId: string;
  public userPoolId: string;
  public loginWith: {
    username?: boolean;
    oauth: AWSAmplifyOAuth;
  };

  constructor(env: EnvironmentInfo, app: string) {
    this.userPoolId = env.poolId;
    this.userPoolClientId = env.clientId;
    this.loginWith = {
      username: true,
      oauth: new AWSAmplifyOAuth(
        isLocalhost ? document.location.origin : env.url,
        isLocalhost ? '' : app,
        env.urlCognito,
      ),
    };
  }
}

export class AWSAmplifyOAuth implements OAuthConfig {
  domain: string;
  scopes = [
    'aws.cognito.signin.user.admin',
    'email',
    'openid',
    'phone',
    'profile'
  ];
  redirectSignIn: string[];
  redirectSignOut: string[];
  responseType: 'code' | 'token' = 'code';
  constructor(url: string, app: string, urlCognito: string) {
    this.redirectSignIn = [`${url}${app ? '/' + app : ''}/sign-in`];
    this.redirectSignOut = [`${url}/user/User/Logoff`];
    this.domain = urlCognito;
  }
}

export interface AWSAmplifyLoginResponse {
  result: AWSAmplifyAuthResult;
  currentUser: any;
  // oAuth: AWSAmplifyOAuth;
}

export enum AWSAmplifyAuthResult {
  NewPasswordRequired,
  Success
}
