import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {TranslocoModule} from "@ngneat/transloco";
import { SvcButtonsModule, SvcFiltersModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { SvcReactionModule } from '../svc-reaction/svc-reaction.module';
import { SvcCopilotService } from './services/svc-copilot.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SvcAudioToTextButtonModule } from '../svc-audio-to-text-button/svc-audio-to-text-button.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuillEditorComponent } from "ngx-quill";
import { SvcCopilotChatComponent } from './components/svc-copilot-chat/svc-copilot-chat.component';
import { SvcCopilotInputComponent } from './components/svc-copilot-input/svc-copilot-input.component';
import { SvcCopilotMessageComponent } from './components/svc-copilot-message/svc-copilot-message.component';
import { SvcCopilotFloatButtonComponent } from './components/svc-copilot-float-button/svc-copilot-float-button.component';

@NgModule({
  declarations: [
    SvcCopilotInputComponent,
    SvcCopilotMessageComponent,
    SvcCopilotChatComponent,
    SvcCopilotFloatButtonComponent
  ],
  imports: [
    LibSharedCommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    QuillEditorComponent,
    TranslocoModule,
    SvcAudioToTextButtonModule,
    SvcUserAvatarModule,
    SvcButtonsModule,
    SvcReactionModule,
    SvcFiltersModule
  ],
  exports: [
    SvcCopilotChatComponent,
    SvcCopilotFloatButtonComponent
  ],
  providers: [
    SvcCopilotService
  ]
})
export class SvcCopilotModule { }
