import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { CloudfrontCookie } from '../model/cloudfront-cookie.mode';
import { SvcHttpClient } from 'projects/lib-shared-common/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class CloudfrontService extends SvcHttpClient {

  constructor(
    appConfig: AppEnvironmentConfig,
    httpClient: HttpClient,
  ) {
    super(appConfig.APIs.apiUrlAuth, httpClient);
  }

  getSignedCookies(): Observable<CloudfrontCookie> {
    return this.get<CloudfrontCookie>(`/Cookie/cloudfront`, { skipRequestCollector: true });
  }
}
