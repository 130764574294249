<div class="grid grid-cols-2 gap-4" *ngIf="isLoading">
  <div class="flex flex-col gap-2" *ngFor="let skeleton of [1, 2]">
    <ngx-skeleton-loader class="h-[18px]" [theme]="{ width: '32px', height: '18px'}">
    </ngx-skeleton-loader>

    <ngx-skeleton-loader class="h-10" [theme]="{ width: '100%', height: '40px', borderRadius: '6px' }">
    </ngx-skeleton-loader>
  </div>
</div>

<div class="grid grid-cols-2 gap-4" *ngIf="!isLoading">
  <ng-container *ngFor="let field of customFields?.fields; index as index">
    <ng-container *ngIf="formCustomFields?.controls?.fields?.controls?.[index] as fieldGroup" [ngSwitch]="field?.fieldTypeId">

      <svc-text
        *ngSwitchCase="fieldTypes.Text"
        fallbackTextValue="-"
        [inputId]="'form-custom-field-text' + index"
        [label]="field?.fieldName"
        [placeholder]="field?.mandatory ? '' : ('Opcional' | transloco)"
        [readonly]="isViewMode"
        [formControl]="fieldGroup?.controls?.fieldValue"
      >
      </svc-text>

      <svc-text
        *ngSwitchCase="fieldTypes.Number"
        mask="0*"
        fallbackTextValue="-"
        [inputId]="'form-custom-field-number' + index"
        [label]="field?.fieldName"
        [placeholder]="field?.mandatory ? '' : ('Opcional' | transloco)"
        [readonly]="isViewMode"
        [formControl]="fieldGroup?.controls?.fieldValue"
      >
      </svc-text>

      <svc-date-picker
        *ngSwitchCase="fieldTypes.Date"
        [inputId]="'form-custom-field-date' + index"
        [formControl]="fieldGroup?.controls?.fieldValue"
        [label]="field?.fieldName"
      >
      </svc-date-picker>

      <svc-chip
        *ngSwitchCase="fieldTypes.List"
        type="single"
        fallbackTextValue="-"
        [inputId]="'form-custom-field-list' + index"
        [placeholder]="field?.mandatory ? '' : ('Opcional' | transloco)"
        [label]="field?.fieldName"
        [options]="field?.optionsCombo"
        [readonly]="isViewMode"
        [formControl]="fieldGroup?.controls?.fieldValue"
      >
      </svc-chip>

      <svc-chip
        *ngSwitchCase="fieldTypes.ListMultiple"
        type="multiple"
        fallbackTextValue="-"
        [inputId]="'form-custom-field-list-multiple' + index"
        [placeholder]="field?.mandatory ? '' : ('Opcional' | transloco)"
        [label]="field?.fieldName"
        [options]="field?.optionsCombo"
        [readonly]="isViewMode"
        [formControl]="fieldGroup?.controls?.fieldValue"
      >
      </svc-chip>

      <mat-checkbox
        *ngSwitchCase="fieldTypes.Checkbox"
        color="primary"
        class="flex items-end -ml-2"
        [id]="'form-custom-field-checkbox' + index"
        [disabled]="isViewMode"
        [formControl]="fieldGroup?.controls?.fieldValue"
      >
        <p class="text-default text-base font-medium font-sans">
          {{ field?.fieldName }}
        </p>
      </mat-checkbox>

      <svc-text
        *ngSwitchCase="fieldTypes.Hour"
        mask="Hh:m0"
        fallbackTextValue="-"
        [inputId]="'form-custom-field-hour' + index"
        [label]="field?.fieldName"
        [placeholder]="field?.mandatory ? '' : ('Opcional' | transloco)"
        [readonly]="isViewMode"
        [formControl]="fieldGroup?.controls?.fieldValue"
        [dropSpecialCharacters]="false"
        [info]="'Por favor, insira a hora utilizando o padrão internacional de 24 horas' | transloco"
        (onBlur)="formatHour(fieldGroup?.controls?.fieldValue)"
      >
      </svc-text>

    </ng-container>
  </ng-container>
</div>
