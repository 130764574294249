import { Component, HostBinding, QueryList, ContentChildren, OnDestroy, ElementRef, Input, ViewChild, AfterViewChecked, Output, EventEmitter, AfterContentInit, Inject, forwardRef, Optional, ViewChildren } from '@angular/core';
import { SvcDataBodyComponent } from '../svc-data-body/svc-data-body.component';
import { Subscription, tap } from 'rxjs';
import { SvcDataCellComponent } from '../svc-data-cell/svc-data-cell.component';
import { DEFAULT_ROW_HEIGHT } from '../services/svc-data-virtual-scroll.service';

const MAX_INDEX = 9999;

@Component({
	selector: 'svc-data-row',
	templateUrl: './svc-data-row.component.html',
	styleUrls: ['./svc-data-row.component.scss'],
})
export class SvcDataRowComponent implements AfterViewChecked, AfterContentInit, OnDestroy {
	@HostBinding('class.svc-data-row') public class: boolean = true;

	@ContentChildren(SvcDataCellComponent) public queryCells: QueryList<SvcDataCellComponent>;
	@ViewChildren(SvcDataCellComponent) public querySkeletonCells: QueryList<SvcDataCellComponent>;
	@ViewChild('dragButton') public dragButtonElRef: ElementRef<HTMLElement>;
	@ViewChild('content', { static: false }) public contentElRef: ElementRef<HTMLElement>;

	@Input('draggable') public draggable: boolean = true;

	@Output()
	public rowClick: EventEmitter<Event> = new EventEmitter();

	public maxIndex = MAX_INDEX;

	public set _minHeight(value: string) {
		if (this.el) {
			this.el.style.minHeight = value;
		}
	}

	public get cells() {
		return this.queryCells?.toArray() ?? [];
	}

	public get skeletonCells() {
		return this.querySkeletonCells?.toArray() ?? [];
	}

	public get el() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement : null;
	}

	public get dragButtonEl() {
		return this.dragButtonElRef.nativeElement;
	}

	public get contentEl() {
		return this.contentElRef?.nativeElement ?? this.el?.querySelector('.svc-data-row-content');
	}

	public virtualIndex: number;
	public index: number;

	private subscription: Subscription;
	private skeletonsSubscription: Subscription;

	public canDragAndReorder: boolean = false;

	constructor(
    @Optional() @Inject(forwardRef(() => SvcDataBodyComponent))
    public parent: SvcDataBodyComponent,
		private elRef: ElementRef<HTMLElement>,
	) {
	}

	public ngAfterViewChecked() {
		this.checkCanDragAndReorder();
	}

	public ngAfterContentInit(): void {
		this.onRender();
	}

	public onRender() {
		this.virtualIndex = this.parent ? Array.from(this.parent.el.querySelectorAll('svc-data-row')).indexOf(this.el) : 0;
		this.index = this.parent?.getRowIndexBasedVirtual(this.virtualIndex) ?? 0;
		this.defineClass();
		this.checkCanDragAndReorder();
		
		this._minHeight = this.parent?.rowHeight ?? DEFAULT_ROW_HEIGHT.toString();

		this.subscription?.unsubscribe();
		this.subscription = this.queryCells.changes.pipe(
			tap(() => {
				this.configureChildrens({ rerenderCells: true });
			}),
		).subscribe();

		this.skeletonsSubscription?.unsubscribe();
		this.skeletonsSubscription = this.querySkeletonCells?.changes.pipe(
			tap(() => {
				this.configureChildrens({ rerenderCells: true });
			}),
		).subscribe();

		this.contentEl.style.zIndex = `${this.maxIndex - this.virtualIndex}`;
	}

	public onDraggableChange() {
		if (this.parent?.dragAndReorder) this.el.classList.add('draggable');
		else this.el.classList.remove('draggable');
	}

	public configureChildrens(options?: { rerenderCells?: boolean }) {
		if (options?.rerenderCells) {
			for(const cell of this.cells) {
				cell.onRender();
			}
			for(const cell of this.skeletonCells) {
				cell.onRender();
			}
		}
	}

	public checkCanDragAndReorder() {
		if (this.canDragAndReorder !== !!(this.parent && this.parent.dragAndReorder)) {
			this.canDragAndReorder = !!(this.parent && this.parent.dragAndReorder);
		}
	}

	private defineClass() {
		if (this.index >= 0) {
			let position = this.index + 1;
			let currentClassName = 'svc-data-row-' + (position % 2 === 0 ? 'even' : 'odd');
			let previousClassName = 'svc-data-row-' + (position % 2 === 0 ? 'odd' : 'even');

			if (!this.el.classList.contains(currentClassName)) this.el.classList.add(currentClassName);

			if (this.el.classList.contains(previousClassName)) this.el.classList.remove(previousClassName);
		}
		this.onDraggableChange();
	}

	public emitClick(event: Event) {
		this.rowClick.emit(event);
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
		this.skeletonsSubscription?.unsubscribe();
	}
}
