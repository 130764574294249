import { Component, Input, OnInit } from '@angular/core';
import { DocumentEditorModule, type IConfig } from "@onlyoffice/document-editor-angular";
import { OnlyofficeService } from '../services/onlyoffice.service';
import { DocumentType } from '../models/document-type';
import { tap } from 'rxjs/operators';
import { SvcGlobalParamService } from 'projects/lib-shared-core/src/lib/services/parameter/global-parameter.service';
import { catchError, finalize, Observable } from 'rxjs';

@Component({
  selector: 'svc-onlyoffice',
  templateUrl: './svc-onlyoffice.component.html',
  styleUrls: ['./svc-onlyoffice.component.scss']
})
export class SvcOnlyofficeComponent implements OnInit {

  @Input() applicationId: string;
  @Input() referenceId: string | number;

  public config: IConfig;
  public documentServerUrl: string;
  public isLoading: boolean;
  public documentLoaded: boolean;

  constructor(
    private _onlyOfficeService: OnlyofficeService,
    private _siteParamService: SvcGlobalParamService,
  ) {
  }

  ngOnInit(): void {
    this.setDocumentServerUrl();
    this.setInitialDocument();
  }

  public createNewEmptyFile() {
    const request = this._onlyOfficeService.createNewEmptyFile({
      applicationId: this.applicationId,
      fileType: DocumentType.word
    });
    this.setDocumentConfig(request);
  }

  public createNewEmptyFileFromUpload() {
    const request = this._onlyOfficeService.createNewEmptyFileFromUpload({
      applicationId: this.applicationId,
      isTemplate: 1,
      file: '',
    });
    this.setDocumentConfig(request);
  }

  public createNewEmptyFileFromUploadId() {
    const request = this._onlyOfficeService.createNewEmptyFileFromUploadId({
      applicationId: this.applicationId,
      isTemplate: 1,
      file: '',
    });
    this.setDocumentConfig(request);
  }

  public listFiles() {
    // TODO: list all files from reference
  }

  public loadFile() {
    // TODO: load file from uploadId
  }

  protected onDocumentReady = () => {
    this.documentLoaded = true;
  }

  protected onLoadComponentError = (errorCode, errorDescription) => {
    this.documentLoaded = false;
  }

  protected onError = (err) => {
    this.documentLoaded = false;
  };

  private setDocumentServerUrl() {
    this._siteParamService.getParameterByParamName('ONLYOFFICE_DOC_SERVER').pipe(
      tap(parameter => {
        this.documentServerUrl = parameter.value;
      }),
      catchError((err) => {
        return err;
      }),
      finalize(() => {})
    ).subscribe();
  }

  private setInitialDocument() {
    if (!this.applicationId || !this.referenceId) return;
    // TODO: list files from reference and load first file
  }

  private setDocumentConfig(request: Observable<any>) {
    this.isLoading = true;
    request.pipe(
      tap(r => {
        const { chat, ...customizationWithoutChat } = r.editorConfig.customization;
        this.config = {
          ...r,
          editorConfig: {
            ...r.editorConfig,
            customization: {
              ...customizationWithoutChat,
              close: {
                visible: false,
                text: 'close'
              }
            }
          }
        };
      }),
      catchError((err) => {
        return err;
      }),
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe();
  }


}
