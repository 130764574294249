import { Injectable } from '@angular/core';
import { SvcHttpClient } from 'projects/lib-shared-common/src/lib/classes/svc-http-client';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class DocumentHttpClient extends SvcHttpClient {

	constructor(
		private appConfig: AppEnvironmentConfig,
		private httpClient: HttpClient,
	) {
		super(appConfig.APIs.apiUrlDocuments, httpClient);
	}
}