import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';

import { AppEnvironmentConfig } from '../../../../config/model/environment.config.model';
import { SvcHttpClient } from 'projects/lib-shared-common/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class SvcMultilingualService extends SvcHttpClient {

  constructor(
    protected _appConfig: AppEnvironmentConfig,
    protected _httpClient: HttpClient,
  ) {
    super(
      _appConfig.APIs.apiUrlMultilingual,
      _httpClient
    );
  }

  public translateText(texts: string[]): Observable<{}> {
    return this.get<{ terms: string }[]>(`/Translate`, {
      params: {
        terms: texts
      }
    });
  }

}
