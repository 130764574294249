export const SvcVideoPlayerFeaturesTypes: Record<string, string> = {
  /**
   * LPP featureTypeId when signedUrl video has text 'lup-upload'
   */
  'lup-upload': 'E8DB7A8A-2217-4CC1-B2CC-073C3E84439A',

  /**
   * LPP featureTypeId when signedUrl video has text 'card-upload'
   */
  'card-upload': '50231B06-9B85-492F-AC7F-2E1FB59EB2A2', 

  /**
   * Posts featureTypeId when applicationId is of type Post
   */
  'post': '57350CF6-F544-4FA3-94B8-B4DE9E4B02B8',

  /**
   * Kaizen featureTypeId when applicationId is of type Kaizen
   */
  'kaizen': '042ED0FD-70A8-482D-B7EA-C4A5E704BCDA',

  /**
   * RCA featureTypeId when applicationId is of type RCA
   */
  'rca': 'AC1C3224-F82D-4E10-B416-27083AE2B59C',
}