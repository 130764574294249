import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, inject, Input, isDevMode, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { SvcDialogService } from '../svc-dialogs/svc-dialog.service';
import { SvcMediaCarouselInternalItem, SvcMediaCarouselItem } from './interfaces/svc-media-carousel-item';
import { SvcMediaCarouselRenderMode } from './interfaces/svc-media-carousel-render-mode';
import { debounceTime, fromEvent, Subject, takeUntil, tap } from 'rxjs';
import { SvcMediaCarouselAddEvent, SvcMediaCarouselRemoveEvent as SvcMediaCarouselDeleteEvent } from './interfaces/svc-media-carousel-events';
import { environment } from 'projects/environments/environment';

const DEFAULT_HEIGHT_PX = 150;
const RATIO_FOR_WIDTH = 1.8;
const CONTAINER_PECENT_RANGE_TO_SHOW_ITEM = {
  min: 0.05,
  max: 0.1,
};
const GAP_BETWEEN_ITEMS_PX = 8;

@Component({
  selector: 'svc-media-carousel',
  templateUrl: './svc-media-carousel.component.html',
  styleUrls: ['./svc-media-carousel.component.scss'],
  host: {
    '[class.svc-media-carousel]': 'true',
    '[class.svc-mc-one-by-one]': `renderMode === 'one-by-one'`,
    '[class.svc-mc-no-items]': `!items.length`,
    '[class.svc-mc-only-one-item]': `items.length === 1 && (!editMode || maxItems == 1)`,
    '[class.svc-mc-empty-view-alone]': `!items.length && emptyView`,
  },
})
export class SvcMediaCarouselComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  readonly #elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  readonly #svcDialogService = inject(SvcDialogService);

  @Optional() @ContentChild(TemplateRef) videoTemplateRef: TemplateRef<any>;

  @ViewChild('viewport') protected viewportRef: ElementRef<HTMLElement>;

  @Input('items') set onSetItems(items: SvcMediaCarouselItem[]) {
    if (JSON.stringify(items ?? []) === JSON.stringify(this.items)) return;
    this.items = items ?? [];
    this.setCurrentIsPending = true;
    this.recalculateItemSizesIfInitialied();
  }
  @Input('height') set onSetHeight(height: number) {
    this.itemHeight = height ?? DEFAULT_HEIGHT_PX;
    this.recalculateItemSizesIfInitialied();
  }
  @Input('renderMode') set setRenderMode(renderMode: SvcMediaCarouselRenderMode) {
    this.renderMode = renderMode ?? SvcMediaCarouselRenderMode.LIST;
    this.recalculateItemSizesIfInitialied();
  }
  @Input('editMode') set setEditMode(editMode: boolean) {
    this.editMode = editMode ?? false;
    this.recalculateItemSizesIfInitialied();
  }
  @Input() maxItems = 0;
  @Input() videoControls = true;
  @Input() initialItem: SvcMediaCarouselItem;
  @Input() goToEmptyViewWhenEditing = true;
  @Input() mustCenterEmptyViewWhenAlone = true;
  @Input() canClickToExpandImage = true;
  @Input() emptyView = true;
  @Input() isDark = false;
  @Input() fitContent: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' = 'cover';
  
  @Output() onCurrentItemChanged = new EventEmitter<SvcMediaCarouselItem>();
  @Output() onAddTriggered = new EventEmitter<SvcMediaCarouselAddEvent>();
  @Output() onDeleteTriggered = new EventEmitter<SvcMediaCarouselDeleteEvent>();
  @Output() onVideoPlay = new EventEmitter<HTMLVideoElement>();
  @Output() onVideoPause = new EventEmitter<void>();
  @Output() onVideoEnd = new EventEmitter<void>();
  
  public editMode = false;
  public renderMode = SvcMediaCarouselRenderMode.LIST;
  public needToTickItems$ = new Subject<void>();
  protected items: SvcMediaCarouselInternalItem[] = [];
  protected current: SvcMediaCarouselInternalItem;
  protected itemHeight: number = DEFAULT_HEIGHT_PX;
  protected itemWidth: number = DEFAULT_HEIGHT_PX * RATIO_FOR_WIDTH;
  protected viewportRemainingToStart: number = 0;
  protected viewportRemainingToEnd: number = 0;
  protected navigatorsOutside = true;
  private setCurrentIsPending = false;
  public get renderModeIsList() { return this.renderMode === SvcMediaCarouselRenderMode.LIST; }
  public get canShowEmptyView() { return this.emptyView && (!this.items.length || (this.editMode && (this.maxItems <= 0 || this.items.length < this.maxItems))); }
  public get currentIndex() { return this.items.indexOf(this.current); }
  public get element() { return this.#elementRef?.nativeElement; }
  public get viewportEl() { return this.viewportRef?.nativeElement; }

  #destroy = new Subject<void>();
  #wasInitialized = false;
  #viewWasInitialized = false;

  public ngOnInit(): void {
    fromEvent(window, 'resize').pipe(
      takeUntil(this.#destroy),
      debounceTime(100),
      tap(() => {
        const { hasChanged } = this.calculateItemsSize();
        if (hasChanged || !this.renderModeIsList) {
          setTimeout(() => {
            this.checkCurretItemPosition();
          });
        }
      }),
    ).subscribe();

    this.#wasInitialized = true;
  }

  public ngAfterViewInit(): void {
    this.calculateItemsSize();
    setTimeout(() => {
      this.checkCurrentIfIsPending();
      this.onViewportScroll();
      this.#viewWasInitialized = true;
    });
  }

  private recalculateItemSizesIfInitialied(): void {
    this.#viewWasInitialized && setTimeout(() => {
      this.calculateItemsSize();
      this.onViewportScroll();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    let editModeOrItemsChanged = false;
    let initialItemChanged = 'initialItem' in changes && !!this.getMatchingItemByIdOrUrl({ id: this.initialItem?.id, url: this.initialItem?.url });

    if ('setRenderMode' in changes) {
      const renderModes: SvcMediaCarouselRenderMode[] = Object.keys(SvcMediaCarouselRenderMode).map((key) => SvcMediaCarouselRenderMode[key]);
      this.renderMode = renderModes.find((m) => m === this.renderMode) ?? SvcMediaCarouselRenderMode.LIST;
      this.navigatorsOutside = this.renderModeIsList;
      if (!('fitContent' in changes)) {
        this.fitContent = this.renderModeIsList ? 'contain' : 'cover';
      }
    }

    if ('setEditMode' in changes) {
      this.setCurrentIsPending = true;
      editModeOrItemsChanged = true;
    }

    if (!initialItemChanged && this.#wasInitialized) {
      const canShowEmptyViewChanged = editModeOrItemsChanged
        ? ((!((changes.onSetItems?.previousValue ?? this.items ?? []).length) || (changes.setEditMode?.previousValue ?? this.editMode)) !== this.canShowEmptyView)
        : false;
      this.checkCurrentIfIsPending({
        fromEmptyViewVisibilityChanged: canShowEmptyViewChanged,
        fromItemsChanged: 'onSetItems' in changes,
      });
    }
  }

  protected onViewportScroll() {
    const scrollLeft = this.viewportEl?.scrollLeft ?? 0;
    const offsetWidth = this.viewportEl?.offsetWidth ?? 0;
    const scrollWidth = this.viewportEl?.scrollWidth ?? 0;
    this.viewportRemainingToStart = scrollLeft;
    this.viewportRemainingToEnd = Math.max(scrollWidth - (offsetWidth + scrollLeft), 0);
  }

  private checkCurrentIfIsPending(options?: {
    fromEmptyViewVisibilityChanged?: boolean,
    fromItemsChanged?: boolean,
  }) {
    if (!this.setCurrentIsPending) return;
    this.setCurrentIsPending = false;
    const fromEmptyViewVisibilityChanged = options?.fromEmptyViewVisibilityChanged ?? false;
    const fromItemsChanged = options?.fromItemsChanged ?? false;
    const currentLeft = this.viewportEl.scrollLeft;
    let currentToChange: SvcMediaCarouselInternalItem;
    let left = currentLeft;

    if (!this.#viewWasInitialized && this.initialItem && this.getMatchingItemByIdOrUrl({ id: this.initialItem.id, url: this.initialItem.url })) {
      this.setCurrent(this.initialItem, { emitEvent: true, animate: false });
      return;
    }
    if (fromItemsChanged && this.current) {
      const newCurrent = this.getMatchingItemByIdOrUrl({ id: this.current.id, url: this.current.url });
      if (newCurrent !== this.current) {
        this.setCurrent(newCurrent, { animate: false });
        return;
      }
    }

    try {
      if (fromEmptyViewVisibilityChanged) {
        if (this.renderModeIsList && (currentLeft > 0 || this.canShowEmptyView)) {
          currentToChange = null;
          left = (this.canShowEmptyView && this.goToEmptyViewWhenEditing) ? 0 : currentLeft + ((this.itemWidth + GAP_BETWEEN_ITEMS_PX) * ((this.canShowEmptyView) ? 1 : -1));
        }
        else if (!this.renderModeIsList) {
          currentToChange = this.current ?? (this.items.length ? this.items[0] : null);
          left = (this.current && (!this.canShowEmptyView || !this.goToEmptyViewWhenEditing)) ? ((this.currentIndex + (this.canShowEmptyView ? 1 : 0)) * this.viewportEl.offsetWidth) : 0;
        }
        return;
      }
      if (this.current && (!this.items.length || !this.getMatchingItemByIdOrUrl({ id: this.current.id, url: this.current.url }))) {
        currentToChange = null;
        left = 0;
      }
      if (!this.renderModeIsList && !this.current && this.items.length) {
        currentToChange = this.canShowEmptyView ? null : this.items[0];
        left = 0;
      }
    }
    finally {
      setTimeout(() => this.viewportEl?.scrollTo({ behavior: 'instant' as any, left }));
      this.setCurrentInternal(currentToChange);
    }
  }

  private checkCurretItemPosition() {
    if (!this.renderModeIsList) {
      this.setCurrent(this.current);
    }
  }

  public refresh() {
    this.onSetItems = [...this.items];
    const { hasChanged } = this.calculateItemsSize();
    setTimeout(() => {
      if (hasChanged || !this.renderModeIsList) {
        this.checkCurretItemPosition();
      }
      this.checkCurrentIfIsPending();
    });
  }

  public goToPrevious(event?: Event): void {
    event?.stopPropagation();
    if (this.renderModeIsList ? this.viewportRemainingToStart > 0 : (this.currentIndex > 0 || (this.currentIndex === 0 && this.canShowEmptyView))) {
      let left = this.viewportEl.scrollLeft - (this.itemWidth ?? 0) - GAP_BETWEEN_ITEMS_PX;
      if (!this.renderModeIsList) {
        this.setCurrentInternal(this.currentIndex > 0 ? this.items[this.currentIndex - 1] : null);
        left = this.current ? ((this.currentIndex + (this.canShowEmptyView ? 1 : 0)) * this.viewportEl.offsetWidth) : 0;
      }
      this.viewportEl.scrollTo({ behavior: 'smooth', left });
    }
  }

  public goToNext(event?: Event): void {
    event?.stopPropagation();
    if (this.renderModeIsList ? this.viewportRemainingToEnd > 0 : ((!this.current && this.items.length) || (this.current && this.currentIndex < (this.items.length - 1)))) {
      let left = this.viewportEl.scrollLeft + (this.itemWidth ?? 0) + GAP_BETWEEN_ITEMS_PX;
      if (!this.renderModeIsList) {
        this.setCurrentInternal(this.current ? this.items[this.currentIndex + 1] : this.items[0]);
        left = (this.currentIndex + (this.canShowEmptyView ? 1 : 0)) * this.viewportEl.offsetWidth;
      }
      this.viewportEl.scrollTo({ behavior: 'smooth', left });
    }
  }

  public setCurrent(item: SvcMediaCarouselItem, options?: { emitEvent?: boolean, animate?: boolean }): boolean {
    const preventEmit = !(options?.emitEvent ?? false);
    if (!item && this.canShowEmptyView) {
      this.setCurrentInternal(null, { preventEmit });
      this.viewportEl.scrollTo({
        behavior: (options?.animate ?? true) && this.renderModeIsList ? 'smooth' : 'instant' as any,
        left: 0
      });
      return true;
    }
    else {
      const internalItem = this.getMatchingItemByIdOrUrl({ id: item.id, url: item.url });
      const index = this.items.indexOf(internalItem);
      if (index >= 0) {
        const itemWidth = this.itemWidth ?? this.viewportEl?.offsetWidth;
        let left = (((itemWidth + GAP_BETWEEN_ITEMS_PX) * (index + (this.canShowEmptyView ? 1 : 0))) - GAP_BETWEEN_ITEMS_PX);
        if (this.renderModeIsList) {
          this.setCurrentInternal(null, { preventEmit });
        }
        else {
          this.setCurrentInternal(this.items[index], { preventEmit });
          left = (this.currentIndex + (this.canShowEmptyView ? 1 : 0)) * this.viewportEl.offsetWidth;
          if (preventEmit) this.needToTickItems$.next();
        }
        this.viewportEl.scrollTo({
          behavior: (options?.animate ?? true) && this.renderModeIsList ? 'smooth' : 'instant' as any,
          left: left
        });
        return true;
      }
    }
    return false;
  }

  public goToEmptyView(): void {
    if (!this.canShowEmptyView) return;
    this.setCurrent(null);
  }

  public getCurrent(): SvcMediaCarouselItem {
    return this.current;
  }

  public getMatchingItemByIdOrUrl<T extends SvcMediaCarouselItem>(values: { id?: number | string, url?: string }): T {
    return <T>this.items.find((item) => {
      return ('id' in values && item.id != null && item.id === values.id) ||
        ('url' in values && item.url && item.url === values.url);
    });
  }

  protected emitAddItem(): void {
    if (this.items.length && (!this.editMode || (this.maxItems > 0 && this.items.length >= this.maxItems))) return;
    this.onAddTriggered.emit({
      onAdded: this.addItem.bind(this),
    });
  }

  protected emitDeleteItem(item: SvcMediaCarouselInternalItem) {
    this.onDeleteTriggered.emit({
      item,
      onDeleted: () => this.deleteItem(item),
    });
  }

  public addItem(values: { item: SvcMediaCarouselItem, atBeginning?: boolean, beCurrent?: boolean }): SvcMediaCarouselItem[] {
    let items = this.items;
    if (this.maxItems > 0 && this.items.length === this.maxItems) {
      environment.isDEVorQA && console.warn(`WARN: The svc-media-carousel reached max items (${this.maxItems})`);
    }
    if (!values.item) return items;
    try {
      let newItem = this.getMatchingItemByIdOrUrl({ url: values.item.url, id: values.item.id });
      const newItemExistingIndex = items.indexOf(newItem);
      const atBeginning = values.atBeginning ?? false;
      const beCurrent = values.beCurrent ?? (this.renderModeIsList ? !atBeginning : true);
      if (newItem && environment.isDEVorQA) {
        environment.isDEVorQA && console.warn(`WARN: The [item] provided already exists in item list! ${JSON.stringify(values.item)}`);
        return;
      }
      if (newItem && (!atBeginning || newItemExistingIndex === 0)) {
        items[newItemExistingIndex] = newItem;
      }
      else {
        if (newItem && atBeginning) {
          items = items.filter((_, i) => i !== newItemExistingIndex);
        }
        if (!newItem) {
          newItem = values.item;
        }
        items = atBeginning ? [newItem, ...items] : [...items, newItem];
      }
      this.items = items;
      setTimeout(() => {
        this.calculateItemsSize();
        if (beCurrent) {
          setTimeout(() => this.setCurrent(newItem, { animate: false }));
        }
        setTimeout(() => this.onViewportScroll());
      });
      return;
    }
    finally {
      setTimeout(() => this.needToTickItems$.next());
      return items;
    }
  }

  public deleteItem(item: SvcMediaCarouselItem): SvcMediaCarouselItem[] {
    let items = this.items;
    try {
      item = item && this.getMatchingItemByIdOrUrl({ id: item.id, url: item.url });
      if (!item) {
        environment.isDEVorQA && console.warn('WARN: The [item] provided doesn\'t exist in item list.');
        return;
      }
      else {
        const itemIndex = items.indexOf(item);
        items = items.filter((i) => i !== item);
        this.items = items;
        if (!this.renderModeIsList) {
          if (item === this.current) {
            if (items.length) {
              this.setCurrent(items[itemIndex - (itemIndex < items.length ? 0 : 1)]);
            }
            else {
              this.setCurrent(null);
            }
          }
          else {
            this.setCurrent(this.current);
          }
        }
        else {
          setTimeout(() => this.onViewportScroll());
        }
        setTimeout(() => this.calculateItemsSize());
      }
    }
    finally {
      this.needToTickItems$.next();
      return items;
    }
  }

  public openItem(item: SvcMediaCarouselItem): void {
    if (this.canClickToExpandImage) {
      this.#svcDialogService.openImage(this.items.map((i) => ({
        url: i.url,
        type: i.type,
        current: i === item,
      })));
    }
  }

  private setCurrentInternal(current: SvcMediaCarouselInternalItem, options?: { preventEmit?: boolean }) {
    if (current === this.current) return;
    this.current = current;
    if (!this.renderModeIsList && !(options?.preventEmit ?? false)) {
      this.onCurrentItemChanged.emit(this.current);
    }
  }

  private calculateItemsSize(): { hasChanged: boolean } {
    const setItemWidth = (width: number) => {
      const hasChanged = width == null ? (this.itemWidth !== width) : (this.itemWidth !== width);
      this.itemWidth = width;
      return { hasChanged };
    };
    if (this.renderModeIsList && this.element) {
      const container = this.viewportEl ?? this.element.children[0] as HTMLElement;
      const containerWidth = container.offsetWidth - ((this.editMode && !this.items.length) ? 64 : 0);
      const pieceWidthNeedsToBeShown = {
        min: Math.round(containerWidth * CONTAINER_PECENT_RANGE_TO_SHOW_ITEM.min),
        max: Math.round(containerWidth * CONTAINER_PECENT_RANGE_TO_SHOW_ITEM.max),
        contains: (value: number) => value >= pieceWidthNeedsToBeShown.min && value <= pieceWidthNeedsToBeShown.max,
      };
      let widthFromRatio = Math.round(this.itemHeight * RATIO_FOR_WIDTH);

      if ((widthFromRatio + 8) >= containerWidth) {
        return setItemWidth(containerWidth - pieceWidthNeedsToBeShown.max - 8);
      }

      let remainingWidth = containerWidth % (widthFromRatio + 8);
      const isInPieceRange = (value: number) => pieceWidthNeedsToBeShown.contains(value);
      const isLessThanMinPieceWidth = (value: number) => value < pieceWidthNeedsToBeShown.min;
      while (!isInPieceRange(remainingWidth)) {
        if (isLessThanMinPieceWidth(remainingWidth)) {
          widthFromRatio--;
        }
        else {
          widthFromRatio++;
        }
        remainingWidth = containerWidth % (widthFromRatio + 8);
      }

      return setItemWidth(widthFromRatio);
    }
    else {
      return setItemWidth(null);
    }
  }

  public tryPlayCurrentItemVideo(): void {
    if (this.current?.type !== 'video') return;

    const itemsContainer = this.element.children[0];
    const currentItemElmentIndex = this.currentIndex + (this.canShowEmptyView ? 1 : 0);
    const itemElement = itemsContainer.children[currentItemElmentIndex];
    const video = itemElement.querySelector('video') as HTMLVideoElement;
    video?.play();
  }

  public ngOnDestroy(): void {
    this.#destroy.next();
    this.#destroy.complete();
  }
}
