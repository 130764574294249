import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { BosHttpClient } from './bos-http-client';
import { CreateBehaviorRequest, GetBehaviorListResponse, BosBehaviorCombo, CreateBehaviorResponse } from '../interfaces/svc-bos-behavior.interface';

@Injectable({
  providedIn: 'root'
})

export class SvcBosBehaviorService {

  constructor(
    private _http: BosHttpClient,
  ) { }

  private _getFormDataBehavior(behavior: CreateBehaviorRequest): FormData {
    const formData = new FormData();

    Object.keys(behavior)?.forEach((key: string) => {
      if (behavior?.[key] || behavior?.[key] === 0)
        formData.append(key, key === 'file' ?  behavior.file : behavior?.[key]?.toString())
    });

    formData.append('isActive', behavior.isActive.toString());
    formData.append('siteId', this._http.siteId.toString());

    return formData;
  }

  public createBehavior(behavior: CreateBehaviorRequest): Observable<CreateBehaviorResponse> {
    return this._http.post('/Behavior', this._getFormDataBehavior(behavior));
  }

  public updateBehavior(behavior: CreateBehaviorRequest): Observable<CreateBehaviorResponse> {
    return this._http.put('/Behavior', this._getFormDataBehavior(behavior));
  }

  public deleteBehavior(behaviorId: number): Observable<boolean> {
    return this._http.delete(`/Behavior`, {
      params: {
        behaviorId,
        siteId: this._http.siteId
      }
    });
  }

  public getBehaviorById(behaviorId: number): Observable<BosBehaviorCombo> {
    return this._http.get('/Behavior', {
      params: {
        siteId: this._http.siteId,
        behaviorId
      }
    });
  }

  public getBehaviorList(showPhoto = false, typeId?: number, isActive?: boolean): Observable<GetBehaviorListResponse> {
    let params = new HttpParams()
      .append('showPhoto', showPhoto)
      .append('siteId', this._http.siteId);

    if (typeId)
      params = params.append('typeId', typeId);

    if (isActive || isActive === false)
      params = params.append('isActive', isActive);

    return this._http.get('/Behavior/List', {
      params
    });
  }

  uploadBehaviorPhoto(behaviorId: number, file: Blob): Observable<void> {
    const formData = new FormData();

    formData.append('behaviorId', behaviorId.toString());
    formData.append('file', file);
    formData.append('siteId', this._http.siteId.toString());

    return this._http.post<void>('/BehaviorPhoto', formData);
  }

  deleteBehaviorPhoto(behaviorId: number): Observable<void> {
    return this._http.delete<void>(`/BehaviorPhoto`, {
      params: {
        behaviorId,
        siteId: this._http.siteId
      }
    });
  }

  public getBehaviorBySiteId(): Observable<BosBehaviorCombo[]> {
    return this._http.get('/Behavior/BySiteId', {
      params: {
        siteId: this._http.siteId
      }
    });
  }
}
