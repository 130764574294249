import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input, OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { SitesService, UserService } from "projects/lib-shared-core/src/public-api";
import { debounceTime, finalize, forkJoin, map, Subject, Subscription, takeUntil, tap } from "rxjs";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";
import { ContentChange, QuillEditorComponent } from "ngx-quill/lib/quill-editor.component";
import { SvcControl } from 'projects/lib-shared-component/src/lib/svc-controls/svc-control';
import { PostService } from "../../services/post.service";
import Block from 'quill/blots/block';
import { Mention, MentionBlot } from 'quill-mention';
import Quill, { Parchment } from 'quill';
import _ from 'lodash';
import { Delta } from 'quill/core';
import { Post} from '../../models/post';
import { environment} from 'projects/environments/environment';
import {
	generateModuleMenuURL,
	openAspRegistryModalByAppId,
} from 'projects/lib-shared-common/src/lib/functions/external-url-redirect';
import { SvcDialogService } from 'projects/lib-shared-component/src/public-api';

Quill.debug('error');
Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });
Block.tagName = 'DIV';
Quill.register(Block, true);

const BlockEmbed = Quill.import('blots/block/embed') as typeof Parchment.EmbedBlot;
class SeeMoreButton extends BlockEmbed {
  static create(value) {
    const node = super.create(value) as HTMLElement;
    node.innerText = value;
    return node;
  }
}
SeeMoreButton.blotName = 'seeMoreEmbed';
SeeMoreButton.tagName = 'span';
SeeMoreButton.className = 'mention-see-more';
SeeMoreButton.scope = 7;
Quill.register(SeeMoreButton);

class RegisterLinkButton extends BlockEmbed {
  static create(value: any) {
    const node = super.create() as HTMLElement;
    node.innerText = value.text;
    node.addEventListener('click', function(e) {
      e.stopImmediatePropagation();
      if (typeof value.open === 'function')
        value.open();
    });
    return node;
  }
}
RegisterLinkButton.blotName = 'registerLinkEmbed';
RegisterLinkButton.tagName = 'div';
RegisterLinkButton.className = 'mention-register-link';
Quill.register(RegisterLinkButton);

const LINE_CLAMP_MAX_LENGTH = 300;

@Component({
  selector: 'svc-post-mention',
  templateUrl: './svc-post-mention.component.html',
  styleUrls: ['./svc-post-mention.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SvcPostMentionComponent),
    multi: true
  }]
})
export class SvcPostMentionComponent extends SvcControl implements OnDestroy {

  @Input() readOnly: boolean;
  @Input() placeholder: string;
  @Input() cursorForce: boolean;
  @Input() lineClamp: boolean;
  @Input() inputId: string;
  @Input() post: Post;

  @ViewChild('editor') editor: QuillEditorComponent;
  @Output() selectTerm = new EventEmitter<any>();

  public mentions: any[];

  private _mentionSource$ = new Subject<{
    searchTerm: string,
    renderList: (list: any[], term) => void,
    mentionChar: string,
  }>();
  private _unsubscribe$ = new Subject<void>();
  private _modalOpen = true;
  private _mentionSubscription: Subscription;
  private _mentionIsLoading = false;
  private _isApplyingLineClamp = false;

  protected modules = {
    toolbar: false,
    clipboard: { matchVisual: false },
    mention: {
      minChars: 0,
      maxChars: 31,
      dataAttributes: ['id', 'value', 'denotationChar'],
      positioningStrategy: 'fixed',
      defaultMenuOrientation: 'bottom',
      allowedChars: /(^[A-zÀ-ÿ0-9]+)/,
      mentionDenotationChars: ["@", "#"],
      isolateCharacter: true,
      mentionListClass: 'mention-editor-list',
      onSelect: (item: any, insertItem: any) => {
        let mention = this.mentions.find(m => m.id && m.id.toString() === item.id.toString())
        if (mention) {
          mention.denotationChar = item.denotationChar;
          this.selectTerm.emit(mention);
          insertItem(mention)
        }
      },
      renderLoading: () => {
        return this.transloco.translate('Digite ao menos 3 caracteres');
      },
      source: async (searchTerm: string, renderList: any, mentionChar: string) => {
        this._mentionSource$.next({ searchTerm, renderList, mentionChar });
      }
    }
  }

  constructor(
    private _userService: UserService,
    private _postService: PostService,
    private _sitesService: SitesService,
    private _injector: Injector,
    private transloco: TranslocoService,
    private _dialogService: SvcDialogService,
  ) {
    super(_injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._listenMentionSourceChanges();
  }

  ngOnDestroy(): void {
    this._modalOpen = false;
    const containerToRemove = document.querySelector('.ql-mention-list-container');
    if (containerToRemove) containerToRemove.remove();
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  private _listenMentionSourceChanges() {
    this._mentionSource$.pipe(
      debounceTime(500),
      takeUntil(this._unsubscribe$),
      tap((options) => {
        const { searchTerm, renderList, mentionChar } = options;
        this._mentionSubscription?.unsubscribe();

        if (searchTerm.length < 3) {
          this._mentionIsLoading = false;
          this._setIsQuillMentionLoading();
          return;
        }

        this._mentionIsLoading = true;
        this._setIsQuillMentionLoading();
        if (mentionChar === "@") {
          this._mentionSubscription = this.mentionUserAndTeam(searchTerm).pipe(
            tap(results => {
              const mentions = [];
              const teamUsersGrouped = _.groupBy([
                ...results.usersResult.users as any,
                ...results.teamsResult.teams as any,
              ], 'site');
              for (const site of Object.keys(teamUsersGrouped).sort((a) => a === 'Multi-Tenance' ? 1 : -1)) {
                const list = teamUsersGrouped[site];
                mentions.push({
                  value: this.transloco.translate(site == 'Multi-Tenance' ? 'Global' : 'Planta'),
                  disabled: true,
                  target: 'level-1'
                });
                const grouped = _.groupBy(list, 'type');
                for (const type in grouped) {
                  const list = grouped[type];
                  mentions.push({
                    value: this.transloco.translate(type == 'user' ? 'Usuários' : 'Times'),
                    disabled: true,
                    target: 'level-2'
                  });
                  list.forEach((d) => mentions.push({
                    id: d.userId || d.teamId,
                    value: d.name,
                    type: type == 'user' ? 1 : 2,
                  }));
                }
              }
              this.mentions = mentions;
              renderList(this.mentions, searchTerm);
            }),
            finalize(() => this._mentionIsLoading = false)
          ).subscribe()
        } else {
          this._mentionSubscription = this._postService.mentionHashtag(searchTerm).pipe(
            tap(list => {
              const mentions = [];
              const hashtagsGrouped = _.groupBy(list.hashtags, 'isGlobal');
              for (const isGlobal in hashtagsGrouped) {
                const list = hashtagsGrouped[isGlobal];
                mentions.push({
                  value: this.transloco.translate(isGlobal == 'true' ? 'Global' : 'Planta'),
                  disabled: true,
                  target: 'level-1'
                });
                list.forEach((h) => mentions.push({
                  id: h.hashtagId,
                  value: h.name.replace('#', ''),
                  siteId: h.siteId
                }));
              }
              this.mentions = mentions;
              renderList(this.mentions, searchTerm);
            }),
            finalize(() => this._mentionIsLoading = false)
          ).subscribe()
        }
      }),
    ).subscribe();
  }

  private _setIsQuillMentionLoading() {
    const mentionLoading = document.querySelector('.ql-mention-list-container .ql-mention-loading');
    if (mentionLoading) {
      if (this._mentionIsLoading) {
        mentionLoading.innerHTML = this.transloco.translate('Pesquisando...');
      }
      else {
        mentionLoading.innerHTML = this.transloco.translate('Digite ao menos 3 caracteres');
      }
    }
  }

  get allMentions(): {
    id: any;
    value: string;
    denotationChar: string;
    isGlobal?: boolean;
    type?: string;
    siteId?: string;
  }[] {
    return this.deltas.filter(d => d.insert?.hasOwnProperty('mention'))
      .map(d => d.insert?.['mention'])
  }

  get text() {
    return this.editor.quillEditor.getText();
  }

  get html() {
    return this.editor.quillEditor.root.innerHTML;
  }
  set html(html) {
    this.editor.quillEditor.root.innerHTML = html;
  }

  get deltas(): Delta {
    return this.editor.quillEditor.getContents();
  }

  set deltas(deltas: Delta) {
    this.editor.quillEditor.setContents(deltas, 'silent');
  }

  protected filterValue(value: string) {
    return value?.replace(/&nbsp;/g, ' ').trim();
  }

  setDisabledState(isDisabled: boolean) {
    super.setDisabledState(isDisabled);
  }

  contentChanged(event: ContentChange) {
    if (this.readOnly && this.lineClamp && !this._isApplyingLineClamp) {
      const text: string = event.editor.getText() ?? '';
      const seeMoreText = this.transloco.translate('Ver mais');
      let length = LINE_CLAMP_MAX_LENGTH;
      if (event.editor.getLength() > length && (!text.endsWith('...') && !text.endsWith('...\n') && !text.endsWith(seeMoreText) && !text.endsWith(`${seeMoreText}\n`))) {
        this._isApplyingLineClamp = true;
        length++;
        while((length - 1) < text.length && /[A-zÀ-ÿ0-9]/g.test(text[length - 1])) {
          length++;
        }
        event.editor.deleteText(length, event.editor.getLength());
        let currentOpsContents = (event.editor.getContents()?.ops ?? []) as any[];
        currentOpsContents = currentOpsContents.map((c, i) => ({
          ...c,
          insert: i === (currentOpsContents.length - 1) ? c.insert.replace(/\n$/g, '') : c.insert,
        }));
        event.editor.setContents(<Delta>{
          ops: [
            ...currentOpsContents.filter((c, i) => {
              return i === (currentOpsContents.length - 1) && c.insert === '\n' ? false : true;
            }),
            { insert: '...' },
            { insert: '\n' },
          ],
        });
        event.editor.insertEmbed(event.editor.getLength() - 1, 'seeMoreEmbed', seeMoreText);
        this._isApplyingLineClamp = false;
      }
    }
    if (this.post.applicationRegistry && !event.content.ops[0].insert.hasOwnProperty('registerLinkEmbed')) {
      const appId = this.post.applicationRegistry.applicationId.toUpperCase() === '6F0234AA-49CE-4FFB-B1A9-AC8F1F507875'
        ? this.post.applicationRegistry.applicationRegistryAdditionalReferenceId
        : this.post.applicationRegistry.applicationRegistryReferenceId;
      const text = appId ? `-${appId}` : '';
      event.editor.insertEmbed(0, 'registerLinkEmbed', {
        text: `${this.transloco.translate(this.post.applicationRegistry.applicationName)}${text}`,
        open: () => {
          this.openRegistry();
        },
      });
    }
  }

  private mentionUserAndTeam(name: string) {
    return forkJoin({
      usersResult: this._userService.getUsersInfo({
        name: name,
        active: true,
        pageIndex: 1,
        pageSize: 99
      }),
      teamsResult: this._userService.getTeamsInfo({
        name: name,
        active: true,
        pageIndex: 1,
        pageSize: 99
      })
    }).pipe(
      map((response) => {
        const currentSite = this._sitesService.currentSite;
        return {
          teamsResult: {
            page: response.teamsResult.page,
            teams: response.teamsResult.teams.map(t => ({
              type: 'team',
              teamId: t.teamId,
              name: t.name,
              siteId: t.siteId,
              site: t.siteName !== currentSite.siteName ? 'Multi-Tenance' : t.siteName
            })),
          },
          usersResult: {
            users: response.usersResult.users.map((u) => ({
              type: 'user',
              ...u,
              site: u.site !== currentSite.siteName ? 'Multi-Tenance' : u.site
            })),
          },
        };
      }),
    );
  }

  private openRegistry() {
    if (!this.post.applicationRegistry.applicationId || !this.post.applicationRegistry.applicationRegistryReferenceId)
      return;

    if (this.post.applicationRegistry.applicationId.toUpperCase() === '6F0234AA-49CE-4FFB-B1A9-AC8F1F507875' && !this.post.applicationRegistry.applicationRegistryAdditionalReferenceId)
      return;

    openAspRegistryModalByAppId(
      this.post.applicationRegistry.applicationRegistryReferenceId,
      this.post.applicationRegistry.applicationId, {
        ...(this._userService.user.lastSiteId !== this.post.site.siteId
          ? { siteId: this.post.site.siteId.toString() }
          : {}
        ),
        ...(this.post.applicationRegistry.applicationId.toUpperCase() === '6F0234AA-49CE-4FFB-B1A9-AC8F1F507875'
          ? { lupIdVersion: this.post.applicationRegistry.applicationRegistryAdditionalReferenceId.toString() }
          : {}
        )
      });
  }
}
