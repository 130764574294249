export enum SvcModalBosType {
  add,
  view,
  edit
}

export enum SvcModalBosUserRole {
  Administrator = '967d8f5e-cb1e-4972-9e3a-56386a195bf7',
  Analyst = '17d64991-f590-4074-826f-f97124df6e03'
}

export enum SvcBosCustomFieldsFeatureName {
  BosType = 'bos-type'
}

export enum SvcBosParameters {
  ShowExportAction = 'WORK_COUNCIL',
  ShowVoiceToText = 'SPEECH_TO_TEST'
}
