import { Injectable } from '@angular/core';
import { ActionPlanIndicator } from './models/actionplan-indicator';
import {CommonCounter, CommonIndicator} from 'projects/lib-shared-model/src/public-api';
import { Observable, Subject, catchError, delay, map, of, tap, throwError } from 'rxjs';
import { ActionplanAnalyticsDrilldown, ActionPlanCategorizedDrillDown } from './models/actionplan-drilldown';
import { ModuleHttpClient } from './module-http-client';
import { ActionPlanCountersByIdsFromModule, ActionPlanCountersByStatus, ActionPlanCountersByStatusFilterParams } from './models/action-plan-counters.interface';
import { HttpParams } from '@angular/common/http';
import { ActionplanPriorityCombo, ActionplanTypeCombo } from './models/actionplan-combos.interface';
import { TasksCategory } from 'projects/lib-shared-core/src/lib/features/main-tasks/models/tasks-category';


@Injectable()
export class LibServiceActionplanAnalyticsService {

  private _indicator: Subject<ActionPlanIndicator> = new Subject<ActionPlanIndicator>();
  private _approvalsIndicator: Subject<CommonCounter[]> = new Subject<CommonCounter[]>();
  private _subordinatesIndicator: Subject<ActionPlanIndicator> = new Subject<ActionPlanIndicator>();
  private _categorizedDrillDown: Subject<ActionPlanCategorizedDrillDown[]> = new Subject<ActionPlanCategorizedDrillDown[]>();

  constructor(
    private _httpClient: ModuleHttpClient,
  ) {}

  public _indicator$: Observable<ActionPlanIndicator> = this._indicator.asObservable();
  public _subordinatesIndicator$: Observable<ActionPlanIndicator> = this._subordinatesIndicator.asObservable();
  public _categorizedDrillDown$: Observable<ActionPlanCategorizedDrillDown[]> = this._categorizedDrillDown.asObservable();
  public _approvalsIndicator$: Observable<CommonCounter[]> = this._approvalsIndicator.asObservable();

  getActionPlanIndicators(): Observable<ActionPlanIndicator> {
    this._indicator.next(null);
    return this._httpClient.get<CommonIndicator>('/Counters/actionplan').pipe(
      map((response: CommonIndicator) => new ActionPlanIndicator(response)),
      tap((indicator) => {
        this._indicator.next(indicator);
      }),
      catchError((error) => {
        this._indicator.next(new ActionPlanIndicator({ hasError: true }));
        return throwError(() => error);
      }),
    );
  }

  getActionPlanSubordinatesIndicators(): Observable<ActionPlanIndicator> {
    this._subordinatesIndicator.next(null);
    return this._httpClient.get<CommonIndicator>('/Counters/actionplan/subordinates').pipe(
      map((response: CommonIndicator) => new ActionPlanIndicator(response)),
      tap((indicator) => {
        this._subordinatesIndicator.next(indicator);
      }),
      catchError((error) => {
        this._subordinatesIndicator.next(new ActionPlanIndicator({ hasError: true }));
        return throwError(() => error);
      }),
    );
  }

  getActionPlanApprovalsIndicator(): Observable<CommonCounter[]> {
    return this._httpClient.get<CommonCounter[]>('/Counters/actionplan/approvals').pipe(
        tap((indicator) => {
          this._approvalsIndicator.next(indicator);
        })
    );
  }

  getActionPlanCategorizedDrillDown(type: TasksCategory): Observable<ActionPlanCategorizedDrillDown[]> {
    return this._httpClient.get<ActionPlanCategorizedDrillDown[]>(`/Analytics/actionplan/details/type?indicadorTypeDetail=${type}`).pipe(
      map((response: ActionPlanCategorizedDrillDown[]) => response.map(r => {
        return {
          ...r,
          indicatorItems: r.indicatorItems.map(i => ({
            ...i,
            owners: [i['owner']]
          }))
        };
      })),
      tap((indicator) => {
        this._categorizedDrillDown.next(indicator);
      })
    );
  }

  getActionPlanCategorizedDrillDownSubordinates(type: TasksCategory): Observable<ActionPlanCategorizedDrillDown[]> {
    return this._httpClient.get<ActionPlanCategorizedDrillDown[]>(`/Analytics/actionplan/subordinates/details/type?indicadorTypeDetail=${type}`).pipe(
        map((response: ActionPlanCategorizedDrillDown[]) => response.map(r => {
          return {
            ...r,
            indicatorItems: r.indicatorItems.map(i => ({
              ...i,
              owners: [i['owner']]
            }))
          };
        })),
      tap((indicator) => {
        this._categorizedDrillDown.next(indicator);
      })
    );
  }

  public getCountersByIdsFromModule(applicationId: string, ids: number[]): Observable<ActionPlanCountersByIdsFromModule[]> {
    let params = new HttpParams();

    params = params.append('ApplicationId', applicationId);

    ids?.forEach(id =>
      params = params.append('Ids', id)
    );

    return this._httpClient.get(`/Counters/actionplan/CountersByIdsFromModule`, {
      params
    });
  }

  private _getParams(filter: any, params: HttpParams, key: string): HttpParams {
    const value = filter?.[key];

    if (Array.isArray(value))
      value.forEach((val: any) =>
        params = params.append(`${key}`, val)
      );
    else
      params = params.append(key, value);

    return params;
  }

  private _getRequestParams(filter: any): HttpParams {
    let params = new HttpParams();
    Object.keys(filter)?.forEach((key: string) => {
      if (filter?.[key] || filter?.[key] === 0)
        params = this._getParams(filter, params, key);
    });

    return params;
  }

  public getCountersByStatusFromModule(applicationId: string, filter: ActionPlanCountersByStatusFilterParams): Observable<ActionPlanCountersByStatus> {
    let params = this._getRequestParams(filter);
    params = params.append('applicationId', applicationId);
    
    return this._httpClient.get('/Counters/actionplan/CountersByStatusFromModule', {
      params
    });
  }

  public getActionplanPriorityList(active?: boolean): Observable<ActionplanPriorityCombo[]> {
    let params = new HttpParams();
    if (active || active === false)
      params = params.append('active', active);

    return this._httpClient.get('/Priority/List', {
      params
    });
  }

  public getActionplanTypeList(active?: boolean): Observable<ActionplanTypeCombo[]> {
    let params = new HttpParams();
    if (active || active === false)
      params = params.append('active', active);

    return this._httpClient.get('/Type/List', {
      params
    });
  }

  public getAnalyticsActionplanDrilldown(applicationId: string, filter: ActionPlanCountersByStatusFilterParams): Observable<ActionplanAnalyticsDrilldown> {
    let params = this._getRequestParams(filter);
    params = params.append('applicationId', applicationId);

    return this._httpClient.get('/Analytics/drilldown', {
      params
    });
  }
}
