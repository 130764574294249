<div class="flex flex-col flex-auto space-y-1 p-2" *screenSizes="let size">
  <div
    #feedElement
    *ngFor="let feed of feeds; let i = index; let first = first; let last = last"
    [ngClass]="{ 'feed-bottom-border': feed !== feeds[feeds.length - 1] }"
    longPress
    (onLongPress)="feed.showOptions = true; menu?.openMenu()"
    (mouseenter)="feed.showOptions = true"
    (mouseleave)="!(menu?.menuOpen ?? false) ? feed.showOptions = false : null"
  >
    <div class="flex px-2 py-2 rounded-t-sm cursor-pointer select-none transition-colors bg-opacity-50" [ngClass]="{ 'pt-3': feed === first, 'pb-3': feed === last, '--:bg-primary-100 --:hover:bg-primary-200': feed === parent.selectedFeed, '--:bg-white --:hover:bg-primary-100': feed !== parent.selectedFeed }" (click)="loadFeed(feed)">
      <div class="flex-auto flex flex-col gap-2 p-0 duration-200 transition-all" [class.pr-2]="feed.showOptions || feed.isPinned">
        <div class="flex">
          <div class="flex-auto">
            <p class="text-base text-primary font-bold line-clamp-2" [template-tooltip]="size.isAboveSM ? tooltipTemplate : null" [tooltipPosition]="parent.expanded ? 'right' : 'left'">
              {{ feed.title | removeHtml }}
            </p>
            <p *ngIf="feed.subtitle" class="text-primary-300 text-xs font-bold line-clamp-1">
              {{ feed.subtitle | removeHtml }}
            </p>
            <ng-template #tooltipTemplate>
              <p [class.pb-2]="feed.subtitle">
                {{ feed.title | removeHtml }}
              </p>
              <p *ngIf="feed.subtitle" class="border-t border-white pt-2">
                {{ feed.subtitle | removeHtml }}
              </p>
            </ng-template>
          </div>
          <div *ngIf="feed.totalNoRead > 0 || feed.isMarkedUnRead" class="flex ite">
            <div *ngIf="feed.totalNoRead > 0" class="flex items-center justify-center bg-primary-400 text-on-primary text-xs rounded-full" [style.width.px]="30" [style.height.px]="30">
              {{ feed.totalNoRead > 99 ? '99+' : feed.totalNoRead }}
            </div>
            <div *ngIf="!feed.totalNoRead" class="bg-primary-400 text-on-primary rounded-full w-4 h-4"></div>
          </div>
        </div>
        <ng-container *ngIf="feed.commentContent">
          <div class="flex items-center gap-x-2">
            <svc-user-avatar [info]="{ name: feed.userName ?? '', picture: feed.userPictureFile ?? '', initialsColor: feed.initialColor ?? '#CCC', size: UserAvatarSize.Feed }"></svc-user-avatar>
            <div class="text-base font-normal line-clamp-2" [innerHTML]="feed.commentContent | htmlSanitizer"></div>
          </div>
          <div *ngIf="feed.hasAttachment" class="flex text-primary gap-x-2 mt-2">
            <mat-icon class="icon-size-6 text-current" svgIcon="heroicons_solid:paper-clip"></mat-icon>
            <div class="flex-auto text-sm">{{ 'Contém anexos' | transloco }}</div>
          </div>
        </ng-container>
        <ng-container *ngIf="!feed.commentContent && feed.hasAttachment">
          <div class="flex items-center gap-x-2">
            <svc-user-avatar [info]="{ name: feed.userName ?? '', picture: feed.userPictureFile ?? '', initialsColor: feed.initialColor ?? '#CCC', size: UserAvatarSize.Feed }"></svc-user-avatar>
            <div class="flex text-primary gap-x-2">
              <mat-icon class="icon-size-6 text-current" svgIcon="heroicons_solid:paper-clip"></mat-icon>
              <div class="flex-auto text-sm">{{ 'Contém anexos' | transloco }}</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="flex flex-col w-0 min-w-0 max-w-0 overflow-hidden transition-all duration-200 gap-1.5" [ngClass]="{ 'w-7 min-w-7 max-w-7': feed.showOptions || feed.isPinned }">
        <div *ngIf="feed.isPinned" class="flex text-default justify-center">
          <mat-icon class="text-current icon-size-6" svgIcon="svc_solid:pin"/>
        </div>
        <div class="flex-auto flex items-center">
          <button
            mat-icon-button
            #menu="matMenuTrigger"
            class="w-7 h-7 min-h-7 text-default transition-opacity duration-200 opacity-0"
            [class.opacity-100]="feed.showOptions"
            [matMenuTriggerFor]="feed.showOptions ? optionsMatMenu : null"
            (menuOpened)="onFeedMenuOpened(feed, feedElement)"
            (menuClosed)="onFeedMenuClosed()"
            (click)="$event.stopImmediatePropagation()"
          >
            <mat-icon class="text-current icon-size-6" svgIcon="fontawesome_solid:ellipsis-vertical"/>
          </button>
          <mat-menu #optionsMatMenu>
            <button mat-menu-item class="text-default" (click)="feedTogglePin(feed)">
              <mat-icon class="text-current icon-size-6" [svgIcon]="feed.isPinned ? 'svc_solid:unpin' : 'svc_solid:pin'"/>
              <span class="text-xl">
                {{ (feed.isPinned ? 'Desafixar' : 'Fixar') | transloco }}
              </span>
            </button>
            <button *ngIf="!feed.totalNoRead && !feed.isMarkedUnRead" mat-menu-item class="text-default" (click)="feedMarkAsUnread(feed)">
              <mat-icon class="text-current icon-size-6" svgIcon="mat_solid:visibility_off"/>
              <span class="text-xl">
                {{ 'Não lido' | transloco }}
              </span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
