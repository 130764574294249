<div class="svc-pivot-table">
  <svc-breadcrumb class="py-1 px-4 sm:py-2 sm:px-6">
    <svc-breadcrumb-item type="HOME">
      Home
    </svc-breadcrumb-item>

    <svc-breadcrumb-item *ngIf="moduleName" link="/">
      {{ moduleName | transloco }}
    </svc-breadcrumb-item>

    <svc-breadcrumb-item *ngIf="moduleName" link="/">
      {{ 'Analytics' | transloco }}
    </svc-breadcrumb-item>

    <svc-breadcrumb-item [active]="true">
      {{ 'Base de dados' | transloco }}
    </svc-breadcrumb-item>
  </svc-breadcrumb>

  <div class="w-full h-full py-3 px-4 sm:px-6">
    <svc-data-table
      #dataTable
      *screenSizes="let size"
      server-side
      [hoverable]="true"
      [items]="files"
      [dataLength]="totalFiles"
      [loading]="isLoading"
      (onGetData)="onGetData($event)"
    >
      <svc-data-header>
        <svc-data-col
          field="year"
          [no-sort]="false"
          [width]="size.isXS ? 'auto' : '100'"
          [class.hidden]="isLoading || ((files?.length || 0) > 0 && !hasCol.year)"
        >
          {{ 'Ano' | transloco }}
        </svc-data-col>

        <svc-data-col
          field="fileName"
          [no-sort]="false"
          [width]="size.isXS ? 'auto' : '200'"
          [class.hidden]="isLoading || !hasCol.filename"
        ></svc-data-col>

        <svc-data-col
          field="requestDateFormatted"
          [no-sort]="false"
        >
          {{ 'Data processamento' | transloco }}
        </svc-data-col>

        <svc-data-col
          class="pointer-events-none"
          [width]="size.isXS ? 'auto' : '132px'"
        >
          {{ 'Download' | transloco }}
        </svc-data-col>
      </svc-data-header>

      <svc-data-body>
        <svc-data-row *ngFor="let file of dataTable.items">

          <svc-data-cell [class.hidden]="isLoading || ((files?.length || 0) > 0 && !hasCol.year)">
            <p class="text-default text-base">
              {{ (file?.month > 9 ? file.month : '0' + file.month) + '/' + file?.year }}
            </p>
          </svc-data-cell>

          <svc-data-cell [class.hidden]="isLoading || !hasCol.filename">
            {{ file.fileName }}
          </svc-data-cell>

          <svc-data-cell>
            <p class="text-default text-base">
              {{ file?.requestDateFormatted | dateFormat }} - {{ file?.requestDateFormatted | timeFormat : 'hh:mm:ss' }}
            </p>
          </svc-data-cell>

          <svc-data-cell>
            <div class="w-full flex justify-end items-center">
              <button mat-icon-button color="primary" class="file-download w-9 h-9 min-w-9 min-h-9" (click)="downloadFile(file?.link)">
                <mat-icon class="icon-size-7 text-current" svgIcon="mat_solid:download_for_offline">
                </mat-icon>
              </button>
            </div>
          </svc-data-cell>
        </svc-data-row>

      </svc-data-body>
    </svc-data-table>

  </div>
</div>


