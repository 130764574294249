import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService, UserService } from 'projects/lib-shared-core/src/public-api';
import { CognitoUser, EnvironmentInfo, SsoItem } from 'projects/lib-shared-core/src/lib/auth/model/environment.info.model';
import { DomSanitizer } from '@angular/platform-browser';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { svcAnimations } from 'projects/lib-shared-component/src/public-api';
import { AuthStatus, UserStatusAuth } from 'projects/lib-shared-core/src/lib/auth/model/user-auth.model';
import { environment } from 'projects/environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { removeQueryParamFromLocation } from 'projects/lib-shared-common/src/public-api';

@Component({
  selector: 'auth-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations: svcAnimations,
  host: {
    '[class.block]': 'true',
    '[class.min-h-full]': 'true',
    '[class.overflow-auto]': 'true',
  }
})
export class AuthSignInComponent implements OnInit, AfterViewInit, OnDestroy {
  alert: { type: string; message: string, appearance: string, buttonText?: string, buttonClick?: VoidFunction } = {
    type: 'success',
    message: '',
    appearance: 'outline'
  };
  signInForm: UntypedFormGroup;
  userEnviromentSingInForm: UntypedFormGroup;
  passwordExpire: 'expired' | 'expiring' | null;

  privateEnvInfo: EnvironmentInfo;
  privateAlert = false;
  privateEnvUserInfoList: { text: string, value: string }[];
  privateHasEnviromentInfoByOriginPath = false;
  privateHasSSORedirecting = false;
  privateHasCurrentUser = false;
  privateCurrentUser: CognitoUser | null;
  isSmScreen = false;
  isEnvironmentInfoLoading = true;
  privateGlobalLogin: string =  '';
  currentAuthingUser: CognitoUser | null;
  loading: boolean;
  daysMax = 1;
  userStatus: UserStatusAuth;

  quickSignInInProcess = false;
  public solvaceLogo$ = this._authService.solvaceLogo$;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  get hasEnviromentInfoByOriginPath() {
    return this.privateHasEnviromentInfoByOriginPath;
  }

  get environmentInfo(): EnvironmentInfo {
    return this._authService.environmentInfo ?? null;
  }

  get environmentUserInfoList(): { text: string, value: string }[] {
    return this.privateEnvUserInfoList?.length
      ? this.privateEnvUserInfoList
      : null;
  }

  get backgroundImg() {
    return this._sanitizer.bypassSecurityTrustStyle(
      this._authService.getCoverImage()
    );
  }

  /**
   * Constructor
   */
  constructor(
    private _translocoService: TranslocoService,
    private _authService: AuthService,
    private _userService: UserService,
    private _formBuilder: UntypedFormBuilder,
    private _sanitizer: DomSanitizer,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }

  async ngAfterViewInit(): Promise<void> {
    this.privateHasEnviromentInfoByOriginPath = await lastValueFrom(this.requestEnvironmentInfo());
  }

  private requestEnvironmentInfo(urlEnv?: string): Observable<boolean> {
    this.isEnvironmentInfoLoading = true;
    this.privateEnvInfo = null;
    return this._authService.postEnvironmentInfo(urlEnv).pipe(
      switchMap((c) => {
        const hasEnvironmentInfo = new Subject<boolean>();
        this.privateEnvInfo = c;
        this._authService.applyCongnitoConfig(c);
  
        this.privateHasSSORedirecting = this._authService.ssoRedirecting;
        this._authService.getCurrentUser()
          .then((user) => {
            this.privateHasCurrentUser = !!user;
            this.privateCurrentUser = user;
            hasEnvironmentInfo.next(true);
          })
          .catch((e) => hasEnvironmentInfo.error(e))
          .finally(() => {
            this.isEnvironmentInfoLoading = false;
            this.checkPreFilledUser();
            hasEnvironmentInfo.complete();
          });
        return hasEnvironmentInfo;
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.onWindowResize();

    // Check if coming from global URL
    this.privateGlobalLogin = this._activatedRoute.snapshot.queryParamMap.get('selectedUser');

    // Create the form
    this.signInForm = this._formBuilder.group({
      user: [''],
      password: ['', Validators.required],
    });

    this.userEnviromentSingInForm = this._formBuilder.group({
      user: ['', [Validators.required]],
      environment: ['', [Validators.required]]
    });

    this.userEnviromentSingInForm.controls.environment.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      tap((env) => {
        this.requestEnvironmentInfo(env).subscribe();
      }),
    ).subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   */

  private checkPreFilledUser() {
    const hasPendingUser = this._activatedRoute.snapshot.queryParamMap.has('user');
    if (hasPendingUser) {
      const pendingUser = this._activatedRoute.snapshot.queryParamMap.get('user');
      if (!this.privateHasCurrentUser && pendingUser) {
        this.signInForm.get('user').setValue(pendingUser);
        setTimeout(() => {
          const passwordInput = document.getElementById('password-field') as HTMLInputElement;
          passwordInput?.focus();
        }, 500);
      }
      removeQueryParamFromLocation('user');
    }
  }

  private setEnviromentUserInfo() {
    if (this.userEnviromentSingInForm.controls['user'].invalid) {
      return;
    }
    this.privateAlert = false;
    this.userEnviromentSingInForm.disable();

    const user = this.userEnviromentSingInForm.value.user;

    this._authService
      .postEnvironmentListByUserName(user)
      .then((envList) => {
        if (envList) {
          this.privateEnvUserInfoList = envList.map(item => ({
            text: item.environmentName,
            value: item.environmentURL,
          }));
        } else {
          throw envList;
        }
      })
      .catch(() => {
        this.alert = {
          type: 'error',
          appearance: 'outline',
          message: this._translocoService.translate(
            'Usuário inválido'
          ),
        };
        this.privateAlert = true;
      })
      .finally(() => {
        this.userEnviromentSingInForm.enable();
      });
  }

  private signIn(form: UntypedFormGroup): void {
    this.passwordExpire = null;
    this.alert = {} as any;
    this._authService
      .signIn(form.value, this.environmentInfo.url)
      .then(async (userStatus) => {
        this.userStatus = userStatus;
        let expired = false;
        try {
          if (userStatus.user && !userStatus.user?.isFederated) {
            expired = await this.checkUserExpiredPassword(userStatus.user);
          }
        }
        finally {
          if (!expired) this.doSignInFlowCheck(userStatus);
        }
      })
      .catch((err) => {
        console.log('deu erro de login', err);
        this._authService.logSignInFailedAttempt(form.get('user').value, this.environmentInfo.url);
        this.raiseLoginError({ form, error: err });
      });
  }

  private signInCurrentUser() {
    this.passwordExpire = null;
    this._authService
      .signInCurrentUser(this.environmentInfo.url)
      .then(async (userStatus) => {
        this.userStatus = userStatus;
        let expired = false;
        try {
          if (userStatus.user && !userStatus.user?.isFederated) {
            expired = await this.checkUserExpiredPassword(userStatus.user);
          }
        }
        finally {
          if (!expired) this.doSignInFlowCheck(userStatus);
        }
      })
      .catch((error) => {
        this.privateCurrentUser = null;
        this.raiseLoginError({ error });
      });
  }

  private async doSignInFlowCheck(userStatus: UserStatusAuth) {
    switch (userStatus.status) {
      case AuthStatus.TemporaryPasswordUser:
        this._router.navigate(['reset-password']);
        break;

      case AuthStatus.NotFoundUser:
        this._router.navigate(['user-locked/new']);
        break;

      case AuthStatus.PermissionPendingUser:
        this._router.navigate(['user-locked/waiting']);
        break;

      case AuthStatus.PendingGDPRUser:
        this._router.navigate(['user-locked/gdpr'], {
          queryParamsHandling: 'preserve'
        });
        break;

      case AuthStatus.RejectedUser:
        this._router.navigate(['user-locked/rejected']);
        break;

      case AuthStatus.MandatoryFederatedUser:
        this._router.navigate(['user-locked/deprecated']);
        break;

      case AuthStatus.EmployeeUser:
      case AuthStatus.SupplierUser:
      case AuthStatus.ContractorUser:
      default: {
        try {
          const user = this._userService.user;
          if (user) {
            this._authService.doValidatedUserRedirecting(user, userStatus);
          }
          else {
            let user = await lastValueFrom(this._userService.get());
            this._authService.doValidatedUserRedirecting(user, userStatus);
          }
        }
        catch {
          this._authService.signOut().subscribe();
          this.showGenericLoginError();
        }
      }
    }
  }

  signInWithCurrentUser() {
    if (this.passwordExpire === 'expiring') {
      this.loading = true;
      this.doSignInFlowCheck(this.userStatus);
      return;
    }

    this.quickSignInInProcess = true;
    this.signInCurrentUser();
  }

  signInWithEnviromentInfo() {
    if (this.passwordExpire === 'expiring') {
      this.loading = true;
      this.passwordExpire = null;
      this.alert = {} as any;
      this.signInForm.disable();
      this.doSignInFlowCheck(this.userStatus);
      return;
    }

    this.signInForm.markAllAsTouched();
    if (this.signInForm.invalid || this.passwordExpire === 'expired') {
      return;
    }

    this.signInForm.disable();
    this.privateAlert = false;
    this.loading = true;

    this.signIn(this.signInForm);
  }

  signInEnviromentUserInfo() {
    this.privateAlert = false;
    if (this.environmentUserInfoList) {
      this.userEnviromentSingInForm.markAllAsTouched();
      if (this.userEnviromentSingInForm.invalid) {
        return;
      }

      this.userEnviromentSingInForm.disable();

      let queryParams: any = {...this._activatedRoute.snapshot.queryParams, selectedUser: this.userEnviromentSingInForm.controls.user.value };
      let queryParamSerialized = '';
      Object.keys(queryParams).forEach((key) => {
        if (key === 'user') return;
        queryParamSerialized += (queryParamSerialized ? '&' : '') + `${key}=${queryParams[key]}`;
      });

      window.location.replace(`${this.environmentInfo.url}/authentication/global-sign-in?${queryParamSerialized}`);
    } else {
      this.setEnviromentUserInfo();
    }
  }

  loginSSO(sso: SsoItem) {
    this.privateAlert = false;
    this._authService.ssoSignIn(sso);
  }

  private raiseLoginError(config?: { form?: UntypedFormGroup, error?: Error | any }) {
    config?.form?.enable();
    let error = config?.error ?? {};
    error = ('error' in error ? error.error : error) ?? {};

    const limitExceededType = 'LimitExceededException'.toLowerCase();
    const notAuthorizedType = 'NotAuthorizedException'.toLowerCase();
    const userLambdaValidationType = 'UserLambdaValidationException'.toLowerCase();
    const attemptMessage1 = 'Attempt limit exceeded, please try after some time.'.toLowerCase();
    const attemptMessage2 = 'Password attempts exceeded'.toLowerCase();
    const attemptMessage3 = 'envMaxFailedAttempts'.toLowerCase();
    const attemptMessage4 = 'repeated failed login attempts'.toLowerCase();
    const attemptMessage5 = 'PreAuthentication failed with error User is blocked due to repeated failed login attempts'.toLowerCase();
    const cantBeResetMessage = 'User password cannot be reset in the current state.'.toLowerCase();
    const errorCode = error?.code ?? error?.__type ?? error?.name ?? '';
    const errorMessage = error?.message ?? '';
    if (
      [limitExceededType, notAuthorizedType, userLambdaValidationType].some(m => errorCode.toLowerCase().includes(m)) &&
      [attemptMessage1, attemptMessage2, attemptMessage3, attemptMessage4, attemptMessage5, cantBeResetMessage].some(m => errorMessage.toLowerCase().includes(m))
    ) {
      this.alert = {
        type: 'error',
        appearance: 'outline',
        message: this._translocoService.translate('Várias tentativas de senha incorreta. Aguarde algumas horas antes de tentar recuperá-la.')
      };
    } else {
      this.alert = {
        type: 'error',
        appearance: 'outline',
        message: this._translocoService.translate('Usuário ou senha inválidos')
      };
    }

    this.privateAlert = true;
    this.loading = false;
  }

  private showGenericLoginError() {
    this.alert = { type: 'error', appearance: 'outline', message: this._translocoService.translate('Ocorreu um erro durante o processo de autenticação. Por favor tente novamente, se o erro persistir contate o administrador do sistema.') };
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.isSmScreen = window.innerWidth <= 1024;
  }

  onRefresh() {
    this._authService.signOut(true).subscribe(() => {
      if (environment.isLocalhost || environment.isDEV) {
        localStorage.clear();
      }
      
      if (!this._router.url.startsWith('/sign-in')) {
        this._router.navigate(['sign-in']);
      }
      else {
        this.privateCurrentUser = null;
        this.privateEnvUserInfoList = null;
        this.userEnviromentSingInForm.reset();
      }
    });
  }

  changeLoginUser() {
    localStorage.clear();
    this.privateCurrentUser = null;
    this.privateEnvUserInfoList = null;
    this.userEnviromentSingInForm.reset();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  private async checkUserExpiredPassword(user: CognitoUser) : Promise<boolean> {
    return this._authService.checkUserExpiredPassword()
      .then(result => {
        if (result?.status === 'expiring') {
          this.privateAlert = true;
          this.passwordExpire = 'expiring';
          this.alert = {
            type: 'info',
            appearance: 'fill',
            buttonText: this._translocoService.translate('Atualizar'),
            buttonClick: () => this._router.navigate(['/expired-password']),
            message: this._translocoService.translate(`Sua senha vai expirar em {0} dias, atualize no link ao lado ou clique em entrar para fazer o login.`).replace('{0}', result.days.toString())
          };
          this.currentAuthingUser = user;
          this._authService.removeExpiredToken();
          this.loading = false;
          return true;
        }
        if (result?.status === 'expired') {
          this.privateAlert = true;
          this.passwordExpire = 'expired';
          this.alert = {
            type: 'error',
            appearance: 'fill',
            buttonText: this._translocoService.translate('Atualizar'),
            buttonClick: () => this._router.navigate(['/expired-password']),
            message: this._translocoService.translate('Sua senha expirou, atualize agora para fazer o login')
          };
          this._authService.setExpiredToken();
          this.loading = false;
          return true;
        }
        this._authService.removeExpiredToken();
        return false;
      });
  }

}
