<div class="flex flex-col" *screenSizes="let size">
  <svc-breadcrumb class="px-6 py-2">
    <svc-breadcrumb-item type="HOME">{{ 'Home' }}</svc-breadcrumb-item>
    <svc-breadcrumb-item *ngIf="moduleName" link="/">{{ moduleName | transloco }}</svc-breadcrumb-item>
    <svc-breadcrumb-item [active]="true">{{ 'Permissões de acesso' | transloco }}</svc-breadcrumb-item>
  </svc-breadcrumb>

  <svc-access-permission-form class="mx-[19px] pt-[19px] mt-6  md:mx-6 sm:mx-4 sm:pt-2" (onAccessAdded)="loadPermissions()"></svc-access-permission-form>

  <div class="mt-[19px] sm:mt-6">
    <div class="flex gap-2 px-4 py-2 md:px-6 justify-between">
      <svc-search-field [initialValue]="''" [forceWidthFull]="size.isBelowMD"></svc-search-field>
 
      <div *ngIf="size.isAboveSM" class="flex justify-end">
        <button
          mat-button
          class="text-primary font-bold"
          [matMenuTriggerFor]="exportMatMenu"
          [disabled]="exporting"
        >
          <mat-icon *ngIf="!exporting" svgIcon="heroicons_solid:ellipsis-vertical"></mat-icon>
          <mat-progress-spinner *ngIf="exporting" [diameter]="24" mode="indeterminate"></mat-progress-spinner>
        </button>

        <mat-menu #exportMatMenu>
          <button mat-menu-item (click)="dt?.exportToExcel()" class="text-default">
            <mat-icon class="text-current icon-size-4" svgIcon="heroicons_solid:document-text"></mat-icon>
            <span class="text-base text-default">{{'Exportar XLS' | transloco}}</span>
          </button>

          <button mat-menu-item (click)="dt?.exportToPdf()" class="text-default">
            <mat-icon class="text-current icon-size-4" svgIcon="mat_solid:picture_as_pdf"></mat-icon>
            <span class="text-base text-default">{{'Exportar PDF' | transloco}}</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="flex flex-col flex-auto">
      <svc-data-table #dt
        clickable
        [items]="filteredUsers | async"
        [loading]="loadingPermissions"
        [dataLength]="(filteredUsers | async)?.length ?? 0"
        [minWidth]="roles?.length > 3 ? '600px' : null"
        exportFileName="Permissão de Acesso"
        exportTitle="Permissão de Acesso"
      >
        <svc-data-header>
          <svc-data-col [no-sort]="false" sort="asc" field="firstLastName">
            {{ 'Nome' | transloco }}
          </svc-data-col>
          <svc-data-col [no-sort]="false" field="email" *ngIf="size.isAboveSM">
            {{ 'Email' | transloco }}
          </svc-data-col>
          <svc-data-col [no-sort]="false" field="userName" *ngIf="size.isAboveSM">
            {{ 'Usuário' | transloco }}
          </svc-data-col>
          <svc-data-col [no-sort]="false" *ngFor="let role of roles" class="text-center justify-center" [field]="role.propName" [width]="size.isSM ? '140px' : '100px'" fieldTypeExport="checkbox">
            {{ role.roleName | transloco }}
          </svc-data-col>
        </svc-data-header>
        <svc-data-body>
          <svc-data-row *ngFor="let user of dt.items" (click)="onUserEdit(user)">
            <svc-data-cell>
              <div class="flex items-center gap-2">
                <svc-smart-user-avatar #userAvatar [size]="24" [ids]="user?.userId"></svc-smart-user-avatar>
                {{ user.firstLastName }}
              </div>
            </svc-data-cell>
            <svc-data-cell *ngIf="size.isAboveSM">{{ user.email }}</svc-data-cell>
            <svc-data-cell *ngIf="size.isAboveSM">{{ user.userName }}</svc-data-cell>
            <svc-data-cell *ngFor="let role of roles" class="items-center">
              <mat-checkbox [checked]="user[role.propName]" color="primary" [disabled]="true"></mat-checkbox>
            </svc-data-cell>
          </svc-data-row>
        </svc-data-body>
      </svc-data-table>
    </div>

  </div>
</div>
