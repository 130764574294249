import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { TranslocoService } from '@ngneat/transloco';
import {catchError, finalize, Observable, Subject, takeUntil, tap} from 'rxjs';

import { SvcModalBos, SvcModalBosCombos } from './shared/interfaces/svc-modal-bos.interface';
import { SvcBosCustomFieldsFeatureName, SvcModalBosType, SvcModalBosUserRole } from './shared/enums/svc-modal-bos.enum';
import { SvcBosCombosService } from './shared/services/svc-bos-combos.service';
import { SvcBosErrorService } from './shared/services/svc-bos-error.service';
import { ISvcChipOption, ISvcUserAvatar, SvcDialogService, SvcToastService, UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { User, UserEnvironment } from 'projects/lib-shared-core/src/lib/features/user/services/user.types';
import { UserService } from 'projects/lib-shared-core/src/lib/features/user/services/user.service';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { SvcModalBosTabBehaviorsComponent } from './shared/components/svc-modal-bos-tab-behaviors/svc-modal-bos-tab-behaviors.component';
import { SvcBosFeedbackService } from './shared/services/svc-bos-feedback.service';
import { SvcBosAddFeedback, SvcBosAddFeedbackResponse, SvcBosFeedbackRegister } from './shared/interfaces/svc-bos-feedback.interface';
import { SvcModalBosService } from './shared/services/svc-modal-bos.service';
import { SvcAccessPermissionService } from '../../admin/svc-access-permission/svc-access-permission.service';
import { StaticApplicationId, SvcGlobalDataPrefetching} from 'projects/lib-shared-core/src/public-api';
import { UserRoleItem } from '../../admin/svc-access-permission/models/user-role.model';
import { AuthService } from 'projects/lib-shared-core/src/lib/auth/auth.service';
import { SvcFormCustomFieldComponent } from '../../general/svc-form-custom-field/svc-form-custom-field.component';


@Component({
  selector: 'svc-modal-bos',
  templateUrl: './svc-modal-bos.component.html',
  styleUrls: ['./svc-modal-bos.component.scss']
})
export class SvcModalBosComponent implements OnInit {

  @AutoDestroy public destroy$ = new Subject<void>();

  @ViewChild('tabBehaviors') public tabBehaviors: SvcModalBosTabBehaviorsComponent;
  @ViewChild('customField') public customField: SvcFormCustomFieldComponent;

  public modalTypes = SvcModalBosType;
  public typeSelected: Partial<ISvcChipOption>;
  public type = new FormControl();
  public environment: UserEnvironment = this._userService.environment;
  public isLoading: boolean;
  public isAllCombosLoading = true;
  public formBos: FormGroup;
  public userAvatar: ISvcUserAvatar;
  public openPlans: number;
  public completePlans: number;
  public rolesCreatePermission: string[] = [SvcModalBosUserRole.Administrator, SvcModalBosUserRole.Analyst];
  public optionsCombos: SvcModalBosCombos = {};
  public maxDate: Date = new Date();
  public bos: SvcBosFeedbackRegister;
  public userHasPermissionToEdit: boolean;
  public customFieldsFeature = SvcBosCustomFieldsFeatureName;
  public loadedPermissionUser: boolean;
  public observerEnabled: boolean;
  public isLoadingObservers: boolean;
  public isShowTabActionPlanLoading: boolean;
  public showTabActionPlan: boolean;

  protected readonly SvcModalBosType = SvcModalBosType;

  constructor(
    public dialogRef: MatDialogRef<SvcModalBosComponent>,
    @Inject(MAT_DIALOG_DATA) public modalConfig: SvcModalBos,
    private _combosService: SvcBosCombosService,
    private _errorService: SvcBosErrorService,
    private _userService: UserService,
    private _fb: FormBuilder,
    private _translocoService: TranslocoService,
    private _datePipe: DatePipe,
    private _feedbackService: SvcBosFeedbackService,
    private _toastService: SvcToastService,
    private _modalBosService: SvcModalBosService,
    private _modalService: SvcDialogService,
    private _accessPermissionService: SvcAccessPermissionService,
    private _authService: AuthService,
    private _globalDataPrefetching: SvcGlobalDataPrefetching,
  ) { }

  public ngOnInit(): void {
    this._initFormBos();
    const user: User = this._userService.user;
    this._setUserAvatar(user?.firstLastName, user?.pictureFile, user?.initialsColor);
    if (this.modalConfig.mode !== this.modalTypes.add)
      this.getData();
    this.getShowTabActionPlan();
  }

  public getData(): void {
    this._getFormAllCombos();
    this._getFeedbackById();
    this._getObserverEnabled();
  }

  private _getObservers(): void {
    if (this.observerEnabled) {
      this.isLoadingObservers = true;
      this._combosService.getObservers().pipe(
        tap(observers =>
          this.optionsCombos = {
            ...this.optionsCombos,
            observers: observers
          }
        ),
        catchError(err => this._showError(err)),
        finalize(() => this.isLoadingObservers = false),
        takeUntil(this.destroy$),
      ).subscribe();
    }
  }

  private _getObserverEnabled(): void {
    this._globalDataPrefetching.data.bosShowOriginator.value$.pipe(
      takeUntil(this.destroy$),
      tap((value) => {
        this.observerEnabled = value;
        this._getObservers();
      }),
    ).subscribe();
  }

  private _getFeedbackById(): void {
    if (this.modalConfig.mode !== SvcModalBosType.add && this.modalConfig?.id) {
      this.isLoading = true;
      this._feedbackService.getFeedbackById(this.modalConfig.id).pipe(
        tap((bos: SvcBosFeedbackRegister) => {
          this.bos = bos;
          this._patchFormBos();
          this._getCustomFields();
          this._getPermissionsUser();
        }),
        catchError(err => this._showError(err)),
        takeUntil(this.destroy$)
      ).subscribe();
    }
  }

  private _getCustomFields(): void {
    this.customField.getCustomFieldByExternalReferenceId(this.bos.typeId, this.bos.feedbackId).pipe(
      catchError(err => this._showError(err)),
      takeUntil(this.destroy$),
      finalize(() => this.isLoading = false)
    ).subscribe();
  }

  private _getPermissionsUser(): void {
    if (this.modalConfig.mode === SvcModalBosType.view && !this.loadedPermissionUser) {
      this._accessPermissionService.getRolesByUser(StaticApplicationId.bos)
        .subscribe({
          next: (userRoles: UserRoleItem[]) => {
            this.loadedPermissionUser = true;
            const originatorId = this.formBos?.value?.creatorUserId?.[0]?.toLowerCase();
            const userId = this._authService.userId?.toLowerCase();

            this.userHasPermissionToEdit = userRoles.some((userRole: UserRoleItem) =>
              userRole.roleId === SvcModalBosUserRole.Administrator
            ) || originatorId === userId;
          },
          error: err => this._errorService.showErrorInToast(err)
        });
    }
  }

  private _getOptionsByBosCombo(keyName: string, keyValue: string): ISvcChipOption[] {
    return this.bos[keyName] ? [{ label: this.bos[keyName], value: this.bos[keyValue] }] : []
  }

  private _getPatchValue(keyName: string): number[] {
    return this.bos?.[keyName] ? [this.bos?.[keyName]] : [];
  }

  private _setCombosWithDefaultData(): void {
    if (this.isAllCombosLoading) {
      this.optionsCombos = {
        ...this.optionsCombos,
        teams: this._getOptionsByBosCombo('teamName', 'teamId'),
        contractors: this._getOptionsByBosCombo('contractorName', 'contractorId'),
        shifts: this.bos.shiftName ? [{ label: this._translocoService.translate(this.bos?.shiftName), value: this.bos?.shiftId }] : [],
      };
    }
  }

  private _patchFormBos(): void {
    this._setUserAvatar(
      this.bos?.userAvatar?.firstLastName,
      this.bos?.userAvatar?.pictureUrl,
      this.bos?.userAvatar?.preferenceColor,
    );

    this._setCombosWithDefaultData();

    this.setTypeSelected({
      label: this.bos.typeName,
      value: this.bos.typeId
    });

    this.formBos?.patchValue({
      ...this.bos,
      feedbackId: `${this._translocoService.translate('BOS')}-${this.bos?.feedbackId}`,
      locationId: this._getPatchValue('locationId'),
      teamId: this._getPatchValue('teamId'),
      shiftId: this._getPatchValue('shiftId'),
      contractorId: this._getPatchValue('contractorId'),
      openDate: this.bos?.openDate,
      feedbackDate: this.bos?.feedbackDate,
      creatorUserId: this._getPatchValue('creatorUserId')
    });

    this.formBos?.disable();
    this.formBos?.get('openDate')?.disable();
    this.formBos?.get('feedbackDate')?.disable();
    this.tabBehaviors.patchBehaviors(this.bos?.behaviors);
  }

  private _initFormBos(): void {
    this.formBos = this._fb.group({
      feedbackId: [`${this._translocoService.translate('BOS')}-`],
      locationId: [null, Validators.required],
      teamId: [null],
      shiftId: [null, Validators.required],
      contractorId: [null],
      openDate: [{ value: this._datePipe.transform(new Date(), 'yyyy-MM-dd'), disabled: true }],
      feedbackDate: [this._datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
      creatorUserId: [[this._userService.userId$], Validators.required],
    });
  }

  public setTypeSelected(type: ISvcChipOption): void {
    this.typeSelected = type;
    this.type.setValue(type.label);
  }

  private _setUserAvatar(name: string, picture: string, initialsColor: string): void {
    this.userAvatar = {
      name,
      picture,
      initialsColor,
      size: UserAvatarSize.Table
    };
  }

  private _getFormAllCombos(): void {
    this.isAllCombosLoading = true;
    this._combosService.getAllCombosToRegisterBos()
      .pipe(
        tap((combos: SvcModalBosCombos) => {
          this.optionsCombos = {
            ...this.optionsCombos,
            ...combos,
          };
        }),
        catchError(err => this._showError(err)),
        finalize(() => this.isAllCombosLoading = false),
        takeUntil(this.destroy$)
      ).subscribe();
  }

  private _showError(err: any): any {
    this._errorService.showErrorInToast(err);
    return err;
  }

  private _getBodyFeedback(isUpdate: boolean): SvcBosAddFeedback {
    const bodyFeedback: SvcBosAddFeedback = {
      feedbackDate: this.formBos?.value?.feedbackDate,
      typeId: Number(this.typeSelected?.value),
      openDate: this.formBos?.controls?.openDate?.value,
      locationId: this.formBos?.value?.locationId?.[0],
      behaviors: this.tabBehaviors.getFormValue(),
      contractorId: this.formBos?.value?.contractorId?.[0],
      shiftId: this.formBos?.value?.shiftId?.[0],
      teamId: this.formBos?.value?.teamId?.[0],
      creatorUserId: this.formBos?.value?.creatorUserId?.[0]
    };

    Object.keys(bodyFeedback)?.forEach(key => {
      if (!bodyFeedback?.[key])
        delete bodyFeedback?.[key];
    });

    if (isUpdate) {
      delete bodyFeedback?.typeId;
      delete bodyFeedback?.openDate;
      bodyFeedback.feedbackId = this.modalConfig.id;
    }

    return bodyFeedback;
  }

  public _getRequestToFeedback(isUpdate: boolean, bodyFeedback: SvcBosAddFeedback): Observable<SvcBosAddFeedbackResponse> {
    return isUpdate ?
      this._feedbackService.updateFeedback(bodyFeedback) :
      this._feedbackService.createFeedback(bodyFeedback);
  }

  private _successCreateOrUpdate(isUpdate: boolean, feedbackResponse: SvcBosAddFeedbackResponse): void {
    this.formBos?.controls?.feedbackId?.setValue(`${this._translocoService.translate('BOS')}-${feedbackResponse.feedbackId}`);
    this.modalConfig = {
      mode: SvcModalBosType.view,
      id: feedbackResponse.feedbackId
    };
    this._getPermissionsUser();
    const message = isUpdate ? 'Alteração salva com sucesso' : 'BOS incluído com sucesso';
    this._toastService.success(this._translocoService.translate(message));
    this.tabBehaviors.setBehaviorsHidden();
    this.formBos.disable();
    this._modalBosService.bosAdd$.next();
  }

  private _changeCustomFieldRecord(isUpdate: boolean, feedbackResponse: SvcBosAddFeedbackResponse): void {
    this.customField?.changeCustomFieldRecord(feedbackResponse.feedbackId, isUpdate).pipe(
      tap(() => this._successCreateOrUpdate(isUpdate, feedbackResponse)),
      catchError(err => this._showError(err)),
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  public createOrUpdateFeedback(isUpdate = false): void {
    this.formBos?.markAllAsTouched();

    if (this.formBos?.valid && this.customField?.formValid()) {
      this.isLoading = true;

      const bodyFeedback: SvcBosAddFeedback = this._getBodyFeedback(isUpdate);
      const request$ = this._getRequestToFeedback(isUpdate, bodyFeedback);
      request$.pipe(
        tap((feedbackResponse: SvcBosAddFeedbackResponse) => {
          this._dispatchEventToLegacy(bodyFeedback, feedbackResponse);
          this._changeCustomFieldRecord(isUpdate, feedbackResponse);
        }),
        catchError(err => {
          this.isLoading = false;
          return this._showError(err);
        }),
        takeUntil(this.destroy$)
      ).subscribe();
    }
  }
  private _dispatchEventToLegacy(bodyFeedback: SvcBosAddFeedback, feedbackResponse: SvcBosAddFeedbackResponse): void {
    window.parent.dispatchEvent(
      new CustomEvent(
        bodyFeedback?.feedbackId ? 'UpdateBosCallback' : 'CreateBosCallback',
        {
          detail: {
            typeName: this.typeSelected.label,
            feedbackId: feedbackResponse.feedbackId
          }
        }
      )
    );
  }


  public setEditMode(): void {
    if (this.modalConfig.mode === SvcModalBosType.view) {
      this.customField.formCustomFields.enable();
      this.tabBehaviors.showAllBehaviors();
      this.modalConfig.mode = SvcModalBosType.edit;
      this.formBos.enable();
      this.formBos.get('openDate').disable()
      this.formBos.get('feedbackDate').enable();
    }
    else
      this.createOrUpdateFeedback(true);
  }

  public deleteBos(): void {
    if (!this.userHasPermissionToEdit) return;

    const text = 'Tem certeza que deseja remover este BOS?';
    this._modalService.openConfirm(text).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this._feedbackService.deleteFeedback(this.modalConfig.id).pipe(
          tap(() => {
            this._modalBosService.bosAdd$.next();
            const message = this._translocoService.translate('BOS removido com sucesso.');
            this._toastService.success(message);
            this.dialogRef.close();
          }),
          catchError(err => this._showError(err)),
          finalize(() => this.isLoading = false),
          takeUntil(this.destroy$)
        ).subscribe();
      }
    });
  }

  private getShowTabActionPlan(): void {
    this.isShowTabActionPlanLoading = true;
    const prefetchingItem = this._globalDataPrefetching.data.bosShowTabActionPlan;
    if (prefetchingItem.isLoading)
      prefetchingItem.isLoading$.pipe(
        tap((showTabActionPlan: boolean) => {
          this.showTabActionPlan = showTabActionPlan;
        }),
        catchError(err => {
          this._errorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => this.isShowTabActionPlanLoading = false),
        takeUntil(this.destroy$),
      ).subscribe();
    else {
      this.showTabActionPlan = prefetchingItem.value;
      this.isShowTabActionPlanLoading = false;
    }
  }
}
