import { environment } from 'projects/environments/environment';
import { externalCommonAspURL, externalNetcoreURL } from 'projects/lib-shared-common/src/public-api';

export function canRedirectToAspFromQueryParamData(queryParamData: string) {
  return (queryParamData ?? '').split('/').length === 2;
}

export function canRedirectToCoreFromQueryParamData(queryParamData: string) {
  return (queryParamData ?? '').split('/').length === 4;
}

export function tryToRedirectToAspFromQueryParamData(queryParamData: string) {
  const splittedValues = (queryParamData ?? '').split('/');
  if (splittedValues.length === 2) {
    const aspParams = { system: splittedValues[0], redirectId: splittedValues[1] };
    const aspUrl = `${environment.isLocalhost ? 'https://devsp.solvacelabs.com' : location.origin}/systems/application/default.asp`;
    externalCommonAspURL(aspUrl, aspParams, false);  
    return true; 
  }
  return false;
}

export function tryToRedirectToCoreFromQueryParamData(queryParamData: string) {
  const splittedValues = (queryParamData ?? '').split('/');
  if (splittedValues.length === 4) { 
    const coreUrl = (environment.isLocalhost ? 'https://devsp.solvacelabs.com' : location.origin) + '/' + splittedValues[0];
    const controller = splittedValues[1]; 
    const action = splittedValues[2];
    const redirectId = splittedValues[3];
    externalNetcoreURL(coreUrl, `{controller=${controller}||action=${action}||variables={redirectId=${redirectId ?? 0}}}`, false);
    return true; 
  }
  return false;
}