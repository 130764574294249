<div 
  class="fadeIn flex flex-col sm:flex-row items-center sm:justify-center flex-auto min-w-0 h-full overflow-auto" 
  [ngClass]="{ 'md:items-start': !isSmScreen, 'items-center': isSmScreen }"
  *transloco="let t"
>
  <div 
    class="relative flex-auto items-center justify-center h-full bg-[#17457A]"
    [ngClass]="{ 'hidden': isSmScreen, 'md:flex': !isSmScreen }"
  >
    <div class="w-full h-full bg-cover" [style.background-image]="backgroundImg"></div>
  </div>
  <div 
    class="flex w-full h-full rounded-none shadow-none bg-card relative overflow-y-auto"
    [ngClass]="{ 'md:w-120': !isSmScreen }"
  >
    <div class="flex flex-col w-full min-h-full px-4 sm:px-12 md:px-16">
      <div
        class="flex-auto flex flex-col justify-center self-center w-full max-w-[360px] py-8 sm:py-12 md:py-16 md:pt-24"
        [ngClass]="{ 'max-w-[520px] md:py-16 md:pt-24': !isSmScreen }"
      >
        <!-- Logo -->
        <div class="mt-8 px-4">
          <img class="w-full" [src]="solvaceLogo$ | async"/>
        </div>
  
        <!-- Title -->
        <div class="mt-10 mb-5 text-center text-[#17457A] text-4xl font-extrabold tracking-tight leading-tight">
          {{ t("Alteração de Senha") }}
        </div>
        <div class="mb-8 text-center text-[#17457A] font-medium">
          {{ t("Defina uma nova senha para continuar") }}
        </div>
  
        <!-- Alert -->
        <svc-alert
          *ngIf="showAlert"
          [appearance]="'outline'"
          [showIcon]="false"
          [type]="alert.type"
          [@shake]="alert.type === 'error'"
        >
          {{ alert.message }}
        </svc-alert>
  
        <!-- Reset password form -->
        <form class="flex flex-col mt-6 space-y-6" [formGroup]="resetPasswordForm" #resetPasswordNgForm="ngForm">
          <!-- Verification code field -->
          <div class="flex flex-col mb-3" *ngIf="!changePasswordRequired">
            <mat-label class="flex-row-1 w-full mb-2">
              {{ t("Código de verificação") }}
            </mat-label>
            <div class="flex flex-row -mb-6">
              <mat-form-field class="flex mx-1">
                <input
                  #verificator1Input
                  (input)="handleFocusNextCode(verificator1Input?.value, verificator2Input)"
                  autofocus
                  type="text"
                  autocomplete="off"
                  class="text-center"
                  id="verficator1"
                  matInput
                  maxlength="1"
                  mask="0"
                  [formControlName]="'verificator1'"
                  (keyup.backspace)="
                    handleBackspace($event, verificator1Input, verificator1Input)
                  "
                  (paste)="handlePasteCode($event, verificator1Input)"
                />
              </mat-form-field>
              <mat-form-field class="flex mx-1">
                <input
                  #verificator2Input
                  (input)="handleFocusNextCode(verificator2Input?.value, verificator3Input)"
                  type="text"
                  autocomplete="off"
                  class="text-center"
                  id="verficator2"
                  matInput
                  maxlength="1"
                  mask="0"
                  [formControlName]="'verificator2'"
                  (keyup.backspace)="
                    handleBackspace($event, verificator2Input, verificator1Input)
                  "
                  (paste)="handlePasteCode($event, verificator2Input)"
                />
              </mat-form-field>
              <mat-form-field class="flex mx-1">
                <input
                  #verificator3Input
                  (input)="handleFocusNextCode(verificator3Input?.value, verificator4Input)"
                  type="text"
                  autocomplete="off"
                  class="text-center"
                  id="verficator3"
                  matInput
                  maxlength="1"
                  mask="0"
                  [formControlName]="'verificator3'"
                  (keyup.backspace)="
                    handleBackspace($event, verificator3Input, verificator2Input)
                  "
                  (paste)="handlePasteCode($event, verificator3Input)"
                />
              </mat-form-field>
              <mat-form-field class="flex mx-1">
                <input
                  #verificator4Input
                  (input)="handleFocusNextCode(verificator4Input?.value, i)"
                  type="text"
                  autocomplete="off"
                  class="text-center"
                  id="verficator4"
                  matInput
                  maxlength="1"
                  mask="0"
                  [formControlName]="'verificator4'"
                  (keyup.backspace)="
                    handleBackspace($event, verificator4Input, verificator3Input)
                  "
                  (paste)="handlePasteCode($event, verificator4Input)"
                />
              </mat-form-field>
              <mat-form-field class="flex mx-1">
                <input
                  #verificator5Input
                  (input)="handleFocusNextCode(verificator5Input?.value, verificator6Input)"
                  type="text"
                  autocomplete="off"
                  class="text-center"
                  id="verficator5"
                  matInput
                  maxlength="1"
                  mask="0"
                  [formControlName]="'verificator5'"
                  (keyup.backspace)="
                    handleBackspace($event, verificator5Input, verificator4Input)
                  "
                  (paste)="handlePasteCode($event, verificator5Input)"
                />
              </mat-form-field>
              <mat-form-field class="flex mx-1">
                <input
                  #verificator6Input
                  class="text-center"
                  type="text"
                  autocomplete="off"
                  id="verficator6"
                  matInput
                  maxlength="1"
                  mask="0"
                  [formControlName]="'verificator6'"
                  (keyup.backspace)="
                    handleBackspace($event, verificator6Input, verificator5Input)
                  "
                  (paste)="handlePasteCode($event, verificator6Input)"
                />
              </mat-form-field>
            </div>
          </div>
  
          <!-- Password field -->
          <svc-text class="w-full"
            [label]="t('Nova senha')"
            [formControl]="resetPasswordForm.controls.password"
            [isObscure]="true"
            type="password"
            autocomplete="new-password"
          ></svc-text>

          <!-- Password confirm field -->
          <svc-text class="w-full"
            [label]="t('Confirmação')"
            [formControl]="resetPasswordForm.controls.passwordConfirm"
            [isObscure]="true"
            type="password"
            autocomplete="new-password"
          ></svc-text>

          <user-password-validator
            [control]="resetPasswordForm.controls.password"
          ></user-password-validator>

          <!-- Submit button -->
          <button
            type="button"
            class="w-full text-white remove-text-disabled-color remove-text-disabled-color"
            mat-flat-button
            [ngClass]="{'bg-zinc-400': resetPasswordForm.invalid, 'bg-[#21295C]': resetPasswordForm.valid}"
            [disabled]="resetPasswordForm.invalid"
            (click)="changePasswordRequired ? changeTemporaryPassword() : resetPassword()"
          >
            <ng-container *ngIf="!resetPasswordForm.disabled">
              {{ t("Confirmar") }}
            </ng-container>
            <mat-progress-spinner
              *ngIf="resetPasswordForm.disabled"
              [diameter]="24"
              [mode]="'indeterminate'"
            ></mat-progress-spinner>
          </button>

          <button
            type="button"
            [routerLink]="['/sign-in']"
            class="w-full bg-zinc-100 text-black"
            mat-flat-button
          >
            {{ t("Voltar") }}
          </button>
  
        </form>
      </div>
    </div>
  </div>
</div>
