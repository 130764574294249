import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { SvcHttpClient } from "projects/lib-shared-common/src/public-api";
import { CommonPagination, CommonPaginationRequest } from "projects/lib-shared-model/src/public-api";
import { Observable, map, switchMap } from "rxjs";
import { SvcComment } from "../models/comment.model";

interface GetCommentsParamsUid extends CommonPaginationRequest {
  referenceUid: string;
  commentConfigTypeId: string;
  externalSiteId?: number;
}
interface GetParentCommentsParams extends CommonPaginationRequest {
  commentConfigTypeId: string;
}

interface GetCommentsParamsId extends CommonPaginationRequest {
  referenceId: number;
  commentConfigTypeId: string;
  externalSiteId?: number;
}

interface GetTotalCommentsByIdParams extends CommonPaginationRequest {
  commentParentId: number;
  commentConfigTypeId: string;
  externalSiteId?: number;
}
interface GetTotalCommentsByUidParams extends CommonPaginationRequest {
  commentParentId: string;
  commentConfigTypeId: string;
  externalSiteId?: number;
}

interface CreateCommentParams {
  referenceId: number | string;
  siteId: number;
  configTypeId: string;
  commentText: string;
  mentionUsers: string[];
  mentionTeams: string[];
  mentionHashTags: string[];
  attachments: Blob[];
}

interface CreateAnswerParams {
  siteId: number;
  configTypeId: string;
  commentText: string;
  mentionUsers: string[];
  mentionTeams: string[];
  mentionHashTags: string[];
  attachments: Blob[];
}

interface GetMentionSuggestionParams {
  commentId?: string | number;
}

@Injectable()
export class SvcCommentsService extends SvcHttpClient {
  constructor(
    protected _appConfig: AppEnvironmentConfig,
    protected _httpClient: HttpClient,
  ) {
    super(
      _appConfig.APIs.apiUrlComments,
      _httpClient
    );
  }

  public getCommentsId(params: GetCommentsParamsId, external: boolean = false): Observable<CommonPagination<SvcComment[]>> {
    if ('externalSiteId' in params && !params.externalSiteId) delete params.externalSiteId;
    return this.get(`/CommentByReferenceId${external ? '/external' : ''}`, {
      params: {
        sortDirection: 2,
        ...params
      }
    });
  }

  public getCommentsUid(params: GetCommentsParamsUid, external: boolean = false): Observable<CommonPagination<SvcComment[]>> {
    if ('externalSiteId' in params && !params.externalSiteId) delete params.externalSiteId;
    return this.get(`/CommentByReferenceUId${external ? '/external' : ''}`, {
      params: {
        sortDirection: 2,
        ...params
      }
    });
  }

  public getParentComments(commentParentId: number, params: GetParentCommentsParams): Observable<CommonPagination<SvcComment[]>> {
    return this.get(`/Comment/Answer/${commentParentId}`, {
      params: {
        sortDirection: 2,
        ...params
      }
    });
  }

  public getTotalCommentsById(params: GetTotalCommentsByIdParams, external: boolean): Observable<number> {
    if ('externalSiteId' in params && !params.externalSiteId) delete params.externalSiteId;
    return this.get<number>(`/CommentByReferenceId${external ? '/external' : ''}/counter`, {
      params: params as any
    });
  }

  public getTotalCommentsByUid(params: GetTotalCommentsByUidParams, external: boolean): Observable<number> {
    if ('externalSiteId' in params && !params.externalSiteId) delete params.externalSiteId;
    return this.get<number>(`/CommentByReferenceUId${external ? '/external' : ''}/counter`, {
      params: params as any
    });
  }

  public createComment(params: CreateCommentParams): Observable<SvcComment> {
    let form = new FormData();
    form.append(typeof params.referenceId === 'number' ? 'referenceId' : 'referenceUId', params.referenceId.toString());
    form.append('referenceSiteId', params.siteId.toString());
    form.append('commentConfigTypeId', params.configTypeId);
    form.append('commentText', params.commentText ?? '');
    params.mentionUsers.forEach((item) => form.append('mentionUsers', item));
    params.mentionTeams.forEach((item) => form.append('mentionTeams', item));
    params.mentionHashTags.forEach((item) => form.append('mentionHashTags', item));
    params.attachments.forEach((item) => form.append('attachments', item));

    const path = typeof params.referenceId === 'number' ? '/CommentByReferenceId' : '/CommentByReferenceUId';
    return this.post(path, form).pipe(
      switchMap((id: number) => this.getCommentById(id)),
    );
  }

  public createAnswer(parentId: number, params: CreateAnswerParams): Observable<SvcComment> {
    let form = new FormData();
    form.append('referenceSiteId', params.siteId.toString());
    form.append('commentConfigTypeId', params.configTypeId);
    form.append('commentText', params.commentText ?? '');
    params.mentionUsers.forEach((item) => form.append('mentionUsers', item));
    params.mentionTeams.forEach((item) => form.append('mentionTeams', item));
    params.mentionHashTags.forEach((item) => form.append('mentionHashTags', item));
    params.attachments.forEach((item) => form.append('attachments', item));

    return this.post(`/Comment/Answer/${parentId}`, form).pipe(
      switchMap((id: number) => this.getCommentById(id)),
    );
  }

  public getCommentById(commentId: number): Observable<SvcComment> {
    return this.get<SvcComment>(`/Comment/${commentId}`);
  }

  public removeComment(commentId: number): Observable<void> {
    return this.delete(`/Comment/${commentId}`);
  }

  public translateTextComment(text: string): Observable<string> {
    return this.get<{ originalText: string, translateText: string }[]>(`/Translate`, {
      params: {
        terms: text
      }
    }).pipe(
      map(x => x[0].translateText)
    );
  }

  public getHashtags(filter: string): Observable<any> {
    return this._httpClient.get(`${this._appConfig.APIs.apiUrlHashtag}/Post/Paginated`, {
      params: {
        name: filter,
        active: true,
        pageIndex: 1,
        pageSize: 5
      }
    });
  }

  public getCommentMentionSuggestion(params: GetMentionSuggestionParams)
  {
    return this.get<{suggestionTeams: [{
        teamId: string;
        teamName: string
      }],
      suggestionUsers: [{
        userId: string;
        userName: string;
      }]}>(`/Comment/mentionsuggestion`, {params: params as any});
  }
}
