import { Injectable } from '@angular/core';
import { SvcDataTableComponent } from '../svc-data-table/svc-data-table.component';
import { SvcDataRenderEvent } from '../events/svc-data-render.event';
import { SvcFunctionsHelper, offsetToParent } from 'projects/lib-shared-common/src/public-api';

export const DEFAULT_ROW_HEIGHT = 52;

@Injectable()
export class SvcDataVirtualScrollService {

	constructor(private helper: SvcFunctionsHelper) { }

	public defineItens(table: SvcDataTableComponent, options?: { force?: boolean }) {
		const vs = table.virtualScroll;
		const qttyCanAppear = this.howManyCanAppear(table);
		const dimensions = this.defineDimensions(table, { qttyToBeRendered: qttyCanAppear });

		const newPaddingScroll = dimensions.paddingTop;
		const initialHiddenItems = dimensions.hiddenItems ?? 0;
		const newTotalHeightContent = dimensions.heightAllItems;
		const newCurrentStartIndex = dimensions.itemsThatAreGone;
		const newCurrentEndIndex = Math.min(vs.currentStartIndex + initialHiddenItems + qttyCanAppear + qttyCanAppear, table.filteredItems.length);

		if (
			(options?.force ?? false) ||
			newPaddingScroll !== vs.paddingScroll ||
			newTotalHeightContent !== vs.totalHeightContent ||
			newCurrentStartIndex !== vs.currentStartIndex ||
			newCurrentEndIndex !== vs.currentEndIndex
		) {
			vs.paddingScroll = newPaddingScroll;
			vs.totalHeightContent = newTotalHeightContent;
			vs.currentStartIndex = newCurrentStartIndex;
			vs.currentEndIndex = newCurrentEndIndex;
			table.items = table.filteredItems.slice(vs.currentStartIndex, vs.currentEndIndex);
			table.isDataEmpty = table.items.length === 0;

			table.onDataRender.emit(
				new SvcDataRenderEvent<any>({
					items: table.items,
					length: table.items.length,
					startIndex: vs.currentStartIndex,
					endIndex: vs.currentEndIndex,
				})
			);
		}
	}

	public onScrollChange(table: SvcDataTableComponent) {
		this.definepreviousItemsHeight(table);
		this.defineItens(table);
	}

	public definepreviousItemsHeight(table: SvcDataTableComponent) {
		let vs = table.virtualScroll;
		const rows = Array.from(table.body?.itemsContainerEl?.querySelectorAll('svc-data-row') ?? []);
		for (let i = 0; i < rows.length; i++) {
			let children = rows[i];
			let realIndex = vs.currentStartIndex + i;
			if (!vs.previousItemsHeight[realIndex] || vs.previousItemsHeight[realIndex] !== children.clientHeight) {
				vs.previousItemsHeight[realIndex] = children.getBoundingClientRect().height;
			}
		}
	}

	public reset(table: SvcDataTableComponent) {
		let vs = table.virtualScroll;
		vs.previousItemsHeight = [];
		vs.totalHeightContent = 0;
		vs.paddingScroll = 0;
	}

	private defineDimensions(table: SvcDataTableComponent, info: { qttyToBeRendered: number }) {
		let vs = table.virtualScroll;
		const rowHeight = this.getRowHeight(table);
		let firstVisibleRowHeight = rowHeight;

		if (vs.currentStartIndex < vs.previousItemsHeight.length) {
			let height = vs.previousItemsHeight[vs.currentStartIndex];
			firstVisibleRowHeight = height ? height : rowHeight;
		}

		const elementScrolling = vs.containerSrollElRef()?.nativeElement;
		const bodyToParentOffset = table.useParentScroll
			? offsetToParent(table.el, elementScrolling) + (table.header.el?.clientHeight ?? 0)
			: 0;
		const currentSrollTop = vs.currentSrollTop - bodyToParentOffset;

		let obj = {
			paddingTop: 0,
			itemsThatAreGone: 0,
			hiddenItems: 0,
			heightAllItems: table.filteredItems.reduce((prev, _, i) =>  prev + (vs.previousItemsHeight[i] ?? rowHeight), 0),
		};

		if (currentSrollTop > 0) {
			let calculationPerItem: {
				paddingTop: number,
				itemsThatAreGone: number,
			}[] = [];
			let lastCalculationItem = {
				paddingTop: 0,
				itemsThatAreGone: 0,
			};

			let scrollCalc = currentSrollTop;
			for (let i = 0; i < vs.previousItemsHeight.length; i++) {
				const height = vs.previousItemsHeight[i] ?? rowHeight;
				if (scrollCalc >= height) {
					const { paddingTop, itemsThatAreGone } = lastCalculationItem;
					calculationPerItem[i] = {
						paddingTop: paddingTop + height,
						itemsThatAreGone: itemsThatAreGone + 1,
					};
					scrollCalc -= height;
					lastCalculationItem = calculationPerItem[i];
					continue;
				}
				const indexNeedsToGoBack = Math.max(i - (Math.max(info.qttyToBeRendered ?? 0, 0)), 0);
				obj.hiddenItems = i - indexNeedsToGoBack;
				if (indexNeedsToGoBack < calculationPerItem.length) {
					lastCalculationItem = calculationPerItem[indexNeedsToGoBack];
				}
				if (obj.hiddenItems < info.qttyToBeRendered) {
					lastCalculationItem.paddingTop = 0;
					lastCalculationItem.itemsThatAreGone = 0;
				}
				break;
			}

			obj.paddingTop = lastCalculationItem.paddingTop;
			obj.itemsThatAreGone = lastCalculationItem.itemsThatAreGone;
		}

		return obj;
	}

	public preparePreviousItemAfterDataChange(table: SvcDataTableComponent) {
		let vs = table.virtualScroll;

		if (!table.infinite) vs.previousItemsHeight = new Array(table.filteredItems.length).fill(null);
		else {
			let anothers = table.filteredItems.length - vs.previousItemsHeight.length;
			if (anothers > 0)
				vs.previousItemsHeight = [...vs.previousItemsHeight, ...new Array<number>(anothers).fill(null)];
		}
	}

	public canApplyVirtualScroll(table: SvcDataTableComponent): boolean {
		const tableInputHeight = table._height ?? 'auto';

		const vs = table.virtualScroll;
		if (tableInputHeight !== 'auto' || table.useParentScroll) {
			return true;
		}
		else if (!table.useParentScroll && vs.height > 0) {
			const topHeight = table.header.el?.clientHeight ?? 0;
			const bottomHeight = table.paginateComp?.el?.clientHeight ?? 0;
			const availableHeight = Math.max(0, vs.height - (topHeight + bottomHeight));
			if (availableHeight > 0) {
				const rowHeight = this.getRowHeight(table);
				const avaiableScrollHeight = Math.max(0, vs.scrollHeight - (topHeight + bottomHeight));
				const scrollableHeight = avaiableScrollHeight - availableHeight;

				if (scrollableHeight > rowHeight) {
					return (scrollableHeight / rowHeight) >= 1.5;
				}
			}
		}
		return false;
	}

	private howManyCanAppear(table: SvcDataTableComponent): number {
		const vs = table.virtualScroll;
		const rowHeight = this.getRowHeight(table);
		const topHeight = !table.useParentScroll ? (table.header.el?.clientHeight ?? 0) : 0;
		const bottomHeight = !table.useParentScroll ? (table.paginateComp?.el?.clientHeight ?? 0) : 0;
		const availableHeight = Math.max(0, vs.height - (topHeight + bottomHeight));
		let height = availableHeight ? availableHeight : parseInt(this.helper.onlyNumbers(table._height));

		let decimalQtty = (isNaN(height) ? 0 : height) / rowHeight;
		let intQtty = Math.floor(decimalQtty);
		return intQtty + (decimalQtty - intQtty > 0 ? 2 : 1);
	}

	private getRowHeight(table: SvcDataTableComponent): number {
		return parseInt(this.helper.onlyNumbers(table.body?.rowHeight ?? DEFAULT_ROW_HEIGHT.toString()));
	}
}
