import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, map, tap } from 'rxjs';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { LanguagesService } from '../languages/services/languages.service';
import { StartupConfigService } from 'projects/config/startup-config.service';

export enum TranslocoTermsStatus {
  PENDING,
  LOADED,
}

@Injectable({
	providedIn: 'root'
})
export class TranslocoApiLoader implements TranslocoLoader {
	private langTerms: { [lang: string]: { loading: boolean, terms: any } } = {};

	private _termsStatus$ = new BehaviorSubject(TranslocoTermsStatus.PENDING);
	public termsStatus$ = this._termsStatus$.asObservable();

	/**
	 * Constructor
	 */
	constructor(
		private _languagesService: LanguagesService,
		private _startupConfigService: StartupConfigService,
	) {
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------
	
	reset(): void {
		this._termsStatus$.next(TranslocoTermsStatus.PENDING);
	}

	getTranslation(lang: string): Observable<Translation> {
		this.langTerms[lang] = {
			loading: true,
			terms: null,
		}
		const response = this._languagesService.getTerms(lang);
		return response.pipe(
			map((res) => {
				this.langTerms[lang].terms = res.reduce((terms, term) => {
					terms[term.tn] = term.tv;
					return terms;
				}, {});
				return this.langTerms[lang].terms;
			}),
			finalize(() => {
				this.langTerms[lang].loading = false;
				this._termsStatus$.next(TranslocoTermsStatus.LOADED);
				this._startupConfigService.initialized();
			})
		);
	}

	public isAlreadyLoaded(lang: string) {
		return this.langTerms[lang] && !this.langTerms[lang].loading;
	}
}
