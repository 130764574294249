<div>
  <div>
    <quill-editor
      #editor
      class="mentions-editor w-full "
      [class.readonly]="readOnly"
      [class.cursor-force]="cursorForce"
      [class.line-clamp]="lineClamp"
      classes="mentions-container"
      [modules]="modules"
      [(ngModel)]="value"
      [readOnly]="readOnly"
      [placeholder]="!readOnly ? placeholder : null"
      (ngModelChange)="onChange(filterValue(value))"
      (onContentChanged)="contentChanged($event)"
      [id]="inputId"
      (onContentChanged)="contentChanged($event)"
    ></quill-editor>
  </div>

</div>
