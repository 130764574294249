import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SvcDataTableAccordionComponent } from './svc-data-table-accordion.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { SvcButtonsModule } from '../svc-buttons/svc-buttons.module';
import { SvcStatusBadgeModule } from '../svc-status-badge/svc-status-badge.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SvcSearchFieldModule } from '../svc-search-field/svc-search-field.module';
import { SvcProgressBarModule } from '../svc-progress-bar/svc-progress-bar.module';

@NgModule({
  declarations: [
    SvcDataTableAccordionComponent
  ],
  imports: [
    CommonModule,
    LibSharedCommonModule,
    LibSharedIconsModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    SvcButtonsModule,
    SvcStatusBadgeModule,
    NgxSkeletonLoaderModule,
    SvcSearchFieldModule,
    MatTooltipModule,
    SvcProgressBarModule
  ],
  exports: [
    SvcDataTableAccordionComponent
  ]
})
export class SvcDataTableAccordionModule { }
