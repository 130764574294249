import { HttpClient, HttpContext, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { Observable } from 'rxjs';

export const SKIP_COLLECTION_HEADER = 'SkipRequestCollection';
export const UNAUTHORIZED_REQUEST_HEADER = 'SvcUnauthorizedRequest';

@Injectable({ providedIn: 'root' })
export class SvcHttpClient {

	constructor(
		@Optional() protected _baseUrl: String,
		protected _httpClient: HttpClient,
	) { }

	public get<T>(path: string, options?: {
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		context?: HttpContext;
		observe?: 'body' | 'response';
		params?: HttpParams | {
			[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		};
		reportProgress?: boolean;
		responseType?: 'json' | 'arraybuffer' | 'blob' | any;
		withCredentials?: boolean;
		skipRequestCollector?: boolean;
	}): Observable<T> {
		options = this._checkOptions(options);
		return this._httpClient.get<T>(`${this._baseUrl ?? ''}${path}`, options as any) as Observable<T>;
	}

	public post<T>(path: string, body: any | null, options?: {
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		context?: HttpContext;
		observe?: 'body' | 'response';
		params?: HttpParams | {
			[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		};
		reportProgress?: boolean;
		responseType?: 'json' | 'arraybuffer' | 'blob' | any;
		withCredentials?: boolean;
		skipRequestCollector?: boolean;
	}): Observable<T> {
		options = this._checkOptions(options);
		return this._httpClient.post<T>(`${this._baseUrl ?? ''}${path}`, body, options as any) as Observable<T>;
	}

	public put<T>(path: string, body: any | null, options?: {
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		context?: HttpContext;
		observe?: 'body' | 'response';
		params?: HttpParams | {
			[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		};
		reportProgress?: boolean;
		responseType?: 'json' | 'arraybuffer' | 'blob' | any;
		withCredentials?: boolean;
		skipRequestCollector?: boolean;
	}): Observable<T> {
		options = this._checkOptions(options);
		return this._httpClient.put<T>(`${this._baseUrl ?? ''}${path}`, body, options as any) as Observable<T>;
	}

	public patch<T>(path: string, body: any | null, options?: {
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		context?: HttpContext;
		observe?: 'body' | 'response';
		params?: HttpParams | {
			[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		};
		reportProgress?: boolean;
		responseType?: 'json' | 'arraybuffer' | 'blob' | any;
		withCredentials?: boolean;
		skipRequestCollector?: boolean;
	}): Observable<T> {
		options = this._checkOptions(options);
		return this._httpClient.patch<T>(`${this._baseUrl ?? ''}${path}`, body, options as any) as Observable<T>;
	}

	public delete<T>(path: string, options?: {
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		context?: HttpContext;
		observe?: 'body' | 'response';
		params?: HttpParams | {
			[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		};
		reportProgress?: boolean;
		responseType?: 'json' | 'arraybuffer' | 'blob' | any;
		withCredentials?: boolean;
		skipRequestCollector?: boolean;
		body?: any | null;
	}): Observable<T> {
		options = this._checkOptions(options);
		return this._httpClient.delete<T>(`${this._baseUrl ?? ''}${path}`, options as any) as Observable<T>;
	}

	public request<T>(method: string, path: string, options?: {
		body?: any;
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		context?: HttpContext;
		observe?: 'body';
		params?: HttpParams | {
			[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		};
		reportProgress?: boolean;
		responseType?: 'json' | 'arraybuffer' | 'blob' | any;
		withCredentials?: boolean;
		skipRequestCollector?: boolean;
	}): Observable<T> {
		options = this._checkOptions(options);
		return this._httpClient.request<T>(method, `${this._baseUrl ?? ''}${path}`, options as any) as Observable<T>;
	}

	public unauthorizedGet<T>(path: string, options?: {
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		context?: HttpContext;
		observe?: 'body' | 'response';
		params?: HttpParams | {
			[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		};
		reportProgress?: boolean;
		responseType?: 'json' | 'arraybuffer' | 'blob' | any;
	}): Observable<T> {
		options = this._checkOptions({
			...(options ?? {}),
			unauthorized: true,
		});
		return this.get<T>(`${this._baseUrl ?? ''}${path}`, options);
	}

	private _checkOptions(options: any) {
		options = options ?? {};
		let headers = options.headers ?? {};
		let initialHeaders = headers;
		let hasInitialHeaders = typeof headers === 'object';
		const addHeader = (key: string, value?: string) => {
			if (headers instanceof HttpHeaders) {
				headers = headers.set(key, value ?? '');
			}
			else {
				headers = new HttpHeaders().set(key, value ?? '');
				if (hasInitialHeaders) {
					Object.keys(initialHeaders).forEach((key) => headers = headers.set(key, initialHeaders[key]))
				}
			}
			hasInitialHeaders = false;
			options.headers = headers;
		};
		if (options?.skipRequestCollector) {
			addHeader(SKIP_COLLECTION_HEADER);
		}
		if (options?.unauthorized) {
			addHeader(UNAUTHORIZED_REQUEST_HEADER);
			options = {
				...options,
				withCredentials: false,
			};
		}
		return options;
	}
}
